import getConfig from 'utils/env';
import httpFacade from 'utils/httpFacade';

const { baseURL } = getConfig();

const http = new httpFacade(baseURL + '/v1/');

export const account_async = async (payload) =>
  await http.get({ url: `accounts/${payload}-account` });
export const account_transactions_async = async (payload) =>
  await http.get({ url: 'wallet/account-transactions', query: payload });
export const bank_account_details_async = async () =>
  await http.get({ url: 'bank-account-details' });
export const log_fund_async = async ({ filter, body }) =>
  await http.post({ url: `log-fund?filter=${filter}`, body });
