import React, { createContext } from 'react';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const logout = () => {
    const lang = localStorage.getItem('_ral');
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('_ral', lang || 'en');
    window.location.reload();
  };

  return <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
