import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  SHOP_PRODUCTS,
  SHOP_PRODUCT_ITEMS,
  SHOP_PRODUCT_ITEM_PACKAGES,
  SHOP_PURCHASE,
  SHOP_BENEFICIARIES,
  SUPPORTED_NETWORK,
  TOP_UP_AIRTIME,
  DATA_PACKAGES,
  TOP_UP_DATA,
  CABLE_TV_PROVIDER,
  CABLE_TV_PACKAGE,
  VERIFY_SMART_CARD,
  CABLE_TV_TOP_UP,
  PRODUCT_ITEMS,
  EDUCATION_TOP_UP,
  IROKO_PROVIDER,
  TOP_UP_IROKO,
  PAN_AFRICAN_AIRTIME_PRE,
  PAN_AFRICAN_AIRTIME_TOP_UP,
  ACTIVE_PRODUCT,
  PURCHASE_POWER,
  CHECK_METER
} from 'constants/actionTypes';

import {
  shop_products_failed,
  shop_products_success,
  shop_product_item_packages_success,
  shop_product_item_packages_failed,
  shop_product_items_success,
  shop_product_items_failed,
  set_from_country,
  shop_purchase_success,
  shop_purchase_failed,
  shop_beneficaries_get_success,
  shop_beneficaries_get_failed,
  shop_beneficaries_post_success,
  shop_beneficaries_post_failed,
  supported_network_success,
  supported_network_failed,
  top_up_airtime_success,
  top_up_airtime_failed,
  data_packages_get_success,
  data_packages_get_failed,
  top_up_data_success,
  top_up_data_failed,
  cable_tv_provider_success,
  cable_tv_provider_failed,
  cable_tv_package_success,
  cable_tv_package_failed,
  verify_smart_card_success,
  verify_smart_card_failed,
  cable_tv_top_up_success,
  cable_tv_top_up_failed,
  product_items_get_success,
  product_items_get_failed,
  education_top_up_success,
  education_top_up_failed,
  iroko_provider_get_success,
  iroko_provider_get_failed,
  top_up_iroko_success,
  top_up_iroko_failed,
  pan_african_airtime_pre_success,
  pan_african_airtime_pre_failed,
  pan_african_airtime_top_up_success,
  pan_african_airtime_top_up_failed,
  active_product_success,
  active_product_failed,
  check_meter_success,
  check_meter_failed,
  purchase_power_success,
  purchase_power_failed
} from './actions';

import * as Api from './api';

//#region SHOP_PRODUCTS_REQUEST
function* shop_products_request() {
  try {
    yield put(showLoading());
    const lang = localStorage.getItem('_ral') || 'en';
    const res = yield call(Api.shop_products_async, { lang });
    sessionStorage.setItem('_rasp', JSON.stringify(res.data.products));
    yield put(shop_products_success(res.data.products));
  } catch (error) {
    if (error?.data?.error) {
      yield put(shop_products_failed(error.data.error));
    } else {
      yield put(shop_products_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_products_request() {
  yield takeEvery(SHOP_PRODUCTS.GET_REQUEST, shop_products_request);
}
//#endregion

//#region SHOP_PRODUCT_ITEMS_REQUEST
function* shop_product_items_request({ payload }) {
  try {
    yield put(showLoading());
    payload.lang = localStorage.getItem('_ral') || 'en';
    const res = yield call(Api.shop_product_items_async, payload);
    yield put(shop_product_items_success(res.data.items));
    if (res.data.country) {
      yield put(set_from_country(res.data.country));
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(shop_product_items_failed(error.data.error));
    } else {
      yield put(shop_product_items_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_product_items_request() {
  yield takeEvery(SHOP_PRODUCT_ITEMS.POST_REQUEST, shop_product_items_request);
}
//#endregion

//#region SHOP_PRODUCT_ITEM_PACKAGES_REQUEST
function* shop_product_item_packages_request({ payload }) {
  try {
    yield put(showLoading());
    payload.lang = localStorage.getItem('_ral') || 'en';
    const res = yield call(Api.shop_product_item_packages_async, payload);
    yield put(shop_product_item_packages_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(shop_product_item_packages_failed(error.data.error));
    } else {
      yield put(shop_product_item_packages_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_product_item_packages_request() {
  yield takeEvery(SHOP_PRODUCT_ITEM_PACKAGES.POST_REQUEST, shop_product_item_packages_request);
}
//#endregion

//#region SHOP_PURCHASE_REQUEST
function* shop_purchase_request({ payload }) {
  try {
    yield put(showLoading());
    payload.lang = localStorage.getItem('_ral') || 'en';
    const res = yield call(Api.shop_purchase_async, payload);
    if (res?.data?.code === 200) {
      yield put(shop_purchase_success(res.data.message));
    } else {
      yield put(shop_purchase_failed({ code: 500, id: 'shop.purchase.error.500' }));
    }
  } catch (error) {
    if (error?.data?.code === 422) {
      let errors = Object.values(error.data.error);
      errors = errors.map((error) => error[0])?.[0];
      yield put(shop_purchase_failed({ code: 422, mgs: errors }));
    } else {
      yield put(shop_purchase_failed({ code: 500, id: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_purchase_request() {
  yield takeEvery(SHOP_PURCHASE.POST_REQUEST, shop_purchase_request);
}
//#endregion

//#region SHOP_BENEFICIARIES_POST_REQUEST
function* shop_beneficaries_post_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.shop_beneficaries_post_async, payload);
    yield put(shop_beneficaries_post_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(shop_beneficaries_post_failed(error.data.error));
    } else {
      yield put(shop_beneficaries_post_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_beneficaries_post_request() {
  yield takeEvery(SHOP_BENEFICIARIES.POST_REQUEST, shop_beneficaries_post_request);
}
//#endregion

//#region SHOP_BENEFICIARIES_GET_REQUEST
function* shop_beneficaries_get_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.shop_beneficaries_get_async, payload);
    yield put(shop_beneficaries_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(shop_beneficaries_get_failed(error.data.error));
    } else {
      yield put(shop_beneficaries_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_shop_beneficaries_get_request() {
  yield takeEvery(SHOP_BENEFICIARIES.GET_REQUEST, shop_beneficaries_get_request);
}
//#endregion

//#region SUPPORTED_NETWORK_REQUEST
function* supported_network_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.supported_network_async, payload);
    yield put(supported_network_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(supported_network_failed(error.data.error));
    } else {
      yield put(supported_network_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_supported_network_request() {
  yield takeEvery(SUPPORTED_NETWORK.GET_REQUEST, supported_network_request);
}
//#endregion

//#region TOP_UP_AIRTIME_REQUEST
function* top_up_airtime_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.top_up_airtime_async, payload);
    yield put(top_up_airtime_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(top_up_airtime_failed(error.data.error));
    } else {
      yield put(top_up_airtime_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_top_up_airtime_request() {
  yield takeEvery(TOP_UP_AIRTIME.POST_REQUEST, top_up_airtime_request);
}
//#endregion

//#region DATA_GET_REQUEST
function* data_packages_get_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.active_data_bundle_packages_async, payload);
    yield put(data_packages_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(data_packages_get_failed(error.data.error));
    } else {
      yield put(data_packages_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_data_packages_get_request() {
  yield takeEvery(DATA_PACKAGES.GET_REQUEST, data_packages_get_request);
}
//#endregion

//#region TOP_UP_DATA_REQUEST
function* top_up_data_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.top_up_data_async, payload);
    yield put(top_up_data_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(top_up_data_failed(error.data.error));
    } else {
      yield put(top_up_data_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_top_up_data_request() {
  yield takeEvery(TOP_UP_DATA.POST_REQUEST, top_up_data_request);
}
//#endregion

//#region CABLE_TV_PROVIDER_REQUEST
function* cable_tv_provider_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.cable_tv_provider_async, payload);
    yield put(cable_tv_provider_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(cable_tv_provider_failed(error.data.error));
    } else {
      yield put(cable_tv_provider_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_cable_tv_provider_request() {
  yield takeEvery(CABLE_TV_PROVIDER.GET_REQUEST, cable_tv_provider_request);
}
//#endregion

//#region CABLE_TV_PACKAGE_REQUEST
function* cable_tv_package_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.cable_tv_package_async, payload);
    yield put(cable_tv_package_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(cable_tv_package_failed(error.data.error));
    } else {
      yield put(cable_tv_package_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_cable_tv_package_request() {
  yield takeEvery(CABLE_TV_PACKAGE.GET_REQUEST, cable_tv_package_request);
}
//#endregion

//#region VERIFY_SMART_CARD_REQUEST
function* verify_smart_card_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.verify_smart_card_async, payload);
    if (!res.data.code) {
      yield put(verify_smart_card_failed('Please Provide a valid smart card'));
    } else {
      yield put(verify_smart_card_success(res.data));
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(verify_smart_card_failed(error.data.error));
    } else {
      yield put(verify_smart_card_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_verify_smart_card_request() {
  yield takeEvery(VERIFY_SMART_CARD.POST_REQUEST, verify_smart_card_request);
}
//#endregion

//#region CABLE_TV_TOP_UP_REQUEST
function* cable_tv_top_up_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.cable_tv_top_up_async, payload);
    yield put(cable_tv_top_up_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(cable_tv_top_up_failed(error.data.error));
    } else {
      yield put(cable_tv_top_up_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_cable_tv_top_up_request() {
  yield takeEvery(CABLE_TV_TOP_UP.POST_REQUEST, cable_tv_top_up_request);
}
//#endregion

//#region PRODUCT_ITEMS_GET_REQUEST
function* product_items_get_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.product_items_get_async, payload);
    yield put(product_items_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(product_items_get_failed(error.data.error));
    } else {
      yield put(product_items_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_product_items_get_request() {
  yield takeEvery(PRODUCT_ITEMS.GET_REQUEST, product_items_get_request);
}
//#endregion

//#region EDUCATION_TOP_UP_REQUEST
function* education_top_up_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.education_top_up_async, payload);
    yield put(education_top_up_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(education_top_up_failed(error.data.error));
    } else {
      yield put(education_top_up_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_education_top_up_request() {
  yield takeEvery(EDUCATION_TOP_UP.POST_REQUEST, education_top_up_request);
}
//#endregion

//#region PRODUCT_ITEMS_GET_REQUEST
function* iroko_provider_get_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.iroko_provider_get_async, payload);
    yield put(iroko_provider_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(iroko_provider_get_failed(error.data.error));
    } else {
      yield put(iroko_provider_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_iroko_provider_get_request() {
  yield takeEvery(IROKO_PROVIDER.GET_REQUEST, iroko_provider_get_request);
}
//#endregion

//#region TOP_UP_IROKO_REQUEST
function* top_up_iroko_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.top_up_iroko_async, payload);
    yield put(top_up_iroko_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(top_up_iroko_failed(error.data.error));
    } else {
      yield put(top_up_iroko_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_top_up_iroko_request() {
  yield takeEvery(TOP_UP_IROKO.POST_REQUEST, top_up_iroko_request);
}
//#endregion

//#region PAN_AFRICAN_AIRTIME_PRE_REQUEST
function* pan_african_airtime_pre_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.pan_african_airtime_pre_async, payload);
    yield put(pan_african_airtime_pre_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(pan_african_airtime_pre_failed(error.data.error));
    } else {
      yield put(pan_african_airtime_pre_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_pan_african_airtime_pre_request() {
  yield takeEvery(PAN_AFRICAN_AIRTIME_PRE.GET_REQUEST, pan_african_airtime_pre_request);
}
//#endregion

//#region PAN_AFRICAN_AIRTIME_TOP_UP_REQUEST
function* pan_african_airtime_top_up_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.pan_african_airtime_top_up_async, payload);
    yield put(pan_african_airtime_top_up_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(pan_african_airtime_top_up_failed(error.data.error));
    } else {
      yield put(pan_african_airtime_top_up_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_pan_african_airtime_top_up_request() {
  yield takeEvery(PAN_AFRICAN_AIRTIME_TOP_UP.POST_REQUEST, pan_african_airtime_top_up_request);
}
//#endregion

//#region ACTIVE_PRODUCT_GET_REQUEST
function* active_product_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.active_product_async, payload);
    yield put(active_product_success(Object.values(res.data.items)));
  } catch (error) {
    if (error?.data?.error) {
      yield put(active_product_failed(error.data.error));
    } else {
      yield put(active_product_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_active_product_request() {
  yield takeEvery(ACTIVE_PRODUCT.GET_REQUEST, active_product_request);
}
//#endregion

//#region CHECK_METER_POST_REQUEST
function* check_meter_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.check_meter_async, payload);
    if (!res.data.error) {
      yield put(check_meter_success(res.data));
    } else {
      yield put(check_meter_failed('Transaction failed, check meter number and try again!'));
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(check_meter_failed(error.data.error));
    } else {
      yield put(check_meter_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_check_meter_request() {
  yield takeEvery(CHECK_METER.POST_REQUEST, check_meter_request);
}
//#endregion

//#region PURCHASE_POWER_POST_REQUEST
function* purchase_power_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.purchase_power_async, payload);
    if (res.data.status) {
      yield put(purchase_power_success(res.data));
    } else {
      yield put(purchase_power_failed('Transaction failed, try again!'));
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(purchase_power_failed(error.data.error));
    } else {
      yield put(purchase_power_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_purchase_power_request() {
  yield takeEvery(PURCHASE_POWER.POST_REQUEST, purchase_power_request);
}
//#endregion

const shopSagas = [
  fork(watch_shop_products_request),
  fork(watch_shop_product_items_request),
  fork(watch_shop_product_item_packages_request),
  fork(watch_shop_purchase_request),
  fork(watch_shop_beneficaries_get_request),
  fork(watch_shop_beneficaries_post_request),
  fork(watch_supported_network_request),
  fork(watch_top_up_airtime_request),
  fork(watch_data_packages_get_request),
  fork(watch_top_up_data_request),
  fork(watch_cable_tv_provider_request),
  fork(watch_cable_tv_package_request),
  fork(watch_verify_smart_card_request),
  fork(watch_cable_tv_top_up_request),
  fork(watch_product_items_get_request),
  fork(watch_education_top_up_request),
  fork(watch_iroko_provider_get_request),
  fork(watch_top_up_iroko_request),
  fork(watch_pan_african_airtime_pre_request),
  fork(watch_pan_african_airtime_top_up_request),
  fork(watch_active_product_request),
  fork(watch_check_meter_request),
  fork(watch_purchase_power_request)
];

export default shopSagas;
