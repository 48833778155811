import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { ErrorIcon, FileIcon } from 'assets/icons/svg';

const Upload = ({
  field,
  form,
  placeholder,
  inputValue,
  innerClass = '',
  icon = <FileIcon />,
  lableBg = '',
  multiple,
  error,
  touched,
  ...rest
}) => {
  const handleChange = (e) => {
    const files = e.currentTarget.files;
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, multiple ? files : files[0]);
  };

  const errorClass = `border-red-300 text-red-900 focus:border-red-300`;
  const isTrue = error && touched;

  return (
    <div className="relative text-left">
      <label htmlFor={rest.name} className="block leading-5 sm:mt-px sm:pt-2 mb-2">
        {placeholder}
      </label>
      <div className={`relative h-12 ${innerClass}`}>
        <input
          {...rest}
          type="file"
          onChange={handleChange}
          onBlur={handleChange}
          className="absolute block w-1/3 pb-2 pt-4 h-12 opacity-0 z-10 cursor-pointer"
        />

        <div
          className={`absolute flex justify-center items-center w-1/3 h-12 border text-ra-dark border-ra-300 rounded-md ${lableBg} ${
            isTrue && errorClass
          }`}
        >
          <span className="mr-1">{icon}</span>
          Upload file
        </div>
      </div>
      {inputValue && <div className="font-bold">{inputValue.name}</div>}

      {isTrue && (
        <>
          <div className="absolute -mt-8 right-0 pr-3 flex items-center pointer-events-none">
            <ErrorIcon />
          </div>
          <p className="mt-0 text-xs text-red-600" id={`${rest.name}-error`}>
            {error}
          </p>
        </>
      )}
    </div>
  );
};

Upload.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.any,
  accept: PropTypes.any,
  multiple: PropTypes.any,
  error: PropTypes.string,
  touched: PropTypes.any
};

export default Upload;
