import React from 'react';
import PropTypes from 'prop-types';
import LaddaButton, { XL, SLIDE_UP } from 'react-ladda';

import 'ladda/dist/ladda-themeless.min.css';

const Button = ({ title, color, textColor = 'text-white', loading, disabled, classes }) => {
  return (
    <LaddaButton
      type="submit"
      className={`relative w-full items-center px-5 py-3 border border-transparent text-sm eading-5 font-medium rounded-md ${classes} ${
        disabled || loading ? ' cursor-not-allowed text-gray-300' : textColor + ' ' + color
      } focus:outline-none btn-ladda`}
      loading={loading}
      disabled={disabled || loading}
      data-color="#eee"
      data-size={XL}
      data-style={SLIDE_UP}
      data-spinner-size={30}
      data-spinner-color="#ddd"
      data-spinner-lines={12}
    >
      {title}
    </LaddaButton>
  );
};

Button.protoTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Button;
