import React from 'react';
import PropTypes from 'prop-types';

const TargetsCard = ({ intl, reach, audience, impression, ta_loading, im_loading }) => {
  return (
    <div className="bg-white w-full mb-5 pb-5">
      <div className="border-gray-300 rounded-md">
        <div className="flex border-b border-gray-300 py-5">
          <h4 className="font-medium px-5 text-xl">
            {intl.formatMessage({
              id: 'advertise.campaigns.targets'
            })}
          </h4>
        </div>
        <div className="px-5 mt-2">
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.potential-reach'
              })}
            </span>
            <span>{reach}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.target-audience'
              })}
            </span>
            <span>{ta_loading ? 'loading...' : audience}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.impression'
              })}
            </span>
            <span>{im_loading ? 'loading...' : impression}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

TargetsCard.propTypes = {
  reach: PropTypes.number.isRequired,
  audience: PropTypes.number.isRequired,
  impression: PropTypes.number.isRequired,
  ta_loading: PropTypes.bool.isRequired,
  im_loading: PropTypes.bool.isRequired
};

export default TargetsCard;
