import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string()
    .required('signup.form.error.password_1')
    .min(8, 'signup.form.error.password_2'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'signup.form.error.confirm_password_1')
    .required('signup.form.error.confirm_password_2')
});
