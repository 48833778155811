import * as yup from 'yup';

const fileValidation = (size, type) =>
  yup
    .mixed()
    .required('advertise.campaigns.errors.file_1')
    .test(
      'fileSize',
      'advertise.campaigns.errors.file_2',
      (value) => value && value.size <= 1024 * 1024 * size
    )
    .test(
      'fileType',
      'advertise.campaigns.errors.file_3',
      (value) => value && type.includes(value.type)
    );

export const ContactGroupValidations = yup.object().shape({
  name: yup.string().required('advertise.campaigns.contact.errors_1'),
  description: yup.string().required('advertise.campaigns.camp.errors_5'),
  upload: fileValidation(10, [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ])
});

export const ContactValidations = yup.object().shape({
  name: yup.string().required('advertise.campaigns.contact.errors_2'),
  email: yup.string().email('signin.form.error.email_1').required('signin.form.error.email_2'),
  phone: yup
    .string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
      message: 'signup.form.error.phone_1'
    })
    .required('signup.form.error.phone_2')
});
