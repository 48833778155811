import { images } from 'exports'
import React from 'react'
import { Text72 } from 'pages/LandingPage/styles/text'
import HeaderDiv from '../personal/HeaderDiv'
import MobileStores from '../personal/MobileStores'
const BusinessHeader = () => {
    return (
        <>

            <HeaderDiv
                headerCompoenent={<Text72>A smart way to engage, gain and retain customers.</Text72>}
                smallText={<>Utilize Reachafrika to raise the conversion rate of your marketing videos. Our reward advertising method, in which users receive benefits for watching advertisements, helps advertisers attract the most interest from potential customers for their business.</>}
                img={images.businessheader} bgcolor=' linear-gradient(276.1deg, #3A41C8 0.31%, #935FE5 100%)'
                extra={<MobileStores />}

            />
        </>
    )
}
export default BusinessHeader