import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

// COMPONENT IMPORTS
import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay as Loading } from 'components/Loading';
import ErrorModal from 'components/Shop/ErrorModal';
import SuccessModal from 'components/Shop/SuccessModal';
import InvoiceCard from 'components/Shop/InvoiceCard';
import PinConfirmModal from 'components/PinConfirmModal';
import Input from 'components/Form/Input';
import PhoneInput from 'components/Form/Phone';
import Select from 'components/Form/Select';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';

import useInvoice from 'hooks/useInvoice';

import TelcosImg from 'assets/img/telcos-2.png';

import {
  shop_purchase_request,
  reward_account_request,
  shop_product_items_request,
  auth_prerequisites_request,
  shop_beneficaries_get_request,
  shop_beneficaries_post_request,
  validate_pin_request,
  shop_purchase_failed,
  shop_purchase_success
} from 'redux/actions';

// VALIDAITON IMPORTS
import { local } from 'validations/shop/airtime';

const ShopLocalAirtime = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  const formRef = useRef(null);

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'shop.airtime.local.page.title'
  })}`;

  //#region STATES
  const [newBeneficiary, setNewBeneficiary] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);
  const [isBenSelectOpen, setBenSelectOpen] = useState(false);
  const [bill, setBill] = useState(0);
  const [payable, setPayable] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [reward, setReward] = useState(0);
  const [useReward, toggleUseReward] = useState(false);
  const [isPinModalOpen, togglePinModal] = useState(false);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  const [isErrorModalOpen, toggleErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  //#endregion

  //#region SELECTORS
  const {
    error,
    loading,
    transaction_error,
    shop_purchase,
    shop_product_items,
    shop_beneficaries_post_data,
    shop_beneficaries_get_data
  } = useSelector((state) => state.shopReducers);

  const { loading: authLoading, auth_prerequisites, user, isPinCorrect } = useSelector(
    (state) => state.authReducers
  );

  const { loading: walletLoading, reward_account_data } = useSelector(
    (state) => state.walletReducers
  );
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(shop_product_items_request({ product: 'local_airtime' }));
      dispatch(reward_account_request());
      dispatch(shop_beneficaries_get_request({ product: 'local_airtime' }));
    }
    return () => {
      mounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (shop_purchase) {
        toggleErrorModal(false);
        toggleSuccessModal(true);
        setSuccessMessage(shop_purchase);
      }

      if (transaction_error) {
        toggleSuccessModal(false);
        toggleErrorModal(true);
        setErrorMessage(transaction_error);
      }

      if (shop_beneficaries_post_data) {
        alert.show(
          intl.formatMessage({
            id: 'shop.products.beneficiary.success'
          }),
          {
            type: 'success',
            title: 'OK'
          }
        );
      }

      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => {
      mounted = false;
      dispatch(shop_purchase_failed(null));
      dispatch(shop_purchase_success(null));
    };
  }, [alert, shop_beneficaries_post_data, dispatch, error, shop_purchase, transaction_error, intl]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !auth_prerequisites?.profiles) {
      dispatch(auth_prerequisites_request());
    }
    return () => (mounted = false);
  }, [dispatch, auth_prerequisites]);

  let countries = [];
  if (auth_prerequisites?.profiles) {
    auth_prerequisites.profiles.forEach((profile) => {
      countries.push(profile.name);
    });
  }

  useInvoice(props?.location?.state?.convenience_fee * 1, reward_account_data, bill, useReward, {
    fee: setConvenienceFee,
    reward: setReward,
    payable: setPayable
  });
  //#endregion

  const beneficaries = shop_beneficaries_get_data?.map((i) => {
    return {
      id: i.id,
      name: i.data.name,
      phone: i.data.phone
    };
  });

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <>
      {loading || authLoading || walletLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="shop.airtime.local.nav.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:py-10 xl:mx-24">
            <div className="flex flex-wrap flex-col-reverse xl:flex-row xl:flex-no-wrap mt-1 ml-2">
              <div className="bg-white w-full xl:w-4/6 xl:mr-2 rounded-md">
                <div className="flex border-b border-gray-300 py-5">
                  <h4 className="font-medium px-5 text-xl">
                    {intl.formatMessage({
                      id: 'shop.airtime.local.form.title'
                    })}
                  </h4>
                </div>
                <div className="flex px-5 md:px-10 py-5">
                  <div className="hidden w-2/12 mr-5 md:block">
                    <img className="w-full" src={TelcosImg} alt="Telcos" />
                  </div>
                  <div className="w-full xl:w-10/12 xl:ml-5">
                    <Formik
                      initialValues={{
                        network: '',
                        amount: '',
                        phone: user.phone
                      }}
                      innerRef={formRef}
                      validationSchema={local}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        dispatch(
                          shop_purchase_request({
                            product: 'local_airtime',
                            params: {
                              ...values,
                              payable
                            }
                          })
                        );
                        resetForm();
                        setBill(0);
                        setPayable(0);
                        setSubmitting(false);
                        togglePinModal(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        dirty,
                        isValid,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit
                      }) => (
                        <form className="block" onSubmit={handleSubmit}>
                          <ErrorModal
                            intl={intl}
                            error={errorMessage}
                            handleClose={toggleErrorModal}
                            isOpen={isErrorModalOpen}
                          />
                          <SuccessModal
                            intl={intl}
                            message={successMessage || 'Purchase was successful'}
                            handleClose={toggleSuccessModal}
                            isOpen={isSuccessModalOpen}
                          />
                          <PinConfirmModal
                            intl={intl}
                            loading={loading || authLoading || walletLoading}
                            dispatch={dispatch}
                            isPinCorrect={isPinCorrect}
                            isSubmitting={isSubmitting}
                            isModalOpen={isPinModalOpen}
                            toggleModal={togglePinModal}
                            action={validate_pin_request}
                            handleSumbit={handleFormSubmit}
                          />
                          <div className="flex flex-wrap -mx-2">
                            <div className="w-full lg:w-1/2 p-2">
                              <Select
                                name="network"
                                placeholder={intl.formatMessage({
                                  id: 'shop.airtime.form.ph.network'
                                })}
                                value={values.network}
                                error={
                                  errors.network &&
                                  intl.formatMessage({
                                    id: errors.network
                                  })
                                }
                                touched={touched.network}
                                options={shop_product_items}
                                setFieldValue={(name, obj) => {
                                  if (obj.id) {
                                    setFieldValue(name, obj.id);
                                  }
                                }}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                                optionLabelKey="alias"
                                optionValueKey="id"
                              />
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                              <Input
                                type="number"
                                name="amount"
                                min="1"
                                placeholder={intl.formatMessage({
                                  id: 'shop.airtime.form.ph.amount'
                                })}
                                inputValue={values.amount}
                                onChange={(e) => {
                                  setBill(e.target.value * 1);
                                  handleChange(e);
                                }}
                                onBlur={(e) => {
                                  setBill(e.target.value * 1);
                                  handleBlur(e);
                                }}
                                error={
                                  errors.amount &&
                                  intl.formatMessage({
                                    id: errors.amount
                                  })
                                }
                                touched={touched.amount}
                                useFloatingLable={false}
                              />
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                              {countries.length ? (
                                <PhoneInput
                                  name="phone"
                                  placeholder={intl.formatMessage({
                                    id: 'signup.form.placeholder.phone'
                                  })}
                                  value={values.phone}
                                  error={
                                    errors.phone &&
                                    intl.formatMessage({
                                      id: errors.phone
                                    })
                                  }
                                  touched={touched.phone}
                                  countries={countries}
                                  profiles={auth_prerequisites.profiles}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  useFloatingLable={false}
                                />
                              ) : (
                                <div style={{ position: 'inherit' }} className="loading-text">
                                  loading profile
                                </div>
                              )}
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                              <Input
                                type="text"
                                name="beneficiary"
                                placeholder={intl.formatMessage({
                                  id: 'shop.products.beneficiary'
                                })}
                                inputValue={
                                  newBeneficiary ||
                                  beneficiary ||
                                  user.firstname + ' ' + user.lastname
                                }
                                onChange={(e) => {
                                  setNewBeneficiary(e.target.value);
                                }}
                                onBlur={(e) => {
                                  setNewBeneficiary(e.target.value);
                                }}
                                useFloatingLable={false}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col xl:flex-row">
                            <div className="flex flex-col w-full xl:w-1/2 xl:mr-2">
                              {isBenSelectOpen && (
                                <div className="mb-6">
                                  <Select
                                    name="beneficiary"
                                    placeholder={intl.formatMessage({
                                      id: 'shop.products.select.benefactor'
                                    })}
                                    value={
                                      beneficiary
                                        ? beneficaries?.find((e) => e.name === beneficiary).id
                                        : ''
                                    }
                                    options={beneficaries}
                                    setFieldValue={(name, value) => {
                                      setFieldValue('phone', value.phone);
                                      setBeneficiary(value.name);
                                    }}
                                    setFieldTouched={() => {}}
                                    useFloatingLable={false}
                                    optionLabelKey="name"
                                    optionValueKey="id"
                                  />
                                </div>
                              )}
                              <Checkbox
                                id="select_beneficiary"
                                title={intl.formatMessage({
                                  id: 'shop.products.select.beneficiary'
                                })}
                                className="mb-6 flex items-center"
                                action={() => {
                                  if (isBenSelectOpen) {
                                    setNewBeneficiary(null);
                                    setBeneficiary(null);
                                  }
                                  setBenSelectOpen(!isBenSelectOpen);
                                }}
                              />
                              {!isBenSelectOpen && (
                                <Checkbox
                                  id="save_beneficiary"
                                  title={intl.formatMessage({
                                    id: 'shop.products.save.beneficiary'
                                  })}
                                  className="mb-6 flex items-center"
                                  action={() => {
                                    let cb = document.getElementById('save_beneficiary');
                                    if (values.phone === user.phone) {
                                      alert.show(
                                        intl.formatMessage({
                                          id: 'shop.products.beneficiary.error'
                                        }),
                                        {
                                          type: 'warn',
                                          title: intl.formatMessage({
                                            id: 'error.title'
                                          })
                                        }
                                      );
                                      cb.checked = false;
                                    } else {
                                      if (newBeneficiary && values.phone) {
                                        cb.checked = true;
                                        dispatch(
                                          shop_beneficaries_post_request({
                                            product: 'local_airtime',
                                            params: {
                                              name: newBeneficiary,
                                              phone: values.phone
                                            }
                                          })
                                        );
                                      } else {
                                        alert.show(
                                          intl.formatMessage({
                                            id: 'shop.products.beneficiary.error_1'
                                          }),
                                          {
                                            type: 'error',
                                            title: intl.formatMessage({
                                              id: 'error.title'
                                            })
                                          }
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-full xl:w-1/2 xl:ml-2">
                              <Checkbox
                                id="use_reward"
                                title={intl.formatMessage({
                                  id: 'shop.products.use_reward'
                                })}
                                action={() => toggleUseReward(!useReward)}
                                className="mb-6 flex items-center"
                              />
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 xl:px-4 xl:w-1/2">
                            <Button
                              title={intl.formatMessage({
                                id: 'button.title.proceed'
                              })}
                              className="border-ra-dark"
                              textColor="text-ra-dark"
                              disabled={!(dirty && isValid) || isSubmitting}
                              click={() => {
                                togglePinModal(!isPinModalOpen);
                              }}
                            />
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <InvoiceCard
                intl={intl}
                bill={bill}
                reward={reward}
                convenienceFee={convenienceFee}
                payable={payable}
              />
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ShopLocalAirtime;
