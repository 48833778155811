import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

// COMPONENT IMPORTS
import Checkbox from 'components/Form/Checkbox';
import Upload from 'components/Form/Upload';
import Input from 'components/Form/Input';
import Button from 'components/Button';

import PinConfirmModal from 'components/PinConfirmModal';

// COMPONENTS
import { CloudUploadIcon } from 'assets/icons/svg';

const StepFour = ({
  intl,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setStep,
  isValid,
  isSubmitting,
  setFieldValue,
  isPinCorrect,
  dispatch,
  action,
  isModalOpen,
  toggleModal,
  handleSumbit
}) => {
  return (
    <>
      <PinConfirmModal
        intl={intl}
        dispatch={dispatch}
        action={action}
        isModalOpen={isModalOpen}
        isPinCorrect={isPinCorrect}
        toggleModal={toggleModal}
        isSubmitting={isSubmitting}
        handleSumbit={handleSumbit}
      />
      <div className="p-4">
        <div className="flex flex-wrap mx-1">
          <div className="w-full p-2">
            <div className="relative w-full md:w-1/2">
              <Field
                component={Upload}
                placeholder={intl.formatMessage({
                  id: 'advertise.campaigns.form.placeholder.upload-' + values.type
                })}
                name="media_file"
                innerClass="flex justify-end border border-dashed gray-400 rounded-md"
                icon={<CloudUploadIcon />}
                lableBg="bg-ra-300"
                inputValue={values.media_file}
                accept={`${
                  values.type === 'video'
                    ? 'video/mp4,video/x-m4v,video/mpeg'
                    : 'image/jpg,image/jpeg,image/png'
                }`}
                error={
                  errors.media_file &&
                  intl.formatMessage({
                    id: errors.media_file
                  })
                }
                touched={touched.media_file}
              />
            </div>
          </div>
          <div className="w-full p-2">
            <Checkbox
              id="selection_date"
              name="selection_date"
              title={intl.formatMessage({
                id: 'advertise.campaigns.form.placeholder.start-now'
              })}
              value={values.selection_date === 'now'}
              action={(e) => {
                setFieldValue('selection_date', !JSON.parse(e.target.value) ? 'now' : 'later');
              }}
              className="mb-6 flex items-center"
            />
          </div>
          <div className="flex flex-wrap w-full p-2">
            {values.selection_date === 'later' && (
              <div className="w-full md:w-1/3 md:mr-3">
                <Input
                  type="date"
                  id="start_date"
                  name="start_date"
                  min={new Date().toISOString().split('T')[0]}
                  placeholder={intl.formatMessage({
                    id: 'advertise.campaigns.form.placeholder.start-date'
                  })}
                  inputValue={values.start_date}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('later', new Date(e.target.value).toString());
                    setFieldValue('end_date', '');
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.start_date &&
                    intl.formatMessage({
                      id: errors.start_date
                    })
                  }
                  touched={touched.start_date}
                  useFloatingLable={false}
                />
              </div>
            )}

            <div className="w-full md:w-1/3">
              <Input
                type="date"
                id="end_date"
                name="end_date"
                min={values.start_date || new Date().toISOString().split('T')[0]}
                placeholder={intl.formatMessage({
                  id: 'advertise.campaigns.form.placeholder.end-date'
                })}
                inputValue={values.end_date}
                onChange={(e) => {
                  handleChange(e);
                  if (values.selection_date === 'later') {
                    setFieldValue(
                      'later',
                      [values.later, new Date(e.target.value).toString()].toString()
                    );
                  } else {
                    setFieldValue('now', new Date(e.target.value).toString());
                  }
                }}
                onBlur={handleBlur}
                error={
                  errors.end_date &&
                  intl.formatMessage({
                    id: errors.end_date
                  })
                }
                touched={touched.end_date}
                useFloatingLable={false}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between mx-2 my-6">
          <div className="w-1/3">
            <Button
              title={intl.formatMessage({
                id: 'button.title.previous'
              })}
              textColor="text-ra-dark"
              className="border-ra-dark"
              click={() => setStep(2)}
            />
          </div>
          <div className="w-1/3">
            <Button
              title={intl.formatMessage({
                id: 'button.title.finish'
              })}
              className="border-ra-dark"
              textColor="text-ra-dark"
              disabled={!isValid}
              click={() => {
                toggleModal(!isModalOpen);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

StepFour.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default StepFour;
