import {
    COUNTRIES_CHANGER
  } from 'constants/actionTypes';
  
  //#region NORMAL_ACCOUNT

  export const change_country = (payload) => ({
    type: COUNTRIES_CHANGER.CHANGE_COUNTRY,
    payload
  });

  //#endregion

  //#region CREDIT_ACCOUNT
