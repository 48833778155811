import React from 'react';
import { useAlert } from 'react-alert';

import QuickActionCards from '../Cards/QuickAction';

// Image
import TelcosImg from 'assets/img/telcos.png';
import DataImg from 'assets/img/data.png';
import DecorderImg from 'assets/img/decorder.png';
import MagaPhoneImg from 'assets/img/maga-phone.png';
import AirtimeOptionModal from 'components/Shop/AirtimeOptionModal';

const QuickActions = ({ intl, className, products, push }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const alert = useAlert();

  const airtime = products?.find((p) => p.name === 'airtime');
  const data_bundle = products?.find((p) => p.name === 'data_bundle');

  return (
    products && (
      <>
        <AirtimeOptionModal
          intl={intl}
          handleClose={setModalOpen}
          isOpen={isModalOpen}
          local={airtime?.groups.find((i) => i.name === 'local_airtime')}
          international={airtime?.groups.find((i) => i.name === 'international_airtime')}
        />
        <div className="mt-10">
          <h6 className="text-2xl md:text-3xl font-bold">
            {intl.formatMessage({
              id: 'home.quick-action.title'
            })}
          </h6>
          <div className="flex flex-wrap xl:flex-no-wrap mt-2">
            <QuickActionCards
              to="#"
              action={(_) => setModalOpen(true)}
              title={intl.formatMessage({
                id: 'home.quick-action.telcos.title_1'
              })}
              subtitle={intl.formatMessage({
                id: 'home.quick-action.telcos'
              })}
              imageSrc={TelcosImg}
              className={`w-full mr-0 xl:w-1/2 xl:mr-2 mb-1 ${className}`}
            />
            <QuickActionCards
              to="#"
              action={(_) =>
                data_bundle
                  ? push('shop/data_bundle')
                  : // TODO: translate
                    alert.show('Product not available', {
                      type: 'warn',
                      title: intl.formatMessage({
                        id: 'error.title'
                      })
                    })
              }
              title={intl.formatMessage({
                id: 'home.quick-action.telcos.title_2'
              })}
              subtitle={intl.formatMessage({
                id: 'home.quick-action.telcos'
              })}
              imageSrc={DataImg}
              className={`w-full ml-0 mt-4 xl:w-1/2 xl:ml-2 xl:mb-1 xl:mt-1 ${className}`}
            />
          </div>
          <div className="flex flex-wrap xl:flex-no-wrap mt-4 xl:mt-2">
            <QuickActionCards
              to="shop"
              title={intl.formatMessage({
                id: 'home.quick-action.utils.title'
              })}
              subtitle={intl.formatMessage({
                id: 'home.quick-action.utils'
              })}
              imageSrc={DecorderImg}
              className={`w-full mr-0 xl:w-1/2 xl:mr-2 ${className}`}
            />
            <QuickActionCards
              to="reduce-your-payment"
              title={intl.formatMessage({
                id: 'home.quick-action.bonus.title'
              })}
              subtitle={intl.formatMessage({
                id: 'home.quick-action.bonus'
              })}
              imageSrc={MagaPhoneImg}
              className={`w-full ml-0 mt-4 xl:w-1/2 xl:ml-2 xl:mt-0 ${className}`}
            />
          </div>
        </div>
      </>
    )
  );
};

export default QuickActions;
