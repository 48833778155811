import * as Yup from 'yup';

export default Yup.object().shape({
  package: Yup.string().required('Checker type is required!'),
  phone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
      message: 'Invalid phone number!'
    })
    .required('Invalid phone number!'),
  name: Yup.string().required('Name is required!')
});
