import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const Card = ({ intl, item }) => {
  return (
    <div className="flex flex-col items-center bg-white rounded-sm p-5 w-full xl:w-1/4 mb-3 xl:mb-0 lg:mr-4 h-56">
      <span className="">{item.icon}</span>
      <p className="my-8">{item.title}</p>
      <Link to={item.to} className="w-full">
        <Button
          title={intl.formatMessage({
            id: 'button.title.purchase'
          })}
          textColor="text-ra-dark"
          className="border-ra-dark"
        />
      </Link>
    </div>
  );
};

Card.propTypes = {
  item: PropTypes.object.isRequired
};

export default Card;
