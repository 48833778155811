import Tcard from './TargetsCard';
import Ccard from './CostAndBudgetCard';
import Step1 from './StepOne';
import Step2 from './StepTwo';
import Step3 from './StepThree';
import Step4 from './StepFour';

export const TargetsCard = Tcard;
export const CostAndBudgetCard = Ccard;
export const StepOne = Step1;
export const StepTwo = Step2;
export const StepThree = Step3;
export const StepFour = Step4;
