import React, { useEffect, useState, useRef } from 'react';

const Thumb = ({ imageFile, src, alt, className, style }) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);

  const prevImageFileRef = useRef();

  useEffect(() => {
    if (imageFile && imageFile !== prevImageFileRef.current) {
      prevImageFileRef.current = imageFile;
      setLoading(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        setLoading(false);
        setThumb(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  if (!imageFile && !src) return null;
  if (loading) return 'loading...';

  return (
    <img
      src={thumb || src}
      alt={(imageFile && imageFile.name) || alt}
      className={className}
      style={style}
    />
  );
};

export default Thumb;
