import React, { useState } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import TelcosImg from 'assets/img/telcos-2.png';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Checkbox from 'components/Form/Checkbox';
import FormButton from 'components/Form/Button';
const ShopBroadband = (props) => {
  document.title = 'REACHAFRIKA | SHOP - BROADBAND';
  const [isBenSelectOpen, setBenSelectOpen] = useState(false);
  const intl = useIntl();

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} title="Broadband" />
        <div className="text-sm pt-2 pb-6 mx-4 md:py-10 xl:mx-24">
          <div className="flex flex-wrap flex-col-reverse xl:flex-row xl:flex-no-wrap mt-1 ml-2">
            <div className="bg-white w-full xl:w-4/6 xl:mr-2 rounded-md">
              <div className="flex border-b border-gray-300 py-5">
                <h4 className="font-medium px-5 text-xl">Buy Airtime</h4>
              </div>
              <div className="flex px-5 md:px-10 py-5">
                <div className="hidden w-2/12 mr-5 md:block">
                  <img className="w-full" src={TelcosImg} alt="Telcos" />
                </div>
                <div className="w-full xl:w-10/12 xl:ml-5">
                  <Formik
                    initialValues={{
                      provider: '',
                      package: '',
                      provider_user_id: '',
                      beneficiary: ''
                    }}
                    // validationSchema={SignInSchema}
                    onSubmit={(values) => {
                      // dispatch(loginRequest({ data: values, history: props.history }));
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isSubmitting,
                      isValid,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                      handleSubmit
                    }) => (
                      <form className="block" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap xl:flex-no-wrap">
                          <div className="flex flex-col w-full xl:w-1/2 xl:mr-2">
                            <div className="mb-6">
                              <Select
                                name="provider"
                                placeholder="Select Provider"
                                value={values.provider}
                                error={errors.provider}
                                touched={touched.provider}
                                options={['Smile']}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                              />
                            </div>
                            <Input
                              type="text"
                              name="provider_user_id"
                              placeholder="Provider User ID"
                              inputValue={values.provider_user_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.provider_user_id}
                              touched={touched.provider_user_id}
                              useFloatingLable={false}
                            />
                          </div>
                          <div className="flex flex-col w-full xl:w-1/2 xl:ml-2">
                            <div className="mb-6">
                              <Select
                                name="package"
                                placeholder="Select Package"
                                value={values.package}
                                error={errors.package}
                                touched={touched.package}
                                options={['Smile']}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                              />
                            </div>
                            <Input
                              type="text"
                              name="beneficiary"
                              placeholder="Beneficiary"
                              inputValue={values.beneficiary}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.beneficiary}
                              touched={touched.beneficiary}
                              useFloatingLable={false}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col xl:flex-row">
                          <div className="flex flex-col w-full xl:w-1/2 xl:mr-2">
                            {isBenSelectOpen && (
                              <div className="mb-6">
                                <Select
                                  name="beneficiary"
                                  placeholder="Select Benefactor"
                                  value={values.beneficiary}
                                  error={errors.beneficiary}
                                  touched={touched.beneficiary}
                                  options={['MTN', 'GLO', '9 MOBILE', 'AIRTEL']}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  useFloatingLable={false}
                                />
                              </div>
                            )}
                            <Checkbox
                              id="select_beneficiary"
                              title="Select beneficiary"
                              action={() => setBenSelectOpen(!isBenSelectOpen)}
                              className="mb-6 flex items-center"
                            />
                            <Checkbox
                              id="save_beneficiary"
                              title="Save beneficiary"
                              action={() => setBenSelectOpen(!isBenSelectOpen)}
                              className="mb-6 flex items-center"
                            />
                          </div>
                          <div className="flex flex-col w-full xl:w-1/2 xl:ml-2">
                            <Checkbox
                              id="use_reward"
                              title="Use Reward"
                              action={() => {}}
                              className="mb-6 flex items-center"
                            />
                          </div>
                        </div>
                        <div className="flex justify-center mt-2 xl:px-4 xl:w-1/2">
                          <FormButton
                            title="Proceed"
                            color="bg-ra-dark"
                            disabled={!(dirty && isValid) || isSubmitting}
                            loading={isSubmitting}
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="bg-white w-full xl:w-2/6 mb-5 xl:mb-0 pb-5 xl:ml-2">
              <div className="border-gray-300 rounded-md">
                <div className="flex border-b border-gray-300 py-5">
                  <h4 className="font-medium px-5 text-xl">Invoice</h4>
                </div>
                <div className="px-5 mt-2">
                  <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
                    <span>Bill</span>
                    <span>0</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
                    <span>Convenience Fee</span>
                    <span>0</span>
                  </div>
                  <div className="flex justify-between bg-gray-100 py-4 px-2 mt-2 font-semibold rounded-md">
                    <span>Net Payable</span>
                    <span>0</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default ShopBroadband;
