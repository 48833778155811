import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { CAMPAIGNS, CAMPAIGNS_REWARDS, VIEW_CAMPAIGN } from 'constants/actionTypes';

import {
  campaigns_success,
  campaigns_failed,
  campaigns_rewards_success,
  campaigns_rewards_failed,
  view_campaign_success,
  view_campaign_failed
} from './actions';

import * as Api from './api';

//#region CAMPAIGNS_REQUEST
function* campaigns_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_campaigns, payload);
    yield put(campaigns_success(res.data.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(campaigns_failed(error.data.error));
    } else {
      yield put(campaigns_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_campaigns_request() {
  yield takeEvery(CAMPAIGNS.GET_REQUEST, campaigns_request);
}
//#endregion

//#region CAMPAIGNS_REWARDS_REQUEST
function* campaigns_rewards_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_campaigns_rewards);
    yield put(campaigns_rewards_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(campaigns_rewards_failed(error.data.error));
    } else {
      yield put(campaigns_rewards_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_campaigns_rewards_request() {
  yield takeEvery(CAMPAIGNS_REWARDS.GET_REQUEST, campaigns_rewards_request);
}
//#endregion

//#region VIEW_CAMPAIGN
function* view_campaign_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.post_view_campaign, payload);
    yield put(view_campaign_success(res.data.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(view_campaign_failed(error.data.error));
    } else {
      yield put(view_campaign_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_view_campaign_request() {
  yield takeEvery(VIEW_CAMPAIGN.POST_REQUEST, view_campaign_request);
}
//#endregion

const reducePaymentSagas = [
  fork(watch_campaigns_request),
  fork(watch_campaigns_rewards_request),
  fork(watch_view_campaign_request)
];

export default reducePaymentSagas;
