import {
  CONTACTS_GROUPS,
  CONTACTS_GROUPS_CONTACT,
  CREDIT_TRANSACTIONS,
  CREDIT_TOP_UP,
  BANKS,
  ADVERTISE_CAMPAIGNS,
  FREQUENCIES,
  POTENTIAL_REACH_COUNT,
  TARGET_AUDIENCE_COUNT,
  IMPRESSION_COUNT,
  VIEWERSHIP_RATE,
  VIEW_OF_WEEK,
  CLICK_THRU_RATE,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: null,
  message: null,
  contacts_groups: [],
  contacts_list_detials: {},
  contacts_list: [],
  credit_transactions: [],
  credit_top: null,
  banks: [],
  campaigns: [],
  campaign: null,
  frequencies: [],
  potential_reach_count: 0,
  target_audience_count: 0,
  target_audience_loading: false,
  impression_loading: false,
  impression_count: 0,
  viewership_rate: {},
  click_thru_rate: {},
  views_of_the_week: { days: [], counts: [] }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region CONTACTS_GROUPS.GET
    case CONTACTS_GROUPS.GET_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS.GET_SUCCESS:
      return { ...state, loading: false, contacts_groups: action.payload };
    case CONTACTS_GROUPS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS.POST
    case CONTACTS_GROUPS.POST_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts_groups: [...state.contacts_groups, action.payload]
      };
    case CONTACTS_GROUPS.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS.PATCH
    case CONTACTS_GROUPS.PATCH_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS.PATCH_SUCCESS:
      let oldData = [...state.contacts_groups];
      const index = oldData.findIndex((e) => e.id === action.payload.id);
      oldData[index] = action.payload;
      return {
        ...state,
        loading: false,
        contacts_groups: oldData
      };
    case CONTACTS_GROUPS.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS.DELETE
    case CONTACTS_GROUPS.DELETE_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS.DELETE_SUCCESS:
      let prevData = [...state.contacts_groups].filter((e) => e.id !== action.payload);
      return {
        ...state,
        loading: false,
        contacts_groups: prevData
      };
    case CONTACTS_GROUPS.DELETE_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS_CONTACT.GET
    case CONTACTS_GROUPS_CONTACT.GET_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS_CONTACT.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts_list_detials: action.payload,
        contacts_list: action.payload.contacts
      };
    case CONTACTS_GROUPS_CONTACT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS_CONTACT.POST
    case CONTACTS_GROUPS_CONTACT.POST_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS_CONTACT.POST_SUCCESS:
      return { ...state, loading: false, contacts_list: [...state.contacts_list, action.payload] };
    case CONTACTS_GROUPS_CONTACT.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS_CONTACT.PATCH
    case CONTACTS_GROUPS_CONTACT.PATCH_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS_CONTACT.PATCH_SUCCESS:
      let oldContactListData = [...state.contacts_list];
      const indexX = oldContactListData.findIndex((e) => e.id === action.payload.id);
      oldContactListData[indexX] = action.payload;
      return { ...state, loading: false, contacts_list: oldContactListData };
    case CONTACTS_GROUPS_CONTACT.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CONTACTS_GROUPS_CONTACT.DELETE
    case CONTACTS_GROUPS_CONTACT.DELETE_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_GROUPS_CONTACT.DELETE_SUCCESS:
      let prevContactListData = [...state.contacts_list].filter((e) => e.id !== action.payload);
      return { ...state, loading: false, contacts_list: prevContactListData };
    case CONTACTS_GROUPS_CONTACT.DELETE_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CREDIT_TRANSACTIONS.GET
    case CREDIT_TRANSACTIONS.GET_REQUEST:
      return { ...state, loading: true };
    case CREDIT_TRANSACTIONS.GET_SUCCESS:
      return { ...state, loading: false, credit_transactions: action.payload };
    case CREDIT_TRANSACTIONS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CREDIT_TOP_UP.POST
    case CREDIT_TOP_UP.POST_REQUEST:
      return { ...state, loading: true };
    case CREDIT_TOP_UP.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        credit_top: action.payload
      };
    case CREDIT_TOP_UP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region BANKS.GET
    case BANKS.GET_REQUEST:
      return { ...state, loading: true };
    case BANKS.GET_SUCCESS:
      return { ...state, loading: false, banks: action.payload };
    case BANKS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ADVERTISE_CAMPAIGNS.GET
    case ADVERTISE_CAMPAIGNS.GET_REQUEST:
      return { ...state, loading: true };
    case ADVERTISE_CAMPAIGNS.GET_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case ADVERTISE_CAMPAIGNS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ADVERTISE_CAMPAIGNS.POST
    case ADVERTISE_CAMPAIGNS.POST_REQUEST:
      return { ...state, loading: true };
    case ADVERTISE_CAMPAIGNS.POST_SUCCESS:
      return { ...state, loading: false, contacts_post: action.payload };
    case ADVERTISE_CAMPAIGNS.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ADVERTISE_CAMPAIGNS.GET_ONE
    case ADVERTISE_CAMPAIGNS.GET_ONE_REQUEST:
      return { ...state, loading: true };
    case ADVERTISE_CAMPAIGNS.GET_ONE_SUCCESS:
      return { ...state, loading: false, campaign: action.payload };
    case ADVERTISE_CAMPAIGNS.GET_ONE_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ADVERTISE_CAMPAIGNS.PATCH
    case ADVERTISE_CAMPAIGNS.PATCH_REQUEST:
      return { ...state, loading: true };
    case ADVERTISE_CAMPAIGNS.PATCH_SUCCESS:
      const oldCampaignData = [...state.campaigns];
      const indexxX = oldCampaignData.findIndex((e) => e.id === action.payload.id);
      oldCampaignData[indexxX] = action.payload;
      return { ...state, loading: false, campaign: oldCampaignData };
    case ADVERTISE_CAMPAIGNS.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ADVERTISE_CAMPAIGNS.DELETE
    case ADVERTISE_CAMPAIGNS.DELETE_REQUEST:
      return { ...state, loading: true };
    case ADVERTISE_CAMPAIGNS.DELETE_SUCCESS:
      let prevCampaignData = [...state.campaigns].filter((e) => e.id !== action.payload);
      return { ...state, loading: false, campaigns: prevCampaignData };
    case ADVERTISE_CAMPAIGNS.DELETE_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region FREQUENCIES.GET
    case FREQUENCIES.GET_REQUEST:
      return { ...state, loading: true };
    case FREQUENCIES.GET_SUCCESS:
      return { ...state, loading: false, frequencies: action.payload };
    case FREQUENCIES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region POTENTIAL_REACH_COUNT.GET
    case POTENTIAL_REACH_COUNT.GET_REQUEST:
      return { ...state, loading: true };
    case POTENTIAL_REACH_COUNT.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        potential_reach_count: action.payload.count,
        target_audience_count: action.payload.count
      };
    case POTENTIAL_REACH_COUNT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region TARGET_AUDIENCE_COUNT.GET
    case TARGET_AUDIENCE_COUNT.GET_REQUEST:
      return { ...state, target_audience_loading: true };
    case TARGET_AUDIENCE_COUNT.GET_SUCCESS:
      return {
        ...state,
        target_audience_loading: false,
        target_audience_count: action.payload.count
      };
    case TARGET_AUDIENCE_COUNT.GET_FAILED:
      return { ...state, target_audience_loading: false, error: action.payload };
    //#endregion
    //#region IMPRESSION_COUNT.POST
    case IMPRESSION_COUNT.POST_REQUEST:
      return { ...state, impression_loading: true };
    case IMPRESSION_COUNT.POST_SUCCESS:
      return { ...state, impression_loading: false, impression_count: action.payload.impression };
    case IMPRESSION_COUNT.POST_FAILED:
      return { ...state, impression_loading: false, error: action.payload };
    //#endregion
    //#region VIEWERSHIP_RATE.GET
    case VIEWERSHIP_RATE.GET_REQUEST:
      return { ...state, loading: true };
    case VIEWERSHIP_RATE.GET_SUCCESS:
      return { ...state, loading: false, viewership_rate: action.payload };
    case VIEWERSHIP_RATE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CLICK_THRU_RATE.GET
    case CLICK_THRU_RATE.GET_REQUEST:
      return { ...state, loading: true };
    case CLICK_THRU_RATE.GET_SUCCESS:
      return { ...state, loading: false, click_thru_rate: action.payload };
    case CLICK_THRU_RATE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VIEW_OF_WEEK.GET
    case VIEW_OF_WEEK.GET_REQUEST:
      return { ...state, loading: true };
    case VIEW_OF_WEEK.GET_SUCCESS:
      return { ...state, loading: false, views_of_the_week: action.payload };
    case VIEW_OF_WEEK.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CLEAR_MESSAGES
    case CLEAR_MESSAGES.REQUEST:
      return { ...state, loading: false, message: null, error: null };
    //#endregion
    default:
      return state;
  }
};
