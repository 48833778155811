import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';

import Button from 'components/Button';
import FormButton from 'components/Form/Button';

const Four = ({
  intl,
  dispatch,
  sentOtpRequest,
  otpReceived,
  setFieldValue,
  setStep,
  isSubmitting,
  phone,
  isValid,
  dirty
}) => {
  const [otp, setOtp] = useState(null);
  const [isOTPMatched, setOTPMatched] = useState(false);
  return (
    <>
      <OtpInput
        value={otp}
        onChange={(otp) => {
          setOtp(otp);
          if (otp * 1 === otpReceived * 1) {
            setFieldValue('phone_verified_at', true);
            setOTPMatched(true);
          }
        }}
        containerStyle="justify-center text-ra-dark"
        inputStyle={{
          appearance: 'none',
          borderColor: '#d2d6dc',
          borderWidth: 1,
          borderRadius: '0.375rem',
          padding: '0.75rem',
          width: '3rem'
        }}
        numInputs={4}
        isInputNum
        shouldAutoFocus
        separator={<span className="p-1">●</span>}
      />
      <p className="text-xs leading-5 text-gray-500 mt-10">
        {intl.formatMessage({
          id: 'signup.form.code.unsuccess'
        })}
        <Link to="#!" onClick={() => dispatch(sentOtpRequest({ phone }))}>
          <span className="text-ra-dark ml-1">
            {intl.formatMessage({
              id: 'signup.form.code.resend'
            })}
          </span>
        </Link>
      </p>
      <div className="flex-wrap justify-center sm:flex sm:justify-between mt-8">
        <div>
          <Button
            title={intl.formatMessage({
              id: 'button.title.back'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            click={() => setStep(3)}
          />
        </div>
        <div className="mt-4 sm:mt-0">
          <FormButton
            title={intl.formatMessage({
              id: 'button.title.finish'
            })}
            color="bg-ra-dark"
            disabled={isSubmitting || !(isValid && dirty) || !isOTPMatched}
            loading={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};

Four.propTypes = {
  phone: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  sentOtpRequest: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  otpReceived: PropTypes.number,
  isSubmitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired
};

export default Four;
