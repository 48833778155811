import { images } from 'exports'
import React from 'react'
import { Text72 } from 'pages/LandingPage/styles/text'
import MobileStores from './MobileStores'
import HeaderDiv from './HeaderDiv'
const LandingPageHeader = () => {
    return (
        <>

            <HeaderDiv
                headerCompoenent={<Text72>Make life easier with Reachafrika</Text72>}
                smallText={<>By joining over 10,000 users across Africa earn cash by watching <br />
                    Ads, earn rewards whenever you make purchase from partners <br />
                    outlets, pay utility bills, purchase airtime, data and TV subscriptions.</>}
                img={images.landingImg1} bgcolor='linear-gradient(276.1deg, #3082D4 0.31%, #47B7E8 100%)'
                extra={<MobileStores />}

            />
        </>
    )
}
export default LandingPageHeader