import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const navigationItem = (props) => {

  return (
    <Link
      to={ props.openNew ? { pathname: props.url } : props.url }
      rel="noopener noreferrer"
      role={props.role}
      // style={{ color: props.title !== 'Login' ? '#fff' : '#000' }}
      target={props.openNew ? '_blank' : '_self'}
      className={props.classItems}
    >
      {props.title}
    </Link>
  );
};

navigationItem.propTypes = {
  url: PropTypes.string.isRequired,
  classItems: PropTypes.string,
  title: PropTypes.string.isRequired,
  role: PropTypes.string
};

export default navigationItem;
