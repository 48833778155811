import {
  LANGUAGE_CHANGE,
  REFERRAL_INVITE_INFO,
  PROFILES,
  USER_PROFILES,
  ACTIVATE_PROFILE,
  LOGIN,
  SIGN_UP,
  VALIDATE_EMAIL,
  VALIDATE_PHONE,
  SEND_OTP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_PIN,
  LANGUAGES,
  INTERESTS,
  PROFESSIONS,
  COUNTRIES,
  USER_PROFILE,
  USER_PROFILE_AVATAR,
  USER_PROFILE_MOBILE,
  VALIDATE_PIN,
  AUTH_PREREQUISITES,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

//#region LANGUAGE_CHANGE
export const language_change_request = (payload) => ({
  type: LANGUAGE_CHANGE.POST_REQUEST,
  payload
});
//#endregion

//#region REFERRAL_INVITE_INFO
export const referrals_invite_info_request = () => ({
  type: REFERRAL_INVITE_INFO.GET_REQUEST
});
export const referrals_invite_info_success = (payload) => ({
  type: REFERRAL_INVITE_INFO.GET_SUCCESS,
  payload
});
export const referrals_invite_info_failed = (payload) => ({
  type: REFERRAL_INVITE_INFO.GET_FAILED,
  payload
});
//#endregion

//#region PROFILES
export const profiles_request = () => ({
  type: PROFILES.GET_REQUEST
});
export const profiles_success = (payload) => ({
  type: PROFILES.GET_SUCCESS,
  payload
});
export const profiles_failed = (payload) => ({
  type: PROFILES.GET_FAILED,
  payload
});
//#endregion

//#region USER_PROFILES.GET
export const user_profiles_get_request = () => ({
  type: USER_PROFILES.GET_REQUEST
});
export const user_profiles_get_success = (payload) => ({
  type: USER_PROFILES.GET_SUCCESS,
  payload
});
export const user_profiles_get_failed = (payload) => ({
  type: USER_PROFILES.GET_FAILED,
  payload
});
//#endregion

//#region USER_PROFILES.POST
export const user_profiles_post_request = (payload) => ({
  type: USER_PROFILES.POST_REQUEST,
  payload
});
export const user_profiles_post_success = (payload) => ({
  type: USER_PROFILES.POST_SUCCESS,
  payload
});
export const user_profiles_post_failed = (payload) => ({
  type: USER_PROFILES.POST_FAILED,
  payload
});
//#endregion

//#region ACTIVATE_PROFILE
export const activate_profile_request = (payload) => ({
  type: ACTIVATE_PROFILE.GET_REQUEST,
  payload
});
export const activate_profile_success = (payload) => ({
  type: ACTIVATE_PROFILE.GET_SUCCESS,
  payload
});
export const activate_profile_failed = (payload) => ({
  type: ACTIVATE_PROFILE.GET_FAILED,
  payload
});
//#endregion

//#region LOGIN
export const loginRequest = (payload) => ({
  type: LOGIN.POST_REQUEST,
  payload
});
export const loginSuccess = (payload) => ({
  type: LOGIN.POST_SUCCESS,
  payload
});
export const loginFailed = (payload) => ({
  type: LOGIN.POST_FAILED,
  payload
});
//#endregion

//#region SIGN_UP
export const signupRequest = (payload) => ({
  type: SIGN_UP.POST_REQUEST,
  payload
});
export const signupSuccess = (payload) => ({
  type: SIGN_UP.POST_SUCCESS,
  payload
});
export const signupFailed = (payload) => ({
  type: SIGN_UP.POST_FAILED,
  payload
});
//#endregion

//#region VALIDATE_EMAIL
export const validateEmailRequest = (payload) => ({
  type: VALIDATE_EMAIL.POST_REQUEST,
  payload
});
export const validateEmailSuccess = (payload) => ({
  type: VALIDATE_EMAIL.POST_SUCCESS,
  payload
});
export const validateEmailFailed = (payload) => ({
  type: VALIDATE_EMAIL.POST_FAILED,
  payload
});
//#endregion

//#region VALIDATE_PHONE
export const validatePhoneRequest = (payload) => ({
  type: VALIDATE_PHONE.POST_REQUEST,
  payload
});
export const validatePhoneSuccess = (payload) => ({
  type: VALIDATE_PHONE.POST_SUCCESS,
  payload
});
export const validatePhoneFailed = (payload) => ({
  type: VALIDATE_PHONE.POST_FAILED,
  payload
});
//#endregion

//#region SEND_OTP
export const sentOtpRequest = (payload) => ({
  type: SEND_OTP.POST_REQUEST,
  payload
});
export const sentOtpSuccess = (payload) => ({
  type: SEND_OTP.POST_SUCCESS,
  payload
});
export const sentOtpFailed = (payload) => ({
  type: SEND_OTP.POST_FAILED,
  payload
});
//#endregion

//#region FORGOT_PASSWORD
export const forgot_password_request = (payload) => ({
  type: FORGOT_PASSWORD.POST_REQUEST,
  payload
});
export const forgot_password_success = (payload) => ({
  type: FORGOT_PASSWORD.POST_SUCCESS,
  payload
});
export const forgot_password_failed = (payload) => ({
  type: FORGOT_PASSWORD.POST_FAILED,
  payload
});
//#endregion

//#region RESET_PASSWORD
export const reset_password_request = (payload) => ({
  type: RESET_PASSWORD.POST_REQUEST,
  payload
});
export const reset_password_success = (payload) => ({
  type: RESET_PASSWORD.POST_SUCCESS,
  payload
});
export const reset_password_failed = (payload) => ({
  type: RESET_PASSWORD.POST_FAILED,
  payload
});
//#endregion

//#region CHANGE_EMAIL
export const change_email_request = (payload) => ({
  type: CHANGE_EMAIL.POST_REQUEST,
  payload
});
export const change_email_success = (payload) => ({
  type: CHANGE_EMAIL.POST_SUCCESS,
  payload
});
export const change_email_failed = (payload) => ({
  type: CHANGE_EMAIL.POST_FAILED,
  payload
});
//#endregion

//#region CHANGE_PASSWORD
export const change_password_request = (payload) => ({
  type: CHANGE_PASSWORD.POST_REQUEST,
  payload
});
export const change_password_success = (payload) => ({
  type: CHANGE_PASSWORD.POST_SUCCESS,
  payload
});
export const change_password_failed = (payload) => ({
  type: CHANGE_PASSWORD.POST_FAILED,
  payload
});
//#endregion

//#region CHANGE_PIN
export const change_pin_request = (payload) => ({
  type: CHANGE_PIN.POST_REQUEST,
  payload
});
export const change_pin_success = (payload) => ({
  type: CHANGE_PIN.POST_SUCCESS,
  payload
});
export const change_pin_failed = (payload) => ({
  type: CHANGE_PIN.POST_FAILED,
  payload
});
//#endregion

//#region LANGUAGES
export const languages_request = (payload) => ({
  type: LANGUAGES.GET_REQUEST,
  payload
});
export const languages_success = (payload) => ({
  type: LANGUAGES.GET_SUCCESS,
  payload
});
export const languages_failed = (payload) => ({
  type: LANGUAGES.GET_FAILED,
  payload
});
//#endregion

//#region INTERESTS
export const interests_request = (payload) => ({
  type: INTERESTS.GET_REQUEST,
  payload
});
export const interests_success = (payload) => ({
  type: INTERESTS.GET_SUCCESS,
  payload
});
export const interests_failed = (payload) => ({
  type: INTERESTS.GET_FAILED,
  payload
});
//#endregion

//#region PROFESSIONS
export const professions_request = (payload) => ({
  type: PROFESSIONS.GET_REQUEST,
  payload
});
export const professions_success = (payload) => ({
  type: PROFESSIONS.GET_SUCCESS,
  payload
});
export const professions_failed = (payload) => ({
  type: PROFESSIONS.GET_FAILED,
  payload
});
//#endregion

//#region COUNTRIES
export const countries_request = (payload) => ({
  type: COUNTRIES.GET_REQUEST,
  payload
});
export const countries_success = (payload) => ({
  type: COUNTRIES.GET_SUCCESS,
  payload
});
export const countries_failed = (payload) => ({
  type: COUNTRIES.GET_FAILED,
  payload
});
//#endregion

//#region USER_PROFILE
export const user_profile_get_request = () => ({
  type: USER_PROFILE.GET_REQUEST
});
export const user_profile_get_success = (payload) => ({
  type: USER_PROFILE.GET_SUCCESS,
  payload
});
export const user_profile_get_failed = (payload) => ({
  type: USER_PROFILE.GET_FAILED,
  payload
});
export const user_profile_patch_request = (payload) => ({
  type: USER_PROFILE.PATCH_REQUEST,
  payload
});
export const user_profile_patch_success = (payload) => ({
  type: USER_PROFILE.PATCH_SUCCESS,
  payload
});
export const user_profile_patch_failed = (payload) => ({
  type: USER_PROFILE.PATCH_FAILED,
  payload
});
//#endregion

//#region USER_PROFILE_AVATAR
export const user_profile_avatar_patch_request = (payload) => ({
  type: USER_PROFILE_AVATAR.PATCH_REQUEST,
  payload
});
export const user_profile_avatar_patch_success = (payload) => ({
  type: USER_PROFILE_AVATAR.PATCH_SUCCESS,
  payload
});
export const user_profile_avatar_patch_failed = (payload) => ({
  type: USER_PROFILE_AVATAR.PATCH_FAILED,
  payload
});
//#endregion

//#region USER_PROFILE_MOBILE
export const user_profile_mobile_patch_request = (payload) => ({
  type: USER_PROFILE_MOBILE.PATCH_REQUEST,
  payload
});
export const user_profile_mobile_patch_success = (payload) => ({
  type: USER_PROFILE_MOBILE.PATCH_SUCCESS,
  payload
});
export const user_profile_mobile_patch_failed = (payload) => ({
  type: USER_PROFILE_MOBILE.PATCH_FAILED,
  payload
});
//#endregion

//#region VALIDATE_PIN
export const validate_pin_request = (payload) => ({
  type: VALIDATE_PIN.GET_REQUEST,
  payload
});
export const validate_pin_success = (payload) => ({
  type: VALIDATE_PIN.GET_SUCCESS,
  payload
});
export const validate_pin_failed = (payload) => ({
  type: VALIDATE_PIN.GET_FAILED,
  payload
});
//#endregion

//#region AUTH_PREREQUISITES
export const auth_prerequisites_request = () => ({
  type: AUTH_PREREQUISITES.GET_REQUEST
});
export const auth_prerequisites_success = (payload) => ({
  type: AUTH_PREREQUISITES.GET_SUCCESS,
  payload
});
export const auth_prerequisites_failed = (payload) => ({
  type: AUTH_PREREQUISITES.GET_FAILED,
  payload
});
//#endregion

//#region CLEAR_MESSAGES
export const clear_message_request = () => ({
  type: CLEAR_MESSAGES.REQUEST
});
//#endregion
