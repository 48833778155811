import React, { useState, useRef, useEffect } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import ReactSlider from 'react-slider';
import screenfull from 'screenfull';

import { PlayIcon, SpeakerIcon, MuteIcon, FullScreenIcon } from 'assets/icons/svg';
import PauseIcon from 'assets/img/pause.png';

const VideoPlayer = ({ url, onEnded }) => {
  const [videoState, setVideoState] = useState({
    playing: true,
    volume: 0.8,
    muted: false,
    light: false,
    playbackRate: 1.0
  });

  const [videoVolumeState, setVideoVolumeState] = useState(videoState.volume);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  const playerRef = useRef(null);

  const { playing, volume, muted, light, playbackRate } = videoState;

  const handlePlay = () => setVideoState({ ...videoState, playing: true });

  const handlePause = () => setVideoState({ ...videoState, playing: false });

  const handleVolumeChange = (value) =>
    setVideoState({ ...videoState, volume: parseFloat(value) / 100 });

  const handleToggleMuted = () => {
    setVideoVolumeState(videoState.volume);
    setVideoState({
      ...videoState,
      muted: !videoState.muted,
      volume: videoState.muted ? 0 : videoVolumeState
    });
  };

  const handleClickFullscreen = () => screenfull.toggle(findDOMNode(playerRef.current));

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      handleClickFullscreen();
    }
    return () => (mounted = false);
  }, []);

  return (
    <div className="relative w-full video-player" ref={(player) => (playerRef.current = player)}>
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        playing={playing}
        controls={false}
        light={light}
        volume={volume}
        loop={false}
        muted={muted}
        playbackRate={playbackRate}
        onPlay={handlePlay}
        onDuration={(duration) => {
          setDuration(duration);
        }}
        onProgress={(progress) => {
          setProgress((Math.round(progress.playedSeconds) / duration) * 100);
        }}
        onEnded={() => {
          onEnded();
          screenfull.exit();
          setVideoState({ ...videoState, playing: false });
        }}
        style={{ pointerEvents: 'none' }}
        stopOnUnmount={true}
      />

      <div className="video-player-control absolute bottom-0 w-full flex justify-between items-center p-5 transform transition-all">
        {playing ? (
          <div
            title="Pause"
            className="cursor-pointer px-4"
            onClick={() => {
              handlePause();
            }}
          >
            <img src={PauseIcon} alt="pause" width="23px" />
          </div>
        ) : (
          <div
            title="Play"
            className="cursor-pointer px-4"
            onClick={() => {
              handlePlay();
            }}
          >
            <PlayIcon />
          </div>
        )}
        <ReactSlider
          value={progress}
          defaultValue={progress}
          className="hidden w-full px-4 md:block"
          trackClassName="video-player-track"
          disabled
        />
        <div className="flex justify-end items-center">
          <div className="px-4">
            <span
              title={volume || !muted ? 'Mute' : 'Unmute'}
              className="cursor-pointer"
              onClick={() => {
                handleToggleMuted();
              }}
            >
              {volume || !muted ? <SpeakerIcon /> : <MuteIcon />}
            </span>
          </div>
          <ReactSlider
            value={volume * 100}
            defaultValue={volume * 100}
            onChange={handleVolumeChange}
            className="hidden w-36 px-4 md:block"
            thumbClassName="bg-white rounded-full h-5 w-5 focus:outline-none p-1 -mt-2 cursor-pointer"
            trackClassName="video-player-track"
            renderThumb={(props, state) => <div {...props} title={state.value} />}
          />
          <div className="px-4">
            <span
              title="Full Screen"
              className="cursor-pointer"
              onClick={() => {
                handleClickFullscreen();
              }}
            >
              <FullScreenIcon />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired
};

export default VideoPlayer;
