import React from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import NormalWallet from 'assets/img/noraml_wallet.png';
import RewardWallet from 'assets/img/reward_wallet.png';

const WalletMenu = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'wallet.page.title'
  })}`;

  const { user_profiles } = useSelector((state) => state.authReducers);

  const activeProfile = user_profiles?.find((p) => p.active)?.profile;

  const Card = ({ name, text, link, image, classname }) => (
    <NavLink
      to={`wallet/${link}`}
      className={`w-full lg:w-1/2 bg-white overflow-hidden shadow-sm rounded-lg font-normal ${classname}`}
    >
      <div className="flex flex-col items-center p-10 md:p-20">
        <img src={image} alt={name} className="my-5 w-24" />
        <h3 className="mb-5 text-2xl leading-3">{name}</h3>
        <p className="mx-8 text-sm text-center leading-5">{text}</p>
      </div>
    </NavLink>
  );

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} id="wallet.nav.title" />
        <div className="pt-2 pb-6 mt-4 mx-4 md:mx-10 md:py-10 xl:mx-24">
          <div className="flex justify-around flex-wrap lg:flex-no-wrap">
            {activeProfile?.has_wallet && (
              <Card
                image={NormalWallet}
                link="normal"
                classname="mb-5 lg:mr-5"
                name={intl.formatMessage({
                  id: 'wallet.normal.card.title'
                })}
                text={intl.formatMessage({
                  id: 'wallet.reward.card.text'
                })}
              />
            )}
            <Card
              image={RewardWallet}
              link="reward"
              classname="mb-5 lg:ml-5"
              name={intl.formatMessage({
                id: 'wallet.reward.card.title'
              })}
              text={intl.formatMessage({
                id: 'wallet.reward.card.text'
              })}
            />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default WalletMenu;
