module.exports = {
  /* 00. Generic */
  'general.loading': 'En cours de Chargement',
  'general.all': 'Toute',
  'general.others': 'autres',
  'logout.text': 'Se déconnecter',

  'button.title.next': 'Suivant',
  'button.title.back': 'Précédant',
  'button.title.finish': 'Terminer',
  'button.title.submit': 'Soumettre',

  'button.title.confirm': 'Confirmer',
  'button.title.previous': 'Précédente',
  'button.title.close': 'Fermer',
  'button.title.claim': 'Prétendre',
  'button.title.visit': 'Visite',
  'button.title.cancel': 'Annuler',
  'button.title.dismiss': 'REJETER',
  'button.title.try-again': 'Ressayer',
  'button.title.buy': 'ACHETER',
  'button.title.proceed': 'Continuer',
  'button.title.purchase': 'Achat',
  'button.title.save-changes': 'Enregistrer',

  'error.title': 'Action échoué',
  'network.error.text': 'Erreur réseau inattendue',

  /* 01. Not Found */
  'not-found.text': 'Page non trouvée',

  /* 02. Sign in */
  'signin.page.title': "S'IDENTIFIER",
  'login.text': 'Se connecter',
  'signin.form.title': 'Connection',
  'signin.form.sub-text': "Vous n'avez pas de compte ab?",
  'signin.form.error.email_1': 'Adresse e-mail invalide!',
  'signin.form.error.email_2': 'Email est requis!',
  'signin.form.placeholder.password': 'Mot de passe',
  'signin.form.error.password': 'Mot de passe requis',
  'signin.form.forgot-password': 'Mot de passe oublié?',

  /* 03. Sign Up */
  'signup.page.title': "S'INSCRIRE",
  'signup.text': "S'inscrire",
  'signup.form.title': 'Créer un compte',
  'signup.form.sub-text': 'Déjà Inscrit?',
  'signup.form.error.email_1': 'Adresse e-mail invalide!',
  'signup.form.error.email_2': 'Email est requis!',
  'signup.form.error.email_3': "L'email existe déjà!",
  'signup.form.placeholder.password': 'Mot de passe',
  'signup.form.error.password_1': 'Mot de passe requis!',
  'signup.form.error.password_2': 'Le mot de passe doit comporter au moins 8 caractères!',
  'signup.form.placeholder.confirm_password': 'Confirmer votre mot de Passe',
  'signup.form.error.confirm_password_1': 'Les mots de passe ne correspondent pas!',
  'signup.form.error.confirm_password_2': 'La confirmation du mot de passe est requise!',
  'signup.form.placeholder.gender': 'Sexe',
  'signup.form.gender.male': 'MÂLE',
  'signup.form.gender.female': 'FEMELLE',
  'signup.form.gender.others': 'AUTRES',
  'signup.form.error.gender': 'Le sexe est requis!',
  'signup.form.placeholder.firstname': 'Prénom',
  'signup.form.error.firstname': 'Le prénom est requis!',
  'signup.form.placeholder.lastname': 'Nom de famille',
  'signup.form.error.lastname': 'Le nom de famille est requis!',
  'signup.form.placeholder.pin': 'PIN',
  'signup.form.error.pin_1': 'Le code PIN doit comporter au moins 4 chiffres!',
  'signup.form.error.pin_2': 'Le code PIN ne doit pas dépasser 6 chiffres!',
  'signup.form.error.pin_3': 'PIN invalide!',
  'signup.form.error.pin_4': 'Le code PIN est requis!',
  'signup.form.placeholder.confirm_pin': 'Confirmez votre PIN',
  'signup.form.error.confirm_pin_1': 'Le code PIN ne correspond pas!',
  'signup.form.error.confirm_pin_2': 'Confirmer que le code PIN est requis',
  'signup.form.legal.term.link-text': "J'accepte les Termes et Conditions",
  'signup.form.otp.text': 'Nous vous enverrons un SMS de vérification avec un code à 4 chiffres',
  'signup.form.placeholder.phone': 'Numéro de téléphone',
  'signup.form.error.phone_1': 'Numéro de téléphone invalide!',
  'signup.form.error.phone_2': 'Le numéro de téléphone est obligatoire!',
  'signup.form.error.phone_3': 'Le numéro de téléphone existe déjà!',
  'signup.form.error.profile_id': "L’ID du Profile n'a pas été sélectionné !",
  'signup.form.success.title': 'Inscription réussi',
  'signup.form.verification.text': "Nous venons d'envoyer une vérification à ",
  'signup.form.success.sub-text_1': 'Un courriel a été envoyé à ',
  'signup.form.success.sub-text_2':
    " veuillez vérifier et valider votre compte pour commencer à l'utiliser.",
  'signup.form.success.sub-text_3':
    'Pour vérifier votre compte, vérifiez votre mail pour le lien de vérification.',
  'signup.form.code.unsuccess': 'Je n’ai pas reçu le code.',
  'signup.form.code.resend': 'Renvoyer',

  /* 04. Forgot Password */
  'forget-password.page.title': 'MOT DE PASSE OUBLIÉ',
  'forgot-password.form.title': 'Mot de passe oublié',
  'forgot-password.success.text': 'Email envoyé!',

  /* 05. Reset Password */
  'reset-password.page.title': 'RÉINITIALISER LE MOT DE PASSE',
  'reset-password.form.title': 'Réinitialiser le mot de passe',
  'reset-password.success': 'Mot de passe changé avec succès',

  /* 06. Home */
  'home.page.title': 'Accueil',
  'home.header.title': "Profitez d'une expérience de paiement fluide",
  'home.header.sub-title': 'Raisons pour lesquelles les gens choisissent Reachafrika',
  'home.reason.title_1': 'Transactions Sûres Et Sécurisées',
  'home.reason.text_1':
    'La protection de vos données et de votre vie privée est primordiale pour nous. Tous nos processus sont conformes au PCIDSS de niveau 1 afin de s’assurer que toutes vos transactions soient traitées automatiquement selon les normes maximales de sécurité de paiements en ligne.',
  'home.reason.title_2': "Service D'assistance A La Clientèle 24 / 7",
  'home.reason.text_2':
    "Nous disposons d'une équipe dédiée sur différentes plateformes (email, téléphone, WhatsApp, etc.) pour répondre à vos besoins à tout moment.",
  'home.reason.title_3': 'Gagnez Des Récompenses Incroyables',
  'home.reason.text_3':
    "Gagnez des sommes illimitées en regardant les publicités sur ReachAfrika. L'argent gagné peut être utilisé pour réduire vos paiements. Il n'y a pas de limite au montant que vous pouvez gagner tant qu'il y a suffisamment de publicités qui correspondent à votre profil.",

  'home.section_1.title': 'Simplifier vous la vie avec Reachafrika',
  'home.section_1.text':
    "Effectuez tous vos paiements avec commodité. Que ce soit le paiement de factures, l'achat de crédit et de data ou les souscriptions télés. Faites tout cela et encore plus sur ReachAfrika.",
  'home.quick-action.title': 'Actions Rapides',
  'home.quick-action.telcos.title_1': 'Achetez du crédit',
  'home.quick-action.telcos.title_2': 'Achetez de la Data',
  'home.quick-action.telcos': 'Glo, MTN, Vodafone, Airtel, 9mobile',
  'home.quick-action.utils.title': 'payez des factures',
  'home.quick-action.utils': 'DSTV, Electricité, Iroko TV',
  'home.quick-action.bonus.title': 'Réduire Vos paiements',
  'home.quick-action.bonus': 'Regardez des annonces et gagnez des cadeaux',

  'home.header.link.text_1': 'Achetez du crédit',
  'home.header.link.text_2': 'Achetez de la Data',
  'home.header.link.text_3': 'Régler des factures',
  'home.header.link.text_4': 'Réduisez Vos paiements',

  'home.header.account-bal': 'Solde',
  'home.header.coins-bal': 'Solde points',

  'home.section_2.title': 'le Paiement devient simple',
  'home.section_2.sub-title':
    'Avec ces fonctionnalités étonnantes, vous êtes assuré d’une expérience de paiement formidable.',
  'home.section_2.item_1.title': 'Achat de crédit et de data instantané.',
  'home.section_2.item_1.sub-title':
    'Quel que soit votre réseau, rechargez du crédit et de la data n’importe où !',
  'home.section_2.item_2.title': 'L’abonnement télé devient simple!',
  'home.section_2.item_2.sub-title':
    "Payez vos abonnements DSTV, GoTV and StarTimes en un clin d'oeil",
  'home.section_2.item_3.title': 'Payez vos factures à tout moment',
  'home.section_2.item_3.sub-title': "Avec ReachAfrika, vous aurez toujours de l'électricité",
  'home.section_2.item_4.title': "Vérifiez vos résultats d'Examen",
  'home.section_2.item_4.sub-title': "Achetez tous  vos vouchers d'examen avec Reachafrika",

  'home.section_3.title': 'Comment ça marche',
  'home.section_3.sub-title':
    'Suivez cette vidéo pour voir comment s’inscrire, payer des factures et voir des annonces.',

  'home.section_4.text': 'Gagnez de l’argent en partageant la bonne nouvelle!',

  'home.section_5.title': 'Nos heureux clients',
  'home.section_5.customer_1':
    'En plus des forfaits data abordable, le site est facile à naviguer et à utiliser avec un design convivial.',
  'home.section_5.customer_2':
    "Reachafrika est l'une des meilleures applis de paiement de factures à mon avis, non seulement parce qu'elle est simple et rapide, mais aussi parce que leur forfait data est très abordable.",
  'home.section_5.customer_3':
    "J'ai utilisé la plateforme Reachafrika pour payer mon abonnement télé et mes factures d'électricité, c'est très convivial et rapide.",
  'home.section_5.customer_4':
    'Si vous êtes à la recherche d’une appli qui facilite le paiement de vos factures, Reachafrika est le choix idéal.... Très pratique et fiable ',

  'home.section_6.title': ' Disponible dès maintenant Téléchargez et utiliser.',

  'home.section_7.title': 'Voulez-vous en savoir plus?',
  'home.section_7.text': 'Nous sommes disponible sur WhatsApp, par téléphone et Email.',

  /* 07. about us */
  'about-us.page.title': 'A PROPOS',
  'about-us.header': 'Paiement de factures pratique, sûr & fiable.',
  'about-us.text_1': 'développée pour offrir',
  'about-us.text_2':
    "Les utilisateurs peuvent recharger du crédit, des données, payer leur abonnement télé et bien d'autres choses encore sur la plateforme. Reachafrika est une plateforme développée par Transnational Internet Services Limited et exploitée au Nigeria par Tranis Internet Services Nigeria Limited.",
  'about-us.text_3': 'Reachafrika, Nous vous simplifions la vie...',
  'about-us.section_2.header': 'Notre Mission',
  'about-us.section_2.text':
    'Vous simplifier la vie la vie en offrant des solutions de paiement pratiques, sécurisées et fiables',
  'about-us.section_3.header': 'Vision',
  'about-us.section_3.text':
    ' Bâtir une plate-forme de paiement panafricaine de classe mondiale offrant commodité, sécurité et fiabilité et connectant les personnes, les entreprises et les organisations.',

  /* 08. Prix*/

  /* 09. Proposition de valeur */

  /* 10. footer */
  'footer.hearder_1': 'Adresse',
  'footer.hearder_2': 'Contact',
  'footer.hearder_3': 'Notre Société',
  'footer.nav.link_1': 'A Propos',
  'footer.nav.link_2': 'Politique de confidentialité',
  'footer.nav.link_3': 'Conditions Générales',
  'footer.nav.link_4': 'FAQs',
  'footer.nav.link_5': 'Help Center',
  'footer.download': 'Télécharger',
  'footer.copy': 'Tout droit réservé.',

  /* 11. profile */
  'profile.page.title': 'Profil',

  'profile.page.title.my': 'Mes profils',
  'profile.page.title.add': 'Ajouter un profil',
  'profile.page.title.verify': 'Vérification',

  'profile.update.success': 'Mise à jour réussie',
  'profile.form.placeholder.gender': 'Sexe',
  'profile.form.placeholder.marital_status': 'Situation matrimoniale',
  'profile.form.marital_status.options.married': 'Marié',
  'profile.form.marital_status.options.single': 'Célibataire',
  'profile.form.marital_status.options.divorced': 'Divorcé',
  'profile.form.placeholder.dob': 'Date de Naissance',
  'profile.form.placeholder.education': 'Education (Choisissez le plus haut niveau)',
  'profile.form.placeholder.religion': 'Religion',
  'profile.form.placeholder.professions': 'Professions',
  'profile.form.placeholder.languages': 'Langues',
  'profile.form.placeholder.interests': 'Intérêt',

  /* 11. change password */
  'change-password.page.title': 'Changer de Mot de  Passe',
  'change-password.success': 'Changement de Mot de Passe Réussi ‘’',
  'change-password.form.placeholder.current-password': 'Mot de Passe Actuel',
  'change-password.form.placeholder.new-password': 'Nouveau mot de Passe',
  'change-password.form.placeholder.confirm-new-password': 'Confirmer votre nouveau Mot de Passe',
  'change-password.change.success': 'Le mot de passe a été changé avec succès',

  /* 12. change email */
  'change-email.page.title': 'Changer  d’Email',
  'change-email.success': 'Changement d’Email Réussi ‘',
  'change-email.form.placeholder.current-email': ' Email Actuel',
  'change-email.form.placeholder.new-email': 'Nouveau Email',

  /* 13. change lang */
  'change-lang.page.title': 'Changer de langue',
  'change-lang.page.subtitle': "Changer la langue de l'application",
  'change-lang.page.text':
    'Changer la langue dans cette application convertira tous les textes dans la langue sélectionnée.',

  /* 13. change pin */
  'change-pin.page.title': 'Changement de Pin',
  'change-pin.success': 'Changement de PIN Réussi',
  'change-pin.form.placeholder.current-pin': 'Pin Actuel',
  'change-pin.form.placeholder.new-pin': 'Nouveau Pin',
  'change-pin.form.placeholder.confirm-new-pin': 'Confirmer votre  Nouveau Pin',
  'change-pin.change.success': 'Le code PIN a bien été modifié',

  'change-pin.change.errors_1': 'Le code PIN doit comporter au moins 4 chiffres!',
  'change-pin.change.errors_2': 'Le code PIN ne doit pas dépasser 6 chiffres!',
  'change-pin.change.errors_3': 'Numéro PIN invalide!',
  'change-pin.change.errors_4': 'Le code PIN actuel est requis!',

  /* 14. faqs */
  'faqs.page.title': 'FAQs',

  /* 15. privacy policy */
  'privacy-policy.page.title': 'Politique de Confidentialité',

  /* 16. terms and condition */
  'terms-and-condition.page.title': 'Condition Générales',

  /* 17. help center */
  'help-center.page.title': 'Assistance',
  'help-center.page.header': 'En quoi pouvons-nous vous aider’',
  'help-center.page.text':
    ' Nous avons une équipe dédiée sur diverses plates-formes prête à vous assister immédiate.',

  /* 18. advertise */
  'advertise.menu.page.title': 'Lancer une PUB - MENU',
  'advertise.menu.nav.title': 'lancer une PUB',
  'advertise.menu.card.name_1': 'Campagnes',
  'advertise.menu.card.text_1':
    'Lancer des campagnes publicitaires pour les personnes physiques ,groupes et entreprises ici.',
  'advertise.menu.card.name_2': 'Crédits',
  'advertise.menu.card.text_2': 'Acheter du crédit qui sera utilisé pour acheter lancer des pubs.',
  'advertise.menu.card.name_3': 'Contacts',
  'advertise.menu.card.text_3':
    'Importer et sauvegarder votre liste de contacts pour un accès rapide et facile.',

  'advertise.campaigns.page.title_1': 'lancer une Pub - CAMPAGNES',
  'advertise.campaigns.page.nav.title': 'Campagnes',
  'advertise.campaigns.table.header_1': 'TITRE',
  'advertise.campaigns.table.header_2': 'BUDGET',
  'advertise.campaigns.table.header_3': 'IMPRESSION',
  'advertise.campaigns.table.header_4': 'VUES',
  'advertise.campaigns.table.header_5': 'TYPE',
  'advertise.campaigns.table.header_6': 'STATUT',
  'advertise.campaigns.table.header_7': 'ACTIONS',
  'advertise.campaigns.table.title': 'Campagne Précédente',

  'advertise.campaigns.page.title_2': 'Lancer une PUB - CREER UNE CAMPAGNE',
  'advertise.campaigns.page.title': 'Créer une Campagne',
  'advertise.campaigns.error.title': 'Opération non valide',
  'advertise.campaigns.error.text': 'votre solde est insuffisant.',

  'advertise.campaigns.form.step_1': ' Informations de la Campagne ',
  'advertise.campaigns.form.step_2': 'Audience Cible',
  'advertise.campaigns.form.step_3': 'Budget',
  'advertise.campaigns.form.step_4': 'Vidéo',

  'advertise.campaigns.cost.budget.header': ' Coût & Budget',
  'advertise.campaigns.cost.budget.sub-title_1': 'Crédit Disponible',
  'advertise.campaigns.cost.budget.sub-title_2': 'Crédit de la Campagne ',
  'advertise.campaigns.cost.budget.sub-title_3': 'Solde',
  'advertise.campaigns.form.placeholder.campaign-title': 'Titre de la campagne',
  'advertise.campaigns.form.placeholder.callback-url': 'Callback Url',
  'advertise.campaigns.form.placeholder.campaign-type': 'Type de Campagne',
  'advertise.campaigns.form.campaign-type.options.video': 'vidéo',
  'advertise.campaigns.form.campaign-type.options.flyer': 'flyer',
  'advertise.campaigns.form.placeholder.cta-message': " Message d'appel à l'action ",
  'advertise.campaigns.form.placeholder.description': 'Description',
  'advertise.campaigns.form.step-two.choose-audience': 'Choisissez votre audience',
  'advertise.campaigns.form.step-two.filter': 'Filtrer',
  'advertise.campaigns.form.step-two.use-contact': 'Utiliser des Contacts',
  'advertise.campaigns.form.placeholder.contacts': 'Choisissez un groupe de Contacts',
  'advertise.campaigns.form.placeholder.education': 'Education',
  'advertise.campaigns.form.placeholder.min_age': 'Age Minimum ',
  'advertise.campaigns.form.placeholder.max_age': 'Age Maximum ',
  'advertise.campaigns.form.placeholder.lat': 'Latitude',
  'advertise.campaigns.form.placeholder.lng': 'Longitude',
  'advertise.campaigns.form.religion.options.christianity': 'Chrétien',
  'advertise.campaigns.form.religion.options.islamic': 'Musulman',
  'advertise.campaigns.form.placeholder.budget': 'Budget',
  'advertise.campaigns.form.placeholder.frequency': 'Fréquence',
  'advertise.campaigns.form.placeholder.upload-video': 'Charger une vidéo',
  'advertise.campaigns.form.placeholder.upload-flyer': 'Charger une image',
  'advertise.campaigns.form.placeholder.start-now': 'Commencer maintenant',
  'advertise.campaigns.form.placeholder.start-date': 'Date de Début',
  'advertise.campaigns.form.placeholder.end-date': 'Date de Fin',
  'advertise.campaigns.targets': 'Cibles',
  'advertise.campaigns.potential-reach': 'Portée Potentielle',
  'advertise.campaigns.target-audience': 'Audience Cible',
  'advertise.campaigns.impression': 'Impression',

  'advertise.campaigns.page.title_3': 'Lancer une PUB – STATISTIQUE DE CAMPAIGNES',
  'advertise.campaigns.stat.label.1': 'Vues Total',
  'advertise.campaigns.stat.label.2': 'Impression Totale',
  'advertise.campaigns.stat.label.3': 'Total Clicks',
  'advertise.campaigns.stat.label.4': 'Vues ‘',
  'advertise.campaigns.nav.title_1': 'Statistique de Campagnes',
  'advertise.campaigns.graph_1.title': 'taux de Vues',
  'advertise.campaigns.graph_2.title': 'Click through rate',
  'advertise.campaigns.filter.label': 'Vues',

  'advertise.campaigns.view.page.title': 'LANCER UNE PUB – VUES CAMPAGNES',
  'advertise.campaigns.view.nav.title': 'Informations de la Campagne',
  'advertise.campaigns.view.header_1': 'IMPRESSIONS',
  'advertise.campaigns.view.header_2': 'DEBUT',
  'advertise.campaigns.view.header_3': 'DESCRIPTION',
  'advertise.campaigns.view.header_4': 'VUES',
  'advertise.campaigns.view.header_5': 'FIN',
  'advertise.campaigns.view.header_6': 'APPEL A L’ ACTION',

  'advertise.campaigns.errors.file_1': 'Aucun fichier sélectionné!',
  'advertise.campaigns.errors.file_2': 'La taille du fichier est trop grande!',
  'advertise.campaigns.errors.file_3': 'Format de fichier non pris en charge!',

  'advertise.campaigns.credit.errors_1': 'Invalid number',
  'advertise.campaigns.credit.errors_2': 'Le montant doit être supérieur à 499',
  'advertise.campaigns.credit.errors_3': 'Le montant est requis!',
  'advertise.campaigns.credit.errors_4': 'Le mode de paiement est requis',
  'advertise.campaigns.credit.errors_5': 'La banque est requise!',

  'advertise.campaigns.camp.errors_1': 'Le titre de la campagne est obligatoire',
  'advertise.campaigns.camp.errors_2_a': 'URL fournie non valide!',
  'advertise.campaigns.camp.errors_2_b': "L'URL de rappel est requise",
  'advertise.campaigns.camp.errors_3': 'Le type de campagne est obligatoire',
  'advertise.campaigns.camp.errors_4': 'Le message CTA est requis',
  'advertise.campaigns.camp.errors_5': 'La description est requise',
  'advertise.campaigns.camp.errors_6': "L'audience est requise",
  'advertise.campaigns.camp.errors_7': 'Le budget un est requis',
  'advertise.campaigns.camp.errors_8': 'La fréquence un est requise',
  'advertise.campaigns.camp.errors_9': 'Les groupes de contact sont requis',
  'advertise.campaigns.camp.errors_10': 'La date de début est requise',
  'advertise.campaigns.camp.errors_11': 'La date de fin est obligatoire',

  'advertise.campaigns.contact.errors_1': 'Le nom du groupe est obligatoire',
  'advertise.campaigns.contact.errors_2': 'Le nom est requis',

  'advertise.contacts.page.title': ' LANCER UNE PUB - CONTACTS',
  'advertise.contacts.nav.title': 'Contacts',
  'advertise.contacts.table.title': 'Liste de Contacts',
  'advertise.contacts.table.add': 'Contacts',
  'advertise.contacts.create.modal.title': 'Créer un Groupe de Contact',
  'advertise.contacts.create.modal.form.placeholder.name': 'Nom de Groupe',
  'advertise.contacts.create.modal.form.placeholder.description': 'Description',
  'advertise.contacts.create.modal.form.placeholder.upload': 'Télécharger un Fichier Excel',

  'advertise.contacts.add.modal.title': 'Ajouter un Nouveau Contact',
  'advertise.contacts.add.modal.form.placeholder.name': 'Nom',
  'advertise.contacts.table.title_2': 'Groupe de Contacts',

  'advertise.contacts.table.header_1': 'NOM',
  'advertise.contacts.table.header_2': 'LA DESCRIPTION',
  'advertise.contacts.table.header_3': 'MEMBRES',
  'advertise.contacts.table.header_4': 'ACTIONS',
  'advertise.contacts.table.header_5': 'TÉLÉPHONE',

  'advertise.credits.page.title': 'LANCER UNE PUB - CREDITS',
  'advertise.credits.success.title': 'Transaction Réussie',
  'advertise.credits.success.text_1': "En attente de l'approbation de l'administrateur",
  'advertise.credits.success.text_2': 'Recharge réussie',
  'advertise.credits.nav.title': 'Crédits',
  'advertise.credits.modal.title': 'Acheter du Crédits',
  'advertise.credits.form.placeholder.amount': 'Montant',
  'advertise.credits.amount': 'Montant',
  'advertise.credits.credit_discount': 'Réduction',
  'advertise.credits.payable': 'Net à Payer',
  'advertise.credits.form.placeholder.method': 'Mode de Règlement',
  'advertise.credits.form.placeholder.bank': 'Banque',
  'advertise.credits.form.placeholder.pop': 'Preuve de Paiement',
  'advertise.credits.supported-banks': 'Banque supportées',
  'advertise.credits.button-submit': 'Payer',
  'advertise.credits.table.title': ' Transactions de Crédits ',

  'advertise.credits.table.header_1': 'Montant (NGN)',
  'advertise.credits.table.header_2': 'Prime (NGN)',
  'advertise.credits.table.header_3': 'La description',
  'advertise.credits.table.header_4': 'Totale (NGN)',
  'advertise.credits.table.header_5': 'Référence',
  'advertise.credits.table.header_6': 'Type',
  'advertise.credits.table.header_7': 'STATUS',
  'advertise.credits.table.header_8': 'DATE',

  'advertise.credits.button-add': 'Ajouter du  Crédit',

  /* 19. shop */
  'pin.confirm.form.title': 'Veuillez confirmer votre  Pin',
  'pin.confirm.error.pin.required': 'Votre PIN es requis!',
  'pin.confirm.error.pin.incorrect': ' Votre PIN es requis!',

  'shop.purchase.error.500': 'La transaction a échoué, veuillez réessayer.',

  'shop.success.alert.earned-text_1': 'Vous avez gagné’',
  'shop.success.alert.earned-text_2': 'Visit to know more about this campaign',

  'shop.products.page.title': 'BOUTIQUE – MENU PRODUITS',
  'shop.products.nav.title': 'Produits Boutique',
  'shop.products.error.title': 'la Requête a échoué, ressayer encore!',
  'shop.products.use_reward': 'Utiliser vos points',
  'shop.products.beneficiary': 'Bénéficiaire',
  'shop.products.select.benefactor': 'Choisir un Bénéficiaire',
  'shop.products.select.beneficiary': 'Choisir un Bénéficiaire',
  'shop.products.save.beneficiary': 'Sauvegarder un Bénéficiaire',
  'shop.products.beneficiary.success': 'Le Bénéficiaire a été Sauvegardé!',
  'shop.products.beneficiary.error':
    'Pas besoin d’Ajouter votre numéro de téléphone comme bénéficiaire ',
  'shop.products.beneficiary.error_1': 'Erreur, Rafraichir',

  /* 19.a shop airtime*/
  'shop.airtime.option.title_1': 'Acheter du Crédit local',
  'shop.airtime.option.text_1': 'Acheter ou envoyer du crédit dans votre pays',
  'shop.airtime.option.title_2': 'Acheter du crédit à l’International',
  'shop.airtime.option.text_2': 'Acheter ou envoyer du crédit dans d’autres pays',
  'shop.airtime.local.page.title': 'Boutique – CREDIT LOCAL',
  'shop.airtime.local.nav.title': 'Crédit local',
  'shop.airtime.local.form.title': 'Acheter du Crédit',

  'shop.airtime.inter.page.title': 'BOUTIQUE – CREDIT INTERNATIONAL ',
  'shop.airtime.inter.nav.title': 'Crédit International',
  'shop.airtime.inter.form.title': 'Acheter du crédit International',

  'shop.airtime.form.ph.network': 'Choisir le Réseau',
  'shop.airtime.form.ph.amount': 'Montant (₦)',
  'shop.airtime.form.ph.country': 'Choisir un Pays',

  'shop.airtime.form.error.network': 'Le réseau est requis!',
  'shop.airtime.form.error.amount': 'Le montant est requis!',
  'shop.airtime.form.error.phone': 'Numéro de téléphone invalide!',
  'shop.airtime.form.error.country': 'Le pays est requis!',

  /* 19.b shop airtime*/
  'shop.airline.page.title': 'BOUTIQUE – BILLET D’AVION',
  'shop.airline.nav.title': 'BILLET D’AVION',

  /* 19.c shop cable-tv*/
  'shop.cable-tv.page.title': 'BOUTIQUE – Télé',
  'shop.cable-tv.nav.title': 'Télé',
  'shop.cable-tv.form.title': 'Payer votre Abonnement Télé ',
  'shop.cable-tv.form.ph.provider': 'Choisir un Fournisseur',
  'shop.cable-tv.form.ph.package': 'Choisir un Bouquet',
  'shop.cable-tv.form.ph.smart_no': 'Numéro Smartcard',
  'shop.cable-tv.form.error.smart_no': 'Numéro Smartcard est requis!',
  'shop.cable-tv.form.ph.smart_name': 'Détenteur SmartCard',

  /* 19.d shop data*/
  'shop.data.page.title': 'SHOP - DATA',
  'shop.data.nav.title': 'Data',
  'shop.data.form.title': 'Recharger de la Data',

  /* 19.e shop education*/
  'shop.education.page.title': 'SHOP - EDUCATION',
  'shop.education.nav.title': 'Education',
  'shop.education.form.title': 'Acheter Code Education',
  'shop.education.form.ph.product_id': ' Type',
  'shop.education.form.ph.type': 'Jamb Type',
  'shop.education.form.ph.account': 'Numéro de Compte',

  /* 19.f shop movies*/
  'shop.movies.page.title': 'SHOP - CINEMA',
  'shop.movies.nav.title': 'Cinéma',
  'shop.movies.form.title': 'Payer l’abonnement Cinéma',

  /* 19.g shop power*/
  'shop.power.page.title': 'BOUTIQUE - ELECTRICITE',
  'shop.power.page.title_2': 'ELECTRICITE',
  'shop.power.page.header': 'Payer de L’Electricité',
  'shop.power.form.placeholder.1': 'Choisir le Distributeur',
  'shop.power.form.placeholder.2': 'Choisir le Type de Compteur',
  'shop.power.form.placeholder.3': 'Numéro du Compteur',
  'shop.power.form.placeholder.4': 'Montant (₦)',
  'shop.power.form.placeholder.6': 'Name',
  'shop.power.form.placeholder.7': 'Address',

  'shop.invoice.card.title_1': 'Facture',
  'shop.invoice.card.title_2': 'Facture',
  'shop.invoice.card.title_3': 'Reduction',
  'shop.invoice.card.title_4': 'frais',
  'shop.invoice.card.title_5': 'Net à Payer',
  'shop.invoice.modal.title': 'FACTURE',

  'shop.error.modal.title': 'ECHEC!',
  'shop.error.modal.text': 'La transaction a échoué. Veuillez ressayer',
  'shop.success.modal.title': 'Succès!',
  'shop.success.modal.text': 'Vous avez gagné',

  /* 20. wallet */
  'wallet.page.title': 'PORTEMONNAIE - MENU',
  'wallet.nav.title': 'Portemonnaiet',
  'wallet.normal.card.title': 'Portemonnaie Normal ',
  'wallet.reward.card.title': 'Point',
  'wallet.reward.card.text': 'Voir le Solde, Recharger le Compte, Voir les transaction précédentes',
  'wallet.account.bal.title': 'Solde',
  'wallet.addfund.form-title': 'Ajouter des Fonds',
  'wallet.addfund.text': 'ou choisir des options disponibles',
  'wallet.select.payment.form-title': 'Choisir le Mode de Règlement',
  'wallet.select.payment.text_1':
    'Ajouter de l’argent à votre portemonnaie en envoyant de l’argent au compte à partir de votre application bancaire ou par USSD.',
  'wallet.select.payment.alert.title': 'Le Numéro de Compte a été Copié',
  'wallet.select.payment.alert.text_1': 'Votre Numéro de Compte',
  'wallet.select.payment.alert.text_2':
    'has been copied to clipboard successfully. <br />Veuillez utiliser votre appli bancaire ou USSD pour crediter votre compte virtuel Reachafrika',
  'wallet.select.payment.text_3': 'Appuyez pour copier le numéro de compte',
  'wallet.select.payment.text_4': 'Carte de Débit/Crédit ',
  'wallet.select.payment.text_5': 'Recharger votre portemonnaie avec une carte de Débit/Crédit',
  'wallet.normal.page.title': 'PORTEMONNAIE - NORMAL',
  'wallet.reward.page.title': ' PORTEMONNAIE - REWARD',
  'wallet.normal.nav.title': 'Normal',
  'wallet.reward.nav.title': 'Point',
  'wallet.table.title': 'Transactions Précédentes',
  'wallet.table.col.h1': 'DATE',
  'wallet.table.col.h2': 'DESCRIPTION',
  'wallet.table.col.h3': 'REFERENCE',
  'wallet.table.col.h4': 'MONTANT',
  'wallet.table.col.h5': 'TRANSACTION',
  'wallet.table.col.h6': 'METHODE',
  'wallet.table.col.h7': 'STATUT',
  'wallet.button.title': 'Ajouter des Fonds',

  /* 21. table */
  'table.error.text': 'Erreur, rafraichir',
  'table.empty.text': 'Aucune Transaction',

  /* 22. dashboard */
  'dashboard.menu.link-title_1': 'Tableau de Bord',
  'dashboard.menu.link-title_2': 'Portemonnaie',
  'dashboard.menu.link-title_2.sublink_1': 'Normal',
  'dashboard.menu.link-title_2.sublink_2': 'Point',
  'dashboard.menu.link-title_3': 'Boutique',
  'dashboard.menu.link-title_4': 'lancer Une Pub',
  'dashboard.menu.link-title_4.sublink_1': 'Campagnes',
  'dashboard.menu.link-title_4.sublink_2': 'Contacts',
  'dashboard.menu.link-title_4.sublink_3': 'Crédits',
  'dashboard.menu.link-title_5': 'Inviter',
  'dashboard.menu.link-title_6': 'Paramétrages',
  'dashboard.bal-display.account.title_1': 'Compte Normal',
  'dashboard.bal-display.account.title_2': 'Compte Point',

  /* 23. pay bills */
  'pay-bills.page.title': 'PAIEMENT DE FACTURE',
  'pay-bills.invite.nav.title': 'Payer des factures',

  /* 24. reduce payment */
  'reduce-payment.page.title': 'REDUIRE  VOS PAIEMENTS',
  'reduce-payment.none.found': 'Aucune Pub Disponible',

  /* 23. invite */
  'invite.page.title': 'INVITER UN AMI',
  'invite.nav.title': 'Inviter',
  'invite.title': 'Inviter un ami',
  'invite.text_1': 'Invitez un ami en partageant le lien avec eux. Vous gagnez ',
  'invite.coins': 'points',
  'invite.text_2': ' pour Inviter',
  'invite.aler.copy': 'Lien d’Invitation Copie',
  'invite.copy-link': 'Copier le Lien',

  /* 23. loading */
  'loading.text_1': 'Traitement en Cours',
  'loading.text_2': 'Vérifiez votre  connexion',
  'loading.text_3': 'Connexion Internet Perdue'
};
