import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string().email('signup.form.error.email_1').required('signup.form.error.email_2'),
  password: Yup.string()
    .required('signup.form.error.password_1')
    .min(8, 'signup.form.error.password_2'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'signup.form.error.confirm_password_1')
    .required('signup.form.error.confirm_password_2'),
  // gender: Yup.string().required('signup.form.error.gender'),
  firstname: Yup.string().required('signup.form.error.firstname'),
  lastname: Yup.string().required('signup.form.error.lastname'),
  pin: Yup.string()
    .min(4, 'signup.form.error.pin_1')
    .max(6, 'signup.form.error.pin_2')
    .matches(/^[0-9]*$/, { message: 'signup.form.error.pin_3' })
    .required('signup.form.error.pin_4'),
  confirm_pin: Yup.string()
    .oneOf([Yup.ref('pin'), null], 'signup.form.error.confirm_pin_1')
    .required('signup.form.error.confirm_pin_2'),
  phone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
      message: 'signup.form.error.phone_1'
    })
    .required('signup.form.error.phone_2'),
  profile_id: Yup.number().required('signup.form.error.profile_id')
});
