import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text24, Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'

function HappyCustomers() {

  const CommentDiv = ({ quoteSvg, name, text, img }) => {
    return (
      <FlexBox w='430px' h='356px' direction='column' justify='center' gap='24px' sx={{ bgcolor: 'white', p: '24px', borderRadius: '24px' }}>
        <FlexBox w='100%' h='60px'><Img src={quoteSvg} h='64px' w='62px' /></FlexBox>
        <Text20>{text}</Text20>
        <FlexBox justify='flex-start' gap='25px' h='62px' w='100%' direction='row'> <Img src={img} minH='64px' mH={'64px'} mW='62px' minW='62px' /> <Text24>{name}</Text24> </FlexBox>
      </FlexBox>
    )
  }
  const contents = [
    {
      quoteSvg: images.opeyemiquote,
      text: 'Other than the cheap data plans provided, the site is easy to navigate through, easy to use with nice design.',
      img: images.opeyemi,
      name: '  Opeyemi Babalola'
    },
    {
      quoteSvg: images.sandraquote,
      text: 'If you are looking for an app that makes your bills payment seamlessly easy Reachafrika is the perfect choice....very convenient and reliable.',
      img: images.sandra,
      name: 'Sandra'
    },
    {
      quoteSvg: images.stephenquote,
      text: 'Reachafrika is one of the best bill payments on my own opinion, not only that it is simple and fast, but it is also their data bundle are very cheap.',
      img: images.stephen,
      name: 'Stephen Ogunniyi'
    },
    {
      quoteSvg: images.johnquote,
      text: 'I used Reachafrika platform to pay for my Dstv subscription and electricity, it’s very user friendly and fast with no stress at all.',
      img: images.john,
      name: 'John Oshiotse'
    },
  ]
  return (
    <VhContainer bgcolor='#F5F6FA'>

      <FlexBox direction='column' justify='center' gap='48px' sx={{py:'100px'}}>
        <Text56 w='100%' align='center'>Happy customers</Text56>

        <FlexBox gap='24px'>
          {contents.map(content => <CommentDiv {...content} />)}

        </FlexBox>

      </FlexBox>
    </VhContainer>
  )
}

export default HappyCustomers