import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Transition from 'components/TransitionGroup/Transition.js';

import { ChevronIcon } from 'assets/icons/svg';

const Dropdown = ({ intl, user, logout }) => {
  const [isOpen, setOpen] = useState(false);

  const menus = [
    {
      title: intl.formatMessage({
        id: 'home.page.title'
      }),
      link: '/home'
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_1'
      }),
      link: '/dashboard'
    },
    {
      title: intl.formatMessage({
        id: 'profile.page.title'
      }),
      link: '/settings/profile'
    },
    {
      title: intl.formatMessage({
        id: 'change-lang.page.title'
      }),
      link: '/settings/language'
    },
    {
      title: intl.formatMessage({
        id: 'change-pin.page.title'
      }),
      link: '/settings/pin'
    },
    {
      title: intl.formatMessage({
        id: 'change-password.page.title'
      }),
      link: '/settings/password'
    },
    {
      title: intl.formatMessage({
        id: 'logout.text'
      }),
      color: 'red',
      action: () => logout()
    }
  ];

  return (
    <>
      <div className="relative inline-block text-left">
        {isOpen && (
          <button
            className="inset-0 fixed w-full focus:shadow-none cursor-default"
            onClick={() => setOpen(false)}
          />
        )}
        <div className="pb-8 border-b border-gray-100">
          <button
            type="button"
            className="flex md:flex-col lg:flex-row flex-shrink-0 pt-4 px-4 w-full focus:outline-none"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setOpen(!isOpen)}
          >
            <div className="flex items-center">
              <img
                className="inline-block h-9 w-9 rounded-full"
                src={user.image}
                alt={user.firstname + ' ' + user.lastname + 'profile avatar'}
              />
            </div>
            <div className="lg:ml-3">
              <div className="flex items-center justify-between">
                <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                  {user.firstname + ' ' + user.lastname}
                </p>
                <div
                  className={`mt-1 transform duration-300 ${
                    isOpen ? '-rotate-180' : '-rotate-270'
                  }`}
                >
                  <ChevronIcon />
                </div>
              </div>
              <p className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 transition ease-in-out duration-150">
                {user?.email}
              </p>
            </div>
          </button>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute origin-top-right z-50 rounded-md shadow-lg right-10 w-40 -mt-10">
            <div
              className="bg-white shadow-xs rounded-md"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {menus.map((e, i) => {
                const color = e.color || 'gray';
                return (
                  <div key={i}>
                    <div className="border-t border-gray-100"></div>
                    <NavLink
                      to={e.link || '#'}
                      onClick={e.action}
                      className={`group flex items-center px-4 py-3 text-sm leading-5 text-${color}-700 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-${color}-100`}
                      role="menuitem"
                    >
                      {e.title}
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};

Dropdown.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

export default Dropdown;
