import getConfig from 'utils/env';
import httpFacade from 'utils/httpFacade';

const { baseURL } = getConfig();

const httpv1 = new httpFacade('https://api.reachafrika.co/api/v1/');
const httpv2 = new httpFacade(baseURL + '/v2/');

export const loginAsync = async (payload) => await httpv1.post({ url: 'login', body: payload });
export const verifyEmailAsync = async (payload) => await httpv1.post({ url: 'valid-email', body: payload });
export const verifyPhoneAsync = async (payload) => await httpv1.post({ url: 'valid-phone', body: payload });
export const sendOTPAsync = async (payload) => await httpv1.post({ url: 'send-otp', body: payload });
export const forgot_password_async = async (payload) => await httpv1.post({ url: 'forgot-password', body: payload });
// export const reset_password_async = async (payload) => await httpv1.put({ url: 'reset-password', body: payload });
export const reset_password_async = async (payload) => await httpv1.post({ url: 'reset-password', body: payload });
export const change_email_async = async (payload) => await httpv1.post({ url: 'change-email', body: payload });
export const change_password_async = async (payload) => await httpv1.post({ url: 'change-password', body: payload });
export const change_pin_async = async (payload) => await httpv1.post({ url: 'change-pin', body: payload });
export const fetch_resource_async = async (payload) => await httpv1.get({ url: `/${payload}` });
export const user_profile_patch_async = async (payload) => await httpv1.post({ url: 'update-user-profile', body: payload });
export const user_profile_mobile_patch_async = async (payload) => await httpv1.post({ url: 'update-user-profile-from-mobile-app', body: payload });
export const user_profile_avatar_patch_async = async (payload) => await httpv1.post({ url: 'update-user-profile-avatar', body: payload });
export const validate_pin_async = async (payload) => await httpv1.get({ url: 'validate-pin', query: payload });

export const auth_prerequisites_async = async () => await httpv2.get({ url: 'auth/prerequisites' });
export const signupAsync = async (payload) => await httpv2.post({ url: 'auth/sign-up', body: payload });
export const profiles_async = async () => await httpv1.get({ url: 'profiles' });
export const user_profiles_get_async = async (payload) => await httpv1.get({ url: 'profiles/by-user', query: payload });
export const user_profiles_post_async = async (payload) => await httpv1.post({ url: 'profiles', body: payload });
export const activate_profile_async = async (payload) => await httpv1.get({ url: `profiles/mark-as-active/${payload}` });
export const referrals_invite_info_async = async (payload) => await httpv2.get({ url: 'referrals/invite-info' });
