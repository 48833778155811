import { Box } from '@mui/material'
import React from 'react'
const Img = ({ src, alt, h, w, mH, mW, minH, minW, ...props }) => {
    // console.log(src)
    return <Box
        {...props}
        component={'img'}
        src={src}
        alt={alt ? alt : ''}
        height={h ? h : '100%'}
        maxHeight={mH ? mH : '100%'}
        width={w ? w : '100%'}
        maxWidth={mW ? mW : '100%'}
        minWidth={minW ? minW : '0%'}
        minHeight={minH ? minH : '0%'}
    />
}
export { Img }