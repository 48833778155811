import React, { useState, useEffect, useRef } from 'react';
import pick from 'lodash/pick';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Stepper } from 'react-form-stepper';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import { UnderLay as Loading } from 'components/Loading';

import {
  TargetsCard,
  CostAndBudgetCard,
  StepOne,
  StepTwo,
  StepThree,
  StepFour
} from 'components/Adverties/Campaigns';

import {
  credit_account_request,
  contacts_groups_get_request,
  interests_request,
  professions_request,
  frequencies_request,
  potential_reach_count_request,
  target_audience_count_request,
  impression_count_request,
  validate_pin_request,
  ads_campaigns_post_request
} from 'redux/actions';

import validationSchema from 'validations/advertise/campaigns.js';

const AdvertiseCreateCampaigns = (props) => {
  const [currentStep, setStep] = useState(0);
  const [budget, setBudget] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isModalOpen, toggleModal] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  const formRef = useRef(null);

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.campaigns.page.title_2'
  })}`;

  // #region SELECTORS
  const {
    error: authError,
    loading: authLoading,
    interests,
    professions,
    isPinCorrect
  } = useSelector((state) => state.authReducers);
  const { loading: walletLoading, credit_account_data } = useSelector(
    (state) => state.walletReducers
  );

  const {
    error: advertiseError,
    loading: advertiseLoading,
    contacts_groups,
    frequencies,
    potential_reach_count,
    target_audience_loading,
    target_audience_count,
    impression_loading,
    impression_count
  } = useSelector((state) => state.advertiseReducers);
  // #endregion

  // #region USEEFFECT
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(credit_account_request());
      dispatch(potential_reach_count_request());
      dispatch(contacts_groups_get_request());
      dispatch(interests_request());
      dispatch(professions_request());
      dispatch(frequencies_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEmpty(credit_account_data)) {
        if (budget <= credit_account_data.balance) {
          setBalance(credit_account_data.balance - budget);
        } else {
          setBudget(credit_account_data.balance);
          alert.show(
            intl.formatMessage({
              id: 'advertise.campaigns.error.text'
            }),
            {
              type: 'error',
              title: intl.formatMessage({
                id: 'advertise.campaigns.error.title'
              })
            }
          );
        }
      }
    }
    return () => (mounted = false);
  }, [credit_account_data, budget, alert, intl]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (authError) {
        const a = authError.code === 500 ? intl.formatMessage({ id: authError?.mgs }) : authError;
        alert.show(a, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }

      if (advertiseError) {
        const a =
          advertiseError?.code === 500
            ? intl.formatMessage({ id: advertiseError?.mgs })
            : advertiseError;
        alert.show(a, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [alert, advertiseError, dispatch, intl, authError]);
  // #endregion

  // #region CONSTANT
  const stepOne = ['title', 'url', 'type', 'message', 'description'];
  const stepTwo = [
    'audience',
    'professions',
    'interests',
    'education',
    'religion',
    'gender',
    'marital_status',
    'min_age',
    'max_age',
    'contacts',
    'lat',
    'lng'
  ];
  const stepThree = ['budget', 'frequency'];
  const stepFour = [
    'type', // for checking
    'media_file',
    'selection_date',
    'start_date',
    'end_date',
    'now',
    'later'
  ];
  // #endregion

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const steps = (props) => {
    const {
      values,
      errors,
      touched,
      dirty,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      isSubmitting
    } = props;
    switch (currentStep) {
      case 0:
        return (
          <StepOne
            intl={intl}
            dirty={dirty}
            setStep={setStep}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={pick(values, stepOne)}
            errors={pick(errors, stepOne)}
            touched={pick(touched, stepOne)}
            setFieldTouched={setFieldTouched}
          />
        );
      case 1:
        return (
          <StepTwo
            intl={intl}
            setStep={setStep}
            handleBlur={handleBlur}
            handleChange={handleChange}
            interestOpts={interests}
            setFieldValue={setFieldValue}
            professionOpts={professions}
            values={pick(values, stepTwo)}
            errors={pick(errors, stepTwo)}
            contactGroupOpts={contacts_groups}
            touched={pick(touched, stepTwo)}
            setFieldTouched={setFieldTouched}
            dispatch={dispatch}
            action={target_audience_count_request}
            count={target_audience_count}
          />
        );
      case 2:
        return (
          <StepThree
            intl={intl}
            setStep={setStep}
            credit_bal={credit_account_data?.balance}
            setBudget={setBudget}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            frequencyOpts={frequencies}
            values={pick(values, stepThree)}
            errors={pick(errors, stepThree)}
            setFieldTouched={setFieldTouched}
            touched={pick(touched, stepThree)}
            dispatch={dispatch}
            action={impression_count_request}
          />
        );
      case 3:
        return (
          <StepFour
            intl={intl}
            isValid={isValid}
            loading={authLoading || advertiseLoading}
            setStep={setStep}
            dispatch={dispatch}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            action={validate_pin_request}
            isPinCorrect={isPinCorrect}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={pick(values, stepFour)}
            errors={pick(errors, stepFour)}
            touched={pick(touched, stepFour)}
            isSubmitting={isSubmitting}
            handleSumbit={handleFormSubmit}
          />
        );
      default:
        return null;
    }
  };

  const hasValue = (v) => {
    if (typeof v === 'object') {
      if (isEmpty(v)) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!v) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      {authLoading || advertiseLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.campaigns.page.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:py-10 xl:mx-24">
            <div className="flex flex-wrap xl:flex-row xl:flex-no-wrap mt-1 ml-2">
              <div className="bg-white w-full xl:w-4/6 xl:mr-2 rounded-md">
                <div className="mt-4 w-4/5 mx-auto">
                  <Stepper
                    steps={[
                      {
                        label: intl.formatMessage({
                          id: 'advertise.campaigns.form.step_1'
                        })
                      },
                      {
                        label: intl.formatMessage({
                          id: 'advertise.campaigns.form.step_2'
                        })
                      },
                      {
                        label: intl.formatMessage({
                          id: 'advertise.campaigns.form.step_3'
                        })
                      },
                      {
                        label: intl.formatMessage({
                          id: 'advertise.campaigns.form.step_4'
                        })
                      }
                    ]}
                    styleConfig={{
                      activeBgColor: '#1976D2',
                      completedBgColor: '#44C4A1'
                    }}
                    connectorStateColors
                    connectorStyleConfig={{
                      activeColor: '#1976D2',
                      completedColor: 'green',
                      size: 2,
                      stepSize: '10rem'
                    }}
                    activeStep={currentStep}
                  />
                </div>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    title: '',
                    url: '',
                    type: '',
                    message: '',
                    description: '',
                    audience: 'filter',
                    professions: [],
                    interests: [],
                    education: '',
                    religion: '',
                    gender: '',
                    marital_status: '',
                    min_age: '',
                    max_age: '',
                    lat: '',
                    lng: '',
                    budget: '',
                    frequency: '',
                    contacts: [],
                    media_file: '',
                    selection_date: 'later',
                    now: '',
                    later: '',
                    start_date: '',
                    end_date: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    const data = Object.assign({}, values);
                    delete data.start_date;
                    delete data.end_date;
                    data.impressions = impression_count;
                    const formdata = new FormData();
                    Object.keys(data).forEach((key) => {
                      if (key === 'media_file') {
                        formdata.append(key, data[key]);
                      } else {
                        formdata.append(key, hasValue(data[key]) ? data[key] : null);
                      }
                    });
                    dispatch(ads_campaigns_post_request(formdata, props.history));
                    resetForm();
                    setBudget(0);
                    setSubmitting(false);
                    toggleModal(false);
                  }}
                >
                  {({ handleSubmit, ...rest }) => (
                    <form className="block flex-wrap" onSubmit={handleSubmit}>
                      {steps(rest)}
                    </form>
                  )}
                </Formik>
              </div>
              <div className="w-full xl:w-2/6 mt-5 xl:mt-0 pb-5 xl:ml-2">
                <TargetsCard
                  intl={intl}
                  ta_loading={target_audience_loading}
                  reach={potential_reach_count}
                  audience={target_audience_count}
                  im_loading={impression_loading}
                  impression={impression_count}
                />
                <CostAndBudgetCard
                  intl={intl}
                  ac_loading={walletLoading}
                  available_credit={credit_account_data.balance || 0}
                  campaign_credit={budget}
                  balance={balance}
                />
              </div>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseCreateCampaigns;
