import React from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import NormalWallet from 'assets/img/noraml_wallet.png';
import RewardWallet from 'assets/img/reward_wallet.png';

const AdvertiseMenu = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.menu.page.title'
  })}`;

  const Card = ({ image, name, text, link }) => (
    <NavLink
      to={`advertise/${link}`}
      className="w-full bg-white overflow-hidden shadow-sm rounded-lg font-normal"
    >
      <div className="flex flex-col items-center p-20 lg:p-14 xl:p-20">
        <img src={image} alt={name} className="my-5 w-24" />
        <h3 className="mb-5 text-2xl leading-3">{name}</h3>
        <p className="mx-1 text-sm text-center leading-5">{text}</p>
      </div>
    </NavLink>
  );

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} id="advertise.menu.nav.title" />
        <div className="pt-2 pb-6 mt-4 mx-4 md:mx-10 md:py-10 xl:mx-24">
          <div className="grid grid-cols-1 grid-rows-3 grid-flow-col gap-4 lg:grid-flow-row lg:grid-cols-2 xl:grid-cols-3 xl:grid-rows-2">
            <Card
              image={NormalWallet}
              name={intl.formatMessage({
                id: 'advertise.menu.card.name_1'
              })}
              text={intl.formatMessage({
                id: 'advertise.menu.card.text_1'
              })}
              link="campaigns"
            />
            <Card
              image={RewardWallet}
              name={intl.formatMessage({
                id: 'advertise.menu.card.name_2'
              })}
              text={intl.formatMessage({
                id: 'advertise.menu.card.text_2'
              })}
              link="credits"
            />
            <Card
              image={NormalWallet}
              name={intl.formatMessage({
                id: 'advertise.menu.card.name_3'
              })}
              text={intl.formatMessage({
                id: 'advertise.menu.card.text_3'
              })}
              link="contacts"
            />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default AdvertiseMenu;
