import React from 'react';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/SettingWrapper';

// COMPONENT IMPORTS
import { EmailIcon, PhoneIcon, WhatsappIcon } from 'assets/icons/svg';

const HelpCenter = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'help-center.page.title'
  })}`;

  const helpMethod = [
    {
      title: 'Email',
      icon: <EmailIcon />,
      href: 'mailto:support@reachafrika.com'
    },
    {
      title: 'Phone',
      icon: <PhoneIcon />,
      href: 'tel:+2348023629563'
    },
    {
      title: 'Whatsapp',
      icon: <WhatsappIcon />,
      href: 'https://wa.me/2349057974461'
    }
  ];

  const Item = ({ data }) =>
    data.map((e, i) => (
      <a
        href={e.href}
        key={i}
        className="flex justify-start p-5 border rounded-md mb-6 hover:bg-gray-100"
      >
        <span className="mr-4">{e.icon}</span>
        <span className="text-ra-dark"> {e.title}</span>
      </a>
    ));

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden" {...props}>
      <main className="flex-1 relative z-0 md:mt-8 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="p-4 xl:p-0 xl:mt-10 xl:ml-20 xl:mr-80">
          <div className="min-w-0 items-center">
            <h4 className="text-md xl:text-xl font-bold">
              {intl.formatMessage({
                id: 'help-center.page.header'
              })}
            </h4>
            <p className="text-sm xl:text-base">
              {intl.formatMessage({
                id: 'help-center.page.text'
              })}
            </p>
          </div>
          <div className="w-full mt-10">
            <Item data={helpMethod} />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default HelpCenter;
