import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

// COMPONENT IMPORTS
import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay as Loading } from 'components/Loading';
import ErrorModal from 'components/Shop/ErrorModal';
import SuccessModal from 'components/Shop/SuccessModal';
import InvoiceCard from 'components/Shop/InvoiceCard';
import PinConfirmModal from 'components/PinConfirmModal';
import Input from 'components/Form/Input';
import PhoneInput from 'components/Form/Phone';
import Select from 'components/Form/Select';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';

import useInvoice from 'hooks/useInvoice';

import MovieImg from 'assets/img/movies.png';

import {
  shop_purchase_request,
  reward_account_request,
  shop_product_items_request,
  validate_pin_request,
  shop_purchase_failed,
  shop_purchase_success
} from 'redux/actions';

// VALIDAITON IMPORTS
import ValidationSchema from 'validations/shop/movies';

const ShopMovies = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  const formRef = useRef(null);

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'shop.movies.page.title'
  })}`;

  //#region STATES
  const [bill, setBill] = useState(0);
  const [payable, setPayable] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [reward, setReward] = useState(0);
  const [useReward, toggleUseReward] = useState(false);
  const [isPinModalOpen, togglePinModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  const [isErrorModalOpen, toggleErrorModal] = useState(false);
  //#endregion

  //#region SELECTORS
  const { error, loading, transaction_error, shop_purchase, shop_product_items } = useSelector(
    (state) => state.shopReducers
  );

  const { loading: authLoading, user, isPinCorrect } = useSelector((state) => state.authReducers);

  const { loading: walletLoading, reward_account_data } = useSelector(
    (state) => state.walletReducers
  );
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(shop_product_items_request({ product: 'iroko' }));
      dispatch(reward_account_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (shop_purchase) {
        toggleErrorModal(false);
        toggleSuccessModal(true);
        setSuccessMessage(shop_purchase);
      }

      if (transaction_error) {
        toggleSuccessModal(false);
        toggleErrorModal(true);
        setErrorMessage(transaction_error);
      }

      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => {
      mounted = false;
      dispatch(shop_purchase_failed(null));
      dispatch(shop_purchase_success(null));
    };
  }, [alert, dispatch, error, intl, shop_purchase, transaction_error]);

  useInvoice(props?.location?.state?.convenience_fee * 1, reward_account_data, bill, useReward, {
    fee: setConvenienceFee,
    reward: setReward,
    payable: setPayable
  });
  //#endregion

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <>
      {loading || authLoading || walletLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="shop.movies.nav.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:py-10 xl:mx-24">
            <div className="flex flex-wrap flex-col-reverse xl:flex-row xl:flex-no-wrap mt-1 ml-2">
              <div className="bg-white w-full xl:w-4/6 xl:mr-2 rounded-md">
                <div className="flex border-b border-gray-300 py-5">
                  <h4 className="font-medium px-5 text-xl">
                    {intl.formatMessage({
                      id: 'shop.movies.form.title'
                    })}
                  </h4>
                </div>
                <div className="flex px-5 md:px-10 py-5">
                  <div className="hidden w-2/12 mr-5 md:block">
                    <img className="w-full" src={MovieImg} alt="Telcos" />
                  </div>
                  <div className="w-full xl:w-10/12 xl:ml-5">
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        package: '',
                        name: user.firstname + ' ' + user.lastname,
                        phone: user.phone
                      }}
                      validationSchema={ValidationSchema}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        dispatch(
                          shop_purchase_request({
                            product: 'iroko',
                            params: {
                              ...values,
                              payable,
                              amount: bill
                            }
                          })
                        );
                        resetForm();
                        setBill(0);
                        setPayable(0);
                        setSubmitting(false);
                        togglePinModal(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        dirty,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit
                      }) => (
                        <form className="block" onSubmit={handleSubmit}>
                          <ErrorModal
                            intl={intl}
                            error={errorMessage}
                            handleClose={toggleErrorModal}
                            isOpen={isErrorModalOpen}
                          />
                          <SuccessModal
                            intl={intl}
                            message={successMessage || 'purchase was successful'}
                            handleClose={toggleSuccessModal}
                            isOpen={isSuccessModalOpen}
                          />
                          <PinConfirmModal
                            intl={intl}
                            loading={loading || authLoading || walletLoading}
                            dispatch={dispatch}
                            isPinCorrect={isPinCorrect}
                            isSubmitting={isSubmitting}
                            isModalOpen={isPinModalOpen}
                            toggleModal={togglePinModal}
                            action={validate_pin_request}
                            handleSumbit={handleFormSubmit}
                          />
                          <div className="flex flex-wrap -mx-2">
                            <div className="w-full p-2">
                              <Input
                                type="text"
                                name="provider"
                                placeholder={intl.formatMessage({
                                  id: 'shop.cable-tv.form.ph.provider'
                                })}
                                inputValue="IROKO"
                                useFloatingLable={false}
                                readOnly
                              />
                            </div>
                            <div className="w-full p-2">
                              <Select
                                name="package"
                                placeholder={intl.formatMessage({
                                  id: 'shop.cable-tv.form.ph.package'
                                })}
                                value={values.package}
                                options={shop_product_items[0]?.subs}
                                setFieldValue={(name, obj) => {
                                  setFieldValue(name, obj.id);
                                  setBill(obj.price * 1);
                                }}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                                optionLabelKey={['name', 'top_up_currency', 'price']}
                                optionValueKey="id"
                              />
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                              <Input
                                type="text"
                                name="name"
                                placeholder={intl.formatMessage({
                                  id: 'advertise.contacts.add.modal.form.placeholder.name'
                                })}
                                inputValue={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.name}
                                touched={touched.name}
                                useFloatingLable={false}
                              />
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                              <PhoneInput
                                name="phone"
                                placeholder={intl.formatMessage({
                                  id: 'signup.form.placeholder.phone'
                                })}
                                value={values.phone}
                                error={errors.phone}
                                touched={touched.phone}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col xl:flex-row">
                            <div className="flex flex-col w-full xl:w-1/2 xl:mr-2">
                              <Checkbox
                                id="use_reward"
                                title={intl.formatMessage({
                                  id: 'shop.products.use_reward'
                                })}
                                action={() => toggleUseReward(!useReward)}
                                className="mb-6 flex items-center"
                              />
                            </div>
                          </div>
                          <div className="flex justify-center mt-2 xl:px-4 xl:w-1/2">
                            <Button
                              title={intl.formatMessage({
                                id: 'button.title.proceed'
                              })}
                              className="border-ra-dark"
                              textColor="text-ra-dark"
                              disabled={!(dirty && isValid) || isSubmitting}
                              click={() => {
                                togglePinModal(!isPinModalOpen);
                              }}
                            />
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <InvoiceCard
                intl={intl}
                bill={bill}
                reward={reward}
                convenienceFee={convenienceFee}
                payable={payable}
              />
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ShopMovies;
