import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import ErrorModal from 'components/Shop/ErrorModal';
import SuccessModal from 'components/Shop/SuccessModal';
import InvoiceCard from 'components/Shop/InvoiceCard';
import StepOne from 'components/Shop/Power/StepOne';
import StepTwo from 'components/Shop/Power/StepTwo';
import { UnderLay as Loading } from 'components/Loading';
import PinConfirmModal from 'components/PinConfirmModal';

import useInvoice from 'hooks/useInvoice';

import PowerImg from 'assets/img/power.png';

import {
  shop_purchase_request,
  reward_account_request,
  shop_product_items_request,
  check_meter_request,
  validate_pin_request,
  shop_purchase_failed,
  shop_purchase_success
} from 'redux/actions';

import PowerValidation from 'validations/shop/power';

const ShopPower = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const alert = useAlert();

  const formRef = useRef(null);

  document.title = `REACHAFRIKA | ${intl
    .formatMessage({
      id: 'shop.power.page.title'
    })
    .toUpperCase()}`;

  //#region STATES
  const [nextScreen, setNextScreen] = useState(0);
  const [payable, setPayable] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [reward, setReward] = useState(0);
  const [bill, setBill] = useState(0);
  const [isPinModalOpen, togglePinModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  const [isErrorModalOpen, toggleErrorModal] = useState(false);

  //#endregion

  //#region SELECTORS
  const {
    error,
    loading,
    transaction_error,
    shop_purchase,
    shop_product_items,
    check_meter_data
  } = useSelector((state) => state.shopReducers);

  const { loading: authLoading, user, isPinCorrect } = useSelector((state) => state.authReducers);

  const { loading: walletLoading, reward_account_data } = useSelector(
    (state) => state.walletReducers
  );
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(shop_product_items_request({ product: 'power' }));
      dispatch(reward_account_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted && check_meter_data) {
      setNextScreen(1);
    }
    return () => (mounted = false);
  }, [check_meter_data]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (shop_purchase) {
        toggleErrorModal(false);
        toggleSuccessModal(true);
        setSuccessMessage(shop_purchase);
      }

      if (transaction_error) {
        toggleSuccessModal(false);
        toggleErrorModal(true);
        setErrorMessage(transaction_error);
      }

      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => {
      mounted = false;
      dispatch(shop_purchase_failed(null));
      dispatch(shop_purchase_success(null));
    };
  }, [alert, dispatch, error, intl, shop_purchase, transaction_error]);

  useInvoice(props?.location?.state?.convenience_fee * 1, reward_account_data, bill, false, {
    fee: setConvenienceFee,
    reward: setReward,
    payable: setPayable
  });
  //#endregion

  const getNextStep = (props) => {
    switch (nextScreen) {
      case 0:
        return (
          <StepOne
            intl={intl}
            data={shop_product_items}
            dispatch={dispatch}
            request={check_meter_request}
            {...props}
          />
        );
      case 1:
        return (
          <StepTwo
            intl={intl}
            toggleModal={togglePinModal}
            isModalOpen={isPinModalOpen}
            data={check_meter_data}
            setBill={setBill}
            {...props}
          />
        );
      default:
        return null;
    }
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <>
      {loading || authLoading || walletLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="shop.power.page.title_2" />
          <div className="text-sm pt-2 pb-6 mx-4 md:py-10 xl:mx-24">
            <div className="flex flex-wrap flex-col-reverse xl:flex-row xl:flex-no-wrap mt-1 ml-2">
              <div className="bg-white w-full xl:w-4/6 xl:mr-2 rounded-md">
                <div className="flex border-b border-gray-300 py-5">
                  <h4 className="font-medium px-5 text-xl">
                    {intl.formatMessage({
                      id: 'shop.power.page.header'
                    })}
                  </h4>
                </div>
                <div className="flex px-5 md:px-10 py-5">
                  <div className="hidden w-2/12 mr-5 md:block">
                    <img className="w-full" src={PowerImg} alt="Telcos" />
                  </div>
                  <div className="w-full xl:w-10/12 xl:ml-5">
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        disco_id: '',
                        vendor_type: 'PREPAID',
                        meter_no: '',
                        amount: '',
                        phone: user.phone
                      }}
                      validationSchema={PowerValidation}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        dispatch(
                          shop_purchase_request({
                            product: 'power',
                            params: {
                              ...values,
                              payable,
                              reference: check_meter_data.orderId
                            }
                          })
                        );
                        resetForm();
                        setSubmitting(false);
                        setNextScreen(0);
                        togglePinModal(false);
                      }}
                    >
                      {({ handleSubmit, ...rest }) => (
                        <form className="block" onSubmit={handleSubmit}>
                          <ErrorModal
                            intl={intl}
                            error={errorMessage}
                            handleClose={toggleErrorModal}
                            isOpen={isErrorModalOpen}
                          />
                          <SuccessModal
                            intl={intl}
                            message={`Power purchase was successful please copy your token ${successMessage?.token}`}
                            handleClose={toggleSuccessModal}
                            isOpen={isSuccessModalOpen}
                          />
                          <PinConfirmModal
                            intl={intl}
                            loading={loading || authLoading || walletLoading}
                            dispatch={dispatch}
                            isPinCorrect={isPinCorrect}
                            isSubmitting={rest.isSubmitting}
                            isModalOpen={isPinModalOpen}
                            toggleModal={togglePinModal}
                            action={validate_pin_request}
                            handleSumbit={handleFormSubmit}
                          />
                          <div className="flex flex-wrap -mx-2">{getNextStep(rest)}</div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>

              <InvoiceCard
                intl={intl}
                bill={bill}
                reward={reward}
                convenienceFee={convenienceFee}
                payable={payable}
              />
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ShopPower;
