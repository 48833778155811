module.exports = {
  /* 00. Generic */
  'general.loading': 'loading',
  'general.all': 'All',
  'general.others': 'others',
  'logout.text': 'Log Out',

  'button.title.next': 'Next',
  'button.title.back': 'Back',
  'button.title.finish': 'Finish',
  'button.title.submit': 'Submit',

  'button.title.confirm': 'Confirm',
  'button.title.previous': 'Previous',
  'button.title.close': 'Close',
  'button.title.claim': 'Claim',
  'button.title.visit': 'Visit',
  'button.title.cancel': 'Cancel',
  'button.title.dismiss': 'DISMISS',
  'button.title.try-again': 'TRY AGAIN',
  'button.title.buy': 'BUY',
  'button.title.proceed': 'Proceed',
  'button.title.purchase': 'Purchase',
  'button.title.save-changes': 'Save Changes',

  'error.title': 'Action Failed',
  'network.error.text': 'Unexpected network error',

  /* 01. Not Found */
  'not-found.text': 'Page not found',

  /* 02. Sign in */
  'signin.page.title': 'LOGIN',
  'login.text': 'Login',
  'signin.form.title': 'Sign In',
  'signin.form.sub-text': 'Don’t have an account?',
  'signin.form.error.email_1': 'Invalid email address!',
  'signin.form.error.email_2': 'Email is required!',
  'signin.form.placeholder.password': 'Password',
  'signin.form.error.password': 'Password is required',
  'signin.form.forgot-password': 'Forgot password',

  /* 03. Sign Up */
  'signup.page.title': 'SIGN UP',
  'signup.text': 'Sign Up',
  'signup.form.title': 'Create account',
  'signup.form.sub-text': 'Already have account?',
  'signup.form.error.email_1': 'Invalid email address!',
  'signup.form.error.email_2': 'Email is required!',
  'signup.form.error.email_3': 'Email already exists!',
  'signup.form.placeholder.password': 'Password',
  'signup.form.error.password_1': 'Password is required!',
  'signup.form.error.password_2': 'Password should at least be 8 characters long!',
  'signup.form.placeholder.confirm_password': 'Confirm Password',
  'signup.form.error.confirm_password_1': 'Passwords do not match!',
  'signup.form.error.confirm_password_2': 'Password confirm is required!',
  'signup.form.placeholder.gender': 'Gender',
  'signup.form.gender.male': 'male',
  'signup.form.gender.female': 'female',
  'signup.form.gender.others': 'others',
  'signup.form.error.gender': 'Gender is required!',
  'signup.form.placeholder.firstname': 'First Name',
  'signup.form.error.firstname': 'First name is required!',
  'signup.form.placeholder.lastname': 'Last Name',
  'signup.form.error.lastname': 'Last name is required!',
  'signup.form.placeholder.pin': 'PIN',
  'signup.form.error.pin_1': 'PIN should at least be 4 number long!',
  'signup.form.error.pin_2': 'PIN should at most be 6 number long!',
  'signup.form.error.pin_3': 'Invalid PIN!',
  'signup.form.error.pin_4': 'PIN is required!',
  'signup.form.placeholder.confirm_pin': 'Confirm your PIN',
  'signup.form.error.confirm_pin_1': 'PIN do not match!',
  'signup.form.error.confirm_pin_2': 'Confirm PIN is required',
  'signup.legal.term.link-text': 'I accept the terms and conditions',
  'signup.otp.text': 'We will send you a one-time SMS verification code via text message',
  'signup.form.placeholder.phone': 'Phone Number',
  'signup.form.error.phone_1': 'Invalid phone number!',
  'signup.form.error.phone_2': 'Phone number is required!',
  'signup.form.error.phone_3': 'Phone number already exists!',
  'signup.form.error.profile_id': 'Profile id not select!',
  'signup.form.success.title': 'Registration Successful',
  'signup.form.verification.text': 'We just sent a verification to ',
  'signup.form.success.sub-text_1': 'An email has been sent to ',
  'signup.form.success.sub-text_2': ' please check and verify you account to start using it.',
  'signup.form.success.sub-text_3':
    'To verify your account check you email for the verification link.',
  'signup.form.code.unsuccess': 'I didn’t receive the code.',
  'signup.form.code.resend': 'Resend',

  /* 04. Forgot Password */
  'forget-password.page.title': 'FORGOT PASSWORD',
  'forgot-password.form.title': 'Forgot your password',
  'forgot-password.success.text': 'Email Sent!',

  /* 05. Reset Password */
  'reset-password.page.title': 'RESET PASSWORD',
  'reset-password.form.title': 'Reset Password',
  'reset-password.success': 'Password Successfully Changed',

  /* 06. Home */
  'home.page.title': 'Home',
  'home.header.title': 'Enjoy a seamless payment experience',
  'home.header.sub-title': 'Reasons why people choose Reachafrika',
  'home.reason.title_1': 'Safe & Secure Transactions',
  'home.reason.text_1':
    'Protecting your data and Privacy is paramount to us. All our processes are PCIDSS level 1 compliant to ensure that all your transactions are automatically accorded the highest level of security in online payments.',
  'home.reason.title_2': '24/7 Customer service Support',
  'home.reason.text_2':
    'We have a dedicated team on various platforms (Email, Phone, WhatsApp, etc.) to attend to your need(s) at any moment.',
  'home.reason.title_3': 'Earn Amazing Rewards',
  'home.reason.text_3':
    'Earn Unlimited cash by watching ads on ReachAfrika. The cash earned can be used to reduce your payments. There is no limit to how much you can earn as long as there are enough ads that match your profile.',

  'home.section_1.title': 'Make your life easier with Reachafrika',
  'home.section_1.text':
    'Make all payments with convenience. From utility bills, Airtime and Internet Top-up, to TV subscriptions. You can do all this and more on ReachAfrika',
  'home.quick-action.title': 'Quick actions',
  'home.quick-action.telcos.title_1': 'Buy Airtime',
  'home.quick-action.telcos.title_2': 'Buy Data',
  'home.quick-action.telcos': 'Glo, MTN, Vodafone, Airtel, 9mobile',
  'home.quick-action.utils.title': 'Pay Bills',
  'home.quick-action.utils': 'DSTV, Electricity, Iroko TV',
  'home.quick-action.bonus.title': 'Reduce Your Payment',
  'home.quick-action.bonus': 'Watch ads and earn money for free',

  'home.header.link.text_1': 'Buy Airtime',
  'home.header.link.text_2': 'Buy Data',
  'home.header.link.text_3': 'Pay Bills',
  'home.header.link.text_4': 'Reduce Payments',

  'home.header.account-bal': 'Account Balance',
  'home.header.coins-bal': 'Coins Balance',

  'home.section_2.title': 'Payment made easy',
  'home.section_2.sub-title':
    'With these amazing features, you are assured of a wonderful payment experience.',
  'home.section_2.item_1.title': 'Instant airtime and data top up',
  'home.section_2.item_1.sub-title':
    'No matter your choice of network, buy airtime and top up your data on the go!',
  'home.section_2.item_2.title': 'TV subscription made easy!',
  'home.section_2.item_2.sub-title': 'Pay your DSTV, GoTV and StarTimes subscription in a jiffy',
  'home.section_2.item_3.title': 'Pay utility bills anywhere, anytime!',
  'home.section_2.item_3.sub-title': 'With ReachAfrika, your power will never go off!',
  'home.section_2.item_4.title': 'Check your Exam Results',
  'home.section_2.item_4.sub-title': 'Buy all your exam results checker on ReachAfrika',

  'home.section_3.title': 'How it works',
  'home.section_3.sub-title': 'Watch to find out how you can sign up, make payments and watch ads.',

  'home.section_4.text': 'Spread good news and make money doing so!',

  'home.section_5.title': 'Happy Customers',
  'home.section_5.customer_1':
    'Other than the cheap data plans provided, the site is easy to navigate through, easy to use with nice design.',
  'home.section_5.customer_2':
    'Reachafrika is one of the best bill payments on my own opinion, not only that it is simple and fast, but it is also their data bundle are very cheap.',
  'home.section_5.customer_3':
    'I used Reachafrika platform to pay for my Dstv subscription and electricity, it’s very user friendly and fast with no stress at all.',
  'home.section_5.customer_4':
    'If you are looking for an app that makes your bills payment seamlessly easy Reachafrika is the perfect choice....very convenient and reliable.',

  'home.section_6.title': 'Available now Download and get started.',

  'home.section_7.title': 'You want More Information?',
  'home.section_7.text': 'You can reach us on whatsApp, phone and email.',

  /* 07. about us */
  'about-us.page.title': 'ABOUT US',
  'about-us.header': 'convenient, secured & reliable bill payment.',
  'about-us.text_1': 'It is built to offer',
  'about-us.text_2':
    'Users can recharge airtime, data, pay Tv bills and so much more on the platform. Reachafrika is a platform powered by Transnational Internet Services Limited and operated in Nigeria by Tranis Internet Services Nigeria Limited.',
  'about-us.text_3': 'Reachafrika, Making life easier...',
  'about-us.section_2.header': 'Our Mission',
  'about-us.section_2.text':
    'Making life easier by providing a convenient, secured and reliable bill payment solution.',
  'about-us.section_3.header': 'Vision Statement',
  'about-us.section_3.text':
    'To build a world class Pan African bill payment platform that offers convenience, security, reliability and connect people, businesses and organizations.',

  /* 08. price */

  /* 09. value proposition */

  /* 10. footer */
  'footer.hearder_1': 'Address',
  'footer.hearder_2': 'Contact',
  'footer.hearder_3': 'Company',
  'footer.nav.link_1': 'About Us',
  'footer.nav.link_2': 'Privacy Policy',
  'footer.nav.link_3': 'Terms & Conditions',
  'footer.nav.link_4': 'FAQs',
  'footer.nav.link_5': 'Help Center',
  'footer.download': 'Download',
  'footer.copy': 'All rights reserved.',

  /* 11. profile */
  'profile.page.title': 'Profile',

  'profile.page.title.my': 'My Profiles',
  'profile.page.title.add': 'Add Profile',
  'profile.page.title.verify': 'Verification',

  'profile.update.success': 'Update Successful',
  'profile.form.placeholder.gender': 'Gender',
  'profile.form.placeholder.marital_status': 'Marital Status',
  'profile.form.marital_status.options.married': 'married',
  'profile.form.marital_status.options.single': 'single',
  'profile.form.marital_status.options.divorced': 'divorced',
  'profile.form.placeholder.dob': 'Date of Birth',
  'profile.form.placeholder.education': 'Education (Select the highest level)',
  'profile.form.placeholder.religion': 'Religion',
  'profile.form.placeholder.professions': 'Professions',
  'profile.form.placeholder.languages': 'Languages',
  'profile.form.placeholder.interests': 'Interests',

  /* 11. change password */
  'change-password.page.title': 'Change Password',
  'change-password.success': 'Password Successfully Changed',
  'change-password.form.placeholder.current-password': 'Current Password',
  'change-password.form.placeholder.new-password': 'New Password',
  'change-password.form.placeholder.confirm-new-password': 'Confirm New Password',
  'change-password.change.success': 'Password changed successfully',

  /* 12. change email */
  'change-email.page.title': 'Change Email',
  'change-email.success': 'Email Successfully Changed',
  'change-email.form.placeholder.current-email': 'Current Email',
  'change-email.form.placeholder.new-email': 'New Email',
  'change-email.change.success': 'Email changed successfully',

  /* 13. change lang */
  'change-lang.page.title': 'Change Language',
  'change-lang.page.subtitle': 'Change App Language',
  'change-lang.page.text':
    'Changing the language in this app will convert all texts into the selected language.',

  /* 13. change pin */
  'change-pin.page.title': 'Change Pin',
  'change-pin.success': 'Pin Successfully Changed',
  'change-pin.form.placeholder.current-pin': 'Current Pin',
  'change-pin.form.placeholder.new-pin': 'New Pin',
  'change-pin.form.placeholder.confirm-new-pin': 'Confirm New Pin',
  'change-pin.change.success': 'Pin changed successfully',

  'change-pin.change.errors_1': 'PIN should at least be 4 number long!',
  'change-pin.change.errors_2': 'PIN should at most be 6 number long!',
  'change-pin.change.errors_3': 'Invalid PIN number!',
  'change-pin.change.errors_4': 'Current PIN is required!',

  /* 14. faqs */
  'faqs.page.title': 'FAQs',
  'knowledge-base.page.title':'Knowledge Base',

  /* 15. privacy policy */
  'privacy-policy.page.title': 'Privacy Policy',

  /* 16. terms and condition */
  'terms-and-condition.page.title': 'Terms & Condition',

  /* 17. help center */
  'help-center.page.title': 'Help Center',
  'help-center.page.header': 'How may we help you',
  'help-center.page.text':
    'We have our dedicated team on various platforms ready to attend to you immediately.',

  /* 18. advertise */
  'advertise.menu.page.title': 'ADVERTISE - MENU',
  'advertise.menu.nav.title': 'Advertise',
  'advertise.menu.card.name_1': 'Campaigns',
  'advertise.menu.card.text_1': 'Advertise campaigns for individual, group, or a company here.',
  'advertise.menu.card.name_2': 'Credits',
  'advertise.menu.card.text_2': 'Buy credits which which will be used for campaign here.',
  'advertise.menu.card.name_3': 'Contacts',
  'advertise.menu.card.text_3':
    'Save or import your lists of contacts for quick and easy access here.',

  'advertise.campaigns.page.title_1': 'ADVERTISE - CAMPAIGNS',
  'advertise.campaigns.page.nav.title': 'Campaigns',
  'advertise.campaigns.table.header_1': 'TITLE',
  'advertise.campaigns.table.header_2': 'BUDGET',
  'advertise.campaigns.table.header_3': 'IMPRESSION',
  'advertise.campaigns.table.header_4': 'VIEWS',
  'advertise.campaigns.table.header_5': 'TYPE',
  'advertise.campaigns.table.header_6': 'STATUS',
  'advertise.campaigns.table.header_7': 'ACTIONS',
  'advertise.campaigns.table.title': 'Previous Campaigns',

  'advertise.campaigns.page.title_2': 'ADVERTISE - CREATE CAMPAIGN',
  'advertise.campaigns.page.title': 'Create Campaign',
  'advertise.campaigns.error.title': 'Invalid Operation',
  'advertise.campaigns.error.text': "Please you don't have enough balance.",

  'advertise.campaigns.form.step_1': 'Campaign Information',
  'advertise.campaigns.form.step_2': 'Target Audience',
  'advertise.campaigns.form.step_3': 'Budget',
  'advertise.campaigns.form.step_4': 'Video',

  'advertise.campaigns.cost.budget.header': 'Cost & Budget',
  'advertise.campaigns.cost.budget.sub-title_1': 'Available Credit',
  'advertise.campaigns.cost.budget.sub-title_2': 'Campaign Credit',
  'advertise.campaigns.cost.budget.sub-title_3': 'Balance',
  'advertise.campaigns.form.placeholder.campaign-title': 'Campaign Title',
  'advertise.campaigns.form.placeholder.callback-url': 'Callback Url',
  'advertise.campaigns.form.placeholder.campaign-type': 'Campaign Type',
  'advertise.campaigns.form.campaign-type.options.video': 'video',
  'advertise.campaigns.form.campaign-type.options.flyer': 'flyer',
  'advertise.campaigns.form.placeholder.cta-message': 'Call To Action Message',
  'advertise.campaigns.form.placeholder.description': 'Description',
  'advertise.campaigns.form.step-two.choose-audience': 'Choose Audience',
  'advertise.campaigns.form.step-two.filter': 'Filter',
  'advertise.campaigns.form.step-two.use-contact': 'Use Contact',
  'advertise.campaigns.form.placeholder.contacts': 'Select Contact Groups',
  'advertise.campaigns.form.placeholder.education': 'Education',
  'advertise.campaigns.form.placeholder.min_age': 'Minimum Age',
  'advertise.campaigns.form.placeholder.max_age': 'Maximum Age',
  'advertise.campaigns.form.placeholder.lat': 'Latitude',
  'advertise.campaigns.form.placeholder.lng': 'Longitude',
  'advertise.campaigns.form.religion.options.christianity': 'christianity',
  'advertise.campaigns.form.religion.options.islamic': 'islamic',
  'advertise.campaigns.form.placeholder.budget': 'Budget',
  'advertise.campaigns.form.placeholder.frequency': 'Frequency',
  'advertise.campaigns.form.placeholder.upload-': 'Upload a File',
  'advertise.campaigns.form.placeholder.upload-video': 'Upload a video',
  'advertise.campaigns.form.placeholder.upload-flyer': 'Upload an image',
  'advertise.campaigns.form.placeholder.start-now': 'Start Now',
  'advertise.campaigns.form.placeholder.start-date': 'Start Date',
  'advertise.campaigns.form.placeholder.end-date': 'End Date',
  'advertise.campaigns.targets': 'Targets',
  'advertise.campaigns.potential-reach': 'Potential Reach',
  'advertise.campaigns.target-audience': 'Target Audience',
  'advertise.campaigns.impression': 'Impression',

  'advertise.campaigns.page.title_3': 'ADVERTISE - CAMPAIGNS STATS',
  'advertise.campaigns.stat.label.1': 'Total Views',
  'advertise.campaigns.stat.label.2': 'Total Impression',
  'advertise.campaigns.stat.label.3': 'Total Clicks',
  'advertise.campaigns.stat.label.4': 'Views of this week',
  'advertise.campaigns.nav.title_1': 'Campaign Statistics',
  'advertise.campaigns.graph_1.title': 'Viewership rate',
  'advertise.campaigns.graph_2.title': 'Click through rate',
  'advertise.campaigns.filter.label': 'Week’s views',

  'advertise.campaigns.view.page.title': 'ADVERTISE - CAMPAIGNS VIEW',
  'advertise.campaigns.view.nav.title': 'Campaign Details',
  'advertise.campaigns.view.header_1': 'IMPRESSIONS',
  'advertise.campaigns.view.header_2': 'START',
  'advertise.campaigns.view.header_3': 'DESCRIPTION',
  'advertise.campaigns.view.header_4': 'VIEWS',
  'advertise.campaigns.view.header_5': 'END',
  'advertise.campaigns.view.header_6': 'CALL TO ACTION',

  'advertise.campaigns.errors.file_1': 'No file selected!',
  'advertise.campaigns.errors.file_2': 'File Size is too large!',
  'advertise.campaigns.errors.file_3': 'Unsupported File Format!',

  'advertise.campaigns.credit.errors_1': 'Invalid number',
  'advertise.campaigns.credit.errors_2': 'Amount must be greater than 499',
  'advertise.campaigns.credit.errors_3': 'Amount is required!',
  'advertise.campaigns.credit.errors_4': 'Payment method is required',
  'advertise.campaigns.credit.errors_5': 'Bank is required!',

  'advertise.campaigns.camp.errors_1': 'Campaign title is required',
  'advertise.campaigns.camp.errors_2_a': 'Invalid URL provided!',
  'advertise.campaigns.camp.errors_2_b': 'Callback url is required',
  'advertise.campaigns.camp.errors_3': 'Campaign type is required',
  'advertise.campaigns.camp.errors_4': 'CTA message is required',
  'advertise.campaigns.camp.errors_5': 'Description is required',
  'advertise.campaigns.camp.errors_6': 'Audience is required',
  'advertise.campaigns.camp.errors_7': 'Budget one is required',
  'advertise.campaigns.camp.errors_8': 'Frequency one is required',
  'advertise.campaigns.camp.errors_9': 'Contact groups is required',
  'advertise.campaigns.camp.errors_10': 'Start date is required',
  'advertise.campaigns.camp.errors_11': 'End date is required',

  'advertise.campaigns.contact.errors_1': 'Group name is required',
  'advertise.campaigns.contact.errors_2': 'Name is required',

  'advertise.contacts.page.title': 'ADVERTISE - CONTACTS',
  'advertise.contacts.nav.title': 'Contacts',
  'advertise.contacts.table.title': 'Contacts List',
  'advertise.contacts.table.add': 'Contact',
  'advertise.contacts.create.modal.title': 'Create Contact Group',
  'advertise.contacts.create.modal.form.placeholder.name': 'Group Name',
  'advertise.contacts.create.modal.form.placeholder.description': 'Description',
  'advertise.contacts.create.modal.form.placeholder.upload': 'Upload Excel file',

  'advertise.contacts.add.modal.title': 'Add New Contact',
  'advertise.contacts.add.modal.form.placeholder.name': 'Name',
  'advertise.contacts.table.title_2': 'Contacts Groups',

  'advertise.contacts.table.header_1': 'NAME',
  'advertise.contacts.table.header_2': 'DESCRIPTION',
  'advertise.contacts.table.header_3': 'MEMBERS',
  'advertise.contacts.table.header_4': 'ACTIONS',
  'advertise.contacts.table.header_5': 'PHONE',

  'advertise.credits.page.title': 'ADVERTISE - CREDITS',
  'advertise.credits.success.title': 'Successful Transaction',
  'advertise.credits.success.text_1': 'Awaiting admin approval',
  'advertise.credits.success.text_2': 'Topup was successful',
  'advertise.credits.nav.title': 'Credits',
  'advertise.credits.modal.title': 'Top Up Credits',
  'advertise.credits.form.placeholder.amount': 'Amount',
  'advertise.credits.amount': 'Amount',
  'advertise.credits.credit_discount': 'Credit Discount',
  'advertise.credits.payable': 'Payable',
  'advertise.credits.form.placeholder.method': 'Payment Method',
  'advertise.credits.form.placeholder.bank': 'Bank',
  'advertise.credits.form.placeholder.pop': 'Proof of payment',
  'advertise.credits.supported-banks': 'Supported Banks',
  'advertise.credits.button-submit': 'Proceed to payment',
  'advertise.credits.table.title': 'Credits Transactions',

  'advertise.credits.table.header_1': 'Amount (NGN)',
  'advertise.credits.table.header_2': 'Bonus (NGN)',
  'advertise.credits.table.header_3': 'Description',
  'advertise.credits.table.header_4': 'Total (NGN)',
  'advertise.credits.table.header_5': 'Reference',
  'advertise.credits.table.header_6': 'Type',
  'advertise.credits.table.header_7': 'STATUS',
  'advertise.credits.table.header_8': 'DATE',

  'advertise.credits.button-add': 'Add Credit',

  /* 19. shop */
  'pin.confirm.form.title': 'Please Confirm Pin',
  'pin.confirm.error.pin.required': 'PIN is required!',
  'pin.confirm.error.pin.incorrect': 'PIN is incorrect!',

  'shop.purchase.error.500': 'Transaction failed, please try again.',

  'shop.success.alert.earned-text_1': 'You have earned',
  'shop.success.alert.earned-text_2': 'Visit to know more about this campaign',

  'shop.products.page.title': 'SHOP - PRODUCTS MENU',
  'shop.products.nav.title': 'Shop Products',
  'shop.products.error.title': 'Request failed, try again!',
  'shop.products.use_reward': 'Use Reward',
  'shop.products.beneficiary': 'Beneficiary',
  'shop.products.select.benefactor': 'Select Benefactor',
  'shop.products.select.beneficiary': 'Select Beneficiary',
  'shop.products.save.beneficiary': 'Save Beneficiary',
  'shop.products.beneficiary.success': 'Beneficiary Saved!',
  'shop.products.beneficiary.error': 'No need to add your own number as beneficiary',
  'shop.products.beneficiary.error_1': 'Unexpected Error, try reloading',

  /* 19.a shop airtime*/
  'shop.airtime.option.title_1': 'Buy Local Airtime',
  'shop.airtime.option.text_1': 'Buy and send airtime within your country',
  'shop.airtime.option.title_2': 'Buy International Airtime',
  'shop.airtime.option.text_2': 'Buy and send airtime across countries',
  'shop.airtime.local.page.title': 'SHOP - LOCAL AIRTIME',
  'shop.airtime.local.nav.title': 'Local Airtime',
  'shop.airtime.local.form.title': 'Buy Airtime',

  'shop.airtime.inter.page.title': 'SHOP - INTERNATIONAL AIRTIME',
  'shop.airtime.inter.nav.title': 'International Airtime',
  'shop.airtime.inter.form.title': 'Buy International Airtime',

  'shop.airtime.form.ph.network': 'Select Network',
  'shop.airtime.form.ph.amount': 'Amount (₦)',
  'shop.airtime.form.ph.country': 'Select Country',

  'shop.airtime.form.error.network': 'Network is required!',
  'shop.airtime.form.error.amount': 'Amount is required!',
  'shop.airtime.form.error.phone': 'Invalid phone number!',
  'shop.airtime.form.error.country': 'Country is required!',

  /* 19.b shop airline*/
  'shop.airline.page.title': 'SHOP - AIRLINE',
  'shop.airline.nav.title': 'Airline',

  /* 19.c shop cable-tv*/
  'shop.cable-tv.page.title': 'SHOP - CABLE TV',
  'shop.cable-tv.nav.title': 'Cable TV',
  'shop.cable-tv.form.title': 'Buy Cable TV',
  'shop.cable-tv.form.ph.provider': 'Select Provider',
  'shop.cable-tv.form.ph.package': 'Select Package',
  'shop.cable-tv.form.ph.smart_no': 'Smart Card Number',
  'shop.cable-tv.form.error.smart_no': 'Smart card number is required!',
  'shop.cable-tv.form.ph.smart_name': 'Smart Card Holder',

  /* 19.d shop data*/
  'shop.data.page.title': 'SHOP - DATA',
  'shop.data.nav.title': 'Data',
  'shop.data.form.title': 'Buy Data',

  /* 19.e shop education*/
  'shop.education.page.title': 'SHOP - EDUCATION',
  'shop.education.nav.title': 'Education',
  'shop.education.form.title': 'Buy Education',
  'shop.education.form.ph.product_id': 'Checker Type',
  'shop.education.form.ph.type': 'Jamb Type',
  'shop.education.form.ph.account': 'Account Number',

  /* 19.f shop movies*/
  'shop.movies.page.title': 'SHOP - MOVIES',
  'shop.movies.nav.title': 'Movies',
  'shop.movies.form.title': 'Buy Movies',

  /* 19.g shop power*/
  'shop.power.page.title': 'SHOP - POWER',
  'shop.power.page.title_2': 'Power',
  'shop.power.page.header': 'Buy Power',
  'shop.power.form.placeholder.1': 'Select Dist. Company',
  'shop.power.form.placeholder.2': 'Select Vendor Type',
  'shop.power.form.placeholder.3': 'Meter Number',
  'shop.power.form.placeholder.4': 'Amount (₦)',
  'shop.power.form.placeholder.6': 'Name',
  'shop.power.form.placeholder.7': 'Address',

  'shop.invoice.card.title_1': 'Invoice',
  'shop.invoice.card.title_2': 'Bill',
  'shop.invoice.card.title_3': 'Discount',
  'shop.invoice.card.title_4': 'Convenience Fee',
  'shop.invoice.card.title_5': 'Net Payable',
  'shop.invoice.modal.title': 'INVOICE',

  'shop.error.modal.title': 'Failed!',
  'shop.error.modal.text': 'Transaction was not successful. Please try again',
  'shop.success.modal.title': 'Success!',
  'shop.success.modal.text': 'You’ve earned',

  /* 20. wallet */
  'wallet.page.title': 'WALLET - MENU',
  'wallet.nav.title': 'Wallet',
  'wallet.normal.card.title': 'Normal Wallet',
  'wallet.reward.card.title': 'Reward Wallet',
  'wallet.reward.card.text': 'View account balance, top up account, view previous transaction',
  'wallet.account.bal.title': 'Account Balance',
  'wallet.addfund.form-title': 'Add Fund',
  'wallet.addfund.text': 'or select from available options',
  'wallet.select.payment.form-title': 'Select payment method',
  'wallet.select.payment.text_1':
    'Fund your wallet by sending money to this account from your bank app or USSD.',
  'wallet.select.payment.alert.title': 'Account number copied',
  'wallet.select.payment.alert.text_1': 'Your account number',
  'wallet.select.payment.alert.text_2':
    'has been copied to clipboard successfully. <br />You may now use your bank app or USSD to debit your reachafrika bank account',
  'wallet.select.payment.text_3': 'Tap to copy account number',
  'wallet.select.payment.text_4': 'Debit/Credit card',
  'wallet.select.payment.text_5': 'Fund wallet through debit/credit card',
  'wallet.normal.page.title': 'WALLET - NORMAL',
  'wallet.reward.page.title': 'WALLET - REWARD',
  'wallet.normal.nav.title': 'Normal',
  'wallet.reward.nav.title': 'Reward',
  'wallet.table.title': 'Previous Transactions',
  'wallet.table.col.h1': 'DATE',
  'wallet.table.col.h2': 'DESCRIPTION',
  'wallet.table.col.h3': 'REFERENCE',
  'wallet.table.col.h4': 'AMOUNT',
  'wallet.table.col.h5': 'TRANSACTION',
  'wallet.table.col.h6': 'METHOD',
  'wallet.table.col.h7': 'STATUS',
  'wallet.table.button.title': 'Fund',

  /* 21. table */
  'table.error.text': 'Error happend, reload',
  'table.empty.text': 'No record made yet',

  /* 22. dashboard */
  'dashboard.menu.link-title_1': 'Dashboard',
  'dashboard.menu.link-title_2': 'Wallet',
  'dashboard.menu.link-title_2.sublink_1': 'Normal',
  'dashboard.menu.link-title_2.sublink_2': 'Reward',
  'dashboard.menu.link-title_3': 'Shop',
  'dashboard.menu.link-title_4': 'Advertise',
  'dashboard.menu.link-title_4.sublink_1': 'Campaigns',
  'dashboard.menu.link-title_4.sublink_2': 'Contacts',
  'dashboard.menu.link-title_4.sublink_3': 'Credits',
  'dashboard.menu.link-title_5': 'Invite',
  'dashboard.menu.link-title_6': 'Settings',
  'dashboard.bal-display.account.title_1': 'Normal Account',
  'dashboard.bal-display.account.title_2': 'Reward Account',

  /* 23. pay bills */
  'pay-bills.page.title': 'PAY BILLS',
  'pay-bills.invite.nav.title': 'Pay Bills',

  /* 24. reduce payment */
  'reduce-payment.page.title': 'REDUCE YOUR PAYMENT',
  'reduce-payment.none.found': 'No Campaign Ads',

  /* 23. invite */
  'invite.page.title': 'INVITE FRIEND',
  'invite.nav.title': 'Invite',
  'invite.title': 'Invite a friend',
  'invite.text_1': 'Invite a friend by copying and sharing the link with them. You earn ',
  'invite.coins': 'coins',
  'invite.text_2': ' for an invite',
  'invite.aler.copy': 'Invite link copied',
  'invite.copy-link': 'Copy Link',

  /* 23. loading */
  'loading.text_1': 'Processing',
  'loading.text_2': 'Please check you internet connection',
  'loading.text_3': 'Internet Connection Lost'
};
