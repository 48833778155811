import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { MdPhotoCamera } from 'react-icons/md';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import PhoneInput from 'components/Form/Phone';
import Select from 'components/Form/Select';
import ReactSelect from 'components/Form/ReactSelect';
import FormButton from 'components/Form/Button';
import ImagePicker from 'components/Form/ImagePicker';

import Thumb from 'components/Thumb';

import { UnderLay as Loading } from 'components/Loading';

import {
  user_profile_get_request,
  user_profile_patch_request,
  user_profile_avatar_patch_request,
  languages_request,
  interests_request,
  professions_request,
  clear_message_request
} from 'redux/actions';

const Profile = (props) => {
  const [avater, setAvater] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'profile.page.title'
  })}`;

  const { error, loading, message, user, languages, interests, professions } = useSelector(
    (state) => state.authReducers
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(user_profile_get_request());
      dispatch(languages_request());
      dispatch(interests_request());
      dispatch(professions_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (error || message) {
        alert.show(
          error?.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error || message,
          {
            type: `${error ? 'error' : 'success'}`,
            title: `${
              error
                ? intl.formatMessage({
                    id: 'error.title'
                  })
                : intl.formatMessage({
                    id: 'profile.update.success'
                  })
            }`
          }
        );
        dispatch(clear_message_request());
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, error, intl, message]);

  const maritalStatusOpts = [
    {
      id: 'single',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.single'
      })
    },
    {
      id: 'married',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.married'
      })
    },
    {
      id: 'divored',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.divorced'
      })
    }
  ];

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle
            intl={intl}
            title={intl.formatMessage({
              id: 'profile.page.title'
            })}
          />
          <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:mx-32 lg:py-10 xl:mx-24">
            <div className="xl:w-4/6 xxl:w-5/6">
              <div className="flex justify-center bg-ra-200 py-5">
                <div className="flex items-end justify-center relative m-0 h-32 w-32">
                  <Thumb
                    className="inline-block h-32 w-32 left-0 top-0 absolute rounded-full"
                    src={user.image}
                    alt={user.firstname + ' ' + user.lastname + 'profile avatar'}
                    imageFile={avater}
                  />
                  <ImagePicker
                    className="opacity-0 z-40 h-32 w-32 left-0 top-0 absolute cursor-pointer"
                    dispatch={dispatch}
                    action={user_profile_avatar_patch_request}
                    setFieldValue={setAvater}
                  />
                  <div className="relative w-32 flex justify-center bg-gray-200 opacity-50">
                    <MdPhotoCamera size="40" color="#000" />
                  </div>
                </div>
              </div>
              <Formik
                enableReinitialize
                initialValues={{
                  firstname: user.firstname || '',
                  lastname: user.lastname || '',
                  email: user.email || '',
                  phone: user.phone || '',
                  // gender: user.gender || '',
                  marital_status: user.marital_status || '',
                  dob: user.dob || '',
                  education: user.education || '',
                  religion: user.religion || '',
                  professions: user.professions?.map((e) => e.profession_id) || [],
                  languages: user.languages?.map((e) => e.language_id) || [],
                  interests: user.interests?.map((e) => e.interest_id) || []
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const data = Object.assign({}, values);
                  data.professions = JSON.stringify(data.professions);
                  data.languages = JSON.stringify(data.languages);
                  data.interests = JSON.stringify(data.interests);
                  dispatch(user_profile_patch_request(data));
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit
                }) => (
                  <form className="block" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap bg-white p-10">
                      <div className="w-full lg:w-1/2 p-4">
                        <Input
                          type="text"
                          name="firstname"
                          placeholder={intl.formatMessage({
                            id: 'signup.form.placeholder.firstname'
                          })}
                          inputValue={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.firstname}
                          touched={touched.firstname}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full lg:w-1/2 p-4">
                        <Input
                          type="text"
                          name="lastname"
                          placeholder={intl.formatMessage({
                            id: 'signup.form.placeholder.lastname'
                          })}
                          inputValue={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.lastname}
                          touched={touched.lastname}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full lg:w-1/2 p-4">
                        <Input
                          type="email"
                          name="email"
                          readOnly
                          placeholder="Email"
                          inputValue={values.email}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full lg:w-1/2 p-4">
                        <PhoneInput
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: 'signup.form.placeholder.phone'
                          })}
                          value={values.phone}
                          error={errors.phone}
                          touched={touched.phone}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full lg:w-1/2 p-4">
                        <Select
                          name="marital_status"
                          placeholder={intl.formatMessage({
                            id: 'profile.form.placeholder.marital_status'
                          })}
                          value={values.marital_status}
                          error={errors.marital_status}
                          touched={touched.marital_status}
                          options={maritalStatusOpts}
                          setFieldValue={(name, obj) => {
                            if (obj.id) {
                              setFieldValue(name, obj.id);
                            }
                          }}
                          setFieldTouched={setFieldTouched}
                          useFloatingLable={false}
                          optionLabelKey="name"
                          optionValueKey="id"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap bg-white p-10 mt-10">
                      <div className="w-full p-4">
                        <Input
                          type="date"
                          name="dob"
                          placeholder={intl.formatMessage({
                            id: 'profile.form.placeholder.dob'
                          })}
                          inputValue={values.dob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.dob}
                          touched={touched.dob}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full p-4">
                        <Select
                          name="education"
                          placeholder={intl.formatMessage({
                            id: 'profile.form.placeholder.education'
                          })}
                          value={values.education}
                          error={errors.education}
                          touched={touched.education}
                          options={['phd', 'mphil', 'hnd', 'degree']}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full p-4">
                        <Select
                          name="religion"
                          placeholder={intl.formatMessage({
                            id: 'profile.form.placeholder.religion'
                          })}
                          value={values.religion}
                          error={errors.religion}
                          touched={touched.religion}
                          options={['christianity', 'islamic', 'others']}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          useFloatingLable={false}
                        />
                      </div>
                      <div className="w-full p-4">
                        <Field
                          component={ReactSelect}
                          options={professions}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          labelKey="name"
                          valueKey="id"
                          multi
                          name="professions"
                          className="w-full"
                          id="professions"
                          title={intl.formatMessage({
                            id: 'profile.form.placeholder.professions'
                          })}
                          value={values.professions}
                        />
                      </div>
                      <div className="w-full p-4">
                        <Field
                          component={ReactSelect}
                          options={languages}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          labelKey="name"
                          valueKey="id"
                          multi
                          className="w-full"
                          id="languages"
                          name="languages"
                          title={intl.formatMessage({
                            id: 'profile.form.placeholder.languages'
                          })}
                          value={values.languages}
                        />
                      </div>
                      <div className="w-full p-4">
                        <Field
                          component={ReactSelect}
                          options={interests}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          labelKey="name"
                          valueKey="id"
                          multi
                          id="interests"
                          name="interests"
                          className="w-full"
                          title={intl.formatMessage({
                            id: 'profile.form.placeholder.interests'
                          })}
                          value={values.interests}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end w-full bg-white pb-13 px-13">
                      <div className="w-full md:w-2/5 xl:w-1/5">
                        <FormButton
                          title={intl.formatMessage({
                            id: 'button.title.save-changes'
                          })}
                          color="bg-ra-dark"
                          disabled={!(dirty && isValid) || isSubmitting}
                          loading={isSubmitting}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default Profile;
