import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import ReactSelect from 'components/Form/ReactSelect';
import Select from 'components/Form/Select';
import Button from 'components/Button';

// ICONS
import { FilterIcon, UsersIcon } from 'assets/icons/svg';

const StepTwo = ({
  intl,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setStep,
  contactGroupOpts,
  professionOpts,
  interestOpts,
  dispatch,
  action,
  count
}) => {
  const triggerAction = (name, data) => {
    const payload = {
      interests: values.interests,
      professions: values.professions,
      education: values.education,
      religion: values.religion,
      gender: values.gender,
      marital_status: values.marital_status,
      min_age: values.min_age,
      max_age: values.max_age
    };
    const formdata = new FormData();
    Object.keys(payload).forEach((key) => {
      formdata.append(key, isEmpty(payload[key]) ? null : JSON.stringify(payload[key]));
    });
    if (name && data) {
      formdata.set(name, JSON.stringify(data));
      dispatch(action(formdata));
    } else {
      dispatch(action());
    }
  };

  const getReligionOpts = [
    {
      id: 'All',
      name: intl.formatMessage({
        id: 'general.all'
      })
    },
    {
      id: 'christianity',
      name: intl.formatMessage({
        id: 'advertise.campaigns.form.religion.options.christianity'
      })
    },
    {
      id: 'islamic',
      name: intl.formatMessage({
        id: 'advertise.campaigns.form.religion.options.islamic'
      })
    }
  ];

  const genderOpts = [
    {
      id: 'All',
      name: intl.formatMessage({
        id: 'general.all'
      })
    },
    {
      id: 'male',
      name: intl.formatMessage({
        id: 'signup.form.gender.male'
      })
    },
    {
      id: 'female',
      name: intl.formatMessage({
        id: 'signup.form.gender.female'
      })
    },
    {
      id: 'others',
      name: intl.formatMessage({
        id: 'signup.form.gender.others'
      })
    }
  ];

  const maritalStatusOpts = [
    {
      id: 'All',
      name: intl.formatMessage({
        id: 'general.all'
      })
    },
    {
      id: 'single',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.single'
      })
    },
    {
      id: 'married',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.married'
      })
    },
    {
      id: 'divored',
      name: intl.formatMessage({
        id: 'profile.form.marital_status.options.divorced'
      })
    }
  ];

  return (
    <div className="p-4">
      <div className="border p-4 mx-4">
        <span>
          {intl.formatMessage({
            id: 'advertise.campaigns.form.step-two.choose-audience'
          })}
        </span>
        <div className="flex justify-between flex-wrap flex-col xl:flex-no-wrap xl:flex-row mt-2 text-ra-dark">
          <button
            type="button"
            className={`flex justify-center items-center border ${
              values.audience === 'filter'
                ? 'border-ra-300 bg-ra-200'
                : 'hover:border-ra-300 hover:bg-ra-200'
            } rounded p-3 w-full xl:w-2/5`}
            onClick={() => {
              setFieldValue('audience', 'filter');
              if (values.contacts) {
                setFieldValue('contacts', []);
              }
              triggerAction();
            }}
          >
            <span className="mr-1 -mt-1">
              <FilterIcon />
            </span>

            {intl.formatMessage({
              id: 'advertise.campaigns.form.step-two.filter'
            })}
          </button>
          <button
            type="button"
            className={`flex justify-center items-center border ${
              values.audience === 'contact'
                ? 'border-ra-300 bg-ra-200'
                : 'hover:border-ra-300 hover:bg-ra-200'
            } rounded p-3 w-full xl:w-2/5`}
            onClick={() => {
              setFieldValue('audience', 'contact');
              setFieldValue('professions', []);
              setFieldValue('interests', []);
              setFieldValue('education', '');
              setFieldValue('religion', '');
              setFieldValue('gender', '');
              setFieldValue('marital_status', '');
              setFieldValue('min_age', '');
              setFieldValue('max_age', '');
              triggerAction();
            }}
          >
            <span className="mr-1 -mt-1">
              <UsersIcon />
            </span>

            {intl.formatMessage({
              id: 'advertise.campaigns.form.step-two.use-contact'
            })}
          </button>
        </div>
      </div>
      <div className="flex flex-wrap mx-1">
        {values.audience === 'contact' ? (
          <div className="w-full p-2">
            <Field
              component={ReactSelect}
              options={contactGroupOpts}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              labelKey="name"
              valueKey="id"
              multi
              intl={intl}
              extraAction={triggerAction}
              className="w-full"
              id="contacts"
              name="contacts"
              title={intl.formatMessage({
                id: 'advertise.campaigns.form.placeholder.contacts'
              })}
              value={values.contacts}
            />
          </div>
        ) : (
          <>
            <div className="w-full lg:w-1/2 p-2">
              <Field
                component={ReactSelect}
                options={professionOpts}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                labelKey="name"
                valueKey="id"
                multi
                intl={intl}
                extraAction={triggerAction}
                className="w-full"
                id="professions"
                name="professions"
                title={intl.formatMessage({
                  id: 'profile.form.placeholder.professions'
                })}
                value={values.professions}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Field
                component={ReactSelect}
                options={interestOpts}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                labelKey="name"
                valueKey="id"
                multi
                intl={intl}
                extraAction={triggerAction}
                className="w-full"
                id="interests"
                name="interests"
                title={intl.formatMessage({
                  id: 'profile.form.placeholder.interests'
                })}
                value={values.interests}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Select
                name="education"
                placeholder={intl.formatMessage({
                  id: 'advertise.campaigns.form.placeholder.education'
                })}
                value={values.education}
                error={errors.education}
                touched={touched.education}
                options={['phd', 'mphil', 'hnd', 'degree']}
                setFieldValue={(name, value) => {
                  setFieldValue(name, value);
                  triggerAction(name, value);
                }}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Select
                name="religion"
                placeholder={intl.formatMessage({
                  id: 'profile.form.placeholder.religion'
                })}
                value={values.religion}
                error={errors.religion}
                touched={touched.religion}
                options={getReligionOpts}
                setFieldValue={(name, value) => {
                  setFieldValue(name, value.id);
                  triggerAction(name, value.id);
                }}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
                optionLabelKey="name"
                optionValueKey="id"
              />
            </div>

            <div className="w-full lg:w-1/2 p-2">
              <Select
                name="gender"
                placeholder={intl.formatMessage({
                  id: 'profile.form.placeholder.gender'
                })}
                value={values.gender}
                error={errors.gender}
                touched={touched.gender}
                options={genderOpts}
                setFieldValue={(name, value) => {
                  setFieldValue(name, value.id);
                  triggerAction(name, value.id);
                }}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
                optionLabelKey="name"
                optionValueKey="id"
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Select
                name="marital_status"
                placeholder={intl.formatMessage({
                  id: 'profile.form.placeholder.marital_status'
                })}
                value={values.marital_status}
                error={errors.marital_status}
                touched={touched.marital_status}
                // TODO: FIXME:
                options={maritalStatusOpts}
                setFieldValue={(name, value) => {
                  setFieldValue(name, value.id);
                  triggerAction(name, value.id);
                }}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
                optionLabelKey="name"
                optionValueKey="id"
              />
            </div>

            <div className="w-full lg:w-1/2 p-2">
              <Input
                type="number"
                id="min_age"
                name="min_age"
                placeholder={intl.formatMessage({
                  id: 'advertise.campaigns.form.placeholder.min_age'
                })}
                inputValue={values.min_age}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  triggerAction(e.target.name, JSON.parse(e.target.value));
                }}
                error={errors.min_age}
                touched={touched.min_age}
                useFloatingLable={false}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                type="number"
                id="max_age"
                name="max_age"
                placeholder={intl.formatMessage({
                  id: 'advertise.campaigns.form.placeholder.max_age'
                })}
                inputValue={values.max_age}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  triggerAction(e.target.name, JSON.parse(e.target.value));
                }}
                error={errors.max_age}
                touched={touched.max_age}
                useFloatingLable={false}
              />
            </div>
          </>
        )}

        <div className="flex w-1/2 p-2">
          <div className="w-1/2 mr-1">
            <Input
              type="number"
              id="lat"
              name="lat"
              step="any"
              placeholder={intl.formatMessage({
                id: 'advertise.campaigns.form.placeholder.lat'
              })}
              inputValue={values.lat}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lat}
              touched={touched.lat}
              useFloatingLable={false}
            />
          </div>

          <div className="w-1/2 ml-1">
            <Input
              type="number"
              id="lng"
              name="lng"
              step="any"
              placeholder={intl.formatMessage({
                id: 'advertise.campaigns.form.placeholder.lng'
              })}
              inputValue={values.lng}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lng}
              touched={touched.lng}
              useFloatingLable={false}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between m-4">
        <div className="w-1/3">
          <Button
            title={intl.formatMessage({
              id: 'button.title.previous'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            click={() => setStep(0)}
          />
        </div>
        <div className="w-1/3">
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            disabled={!count || !isEmpty(errors)}
            click={() => setStep(2)}
          />
        </div>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  contactGroupOpts: PropTypes.array.isRequired,
  professionOpts: PropTypes.array.isRequired,
  interestOpts: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default StepTwo;
