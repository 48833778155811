import React from 'react';
import PropTypes from 'prop-types';
import { CoinsIcon } from 'assets/icons/svg';

const InfoCard = ({ title, className, accountNo, hasIcon = false, value }) => {
  return (
    <div className={`${className} overflow-hidden shadow-sm rounded-lg text-white`}>
      <div className="px-4 py-5 sm:p-6">
        <div className="mb-20 text-base leading-3 font-semibold">{title}</div>
        <div className="text-xs leading-3 font-normal">{accountNo}</div>
        <div className="flex mt-3 items-center">
          {hasIcon && <CoinsIcon />}
          <div className="font-bold text-3xl ml-1">{value}</div>
        </div>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  accountNo: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
  value: PropTypes.string
};

export default InfoCard;
