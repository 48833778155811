import React from 'react';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CopyIcon, CardIcon } from 'assets/icons/svg';

const SelectPayment = ({ intl, alert, isModalOpen, toggleModal, toggleModal2, user, bank }) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      className="sm:max-w-sm sm:w-full md:max-w-lg lg:max-w-xl bg-white sm:p-6 rounded-lg px-4 mt-5 pt-5 pb-4 shadow-xl"
      close={true}
      closeOutside={true}
    >
      <ModalHeader
        title={intl.formatMessage({
          id: 'wallet.select.payment.form-title'
        })}
        className="mt-1 md:mt-3 text-center"
      />
      <ModalBody>
        <div className="bg-white overflow-hidden shadow-sm border rounded-lg font-normal p-3">
          <div className="px-4 py-5 sm:p-6">
            <div className="mb-5 text-base font-bold">
              <p>
                {bank.account_no} - {bank.bank_name}
              </p>
              <p>Reachafrika - {user.firstname + ' ' + user.lastname}</p>
            </div>
            <p className="text-left">
              {intl.formatMessage({
                id: 'wallet.select.payment.text_1'
              })}
            </p>
          </div>
          <CopyToClipboard
            text={bank.account_no}
            onCopy={() =>
              alert.show(
                `<p> ${intl.formatMessage({
                  id: 'wallet.select.payment.alert.text_1'
                })} ${bank.account_no} ${intl.formatMessage({
                  id: 'wallet.select.payment.alert.text_2'
                })}</p>`,
                {
                  type: 'info',
                  title: `${intl.formatMessage({
                    id: 'wallet.select.payment.alert.title'
                  })}`
                }
              )
            }
          >
            <div className="flex items-center p-2 bg-gray-100 mr-10 border-l-4 mx-5 cursor-pointer">
              <span>
                <CopyIcon />
              </span>
              <span className="ml-4">
                {intl.formatMessage({
                  id: 'wallet.select.payment.text_3'
                })}
              </span>
            </div>
          </CopyToClipboard>
        </div>
        <div
          className="bg-white mt-5 cursor-pointer overflow-hidden shadow-sm border rounded-lg font-normal p-3"
          onClick={() => {
            toggleModal(false);
            toggleModal2(true);
          }}
        >
          <div className="flex items-center p-2">
            <div className="rounded-full p-6 bg-ra-300">
              <CardIcon />
            </div>
            <div className="ml-4">
              <h4 className="font-bold text-lg">
                {intl.formatMessage({
                  id: 'wallet.select.payment.text_4'
                })}
              </h4>
              <p>
                {intl.formatMessage({
                  id: 'wallet.select.payment.text_5'
                })}
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SelectPayment;
