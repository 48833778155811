import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  REFERRAL_INVITE_INFO,
  PROFILES,
  USER_PROFILES,
  ACTIVATE_PROFILE,
  LOGIN,
  SIGN_UP,
  VALIDATE_EMAIL,
  VALIDATE_PHONE,
  SEND_OTP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_PIN,
  LANGUAGES,
  INTERESTS,
  PROFESSIONS,
  USER_PROFILE,
  USER_PROFILE_AVATAR,
  COUNTRIES,
  USER_PROFILE_MOBILE,
  VALIDATE_PIN,
  AUTH_PREREQUISITES
} from 'constants/actionTypes';

import {
  referrals_invite_info_success,
  referrals_invite_info_failed,
  profiles_success,
  profiles_failed,
  user_profiles_get_success,
  user_profiles_get_failed,
  user_profiles_post_success,
  user_profiles_post_failed,
  activate_profile_success,
  activate_profile_failed,
  loginSuccess,
  loginFailed,
  signupSuccess,
  signupFailed,
  validateEmailSuccess,
  validateEmailFailed,
  validatePhoneSuccess,
  validatePhoneFailed,
  sentOtpSuccess,
  sentOtpFailed,
  forgot_password_success,
  forgot_password_failed,
  reset_password_success,
  reset_password_failed,
  change_email_success,
  change_email_failed,
  change_password_success,
  change_password_failed,
  change_pin_success,
  change_pin_failed,
  languages_success,
  languages_failed,
  interests_success,
  interests_failed,
  professions_success,
  professions_failed,
  countries_success,
  countries_failed,
  user_profile_get_success,
  user_profile_get_failed,
  user_profile_patch_success,
  user_profile_patch_failed,
  user_profile_avatar_patch_success,
  user_profile_avatar_patch_failed,
  user_profile_mobile_patch_success,
  user_profile_mobile_patch_failed,
  validate_pin_success,
  validate_pin_failed,
  auth_prerequisites_success,
  auth_prerequisites_failed
} from './actions';

import * as Api from './api';

//#region REFERRAL_INVITE_INFO_REQUEST
function* referrals_invite_info_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.referrals_invite_info_async);
    yield put(referrals_invite_info_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(referrals_invite_info_failed(error.data.error));
    } else {
      yield put(referrals_invite_info_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_referrals_invite_info_request() {
  yield takeEvery(REFERRAL_INVITE_INFO.GET_REQUEST, referrals_invite_info_request);
}
//#endregion

//#region PROFILES_REQUEST
function* profiles_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.profiles_async);
    yield put(profiles_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(profiles_failed(error.data.error));
    } else {
      yield put(profiles_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_profiles_request() {
  yield takeEvery(PROFILES.GET_REQUEST, profiles_request);
}
//#endregion

//#region ACTIVATE_PROFILE_REQUEST
function* activate_profiles_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.activate_profile_async, payload);
    yield put(activate_profile_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(activate_profile_failed(error.data.error));
    } else {
      yield put(activate_profile_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    window.location.reload();
  }
}
export function* watch_activate_profiles_request() {
  yield takeEvery(ACTIVATE_PROFILE.GET_REQUEST, activate_profiles_request);
}
//#endregion

//#region USER_PROFILES.GET
function* user_profiles_get_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.user_profiles_get_async);
    localStorage.setItem('_raap', JSON.stringify(res.data));
    yield put(user_profiles_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profiles_get_failed(error.data.error));
    } else {
      yield put(user_profiles_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profiles_get_request() {
  yield takeEvery(USER_PROFILES.GET_REQUEST, user_profiles_get_request);
}
//#endregion

//#region USER_PROFILES.POST
function* user_profiles_post_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.user_profiles_post_async, payload);
    yield put(user_profiles_post_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profiles_post_failed(error.data.error));
    } else {
      yield put(user_profiles_post_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profiles_post_request() {
  yield takeEvery(USER_PROFILES.POST_REQUEST, user_profiles_post_request);
}
//#endregion

//#region USER_ACTIVE_PROFILES
// function* user_active_profile_request({payload}) {
//   try {
//     yield put(showLoading());
//     const res = yield call(Api.user_active_profile_async, payload);
//     localStorage.setItem('_raap', JSON.stringify(res.data));
//     yield put(user_active_profile_success(res.data));
//   } catch (error) {
//     if (error?.data?.error) {
//       yield put(user_active_profile_failed(error.data.error));
//     } else {
//       yield put(user_active_profile_failed({code: 500, mgs: 'network.error.text'}));
//     }
//   } finally {
//     yield put(hideLoading());
//   }
// }
// export function* watch_user_active_profile_request() {
//   yield takeEvery(USER_PROFILES.GET_REQUEST, user_active_profile_request);
// }
//#endregion

//#region LOGIN_REQUEST
function* loginRequest({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.loginAsync, payload);
    const { user, role, token, token_expiry } = res.data;
    localStorage.setItem('_rau', JSON.stringify(user));
    localStorage.setItem('_rat', token);
    localStorage.setItem('_rar', role);
    localStorage.setItem('_rate', token_expiry);
    window.location.reload();
    yield put(loginSuccess(user));
  } catch (error) {
    if (error?.data?.error) {
      yield put(loginFailed(error.data.error));
    } else {
      yield put(loginFailed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watchLoginRequest() {
  yield takeEvery(LOGIN.POST_REQUEST, loginRequest);
}
//#endregion

//#region SIGN_UP_REQUEST
function* signupRequest({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.signupAsync, payload);
    if (res.data) {
      yield put(signupSuccess(res.data));
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(signupFailed(error.data.error));
    } else {
      yield put(signupFailed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watchSignupRequest() {
  yield takeEvery(SIGN_UP.POST_REQUEST, signupRequest);
}
//#endregion

//#region VALIDATE_EMAIL_REQUEST
function* validateEmailRequest({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.verifyEmailAsync, payload);
    yield put(validateEmailSuccess(!res.data.status));
  } catch (error) {
    if (error?.data?.error) {
      yield put(validateEmailFailed(error.data.error));
    } else {
      yield put(validateEmailFailed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watchValidateEmailRequest() {
  yield takeEvery(VALIDATE_EMAIL.POST_REQUEST, validateEmailRequest);
}
//#endregion

//#region VALIDATE_PHONE_REQUEST
function* validatePhoneRequest({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.verifyPhoneAsync, payload);
    yield put(validatePhoneSuccess(!res.data.status));
  } catch (error) {
    if (error?.data?.error) {
      yield put(validatePhoneFailed(error.data.error));
    } else {
      yield put(validatePhoneFailed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watchValidatePhoneRequest() {
  yield takeEvery(VALIDATE_PHONE.POST_REQUEST, validatePhoneRequest);
}
//#endregion

//#region SEND_OTP_REQUEST
function* sendOtpRequest({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.sendOTPAsync, payload);
    yield put(sentOtpSuccess(res.data.otp));
  } catch (error) {
    if (error?.data?.error) {
      yield put(sentOtpFailed(error.data.error));
    } else {
      yield put(sentOtpFailed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watchsendOtpRequest() {
  yield takeEvery(SEND_OTP.POST_REQUEST, sendOtpRequest);
}
//#endregion

//#region FORGOT_PASSWORD
function* forgot_password_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.forgot_password_async, payload);
    yield put(forgot_password_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(forgot_password_failed(error.data.error));
    } else {
      yield put(forgot_password_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_forgot_password_request() {
  yield takeEvery(FORGOT_PASSWORD.POST_REQUEST, forgot_password_request);
}
//#endregion

//#region RESET_PASSWORD
function* reset_password_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.reset_password_async, payload);
    yield put(reset_password_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(reset_password_failed(error.data.error));
    } else {
      yield put(reset_password_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_reset_password_request() {
  yield takeEvery(RESET_PASSWORD.POST_REQUEST, reset_password_request);
}
//#endregion

//#region CHANGE_EMAIL
function* change_email_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.change_email_async, payload);
    yield put(change_email_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(change_email_failed(error.data.error));
    } else {
      yield put(change_email_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_change_email_request() {
  yield takeEvery(CHANGE_EMAIL.POST_REQUEST, change_email_request);
}
//#endregion

//#region CHANGE_PASSWORD
function* change_password_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.change_password_async, payload);
    if (res.data.status === 'complete') {
      yield put(change_password_success('change-password.change.success'));
    } else {
      yield put(change_password_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } catch (error) {
    if (error?.status === 422) {
      let errors = Object.values(error.data.error);
      errors = errors.map((error) => error[0])?.[0];
      yield put(change_password_failed(errors));
    } else {
      yield put(change_password_failed({ code: 500, id: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_change_password_request() {
  yield takeEvery(CHANGE_PASSWORD.POST_REQUEST, change_password_request);
}
//#endregion

//#region CHANGE_PIN
function* change_pin_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.change_pin_async, payload);
    if (res.data.status === 'complete') {
      yield put(change_pin_success('change-pin.change.success'));
    } else {
      yield put(change_pin_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } catch (error) {
    if (error?.status === 422) {
      let errors = Object.values(error.data.error);
      errors = errors.map((error) => error[0])?.[0];
      yield put(change_pin_failed(errors));
    } else {
      yield put(change_pin_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_change_pin_request() {
  yield takeEvery(CHANGE_PIN.POST_REQUEST, change_pin_request);
}
//#endregion

//#region LANGUAGES
function* languages_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_resource_async, 'languages');
    yield put(languages_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(languages_failed(error.data.error));
    } else {
      yield put(languages_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_languages_request() {
  yield takeEvery(LANGUAGES.GET_REQUEST, languages_request);
}
//#endregion

//#region INTERESTS
function* interests_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_resource_async, 'interests');
    yield put(interests_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(interests_failed(error.data.error));
    } else {
      yield put(interests_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_interests_request() {
  yield takeEvery(INTERESTS.GET_REQUEST, interests_request);
}
//#endregion

//#region PROFESSIONS
function* professions_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_resource_async, 'professions');
    yield put(professions_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(professions_failed(error.data.error));
    } else {
      yield put(professions_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_professions_request() {
  yield takeEvery(PROFESSIONS.GET_REQUEST, professions_request);
}
//#endregion

//#region COUNTRIES
function* countries_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_resource_async, 'countries');
    yield put(countries_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(countries_failed(error.data.error));
    } else {
      yield put(countries_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_countries_request() {
  yield takeEvery(COUNTRIES.GET_REQUEST, countries_request);
}
//#endregion

//#region USER_PROFILE.GET_REQUEST
function* user_profile_get_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_resource_async, 'user');
    localStorage.setItem('_rau', JSON.stringify(res.data));
    yield put(user_profile_get_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profile_get_failed(error.data.error));
    } else {
      yield put(user_profile_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profile_get_request() {
  yield takeEvery(USER_PROFILE.GET_REQUEST, user_profile_get_request);
}
//#endregion

//#region USER_PROFILE.PATCH_REQUEST
function* user_profile_patch_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.user_profile_patch_async, payload);
    localStorage.setItem('_rau', JSON.stringify(res.data.user));
    yield put(user_profile_patch_success(res.data.user));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profile_patch_failed(error.data.error));
    } else {
      yield put(user_profile_patch_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profile_patch_request() {
  yield takeEvery(USER_PROFILE.PATCH_REQUEST, user_profile_patch_request);
}
//#endregion

//#region USER_PROFILE_AVATAR.PATCH_REQUEST
function* user_profile_avatar_patch_request({ payload }) {
  try {
    yield put(showLoading());
    const formData = new FormData();
    formData.append('avatar', payload);
    yield call(Api.user_profile_avatar_patch_async, formData);
    yield put(user_profile_avatar_patch_success('Profile image updated successfully'));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profile_avatar_patch_failed(error.data.error));
    } else {
      yield put(user_profile_avatar_patch_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profile_avatar_patch_request() {
  yield takeEvery(USER_PROFILE_AVATAR.PATCH_REQUEST, user_profile_avatar_patch_request);
}
//#endregion

//#region USER_PROFILE_MOBILE.PATCH_REQUEST
function* user_profile_mobile_patch_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.user_profile_mobile_patch_async, payload);
    yield put(user_profile_mobile_patch_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(user_profile_mobile_patch_failed(error.data.error));
    } else {
      yield put(user_profile_mobile_patch_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_user_profile_mobile_patch_request() {
  yield takeEvery(USER_PROFILE_MOBILE.PATCH_REQUEST, user_profile_mobile_patch_request);
}
//#endregion

//#region VALIDATE_PIN.GET_REQUEST
function* validate_pin_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.validate_pin_async, payload);
    yield put(validate_pin_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(validate_pin_failed(error.data.error));
    } else {
      yield put(validate_pin_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_validate_pin_request() {
  yield takeEvery(VALIDATE_PIN.GET_REQUEST, validate_pin_request);
}
//#endregion

//#region AUTH_PREREQUISITES.GET_REQUEST
function* auth_prerequisites_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.auth_prerequisites_async, payload);
    yield put(auth_prerequisites_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(auth_prerequisites_failed(error.data.error));
    } else {
      yield put(auth_prerequisites_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_auth_prerequisites_request() {
  yield takeEvery(AUTH_PREREQUISITES.GET_REQUEST, auth_prerequisites_request);
}
//#endregion

const authSagas = [
  fork(watch_referrals_invite_info_request),
  fork(watch_profiles_request),
  fork(watch_activate_profiles_request),
  fork(watch_user_profiles_get_request),
  fork(watch_user_profiles_post_request),
  fork(watchLoginRequest),
  fork(watchSignupRequest),
  fork(watchValidateEmailRequest),
  fork(watchValidatePhoneRequest),
  fork(watchsendOtpRequest),
  fork(watch_forgot_password_request),
  fork(watch_reset_password_request),
  fork(watch_change_email_request),
  fork(watch_change_password_request),
  fork(watch_change_pin_request),
  fork(watch_languages_request),
  fork(watch_interests_request),
  fork(watch_professions_request),
  fork(watch_countries_request),
  fork(watch_user_profile_patch_request),
  fork(watch_user_profile_avatar_patch_request),
  fork(watch_user_profile_mobile_patch_request),
  fork(watch_validate_pin_request),
  fork(watch_auth_prerequisites_request)
];

export default authSagas;
