import { CAMPAIGNS, CAMPAIGNS_REWARDS, VIEW_CAMPAIGN } from 'constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: null,
  campaigns: [],
  rewards: null,
  view_campaign_post_data: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region CAMPAIGNS
    case CAMPAIGNS.GET_REQUEST:
      return { ...state, loading: true };
    case CAMPAIGNS.GET_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case CAMPAIGNS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CAMPAIGNS_REWARDS
    case CAMPAIGNS_REWARDS.GET_REQUEST:
      return { ...state, loading: true };
    case CAMPAIGNS_REWARDS.GET_SUCCESS:
      return { ...state, loading: false, rewards: action.payload };
    case CAMPAIGNS_REWARDS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VIEW_CAMPAIGN
    case VIEW_CAMPAIGN.POST_REQUEST:
      return { ...state, loading: true };
    case VIEW_CAMPAIGN.POST_SUCCESS:
      return { ...state, loading: false, view_campaign_post_data: action.payload };
    case VIEW_CAMPAIGN.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    default:
      return state;
  }
};
