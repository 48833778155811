import React from 'react'
import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text56 } from 'pages/LandingPage/styles/text'
import PersonalDiv from './PersonalDiv'

function EarnMoreCash() {
    return (
        // <VhContainer>
        //     <FlexBox >
        //         <FlexBox h='fit-content' direction='column' justify='center' gap={{ xs: '20px', lg: '32px' }} w={{ sm: '95%', lg: '85%' }} position='relative'>

        //             <Text56>Earn more
        //                 <Text56 component='span' color='#2CC4F0' position='relative'>
        //                     <Img src={images.erase} h='60px' w={'72px'} props={{ position: 'absolute', right: '10%', top: '-60px' }} />
        //                     cash
        //                 </Text56> by watching Ads </Text56>
        //             <Text20 color='#657585' >
        //                 
        //             </Text20>
        //         </FlexBox>
        //         <Img src={images.landingimg2} h={{ xs: '50%', lg: '75%' }} />
        //     </FlexBox>
        // </VhContainer>
        <PersonalDiv
            headerCompnent={
                <>
                    <Text56>Earn more
                        <Text56 component='span' color='#2CC4F0' position='relative'>
                            <Img src={images.eraser} h='60px' w={'72px'} props={{ position: 'absolute', right: '10%', top: '-60px' }} />
                            {' '}  cash
                        </Text56> by watching Ads </Text56>
                    <Text20 color='#657585' ></Text20>
                </>}
            smallText='Time is cash! Bywatching advertising on the ReachAfrika app, you can earn unlimited money as a reward for your attention. You can lower your payments by using the money you make.'
            bigImage={images.landingimg2}
            direction={{ xs: 'column', lg: 'row' }}
        />
    )
}

export default EarnMoreCash