import * as Yup from 'yup';

export default Yup.object().shape({
  profile_id: Yup.number().required('shop.airtime.form.error.country')
  // phone: Yup.string()
  //   .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
  //     message: 'shop.airtime.form.error.phone'
  //   })
  //   .required('shop.airtime.form.error.phone')
});
