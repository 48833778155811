import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const QACard = ({ action, to, title, subtitle, className, imageSrc }) => {
  return (
    <Link
      to={`/${to}`}
      onClick={(e) => {
        if (action) {
          e.preventDefault();
          action();
        }
      }}
      className={`flex ${className} overflow-hidden shadow-sm rounded-lg bg-white`}
    >
      <div className="w-28">
        <img src={imageSrc} alt={title} className="w-full h-full" />
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="mb-5 text-base leading-3 font-semibold">{title}</div>
        <div className="text-xs leading-3 font-normal">{subtitle}</div>
      </div>
    </Link>
  );
};

QACard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired
};

export default QACard;
