import React from 'react';
import PropTypes from 'prop-types';

const InvoiceCard = ({ intl, bill, reward, convenienceFee, payable }) => {
  return (
    <div className="bg-white w-full xl:w-2/6 mb-5 xl:mb-0 pb-5 xl:ml-2">
      <div className="border-gray-300 rounded-md">
        <div className="flex border-b border-gray-300 py-5">
          <h4 className="font-medium px-5 text-xl">
            {intl.formatMessage({
              id: 'shop.invoice.card.title_1'
            })}
          </h4>
        </div>
        <div className="px-5 mt-2">
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'shop.invoice.card.title_2'
              })}
            </span>
            <span>{bill}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'shop.invoice.card.title_3'
              })}
            </span>
            <span>{reward}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'shop.invoice.card.title_4'
              })}
            </span>
            <span>{convenienceFee}</span>
          </div>
          <div className="flex justify-between bg-gray-100 py-4 px-2 mt-2 font-semibold rounded-md">
            <span>
              {intl.formatMessage({
                id: 'shop.invoice.card.title_5'
              })}
            </span>
            <span>{payable}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

InvoiceCard.propTypes = {
  bill: PropTypes.number.isRequired,
  reward: PropTypes.number.isRequired,
  convenienceFee: PropTypes.number.isRequired,
  payable: PropTypes.number.isRequired
};

export default InvoiceCard;
