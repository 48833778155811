import React from 'react';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Modal, { ModalBody, ModalHeader } from 'components/Modal';

import LocalImg from '../../assets/img/local.png';
import InternationalImg from '../../assets/img/international.png';

const AirtimeOptionModal = ({ intl, handleClose, isOpen, local, international }) => {
  //

  return (
    <Modal
      isModalOpen={isOpen}
      toggleModal={handleClose}
      className="md:absolute sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:top-40 bg-white text-ra-darker rounded-lg pt-5 pb-4 shadow-xl"
      close={true}
      closeOutside={true}
    >
      {/* TODO: title translate */}
      <ModalHeader title="Select Destination" className="text-2xl text-center pb-2 border-b" />
      <ModalBody className="mt-3 sm:mt-4 px-8">
        {!isEmpty(local) && (
          <NavLink
            className="flex pb-4 border-b mb-3 cursor-pointer"
            to={{
              pathname: `/shop/airtime/local`,
              state: { convenience_fee: local.convenience_fee }
            }}
          >
            <img src={LocalImg} alt="Local Airtime" />
            <div className="ml-3">
              <h6 className="text-gray-700 text-lg">
                {intl.formatMessage({
                  id: 'shop.airtime.option.title_1'
                })}
              </h6>
              <p className="text-gray-400 text-sm">
                {intl.formatMessage({
                  id: 'shop.airtime.option.text_1'
                })}
              </p>
            </div>
          </NavLink>
        )}
        {!isEmpty(international) && (
          <NavLink
            className="flex pb-2 cursor-pointer"
            to={{
              pathname: `/shop/airtime/international`,
              state: { convenience_fee: international.convenience_fee }
            }}
          >
            <img src={InternationalImg} alt="International Airtime" />
            <div className="ml-3">
              <h6 className="text-gray-700 text-lg">
                {intl.formatMessage({
                  id: 'shop.airtime.option.title_2'
                })}
              </h6>
              <p className="text-gray-400 text-sm">
                {intl.formatMessage({
                  id: 'shop.airtime.option.text_2'
                })}
              </p>
            </div>
          </NavLink>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AirtimeOptionModal;
