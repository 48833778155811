import React from 'react';

import Modal, { ModalBody } from 'components/Modal';
import Button from 'components/Button';

import { ModalErrorIcon } from 'assets/icons/svg';

const ErrorModal = ({ intl, handleClose, error, isOpen }) => {
  return (
    <Modal
      isModalOpen={isOpen}
      toggleModal={handleClose}
      className="md:absolute sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:top-40 bg-white text-ra-darker sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
      close={true}
      closeOutside={true}
    >
      <ModalBody>
        <div className="flex flex-col items-center mb-4">
          <div className="mb-2">
            <ModalErrorIcon />
          </div>
          <h3 className="text-2xl leading-3 my-2">
            {intl.formatMessage({
              id: 'shop.error.modal.title'
            })}
          </h3>
          <p className="text-sm text-center leading-5">
            {error?.id
              ? intl.formatMessage({
                  id: error.id
                })
              : error?.mgs}
          </p>
        </div>
        <Button
          title={intl.formatMessage({
            id: 'button.title.try-again'
          })}
          color="bg-ra-dark"
          width="w-full"
          click={() => handleClose()}
        />
      </ModalBody>
    </Modal>
  );
};

export default ErrorModal;
