import { images } from "exports";
import React from "react";
import { FlexBox } from "../styles/containers";
import { Img } from "../styles/image";
import { Text16 } from "../styles/text";
import MobileStores from "./personal/MobileStores";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./footer.module.scss";

function Footer() {
  const { country } = useSelector((state) => state.change_country);
  const regionDetails = {
    nigeria: {
      phone: "+234 907 850 9905",
      address1: "Salvation bus-stop, 58 Opebi",
      address2: "Rd, Opebi 100281 Ikeja Lagos",
    },
    ghana: {
      phone: "+233 543 621 049",
      address1: "5 Roman Road, Off Bostral",
      address2: "Avenue Roman Ridge Accra",
    },
  };

  return (
    <Box
      className={styles.container}
      sx={{
        backgroundImage: `url('${images.footerbg}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <FlexBox
        className={styles.boxContainer}
        h={{ xs: "auto", lg: "381px" }}
        sx={{ px: { xs: "35px", md: "46px" }, py: "5px" }}
      >
        <FlexBox
          direction={{ base: "column", lg: "row" }}
          w={{ xs: "100%", lg: "100%" }}
          gap={{ xs: "20px", lg: "0px" }}
          wrap=""
        >
          <Img src={images.logo} h="30px" w={"auto"} />
          <MobileStores justify={"center"} />
        </FlexBox>
        <FlexBox
          w={{ xs: "100%", lg: "60%" }}
          align=""
          sx={{ pt: { base: "35px", lg: "90px" }, mt: 4 }}
          // direction={{ base: "column", lg: "row" }}
          wrap=""
          gap={{ xs: "10px", lg: "16px" }}
          className={`${styles.footerFlex}`}
        >
          <FlexBox
            direction="column"
            justify="flex-start"
            gap={{ xs: "5px", lg: "16px" }}
            w="30%"
            align="flex-start"
            wrap=""
            className={styles.box}
            sx={{ order: { xs: 1, lg: 1 } }}
          >
            <Text16 weight="700" class="sm:text-xs font-bold">
              COMPANY{" "}
            </Text16>
            <Text16 class="sm:text-xs">
              <a href="/about-us">About </a>
            </Text16>
            <Text16 class="sm:text-xs">
              <a href="/faqs">FAQS</a>
            </Text16>
            <Text16 class="sm:text-xs">
              <a href="/knowledge-base">Knowledge Base </a>
            </Text16>
            <Text16 class="sm:text-xs">
              <a href="help-center">Help Center</a>
            </Text16>
            <Text16 class="sm:text-xs">
              <a href="privacy-policy">Privacy Policy</a>
            </Text16>
          </FlexBox>

          <FlexBox
            direction="column"
            justify="flex-start"
            gap={{ xs: "5px", lg: "16px" }}
            align="flex-start"
            w="30%"
            className={styles.box}
            sx={{ order: { xs: 2, lg: 3 } }}
          >
            <Text16 weight="700" class="sm:text-xs  font-bold">
              CONTACT
            </Text16>
            {/* <Text16>support@reachafrica.com</Text16> */}
            <Text16 class="sm:text-xs">
              <a href="/faqs">FAQS</a>
            </Text16>
            <Text16 class="sm:text-xs">{regionDetails[country].phone}</Text16>
            <a href="mailto:support@reachafrica.com">
              <Text16 class="sm:text-xs">support@reachafrica.com</Text16>
            </a>
          </FlexBox>
          <FlexBox
            direction="column"
            justify="flex-start"
            gap={{ xs: "5px", lg: "16px" }}
            align="flex-start"
            w="30%"
            className={styles.box}
            sx={{ order: { xs: 3, lg: 2 } }}
          >
            <Text16 weight="700" class="sm:text-xs font-bold">
              ADDRESS
            </Text16>
            <Text16 class="CAPITALIZE sm:text-xs">{country}:</Text16>
            <Text16 class="sm:text-xs">
              {" "}
              {regionDetails[country].address1}
            </Text16>
            <Text16 class="sm:text-xs">
              {" "}
              {regionDetails[country].address2}{" "}
            </Text16>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Box>
  );
}

export default Footer;
