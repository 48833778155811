import React from 'react';
import { Formik } from 'formik';
// import { Link } from 'react-router-dom';
// import OtpInput from 'react-otp-input';
// import PhoneInput from 'components/Form/Phone';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';

import Validations from 'validations/add-profile';

const AddForm = ({ profiles, intl, dispatch, action }) => {
  // const [otp, setOtp] = useState(null);
  // const [isOTPMatched, setOTPMatched] = useState(false);
  // const [formState, setFormState] = useState('add');
  // const ref = useRef(null);
  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted && isOTPMatched) {
  //     if (ref.current) {
  //       ref.current.handleSubmit();
  //     }
  //   }
  //   return () => (mounted = false);
  // }, [dispatch, isOTPMatched]);

  return (
    <Formik
      initialValues={{
        profile_id: '',
        // phone: '',
        active: true
      }}
      validationSchema={Validations}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        dispatch(action(values));
        resetForm();
        setSubmitting(false);
      }}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        isValid,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <form className="block min-h-13" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full mx-6 py-2">
              <Select
                name="profile_id"
                placeholder={intl.formatMessage({
                  id: 'shop.airtime.form.ph.country'
                })}
                value={values.profile_id}
                error={
                  errors.profile_id &&
                  intl.formatMessage({
                    id: errors.profile_id
                  })
                }
                touched={touched.profile_id}
                options={profiles}
                setFieldValue={(name, obj) => {
                  // setFieldValue(name, '');
                  // setTimeout(() => {
                  setFieldValue(name, obj.id);
                  // }, 100);
                }}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
                optionLabelKey="description"
                optionValueKey="id"
              />
            </div>
            {/* {values.profile_id && (
            <div className="w-full mx-6 py-2">
              <PhoneInput
                name="phone"
                placeholder={intl.formatMessage({
                  id: 'signup.form.placeholder.phone'
                })}
                countries={
                  [profiles.filter(({ id }) => id === values.profile_id)[0]?.name] || ['ng']
                }
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                useFloatingLable={false}
              />
              <span className="text-sm text-gray-300">
                We will send you a code to verify your phone number
              </span>
            </div>
          )} */}
          </div>
          <div className="flex justify-center mt-2">
            {/* <Button
            title={intl.formatMessage({
              id: 'button.title.proceed'
            })}
            className="border-ra-dark"
            textColor="text-ra-dark"
            disabled={!(dirty && isValid) || isSubmitting}
            click={() => {
              dispatch(sentOtpRequest({ phone: values.phone }));
              setFormState('verify');
            }}
          /> */}

            <Button
              title={intl.formatMessage({
                id: 'button.title.proceed'
              })}
              color="bg-ra-dark"
              disabled={!(dirty && isValid) || isSubmitting}
              loading={isSubmitting}
            />
          </div>
          {/* {formState === 'verify' && (
          <>
            <OtpInput
              value={otp}
              onChange={(otp) => {
                setOtp(otp);
                if (otp * 1 === otpReceived * 1) {
                  setOTPMatched(true);
                }
              }}
              containerStyle="justify-center text-ra-dark"
              inputStyle={{
                appearance: 'none',
                borderColor: '#d2d6dc',
                borderWidth: 1,
                borderRadius: '0.375rem',
                padding: '0.75rem',
                width: '3rem'
              }}
              numInputs={4}
              isInputNum
              shouldAutoFocus
              separator={<span className="p-1">●</span>}
            />
            <p className="text-xs leading-5 text-gray-500 mt-10">
              {intl.formatMessage({
                id: 'signup.form.code.unsuccess'
              })}
              <Link
                to="#!"
                onClick={() => dispatch(sentOtpRequest({ phone: values.phone }))}
              >
                <span className="text-ra-dark ml-1">
                  {intl.formatMessage({
                    id: 'signup.form.code.resend'
                  })}
                </span>
              </Link>
            </p>
          </>
        )} */}
        </form>
      )}
    </Formik>
  );
};

export default AddForm;
