import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AlertCloseIcon, AlertErrorIcon, WarnIcon, SuccessIcon, InfoIcon } from 'assets/icons/svg';

import { clear_message_request, clear_ads_message_request } from 'redux/actions';

const Types = {
  warn: 'yellow',
  info: 'blue',
  success: 'green',
  error: 'red'
};

const initialState = {
  color: Types.warn,
  icon: <WarnIcon />
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'warn':
      return { color: Types.warn, icon: <WarnIcon /> };
    case 'info':
      return { color: Types.info, icon: <InfoIcon /> };
    case 'success':
      return { color: Types.success, icon: <SuccessIcon /> };
    case 'error':
      return { color: Types.error, icon: <AlertErrorIcon /> };
    default:
      return state;
  }
};

const AlertTemplate = ({ style, options, message, close }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchM = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) dispatch({ type: options.type });
    return () => {
      dispatchM(clear_message_request());
      dispatchM(clear_ads_message_request());
      mounted = false;
    };
  }, [dispatchM, options.type]);

  const getMessages = (messages) => {
    console.log('Message here', messages)
    if (typeof messages === 'string') {
      return <p dangerouslySetInnerHTML={{ __html: messages }} />;
    } else if (messages.code === 500) {
      return <p dangerouslySetInnerHTML={{ __html: 'Unexpected network connection error' }} />;
    }
    else if (message.status === 'success') {
      return <p dangerouslySetInnerHTML={{ __html: 'Password reset successful' }} />; 
    } else {
      return messages.type === 'error' ? <p dangerouslySetInnerHTML={{ __html: messages.title }} /> : messages.map((message) => <p dangerouslySetInnerHTML={{ __html: message }} />);
    }
  };

  return (
    <div style={style} className="flex justify-end">
      <div className={`rounded-md bg-${state.color}-50 p-4 w-full`}>
        <div className="flex">
          <div className="flex-shrink-0">{state.icon}</div>
          <div className="ml-3">
            {options.title && (
              <h3 className={`text-sm leading-5 font-medium text-${state.color}-800`}>
                {options.title}
              </h3>
            )}
            {message && (
              <div className={`mt-2 text-sm leading-5 text-${state.color}-700`}>
                {getMessages(message)}
              </div>
            )}
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={close}
                className={`inline-flex rounded-md p-1.5 text-${state.color}-500 hover:bg-${state.color}-100 focus:outline-none focus:bg-${state.color}-100 transition ease-in-out duration-150`}
              >
                <AlertCloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AlertTemplate.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
  message: PropTypes.string,
  close: PropTypes.func
};

export default AlertTemplate;
