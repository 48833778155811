import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import Input from 'components/Form/Input';
import FormButton from 'components/Form/Button';
import Button from 'components/Button';

const AddFund = ({ intl, isModalOpen, toggleModal, action, dispatch }) => {
  const amountOptions = [
    {
      label: '₦ 500',
      value: 500
    },
    {
      label: '₦ 1000',
      value: 1000
    },
    {
      label: '₦ 2000',
      value: 2000
    },
    {
      label: '₦ 5000',
      value: 5000
    },
    {
      label: '₦ 10,000',
      value: 10000
    },
    {
      label: '₦ 15,000',
      value: 15000
    },
    {
      label: '₦ 20,000',
      value: 20000
    },
    {
      label: '₦ 25,000',
      value: 25000
    },
    {
      label: '₦ 30,000',
      value: 30000
    },
    {
      label: '₦ 35,000',
      value: 35000
    },
    {
      label: '₦ 40,000',
      value: 40000
    },
    {
      label: '₦ 45,000',
      value: 45000
    }
  ];

  return (
    <Modal
      toggleModal={toggleModal}
      isModalOpen={isModalOpen}
      className="sm:max-w-sm sm:w-full md:max-w-lg lg:max-w-xl bg-white sm:p-6 rounded-lg mt-5 px-4 pt-5 pb-4 shadow-xl"
      close={true}
      closeOutside={true}
    >
      <ModalHeader
        title={intl.formatMessage({
          id: 'wallet.addfund.form-title'
        })}
        className="mt-1 md:mt-3 md:text-3xl text-center"
      />
      <ModalBody>
        <div className="bg-white font-normal">
          <Formik
            initialValues={{
              amount: 0,
              type: 'card',
              account: 'normal'
            }}
            validationSchema={yup.object().shape({
              amount: yup.string().required('Amount is required!')
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              dispatch(action(1, values));
              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              dirty,
              isValid,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleSubmit
            }) => (
              <form className="block" onSubmit={handleSubmit}>
                <div className="p-6 md:p-3">
                  <Input
                    type="number"
                    name="amount"
                    min="500"
                    style={{ textAlign: 'right' }}
                    placeholder="Enter Amount"
                    inputValue={values.amount}
                    onChange={handleChange}
                    error={errors.amount}
                    touched={true}
                    useFloatingLable={false}
                  />
                  <div className="relative text-center pb-10">
                    <span className="text-sm text-gray-300 bg-white p-1">
                      {intl.formatMessage({
                        id: 'wallet.addfund.text'
                      })}
                    </span>
                    <div className="w-full bg-gray-300 -mt-3" style={{ height: '2px' }} />
                  </div>
                  <div className="flex flex-wrap mb-8 -mx-2">
                    {amountOptions.map((e, i) => (
                      <div key={i} className="w-1/3 lg:w-1/4 p-2">
                        <Button
                          key={e.value}
                          title={e.label}
                          textColor="text-gray-900"
                          className="border-gray-300 py-3 hover:bg-gray-100"
                          click={() => setFieldValue('amount', e.value)}
                        />
                      </div>
                    ))}
                  </div>
                  <FormButton
                    title="Proceed"
                    classes="py-4 text-base"
                    color="bg-ra-dark"
                    disabled={!(dirty && isValid) || isSubmitting}
                    loading={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddFund;
