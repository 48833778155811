import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import Table from 'components/Table';
import Button from 'components/Button';

import { UnderLay as Loading } from 'components/Loading';

import { MdAssessment, MdVisibility, MdPlayArrow, MdPause, MdDelete } from 'react-icons/md';

import {
  ads_campaigns_request,
  ads_campaigns_patch_request,
  ads_campaigns_delete_request
} from 'redux/actions';

const AdvertiseCampaigns = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.campaigns.page.title_1'
  })}`;

  const { loading, error, campaigns } = useSelector((state) => state.advertiseReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(ads_campaigns_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted && error) {
      const a = error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error;
      alert.show(a, {
        type: 'error',
        title: intl.formatMessage({
          id: 'shop.products.error.title'
        })
      });
    }
    return () => (mounted = false);
  }, [alert, error, dispatch, intl]);
  // #endregion

  const columns = [
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_1'
      }),
      accessor: 'title'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_2'
      }),
      accessor: 'budget'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_3'
      }),
      accessor: 'impressions'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_4'
      }),
      accessor: 'views_count'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_5'
      }),
      accessor: 'type'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_6'
      }),
      Cell: ({ row }) => {
        const getState = (key) => {
          switch (key) {
            case 'pending':
              return { title: 'pending', color: 'orange' };
            case 'paused':
              return { title: 'paused', color: 'blue' };
            case 'active':
              return { title: 'active', color: 'green' };
            case 'expired':
            default:
              return { title: 'expired', color: 'red' };
          }
        };
        const state = getState(row.original.status);
        return (
          <button
            className={`font-medium bg-${state.color}-50 rounded-sm border border-${state.color}-200 text-${state.color}-500  hover:text-${state.color}-300 transition ease-in-out focus:outline-none duration-150 px-3 py-2 `}
          >
            {state.title}
          </button>
        );
      }
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.campaigns.table.header_7'
      }),
      Cell: ({ row }) => {
        return (
          <div className="flex justify-items-center justify-between items-center">
            <Link
              to={`/advertise/campaigns/${row.original.key}/statistics`}
              className="mx-1"
              title="statistics"
            >
              <MdAssessment color="#1976D2" size="20" />
            </Link>
            <Link
              to={`/advertise/campaigns/${row.original.key}/view`}
              className="mx-1"
              title="view"
            >
              <MdVisibility color="#1976D2" size="20" />
            </Link>
            <>
              {!row.original.status === 'pending' && !row.original.status === 'expired' && (
                <>
                  {row.original.status === 'active' ? (
                    <button
                      onClick={() => dispatch(ads_campaigns_patch_request(row.original.id))}
                      title="pause"
                      className="mx-1"
                    >
                      <MdPause color="#1976D2" size="20" />
                    </button>
                  ) : (
                    <button
                      onClick={() => dispatch(ads_campaigns_patch_request(row.original.id))}
                      title="play"
                      className="mx-1"
                    >
                      <MdPlayArrow color="#1976D2" size="25" />
                    </button>
                  )}
                </>
              )}
            </>
            <button
              onClick={() => dispatch(ads_campaigns_delete_request(row.original.id))}
              title="delete"
              className="mx-1"
            >
              <MdDelete color="#f05252" size="20" />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.campaigns.page.nav.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:py-10 xl:mx-24">
            <Table
              intl={intl}
              title={intl.formatMessage({
                id: 'advertise.campaigns.table.title'
              })}
              columns={columns}
              data={campaigns}
              error={null}
              headerBtn={
                <Button
                  icon={<span className="text-xl mr-2">+</span>}
                  title={intl.formatMessage({
                    id: 'advertise.campaigns.page.nav.title'
                  })}
                  width="w-2/12"
                  color="bg-ra-dark"
                  click={() => props.history.push('campaigns/create')}
                />
              }
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseCampaigns;
