import React from 'react'
import { images } from 'exports'
import { Img } from 'pages/LandingPage/styles/image'
import { Text56 } from 'pages/LandingPage/styles/text'
import PersonalDiv from './PersonalDiv'

function EnjoyBenefits() {
    return (
           <PersonalDiv
            headerCompnent={
                <Text56>Enjoy
                    <Text56 component='span' color='#E4AF18' position='relative'>
                        <Img src={images.rocketben} h='60px' w={'72px'} props={{ position: 'absolute', right: '10%', top: '-60px' }} />
                       {' '} benefits & discounts
                    </Text56>  from transactions.</Text56>
            }
            smallText='Why pay full price when you may lower your expenses?
            Take advantage of significant benefits when you buy airtime, data, or pay your bills.'
            bigImage={images.landingimg5}
            direction={{xs: 'column-reverse', lg: 'row-reverse' }}
        />
    )
}

export default EnjoyBenefits