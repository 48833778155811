import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import Header from 'components/Landing/Header';
import Footer from 'components/Landing/Footer/Footer';

// Images
import Android from 'assets/landing/google-play.png';
import Ios from 'assets/landing/apple-store.png';
import Man from 'assets/landing/man-laughing.png';
import Chains from 'assets/landing/chains.png';

const AboutUs = () => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'about-us.page.title'
  })}`;

  const { user } = useSelector((state) => state.authReducers);

  return (
    <div className="overflow-hidden">
      <Header intl={intl} user={user} />
      <div className="bg-image-about pl-5 md:mr-5 xl:pl-40 mt-5 xl:mb-20 xl:mr-40">
        <div className="w-full md:w-3/5 xl:w-4/5 flex md:justify-center align-middle items-center">
          <div className="mr-4 md:mr-0 md:mt-10 lg:mt-0">
            <p className="text-base md:text-lg leading-6 md:leading-7 text-ra-black">
              {intl.formatMessage({
                id: 'about-us.text_1'
              })}
            </p>
            <h2 className="font-bold text-32 md:text-40 lg:text-6xl leading-tight md:w-full xl:w-4/5">
              {intl.formatMessage({
                id: 'about-us.header'
              })}
            </h2>

            <div className="w-full md:w-4/5 xl:w-3/5">
              <p className="my-6 text-base md:text-lg leading-6 md:leading-7 text-ra-black">
                {intl.formatMessage({
                  id: 'about-us.text_2'
                })}
              </p>

              <p className="my-6 lg:my-8 text-base md:text-lg leading-6 md:leading-7 text-ra-black">
                {intl.formatMessage({
                  id: 'about-us.text_3'
                })}
              </p>

              <div className="flex justify-start items-center">
                <a
                  className="mr-3"
                  href="https://appurl.io/VnEmxNaZN"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Android} alt="android" width="130" />
                </a>
                <a href="https://appurl.io/VnEmxNaZN">
                  <img src={Ios} alt="ios" width="130" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 md:pt-10 lg:mt-0">
        <div className="flex mb-4 flex-col md:flex-row">
          <div className="md:w-1/2 p-5 md:p-0">
            <img src={Man} alt="man laughing" className="w-full" />
          </div>
          <div className="md:w-1/2 flex flex-col justify-center p-8 md:p-12 xl:p-40">
            <div className="flex flex-col justify-center align-middle">
              <div className="w-full md:w-3/5 lg:w-1/5">
                <h2 className="font-medium text-3xl md:text-32 lg:text-40 mb-4">
                  {intl.formatMessage({
                    id: 'about-us.section_2.header'
                  })}
                </h2>
              </div>
              <div className="flex justify-center md:justify-start items-center">
                <p className="text-base md:text-lg leading-6 md:leading-7 text-ra-black">
                  {intl.formatMessage({
                    id: 'about-us.section_2.text'
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 md:mt-0">
        <div className="flex mb-4 flex-col md:flex-row">
          <div className="md:w-1/2 flex flex-col justify-center p-8 md:p-12 xl:p-40">
            <div className="flex flex-col justify-center align-middle">
              <div className="w-full md:w-3/5 lg:w-1/5">
                <h2 className="font-medium text-3xl md:text-32 lg:text-40 mb-4">
                  {intl.formatMessage({
                    id: 'about-us.section_3.header'
                  })}
                </h2>
              </div>
              <div className="flex justify-center md:justify-start items-center">
                <p className="text-base md:text-lg leading-6 md:leading-7 text-ra-black">
                  {intl.formatMessage({
                    id: 'about-us.section_3.text'
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 p-5 md:p-0">
            <img src={Chains} alt="chain" className="w-full" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
