import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay as Loading } from 'components/Loading';

import BalanceDisplay from 'components/Dashboard/BalanceDisplay';
import QuickActions from 'components/Dashboard/QuickActions';

import {
  normal_account_request,
  reward_account_request,
  shop_products_request
} from 'redux/actions';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();

  const {
    loading: walletLoading,
    error: walletError,
    normal_account_data,
    normal_account_error,
    reward_account_data,
    reward_account_error
  } = useSelector((state) => state.walletReducers);

  const { loading: shopLoading, error: shopError, shop_products } = useSelector(
    (state) => state.shopReducers
  );

  const { user } = useSelector((state) => state.authReducers);

  document.title = `REACHAFRIKA | ${intl
    .formatMessage({
      id: 'dashboard.menu.link-title_1'
    })
    .toUpperCase()}`;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(normal_account_request());
      dispatch(reward_account_request());
      if (!shop_products.length) {
        dispatch(shop_products_request());
      }

      if (shopError || walletError) {
        const a = shopError.code === 500 ? intl.formatMessage({ id: shopError?.mgs }) : shopError;
        const b =
          walletError.code === 500 ? intl.formatMessage({ id: walletError?.mgs }) : walletError;
        alert.show(a, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
        alert.show(b, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, intl, shopError, shop_products.length, walletError]);

  return (
    <>
      {walletLoading || shopLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="dashboard.menu.link-title_1" />
          <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:mx-32 lg:py-10 xl:mx-24">
            <div className="xl:w-4/6 xxl:w-5/6">
              <BalanceDisplay
                intl={intl}
                account_no={user.account_no}
                normal_error={normal_account_error}
                reward_error={reward_account_error}
                normal_total={normal_account_data?.total}
                reward_coins={reward_account_data?.coins}
              />
              <QuickActions intl={intl} products={shop_products} push={props.history.push} />
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default Dashboard;
