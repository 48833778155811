import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';

// ASSETS
import { ErrorIcon } from 'assets/icons/svg';

import { getFormatedNumber } from 'constants/helper';

import 'react-intl-tel-input/dist/main.css';

const PhoneInput = ({
  name,
  error,
  value: inputValue,
  touched,
  dispatch,
  placeholder,
  setFieldValue,
  setFieldTouched,
  countries = ['ng', 'gh', 'ci'],
  profiles,
  validatePhoneRequest,
  useFloatingLable = true
}) => {
  const [styles, setStyles] = useState({});
  const errorClass = `border-red-300 text-red-900 focus:border-red-300`;
  const isTrue = error && touched;

  return (
    <div className="relative phone-input text-left">
      {!useFloatingLable && <div className="mb-1">{placeholder}</div>}
      <IntlTelInput
        fieldName={name}
        fieldId={name}
        initialCountry={'ng'}
        onlyCountries={countries}
        preferredCountries={countries}
        defaultValue={inputValue}
        containerClassName="intl-tel-input w-full z-20"
        inputClassName={`form-input block w-full pb-2 pt-4 text-base h-12 sm:text-sm sm:leading-5 focus:border-ra-dark focus:shadow-none ${
          isTrue && errorClass
        }`}
        onPhoneNumberBlur={(status, value, countryData) => {
          if (!value) setStyles({});
          setFieldTouched(name, true);
          const formatedValue = getFormatedNumber(value, countryData.dialCode);
          dispatch && dispatch(validatePhoneRequest({ phone: formatedValue }));
        }}
        onPhoneNumberChange={(status, value, countryData) => {
          setFieldTouched(name, true);
          const formatedValue = getFormatedNumber(value, countryData.dialCode);
          setFieldValue(name, formatedValue);
          if (profiles) {
            const profile = profiles.find((profile) => profile.name === countryData.iso2);
            setFieldValue('profile_id', profile.id);
          }
        }}
        onPhoneNumberFocus={() => {
          setStyles({
            top: -7,
            fontSize: 11,
            left: '1.25rem',
            opacity: '1',
            backgroundColor: '#ffffff',
            padding: '0 5px'
          });
        }}
        autoPlaceholder={!useFloatingLable}
      />

      {useFloatingLable && (
        <span
          className="floating-label absolute pointer-events-none transition-all duration-200 ease-in-out opacity-25 left-13 top-3"
          style={styles}
        >
          {placeholder}
        </span>
      )}
      {isTrue && (
        <div className="absolute -mt-8 right-0 pr-3 flex items-center pointer-events-none z-40">
          <ErrorIcon />
        </div>
      )}
      {isTrue && (
        <p className="mt-0 text-xs text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};

PhoneInput.propTypes = {
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  validatePhoneRequest: PropTypes.func,
  profiles: PropTypes.any,
  error: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.string
};

export default PhoneInput;
