import React, { Component, createContext } from 'react';

export const LangContext = createContext();

class LangContextProvider extends Component {

  constructor(props) {

    super()
    let country = localStorage.getItem('__raf') ? localStorage.getItem('__raf') : "Nigeria";
    
    this.state = {
      country: country,
      Ghana: {
        img: '/flags/gh.png',
        loc: '5 Roman Road, Off Bostral Avenue Roman Ridge Accra',
        phone: '+233 543 621 049',
        link: 'https://wa.me/2349057974461',
        email: 'support@reachafrica.com'
      },
      Nigeria: {
        img: '/flags/ng.png',
        loc: 'Salvation bus-stop, 58 Opebi Rd, Opebi 100281 Ikeja Lagos',
        phone: '+234 907 850 9905',
        link: 'https://wa.me/2349057974461',
        email: 'support@reachafrica.com'
      } 
    }
  }
  

  
  selectedCountry = (e) => {

    this.setState({
      country: e.target.dataset.country
    }, localStorage.setItem('__raf', e.target.dataset.country))
  }

  render() {
    return (
      <div>
        {/* The data in the state will be provided to the components wrapped in this Provider */}
        <LangContext.Provider value={{ 
          ...this.state, 
          selected: this.selectedCountry
        }}>
          {/* The children of the this context provider */}
          { this.props.children }
        </LangContext.Provider>
      </div>
    )
  }
}

export default LangContextProvider;