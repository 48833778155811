import getConfig from 'utils/env';
import httpFacade from 'utils/httpFacade';

const { baseURL } = getConfig();

const http = new httpFacade(baseURL + '/v1/');

export const fetch_campaigns = async (payload) =>
  await http.get({ url: 'advertise/all-campaigns/fetch-campaigns', query: payload });
export const fetch_campaigns_rewards = async () =>
  await http.get({ url: 'advertise/all-campaigns/fetch-campaigns-rewards' });
export const post_view_campaign = async (payload) =>
  await http.post({ url: 'advertise/all-campaigns/view-campaign', body: payload });
