import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
class AppCarousel extends React.Component {
  render() {
    return (
      <Carousel
        additionalTransfrom={0}
        arrows={this.props.arrows ? this.props.arrows : true}
        autoPlaySpeed={3000}
        centerMode={false}
        className={this.props.className}
        containerClass="container"
        dotListClass=""
        removeArrowOnDeviceType={
          this.props.removeArrowOnDeviceType ? this.props.removeArrowOnDeviceType : null
        }
        draggable
        focusOnSelect={false}
        infinite
        itemClass={this.props.itemClass}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: this.props.lg ? this.props.lg : 3
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: this.props.sm ? this.props.sm : 1
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: this.props.md ? this.props.md : 2
          }
        }}
        showDots={this.props.showDots ? this.props.showDots : false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {this.props.children}
      </Carousel>
    );
  }
}
AppCarousel.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  arrows: PropTypes.bool,
  removeArrowOnDeviceType: PropTypes.array
};
export default AppCarousel;
