import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import { UnderLay as Loading } from 'components/Loading';

import { ads_campaign_request } from 'redux/actions';

const AdvertiseViewCampaign = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.campaigns.view.page.title'
  })}`;

  const { loading, campaign } = useSelector((state) => state.advertiseReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted && props?.match?.params?.key) {
      dispatch(ads_campaign_request(props?.match?.params?.key));
    }
    return () => (mounted = false);
  }, [dispatch, props.match.params.key]);

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.campaigns.view.nav.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:py-10 xl:mx-24">
            {campaign && (
              <div className="bg-white w-full pb-5 xl:w-4/6 xl:mr-2 rounded-md">
                <div className="h-150">
                  {campaign.type === 'video' ? (
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={campaign.link}
                      light={false}
                      controls={true}
                      loop={false}
                    />
                  ) : (
                    <div className="w-full">
                      <img
                        src={campaign.poster}
                        alt={campaign.full_title}
                        className="w-full h-150"
                      />
                    </div>
                  )}
                </div>
                <div className="text-left px-10 my-5 text-base md:text-2xl">
                  <h3 className="leading-6 font-medium uppercase text-gray-900">
                    {campaign.full_title}
                  </h3>
                </div>
                <div className="flex flex-col px-10 md:flex-row md:justify-between">
                  <div className="flex flex-col md:w-1/3">
                    <div className="text-left text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_1'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.impressions}</span>
                    </div>
                    <div className="text-left text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_2'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.start}</span>
                    </div>
                    <div className="text-left text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_3'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.description}</span>
                    </div>
                  </div>
                  <div className="flex flex-col md:w-1/3">
                    <div className="text-right text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_4'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.views_count}</span>
                    </div>
                    <div className="text-right text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_5'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.end}</span>
                    </div>
                    <div className="text-right text-base">
                      <p className="leading-6 font-medium text-gray-900">
                        {' '}
                        {intl.formatMessage({
                          id: 'advertise.campaigns.view.header_6'
                        })}
                      </p>
                      <span className="text-gray-400 ">{campaign.message}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseViewCampaign;
