import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import { CheckIcon, CoinsIcon } from 'assets/icons/svg';
import Button from 'components/Button';

const Alert = ({ isModalOpen, toggleModal, earning, callback, buttonAction, handleClose }) => {
  const [next, setNext] = useState(false);
  const intl = useIntl();

  return (
    <Modal
      styles={{ zIndex: 99999 }}
      isModalOpen={isModalOpen}
      className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl sm:max-w-sm sm:w-full sm:p-6"
    >
      <ModalBody>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <ModalHeader title={!next ? 'Thanks for watching' : 'Visit for more info'} />
          <div className="mt-2">
            {!next ? (
              <p className="flex justify-center text-sm leading-5 text-gray-500">
                {intl.formatMessage({
                  id: 'shop.success.alert.earned-text_1'
                })}
                <span className="w-5 mx-2">
                  <CoinsIcon />
                </span>
                {earning}
              </p>
            ) : (
              <p className="text-sm leading-5 text-gray-500">
                {intl.formatMessage({
                  id: 'shop.success.alert.earned-text_2'
                })}
              </p>
            )}
          </div>
        </div>
      </ModalBody>
      <div className="flex justify-center mt-5 sm:mt-6">
        {!next ? (
          <Button
            title={intl.formatMessage({
              id: 'button.title.claim'
            })}
            width="w-3/4"
            color="bg-ra-dark"
            click={() => {
              buttonAction();
              setNext(true);
            }}
          />
        ) : (
          <>
            <Button
              title={intl.formatMessage({
                id: 'button.title.close'
              })}
              width="w-1/2"
              className="border-ra-dark mr-2"
              textColor="text-ra-dark"
              click={() => {
                setNext(false);
                handleClose();
                toggleModal(!isModalOpen);
                window.location.reload();
              }}
            />
            <a
              href={`//${callback}`}
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 ml-2"
            >
              <Button
                title={intl.formatMessage({
                  id: 'button.title.visit'
                })}
                width="w-full"
                color="bg-ra-dark"
              />
            </a>
          </>
        )}
      </div>
    </Modal>
  );
};

Alert.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  earning: PropTypes.number
};

export default Alert;
