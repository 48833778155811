import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from 'container/layouts/Wrapper';
import { useIntl } from 'react-intl';

import PageTitle from 'components/PageTitle';
import AdsCard from 'components/ReducePayment/AdsCard';

import { UnderLay as Loading } from 'components/Loading';

import { campaigns_request, campaigns_rewards_request, view_campaign_request } from 'redux/actions';

const ReducePayment = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'reduce-payment.page.title'
  })}`;

  const { loading, campaigns, rewards } = useSelector((state) => state.reducePaymentReducers);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(campaigns_request({ paginate: 1 }));
      dispatch(campaigns_rewards_request());
    }
    return () => (mounted = false);
  }, [dispatch]);

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="home.quick-action.bonus.title" />
          <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-10 xl:mx-24">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 ">
              {campaigns?.length && rewards ? (
                campaigns.map((e) => {
                  let value = 0;
                  if (e.type === 'video') {
                    value = rewards.coins * rewards.video_reward;
                  } else {
                    value = rewards.coins * rewards.image_reward;
                  }
                  return (
                    <AdsCard
                      key={e.id}
                      full_title={e.full_title}
                      duration={e.duration}
                      url={e.link}
                      posted_on={e.posted_on}
                      poster={e.poster}
                      description={e.description}
                      value={value}
                      campaign_type={e.campaign_type}
                      type={e.type}
                      callback={e.callback}
                      claimAction={() => dispatch(view_campaign_request({ campaign_id: e.id }))}
                    />
                  );
                })
              ) : (
                <p>
                  {intl.formatMessage({
                    id: 'reduce-payment.none.found'
                  })}
                </p>
              )}
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ReducePayment;
