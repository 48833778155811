import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Transition from 'components/TransitionGroup/Transition.js';

// IMAGES IMPORTS
import Logo from 'assets/img/logo.png';

// CONTEXT IMPORTS
import { AuthContext } from 'context/authContext';

// COMPONENT IMPORTS
import NavMenu from 'components/NavMenu';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const Sidebar = ({ intl, currentPath, isMoblieMenuOpen, user = {}, profiles = {}, location }) => {
  const [hide, setHide] = useState('none');
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setHide('');
      if (!isMoblieMenuOpen) {
        setTimeout(() => setHide('none'), 200);
      }
    }
    return () => (mounted = false);
  }, [isMoblieMenuOpen]);

  return (
    <>
      {/* <!-- Off-canvas menu for mobile --> */}
      <div className="md:hidden" style={{ display: hide }}>
        <div className="fixed w-full z-40 mt-20 inset-0">
          <Transition
            show={isMoblieMenuOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >
            <div className="relative flex-1 flex flex-col w-full bg-white">
              <div className="flex-1 min-h-screen pt-5 pb-4 overflow-y-auto">
                <nav className="mt-5 px-2">
                  <NavMenu intl={intl} currentPath={currentPath} location={location} />
                </nav>
                <div className="flex justify-center mt-40 px-10">
                  <Button
                    title={intl.formatMessage({
                      id: 'logout.text'
                    })}
                    textColor="text-ra-dark"
                    className="border-ra-dark px-5 py-2"
                    click={() => logout()}
                  />
                </div>
              </div>
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14"></div>
        </div>
      </div>
      {/* <!-- Static sidebar for desktop --> */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-48 lg:w-64 border-r border-gray-200 bg-white">
          <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <Dropdown intl={intl} user={user} logout={logout} />
            <nav className="flex-1 bg-white mt-10">
              <NavMenu intl={intl} currentPath={currentPath} location={location} />
            </nav>
            <div className="flex justify-center my-10 md:w-3/4 lg:w-full m-auto">
              <img className="md:h-5 lg:h-6" src={Logo} alt="REACHAFRIKA" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  intl: PropTypes.any.isRequired,
  currentPath: PropTypes.string.isRequired,
  isMoblieMenuOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

export default Sidebar;
