import React, { useState } from 'react';
import Button from '../../components/Button'
import { GrLinkNext } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { useIntl } from 'react-intl';
import Wrapper from 'container/layouts/SettingWrapper';
import Frame from '../settings/Frame.png'

const KnowledgeBase = (props) => {
  const intl = useIntl(); 

  const [deleteApp, setDeleteApp] = useState(false);

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'knowledge-base.page.title'
  })}`;

  const handleDeleteApp = () => {
    setDeleteApp(!deleteApp); // Toggle deleteApp state
  };
  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden" {...props}>
      <main className="flex-1 relative z-0 md:mt-8 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="p-4 xl:p-0 xl:mt-10 xl:ml-20 xl:mr-80">
          {deleteApp ? (<h4 className="flex items-center space-x-2 mb-15"> <span className="text-blue-600 cursor-pointer"  onClick={handleDeleteApp}>Knowledge-base</span> <GrFormNext />  Delete your app account</h4>) :
        (<h1 className="font-bold mb-10">knowledge-base</h1>)}
         
        {deleteApp ? (
            <div >
              <h1 className="font-bold mb-5">Delete Your App Account</h1>
              <div className="font-bold ">Table of contents</div>
              <div>Step 1: Log in to your account and select "Settings"</div>
              <div>Step 2: Select "Delete Account"</div>
              <div>Step 3: Confirm Account Deletion</div>
              <img src={Frame} alt="Frame" className="max-w-full max-h-full" />

            </div>
          ) :
         (<div className='border-2 border-gray-500 rounded-lg'>
            <Button 
            icon={<GrLinkNext  className='ml-50'/>}
             title='Deleting Your App Account' 
             titleClass="mr-10"
             textColor="text-black"
             click={() => setDeleteApp(true)}
              />
         </div>)}

        </div>
      </main>
    </Wrapper> 
  );
};

export default KnowledgeBase;
