import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/TransitionGroup/Transition.js';
import { CloseIcon } from 'assets/icons/svg';

export const Modal = ({
  children,
  isModalOpen,
  toggleModal = () => null,
  className,
  close = false,
  closeOutside = false,
  CloseIconColor = 'text-black',
  styles = { zIndex: 40, overflow: 'auto' }
}) => {
  const [hide, setHide] = useState('block');

  useEffect(() => {
    let mounted = true;
    let id = null;
    if (mounted) {
      setHide('');
      if (!isModalOpen) {
        id = setTimeout(() => setHide('none'), 200);
      }
    }
    return () => {
      clearTimeout(id);
      mounted = false;
    };
  }, [isModalOpen]);

  return (
    <div
      style={{ display: hide, ...styles }}
      className="fixed inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      {/* <CloseIcon /> */}

      <Transition
        show={isModalOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => (closeOutside ? toggleModal(!isModalOpen) : {})}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" ></div>
        </div>
      </Transition>
      <Transition
        show={isModalOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className={`${className} overflow-hidden transform transition-all`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {close && (
            <div className="cursor-pointer absolute p-2 top-1 right-1 z-10">
              <span className={CloseIconColor} onClick={() => toggleModal(!isModalOpen)}>
                <CloseIcon />
              </span>
            </div>
          )}
          {children}
        </div>
      </Transition>
    </div>
  );
};

export const ModalHeader = ({ title, className }) => {
  return (
    <div className={className}>
      <h3 className="leading-6 font-medium text-ra-darker" id="modal-headline">
        {title}
      </h3>
    </div>
  );
};

export const ModalSubHeader = ({ subtitle }) => {
  return (
    <div className="mt-2">
      <p className="text-sm leading-5 text-gray-500">{subtitle}</p>
    </div>
  );
};

export const ModalBody = ({ children, className = 'mt-5 sm:mt-6' }) => {
  return <div className={className}>{children}</div>;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  close: PropTypes.bool,
  closeOutside: PropTypes.bool,
  className: PropTypes.string
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired
};

ModalSubHeader.propTypes = {
  subtitle: PropTypes.any.isRequired
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired
};

export default Modal;
