import React from 'react';
import pick from 'lodash/pick';

import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const StepOne = (props) => {
  return (
    <>
      <div className="w-full lg:w-1/2 p-2">
        <Select
          name="disco_id"
          placeholder={props.intl.formatMessage({
            id: 'shop.power.form.placeholder.1'
          })}
          value={props.values.disco_id}
          error={props.errors.disco_id}
          touched={props.touched.disco_id}
          options={props.data}
          setFieldValue={(name, obj) => {
            if (obj.id) {
              props.setFieldValue(name, obj.id);
            }
          }}
          setFieldTouched={props.setFieldTouched}
          useFloatingLable={false}
          optionLabelKey="alias"
          optionValueKey="id"
        />
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <Select
          name="vendor_type"
          placeholder={props.intl.formatMessage({
            id: 'shop.power.form.placeholder.2'
          })}
          value={props.values.vendor_type}
          error={props.errors.vendor_type}
          touched={props.touched.vendor_type}
          options={['PREPAID', 'POSTPAID']}
          setFieldValue={props.setFieldValue}
          setFieldTouched={props.setFieldTouched}
          useFloatingLable={false}
        />
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <Input
          type="number"
          name="meter_no"
          placeholder={props.intl.formatMessage({
            id: 'shop.power.form.placeholder.3'
          })}
          inputValue={props.values.meter_no}
          onChange={props.handleChange}
          onBlur={(e) => {
            if (e.target.value) {
              const data = pick(props.values, ['disco_id', 'meter_no', 'vendor_type']);
              props.dispatch(props.request(data));
              props.handleBlur(e);
            }
          }}
          error={props.errors.meter_no}
          touched={props.touched.meter_no}
          useFloatingLable={false}
        />
      </div>
    </>
  );
};

export default StepOne;
