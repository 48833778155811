export const getFormatedNumber = (value, dialcode) => {
  //check if dialcode exist
  const hasDialCode = value.includes('+23');
  if (hasDialCode) {
    return value.replace(/\s/g, '');
  } else {
    //check if phone start with zero
    const hasZeroAsFirstNumber = '0' === value.slice(0, 1);
    if (hasZeroAsFirstNumber) {
      return '+' + dialcode + value.slice(1).replace(/\s/g, '');
    } else {
      return '';
    }
  }
};

export const getFormattedMoney = (number) => {
  let main = number;
  if (Number.isNaN(main)) {
    main = 0;
  }
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(main);
};

export const getImageFile = (file_name) => {
  try {
    return require(`assets/icons/${file_name}.png`);
  } catch (err) {
    return require('assets/icons/default.png');
  }
};
