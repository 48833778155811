import {
  NORMAL_ACCOUNT,
  REWARD_ACCOUNT,
  CREDIT_ACCOUNT,
  ACCOUNT_TRANSACTIONS,
  BANK_ACCOUNT_DETAILS,
  LOG_FUND
} from 'constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: null,
  normal_account_data: {},
  reward_account_data: {},
  credit_account_data: {},
  account_transactions_data: [],
  bank_account_details_data: {},
  log_fund_data: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region NORMAL_ACCOUNT
    case NORMAL_ACCOUNT.GET_REQUEST:
      return { ...state, loading: true };
    case NORMAL_ACCOUNT.GET_SUCCESS:
      return { ...state, loading: false, normal_account_data: action.payload };
    case NORMAL_ACCOUNT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region REWARD_ACCOUNT
    case REWARD_ACCOUNT.GET_REQUEST:
      return { ...state, loading: true };
    case REWARD_ACCOUNT.GET_SUCCESS:
      return { ...state, loading: false, reward_account_data: action.payload };
    case REWARD_ACCOUNT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CREDIT_ACCOUNT
    case CREDIT_ACCOUNT.GET_REQUEST:
      return { ...state, loading: true };
    case CREDIT_ACCOUNT.GET_SUCCESS:
      return { ...state, loading: false, credit_account_data: action.payload };
    case CREDIT_ACCOUNT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ACCOUNT_TRANSACTIONS
    case ACCOUNT_TRANSACTIONS.GET_REQUEST:
      return { ...state, loading: true };
    case ACCOUNT_TRANSACTIONS.GET_SUCCESS:
      return { ...state, loading: false, account_transactions_data: action.payload };
    case ACCOUNT_TRANSACTIONS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region BANK_ACCOUNT_DETAILS
    case BANK_ACCOUNT_DETAILS.GET_REQUEST:
      return { ...state, loading: true };
    case BANK_ACCOUNT_DETAILS.GET_SUCCESS:
      return { ...state, loading: false, bank_account_details_data: action.payload };
    case BANK_ACCOUNT_DETAILS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region LOG_FUND
    case LOG_FUND.POST_REQUEST:
      return { ...state, loading: true };
    case LOG_FUND.POST_SUCCESS:
      return { ...state, loading: false, log_fund_data: action.payload };
    case LOG_FUND.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    default:
      return state;
  }
};
