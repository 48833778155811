import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

// COMPONENT IMPORTS
import {
  DashboardIcon,
  WalletIcon,
  ShopIcon,
  AdvertiseIcon,
  InviteMenuIcon,
  SettingIcon
} from 'assets/icons/svg';

import MenuItem from './item';

const NavMenu = ({ intl, currentPath = 'dashboard', location }) => {
  const { shop_products } = useSelector((state) => state.shopReducers);
  const { user_profiles } = useSelector((state) => state.authReducers);

  const menuData = [
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_1'
      }),
      parent: 'dashboard',
      icon: <DashboardIcon />
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_2'
      }),
      icon: <WalletIcon />,
      parent: 'wallet',
      submenu: [
        {
          alias: intl.formatMessage({
            id: 'dashboard.menu.link-title_2.sublink_1'
          }),
          name: 'normal'
        },
        {
          alias: intl.formatMessage({
            id: 'dashboard.menu.link-title_2.sublink_2'
          }),
          name: 'reward'
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_3'
      }),
      icon: <ShopIcon />,
      parent: 'shop',
      submenu: shop_products
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_4'
      }),
      icon: <AdvertiseIcon />,
      parent: 'advertise',
      submenu: [
        {
          alias: intl.formatMessage({
            id: 'dashboard.menu.link-title_4.sublink_1'
          }),
          name: 'campaigns'
        },
        {
          alias: intl.formatMessage({
            id: 'dashboard.menu.link-title_4.sublink_2'
          }),
          name: 'contacts'
        },
        {
          alias: intl.formatMessage({
            id: 'dashboard.menu.link-title_4.sublink_3'
          }),
          name: 'credits'
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_5'
      }),
      parent: 'invite',
      icon: <InviteMenuIcon />
    },
    {
      title: intl.formatMessage({
        id: 'dashboard.menu.link-title_6'
      }),
      icon: <SettingIcon />,
      parent: 'settings',
      submenu: [
        {
          alias: capitalize(
            intl.formatMessage({
              id: 'profile.page.title'
            })
          ),
          name: 'profile'
        },
        {
          alias: intl.formatMessage({
            id: 'change-pin.page.title'
          }),
          name: 'pin'
        },
        {
          alias: intl.formatMessage({
            id: 'change-lang.page.title'
          }),
          name: 'language'
        },
        {
          alias: intl.formatMessage({
            id: 'change-password.page.title'
          }),
          name: 'password'
        },
        {
          alias: intl.formatMessage({
            id: 'faqs.page.title'
          }),
          name: 'faqs',
          newTab: true
        },
        {
          alias: intl.formatMessage({
            id: 'knowledge-base.page.title'
          }),
          name: 'knowledge-base',
          newTab: true
        },
        {
          alias: intl.formatMessage({
            id: 'help-center.page.title'
          }),
          name: 'help-center',
          newTab: true
        },
        {
          alias: intl.formatMessage({
            id: 'privacy-policy.page.title'
          }),
          name: 'privacy-policy',
          newTab: true
        },
        {
          alias: intl.formatMessage({
            id: 'footer.nav.link_3'
          }),
          name: 'terms-&-conditions',
          newTab: true
        }
      ]
    }
  ];

  const activeProfile = user_profiles?.find((p) => p.active)?.profile;

  if (!activeProfile?.has_wallet) {
    delete menuData[1].submenu[0];
  }

  return (
    <>
      {menuData.map((e, i) => (
        <MenuItem
          key={i}
          intl={intl}
          title={e.title}
          icon={e.icon}
          parent={e.parent}
          active={currentPath === e.parent}
          location={location}
          submenu={e.submenu}
        />
      ))}
    </>
  );
};

NavMenu.propTypes = {
  intl: PropTypes.any.isRequired,
  currentPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default NavMenu;
