import { useEffect } from 'react';
const useInvoice = (fee, ra, bill, useReward, set) => {
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const amount = fee || 0;
      set.fee(amount);
      const balance = ra ? ra.balance : 0;
      let val = bill * 1 + amount * 1 - (useReward ? balance * 1 : 0);
      if (balance) {
        set.reward(balance);
      }

      if (useReward) {
        const newReward = balance * 1 - amount * 1 - bill * 1;
        if (newReward > 0) {
          set.reward(newReward);
        } else {
          set.reward(0);
        }
      }

      if (val < 0) {
        set.payable(0);
      } else {
        set.payable(val);
      }
    }
    return () => (mounted = false);
  }, [bill, ra, fee, set, useReward]);

  return null;
};

export default useInvoice;
