import React from 'react';
import Wrapper from 'container/layouts/Wrapper';
import { useIntl } from 'react-intl';

import PageTitle from 'components/PageTitle';
import CardMenu from 'components/Shop/CardMenu';

import { PowerIcon, TvCableIcon, MovieIcon } from 'assets/landing/svg';

const PayBills = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'pay-bills.page.title'
  })}`;

  const data = [
    {
      title: intl.formatMessage({
        id: 'shop.power.page.title_2'
      }),
      to: 'shop/power',
      icon: <PowerIcon />
    },
    {
      title: intl.formatMessage({
        id: 'shop.movies.nav.title'
      }),
      to: 'shop/movies',
      icon: <MovieIcon />
    },
    {
      title: intl.formatMessage({
        id: 'shop.cable-tv.nav.title'
      }),
      to: 'shop/cable_tv',
      icon: <TvCableIcon />
    }
  ];

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} id="pay-bills.invite.nav.title" />
        <div className="pt-2 pb-6 mx-4 md:mx-36 md:py-10 xl:mx-24 h-80">
          <div className="flex flex-wrap -mx-2">
            {data.map((e, i) => (
              <CardMenu intl={intl} item={e} key={i} histroy={props.histroy} />
            ))}
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default PayBills;
