import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Transition from 'components/TransitionGroup/Transition.js';

// ASSETS
import { ErrorIcon } from 'assets/icons/svg';

const MarkSelected = () => {
  return (
    <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
      <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};

const Select = ({
  name,
  placeholder,
  value,
  error,
  touched,
  options,
  setFieldValue,
  setFieldTouched,
  optionLabelKey,
  optionValueKey,
  useFloatingLable = true
}) => {
  const [isOpen, setOpen] = useState(false);
  const styles = value
    ? {
        top: -7,
        fontSize: 11,
        opacity: '1',
        backgroundColor: '#ffffff',
        padding: '0 5px'
      }
    : {};
  const errorClass = `border-red-300 text-red-900 focus:border-red-300`;
  const isTrue = error && touched;

  const isLabelArray = typeof optionLabelKey !== 'string';

  const buildLabel = (obj) => {
    return optionLabelKey
      .map((e, i) => {
        let label = !i ? '' : ' | ';
        return label + obj[e];
      })
      .join('');
  };

  const getDisplayValue = () => {
    if (value) {
      if (isLabelArray) {
        const label = options.find((e) => e[optionValueKey] === value);
        return buildLabel(label);
      }
      return options.find((e) => e[optionValueKey] === value)[optionLabelKey];
    } else {
      return value;
    }
  };

  return (
    <div className="space-y-1 text-left">
      {!useFloatingLable && <div className="">{placeholder}</div>}
      {isOpen && (
        <button
          className="inset-0 fixed w-full focus:shadow-none cursor-default"
          onClick={() => setOpen(false)}
        />
      )}

      <div className="relative">
        <span id="listbox-label" className="inline-block w-full rounded-md shadow-sm">
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={!isOpen ? 'false' : 'true'}
            aria-labelledby="listbox-label"
            className={`cursor-default h-12 relative w-full rounded-md border bg-white pl-3 pr-10 py-2 text-left focus:border-ra-dark focus:shadow-none transition ease-in-out duration-150 sm:text-sm text-base sm:leading-5 ${
              isTrue && errorClass
            }`}
            onClick={() => {
              setOpen(true);
              setFieldTouched(name, true);
            }}
          >
            <span className="block truncate text-sm">
              {optionLabelKey ? getDisplayValue()?.toUpperCase() : value?.toUpperCase()}
            </span>
            {isTrue && (
              <div className="absolute -mt-3 right-2 flex items-center pointer-events-none z-40">
                <ErrorIcon />
              </div>
            )}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>

          {useFloatingLable ? (
            <span
              className="floating-label absolute pointer-events-none transition-all duration-200 ease-in-out opacity-25 left-3 top-3"
              style={styles}
            >
              {placeholder}
            </span>
          ) : (
            <span
              className="absolute pointer-events-none transition-all duration-200 ease-in-out opacity-25 left-3 top-3"
              style={value ? { display: 'none' } : {}}
            >
              {placeholder}
            </span>
          )}
        </span>

        <div
          className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
          style={{ display: !isOpen ? 'none' : '', zIndex: 1099999 }}
        >
          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              className="max-h-60 rounded-md py-1 text-base text-left leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {options &&
                options.map((e, i) => {
                  if (typeof e === 'string') {
                    return (
                      <li
                        key={i}
                        id={`listbox-option-${i}`}
                        role="option"
                        aria-selected={e === value}
                        onClick={() => {
                          setOpen(false);
                          setFieldValue(name, e);
                        }}
                        className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-ra-dark"
                      >
                        <span className="font-normal block truncate">{e?.toUpperCase()}</span>

                        {e === value && <MarkSelected />}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={i}
                        id={`listbox-option-${i}`}
                        role="option"
                        aria-selected={e[optionValueKey] === value}
                        onClick={() => {
                          setOpen(false);
                          setFieldValue(name, e);
                        }}
                        className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-ra-dark"
                      >
                        <span className="font-normal block truncate">
                          {isLabelArray
                            ? buildLabel(e)?.toUpperCase()
                            : e[optionLabelKey]?.toUpperCase()}
                        </span>
                        {e[optionValueKey] === value && <MarkSelected />}
                      </li>
                    );
                  }
                })}
            </ul>
          </Transition>
        </div>
        {isTrue && (
          <p className="mt-0 text-xs text-red-600" id={`${name}-error`}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  optionLabelKey: PropTypes.any,
  optionValueKey: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default Select;
