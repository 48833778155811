import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import walletSagas from './wallet/saga';
import shopSagas from './shop/saga';
import reducePaymentSagas from './reduce-payment/saga';
import advertiseSagas from './advertise/saga';

export default function* rootSaga(getState) {
  yield all([...authSagas, ...walletSagas, ...shopSagas, ...reducePaymentSagas, ...advertiseSagas]);
}
