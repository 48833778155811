import React from 'react';
import PropTypes from 'prop-types';

const iconItem = (props) => {
  return (
    <div className="flex flex-col p-5">
      <span className="w-14 h-14 self-center">{props.icon}</span>
      <div className="text-center mt-2">
        <h2 className="text-xl font-bold">{props.title}</h2>
        <p className="text-center text-ra-black">{props.subTitle}</p>
      </div>
    </div>
  );
};
iconItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired
};
export default iconItem;
