import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import Input from 'components/Form/Input';
import Button from 'components/Button';

const PinConfirmModal = ({
  isModalOpen,
  toggleModal,
  isPinCorrect,
  isSubmitting,
  handleSumbit,
  dispatch,
  loading,
  action,
  intl
}) => {
  const [pin, setPin] = useState(null);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(null);
  const [check, setCheck] = useState(false);
  const disabled = !!error || isSubmitting;

  useEffect(() => {
    if (!pin) {
      setError(
        intl.formatMessage({
          id: 'pin.confirm.error.pin.required'
        })
      );
    }
    if (check && !isPinCorrect) {
      setError(
        intl.formatMessage({
          id: 'pin.confirm.error.pin.incorrect'
        })
      );
    }
  }, [pin, check, intl, isPinCorrect]);

  useEffect(() => {
    if (pin && isPinCorrect) {
      handleSumbit();
      setTouched(false);
      setPin(null);
    }
  }, [handleSumbit, isPinCorrect, pin]);

  return (
    <Modal
      styles={{ zIndex: 99999 }}
      isModalOpen={isModalOpen}
      className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl sm:max-w-sm sm:w-full sm:p-6"
    >
      <ModalBody>
        <div className="mt-3 text-center sm:mt-5">
          <ModalHeader
            title={intl.formatMessage({
              id: 'pin.confirm.form.title'
            })}
            className="mb-4 text-2xl"
          />
          <div className="w-full">
            <Input
              type="password"
              id="pin"
              name="pin"
              placeholder="PIN"
              inputValue={pin || ''}
              onChange={(e) => {
                setPin(e.target.value);
                setError(null);
                setCheck(false);
                if (!e.target.value) {
                  setError(
                    intl.formatMessage({
                      id: 'pin.confirm.error.pin.required'
                    })
                  );
                }
              }}
              onBlur={(_) => setTouched(true)}
              error={!loading ? error : ''}
              touched={touched}
              isPassField
            />
          </div>
          <div className="flex justify-center mt-5 sm:mt-6">
            <Button
              title={intl.formatMessage({
                id: 'button.title.close'
              })}
              width="w-1/2"
              className="border-ra-dark mr-2"
              textColor="text-ra-dark"
              click={() => {
                toggleModal(!isModalOpen);
              }}
            />
            <div className="w-1/2">
              <Button
                title={intl.formatMessage({ id: 'button.title.confirm' })}
                className={`${disabled ? 'cursor-not-allowed' : 'bg-ra-dark'}`}
                textColor={`${disabled ? 'text-ra-dark' : 'text-white'}`}
                disabled={disabled}
                click={() => {
                  setError(null);
                  dispatch(action({ pin }));
                  setCheck(true);
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

PinConfirmModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  earning: PropTypes.number
};

export default PinConfirmModal;
