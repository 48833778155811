import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import Button from 'components/Button';

const Two = ({
  intl,
  values,
  touched,
  errors,
  setStep,
  accept,
  setAccept,
  handleChange,
  handleBlur
}) => {
  return (
    <>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full mx-6 py-2">
          <Input
            type="text"
            name="lastname"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.lastname'
            })}
            inputValue={values.lastname}
            error={
              errors.lastname &&
              intl.formatMessage({
                id: errors.lastname
              })
            }
            touched={touched.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="w-full mx-6 py-2">
          <Input
            type="text"
            name="firstname"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.firstname'
            })}
            inputValue={values.firstname}
            error={
              errors.firstname &&
              intl.formatMessage({
                id: errors.firstname
              })
            }
            touched={touched.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="w-full mx-6 py-2">
          <Input
            type="password"
            name="pin"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.pin'
            })}
            inputValue={values.pin}
            error={
              errors.pin &&
              intl.formatMessage({
                id: errors.pin
              })
            }
            touched={touched.pin}
            onChange={handleChange}
            onBlur={handleBlur}
            isPassField
          />
        </div>
        <div className="w-full mx-6 py-2">
          <Input
            type="password"
            name="confirm_pin"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.confirm_pin'
            })}
            inputValue={values.confirm_pin}
            error={
              errors.confirm_pin &&
              intl.formatMessage({
                id: errors.confirm_pin
              })
            }
            touched={touched.confirm_pin}
            onChange={handleChange}
            onBlur={handleBlur}
            isPassField
          />
        </div>
        <div className="w-full mx-6 py-2">
          <div className="flex items-center">
            <input
              id="accept"
              type="checkbox"
              checked={accept}
              onChange={() => setAccept(!accept)}
              className="form-checkbox h-4 w-4 text-ra-dark transition duration-150 ease-in-out"
            />
            <label htmlFor="accept" className="ml-2 block text-sm leading-5 text-gray-900">
              <Link to="/terms-&-conditions" target={'_blank'} className="text-ra-dark">
                {intl.formatMessage({
                  id: 'signup.legal.term.link-text'
                })}
              </Link>
            </label>
          </div>
        </div>
      </div>

      <div className="flex-wrap justify-center sm:flex sm:justify-between mt-4">
        <div>
          <Button
            title={intl.formatMessage({
              id: 'button.title.back'
            })}
            className="border-ra-dark"
            textColor="text-ra-dark"
            click={() => setStep(1)}
          />
        </div>
        <div className="mt-4 sm:mt-0">
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            color="bg-ra-dark"
            disabled={!isEmpty(errors) || !accept}
            click={() => setStep(3)}
          />
        </div>
      </div>
    </>
  );
};

Two.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  accept: PropTypes.bool.isRequired,
  setAccept: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default Two;
