import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Button from 'components/Button';

const StepThree = ({
  intl,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setStep,
  setBudget,
  credit_bal = 0,
  frequencyOpts,
  dispatch,
  action
}) => {
  return (
    <div className="p-4">
      <div className="flex flex-wrap mx-1">
        <div className="w-full p-2">
          <Input
            type="number"
            step="any"
            id="budget"
            name="budget"
            placeholder={intl.formatMessage({
              id: 'advertise.campaigns.form.placeholder.budget'
            })}
            inputValue={values.budget}
            onChange={(e) => {
              const budget = JSON.parse(e.target.value);
              setBudget(budget);
              if (budget <= credit_bal) {
                handleChange(e);
              } else {
                setFieldValue('budget', credit_bal);
              }
            }}
            onBlur={(e) => {
              handleBlur(e);
              dispatch(action({ [e.target.name]: JSON.parse(e.target.value) }));
            }}
            error={
              errors.budget &&
              intl.formatMessage({
                id: errors.budget
              })
            }
            touched={touched.budget}
            useFloatingLable={false}
          />
        </div>
        <div className="w-full p-2">
          <Select
            name="frequency"
            placeholder={intl.formatMessage({
              id: 'advertise.campaigns.form.placeholder.frequency'
            })}
            value={values.frequency}
            error={
              errors.frequency &&
              intl.formatMessage({
                id: errors.frequency
              })
            }
            touched={touched.frequency}
            options={frequencyOpts}
            setFieldValue={(name, obj) => {
              if (obj.id) {
                setFieldValue(name, obj.id);
              }
            }}
            setFieldTouched={setFieldTouched}
            useFloatingLable={false}
            optionLabelKey="name"
            optionValueKey="id"
          />
        </div>
      </div>
      <div className="flex justify-between m-4">
        <div className="w-1/3">
          <Button
            title={intl.formatMessage({
              id: 'button.title.previous'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            click={() => setStep(1)}
          />
        </div>
        <div className="w-1/3">
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            disabled={!isEmpty(errors)}
            click={() => setStep(3)}
          />
        </div>
      </div>
    </div>
  );
};

StepThree.propTypes = {
  frequencyOpts: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default StepThree;
