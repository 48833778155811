import React from 'react';
import PropTypes from 'prop-types';
import { CoinsIcon } from 'assets/icons/svg';

const Card = ({ title, value, coins, className }) => {
  return (
    <div className={`w-1/2 ${className} bg-white overflow-hidden shadow-sm rounded-lg font-normal`}>
      <div className="px-4 py-5">
        <div className="mb-5 text-xs leading-3">{title}</div>
        <div className="text-base leading-3 font-semibold">
          {coins ? (
            <div className="flex items-center">
              <span className="mr-1">
                <CoinsIcon />
              </span>
              {coins}
            </div>
          ) : (
            value
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  coins: PropTypes.string
};

export default Card;
