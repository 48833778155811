import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Transition from 'components/TransitionGroup/Transition.js';
import AirtimeOptionModal from 'components/Shop/AirtimeOptionModal';

// COMPONENT IMPORTS
import { ChevronIcon } from 'assets/icons/svg';

const MenuItem = ({ intl, title, icon, parent, active, submenu, location, inverse }) => {
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  if (submenu) {
    return (
      <div
        className={`${
          active
            ? `${inverse ? 'bg-white text-ra-dark' : 'text-ra-dark bg-ra-200'}`
            : `${inverse ? 'text-gray-600 bg-white' : 'text-gray-600 bg-white'}`
        }`}
      >
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={!isOpen ? 'false' : 'true'}
          aria-labelledby="listbox-label"
          className={`relative w-full hover:text-ra-dark ${
            inverse ? 'hover:bg-white' : 'hover:bg-ra-200'
          } flex pl-4 items-center text-sm leading-5 px-2 py-3 font-medium focus:outline-none`}
          onClick={() => {
            setOpen(!isOpen);
            if (parent !== 'settings') {
              history.push(`/${parent}`);
            }
          }}
        >
          {icon}
          {title}
          <div
            className={`absolute mt-1 right-4 transform duration-300 ${
              isOpen || active ? 'rotate-270' : '-rotate-90'
            }`}
          >
            <ChevronIcon />
          </div>
        </button>
        <div className="relative mt-1 w-full" style={{ display: isOpen || active ? '' : 'none' }}>
          <Transition
            show={isOpen || active}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              className="max-h-60 py-1 text-base text-left leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {submenu.map((e, i) => (
                <li
                  key={i}
                  id={`listbox-option-${i}`}
                  role="option"
                  aria-selected="false"
                  className="relative flex h-12 mb-1 cursor-default select-none hover:bg-ra-200 hover:text-ra-dark"
                >
                  {e.groups?.length ? (
                    <>
                      <AirtimeOptionModal
                        intl={intl}
                        handleClose={setModalOpen}
                        isOpen={isModalOpen}
                        local={e?.groups?.find((i) => i.name === 'local_airtime')}
                        international={e?.groups?.find((i) => i.name === 'international_airtime')}
                      />
                      <div
                        onClick={(_) => setModalOpen(true)}
                        className={`h-12 w-full text-sm cursor-pointer leading-5 p-3 ml-10 ${
                          location.pathname === `/${parent}/${e.name}`
                            ? 'font-bold'
                            : 'hover:font-bold'
                        }`}
                      >
                        {e.alias}
                      </div>
                    </>
                  ) : (
                    <NavLink
                      to={{
                        pathname: e.newTab ? `/${e.name}` : `/${parent}/${e.name}`,
                        state: { convenience_fee: e.convenience_fee }
                      }}
                      target={e.newTab ? '_blank' : ''}
                      className={`h-12 w-full text-sm leading-5 p-3 ml-10 ${
                        location.pathname === `/${parent}/${e.name}`
                          ? 'font-bold'
                          : 'hover:font-bold'
                      }`}
                    >
                      {e.alias}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </Transition>
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={`/${parent}`}
        className={`mb-1  pl-4 flex items-center text-sm leading-5 px-2 py-3 font-medium ${
          active
            ? `text-ra-dark ${inverse ? 'focus:text-ra-dark bg-white' : 'bg-ra-200'}`
            : `text-gray-600 hover:text-ra-dark${inverse ? '' : 'hover:bg-ra-200'}`
        } focus:outline-none transition ease-in-out duration-150`}
      >
        {icon}
        {title}
      </NavLink>
    );
  }
};

MenuItem.propTypes = {
  intl: PropTypes.any.isRequired,
  title: PropTypes.string,
  icon: PropTypes.node,
  parent: PropTypes.string,
  active: PropTypes.bool,
  submenu: PropTypes.array,
  location: PropTypes.object
};

export default MenuItem;
