import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'
import YouTube from 'react-youtube'

function HowItWorks() {


    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1
        }
    };

    const _onReady = (event) => {
        event.target.pauseVideo();
    };


    return (

        <        >
            <FlexBox direction='column' justify='center' h='auto' sx={{ bgcolor: '#05213D', py: '50px' }} gap='44px'>
                <Text56 align='center' w='100%' color='white'>How it works</Text56>

                {/* <Img src={images.youtubevid} mH='70%' mW={'65%'}/> */}

                <YouTube
                    videoId="xqlf60JBsUc"
                    opts={opts}
                    onReady={_onReady}
                    className="h-50 md:w-4/5 md:h-100 xl:h-150"
                    containerClassName="w-full flex justify-center p-5 md:p-0"
                />
            </FlexBox>
        </>
    )
}

export default HowItWorks