import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavigationItems from '../NavigationItems';
import NavigationItem from '../NavigationItem';
import Transition from 'components/TransitionGroup/Transition';

const MobileNavigation = ({ intl, mobileToggleState, items }) => {
  const [hide, setHide] = useState('none');

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setHide('');
      if (!mobileToggleState) {
        setTimeout(() => setHide('none'), 200);
      }
    }
    return () => (mounted = false);
  }, [mobileToggleState]);

  return (
    <div className="lg:hidden" style={{ display: hide }}>
      <div className="fixed w-full z-40 mt-20 inset-0">
        <Transition
          show={mobileToggleState}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="relative flex-1 flex flex-col w-full bg-white">
            <div className="flex-1 min-h-screen pt-5 pb-4 overflow-y-auto">
              <nav className="mt-5 px-2">
                <NavigationItems
                  role="menuitem"
                  items={items}
                  classItems="mt-1 block px-3 py-2 rounded-md text-28 font-normal text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                />
              </nav>
              <div className="flex flex-wrap justify-center mt-32 px-5">
                <NavigationItem
                  role="menuitem"
                  title={intl.formatMessage({
                    id: 'login.text'
                  })}
                  url="https://play.google.com/store/apps/details?id=com.reachafrika"
                  classItems="w-4/5 text-center px-10 py-3 mb-5 border border-gray-300 text-sm rounded-md hover:text-white hover:bg-ra-dark focus:outline-none transition duration-150 ease-in-out"
                />

                <NavigationItem
                  role="menuitem"
                  title={intl.formatMessage({
                    id: 'signup.text'
                  })}
                  url="https://play.google.com/store/apps/details?id=com.reachafrika"
                  classItems="w-4/5 text-center px-10 py-3 border border-gray-300 text-sm rounded-md text-white bg-ra-dark focus:outline-none transition duration-150 ease-in-out"
                />
              </div>
            </div>
          </div>
        </Transition>
        <div className="flex-shrink-0 w-14"></div>
      </div>
    </div>
  );
};

MobileNavigation.propTypes = {
  items: PropTypes.array.isRequired,
  mobileToggleState: PropTypes.bool.isRequired
};

export default MobileNavigation;
