import React from 'react';
import Radio from 'components/Form/Radio';

const Display = ({ user_profiles, dispatch, action, intl, setFormState }) => {
  return (
    <>
      {user_profiles.map(({ id, active, profile }) => (
        <div key={id} className="flex items-center border-b border-gray-200 py-8">
          <div className="rounded-md mr-3">
            <img className="w-10 rounded-md" src={profile.avatar} alt={profile.description} />
          </div>
          <div className="flex flex-col ml-3">
            <span className="text-base text-gray-700 font-semibold">{profile.description}</span>
            <span className="text-base text-gray-400">{'+228 23 456 7890'}</span>
          </div>
          <div className="flex items-center h-5 absolute right-10">
            <Radio
              id="activate_profile"
              name="activate_profile"
              onChange={() => dispatch(action(id))}
              className="h-8 w-8 text-ra-dark cursor-pointer border-gray-300"
              checked={active}
            />
          </div>
        </div>
      ))}

      <div className="pt-4">
        <button
          className="inline-flex items-center justify-center px-3 py-3 text-ra-dark focus:outline-none"
          onClick={() => setFormState('add')}
        >
          <div className="text-4xl rounded-md border border-ra-dark flex justify-center items-center h-10 w-10">
            <span className="-mt-2">+</span>
          </div>
          <div className="ml-3">
            {intl.formatMessage({
              id: 'profile.page.title.add'
            })}
          </div>
        </button>
      </div>
    </>
  );
};

export default Display;
