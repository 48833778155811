import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

// COMPONENTS
import { ErrorIcon } from 'assets/icons/svg';

const CustomInput = ({
  type,
  name,
  placeholder,
  inputValue,
  onChange,
  onBlur,
  error,
  touched,
  isPassField,
  useAddOnsPrefix = false,
  useFloatingLable = true,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const errorClass = `border-red-300 text-red-900 focus:border-red-300`;
  const isTrue = error && touched;

  const styles = inputValue
    ? {
        top: -7,
        fontSize: 11,
        opacity: '1',
        backgroundColor: '#ffffff',
        padding: '0 5px'
      }
    : {};

  return (
    <div className="relative text-left rounded-md shadow-sm">
      {!useFloatingLable && (
        <label htmlFor={name} className="block leading-5 mb-1">
          {placeholder}
        </label>
      )}

      <div className={useAddOnsPrefix ? 'flex' : ''}>
        {useAddOnsPrefix && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-lg font-bold -mr-2 z-10">
            NGN
          </span>
        )}
        <input
          {...rest}
          autoComplete="off"
          type={showPassword ? 'text' : type}
          name={name}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={useFloatingLable ? '' : placeholder}
          className={`form-input block w-full pb-2 pt-4 text-base h-12 sm:text-sm sm:leading-5 transition duration-150 ease-in-out focus:border-ra-dark focus:shadow-none ${
            isTrue && errorClass
          }`}
          aria-invalid={isTrue}
        />
        {useFloatingLable && (
          <span
            className="floating-label absolute pointer-events-none transition-all duration-200 ease-in-out opacity-25 left-3 top-3"
            style={styles}
          >
            {placeholder}
          </span>
        )}
      </div>

      {isTrue && !isPassField && (
        <div className="absolute -mt-8 right-0 pr-3 flex items-center pointer-events-none">
          <ErrorIcon />
        </div>
      )}

      {isPassField && (
        <span
          onClick={handleShowPassword}
          className={`absolute right-3 ${
            useFloatingLable ? 'top-4' : 'top-10'
          } text-xl text-gray-500 focus-within:hidden ${isTrue && 'text-red-600'}`}
        >
          {showPassword ? <MdVisibility title="hide" /> : <MdVisibilityOff title="show" />}
        </span>
      )}

      {isTrue && (
        <p className="mt-0 text-xs text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputValue: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool
};

export default CustomInput;
