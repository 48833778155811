import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  CONTACTS_GROUPS,
  CONTACTS_GROUPS_CONTACT,
  CREDIT_TRANSACTIONS,
  CREDIT_TOP_UP,
  BANKS,
  ADVERTISE_CAMPAIGNS,
  FREQUENCIES,
  POTENTIAL_REACH_COUNT,
  TARGET_AUDIENCE_COUNT,
  IMPRESSION_COUNT,
  VIEWERSHIP_RATE,
  VIEW_OF_WEEK,
  CLICK_THRU_RATE
} from 'constants/actionTypes';

import {
  contacts_groups_get_success,
  contacts_groups_get_failed,
  contacts_groups_post_success,
  contacts_groups_post_failed,
  contacts_groups_patch_success,
  contacts_groups_patch_failed,
  contacts_groups_delete_success,
  contacts_groups_delete_failed,
  contacts_groups_get_contact_success,
  contacts_groups_get_contact_failed,
  contacts_groups_post_contact_success,
  contacts_groups_post_contact_failed,
  contacts_groups_patch_contact_success,
  contacts_groups_patch_contact_failed,
  contacts_groups_delete_contact_success,
  contacts_groups_delete_contact_failed,
  credit_transactions_success,
  credit_transactions_failed,
  credit_top_up_success,
  credit_top_up_failed,
  banks_success,
  banks_failed,
  ads_campaigns_success,
  ads_campaigns_failed,
  ads_campaign_success,
  ads_campaign_failed,
  ads_campaigns_post_success,
  ads_campaigns_post_failed,
  ads_campaigns_patch_success,
  ads_campaigns_patch_failed,
  ads_campaigns_delete_success,
  ads_campaigns_delete_failed,
  frequencies_success,
  frequencies_failed,
  potential_reach_count_success,
  potential_reach_count_failed,
  target_audience_count_success,
  target_audience_count_failed,
  impression_count_success,
  impression_count_failed,
  viewership_rate_success,
  viewership_rate_failed,
  views_of_the_week_success,
  views_of_the_week_failed,
  click_thru_rate_success,
  click_thru_rate_failed
} from './actions';

import * as Api from './api';

//#region CONTACTS_GROUPS.GET
function* contacts_groups_get_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_get_async);
    if (res.data) {
      yield put(contacts_groups_get_success(res.data));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_get_failed(error.data.error));
    } else {
      yield put(contacts_groups_get_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_get_request() {
  yield takeEvery(CONTACTS_GROUPS.GET_REQUEST, contacts_groups_get_request);
}
//#endregion

//#region CONTACTS_GROUPS.POST
function* contacts_groups_post_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_post_async, payload);
    if (res.data) {
      yield put(contacts_groups_post_success(res.data));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_post_failed(error.data.error));
    } else {
      yield put(contacts_groups_post_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_post_request() {
  yield takeEvery(CONTACTS_GROUPS.POST_REQUEST, contacts_groups_post_request);
}
//#endregion

//#region CONTACTS_GROUPS.PATCH
function* contacts_groups_patch_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_patch_async, payload);
    yield put(contacts_groups_patch_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_patch_failed(error.data.error));
    } else {
      yield put(contacts_groups_patch_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_patch_request() {
  yield takeEvery(CONTACTS_GROUPS.PATCH_REQUEST, contacts_groups_patch_request);
}
//#endregion

//#region CONTACTS_GROUPS.DELETE
function* contacts_groups_delete_request({ payload }) {
  try {
    yield put(showLoading());
    yield call(Api.contacts_groups_delete_async, payload);
    yield put(contacts_groups_delete_success(payload));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_delete_failed(error.data.error));
    } else {
      yield put(contacts_groups_delete_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_delete_request() {
  yield takeEvery(CONTACTS_GROUPS.DELETE_REQUEST, contacts_groups_delete_request);
}
//#endregion

//#region CONTACTS_GROUPS_CONTACT.GET
function* contacts_groups_get_contact_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_get_contact_async, payload);
    yield put(contacts_groups_get_contact_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_get_contact_failed(error.data.error));
    } else {
      yield put(contacts_groups_get_contact_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_get_contact_request() {
  yield takeEvery(CONTACTS_GROUPS_CONTACT.GET_REQUEST, contacts_groups_get_contact_request);
}
//#endregion

//#region CONTACTS_GROUPS_CONTACT.POST
function* contacts_groups_post_contact_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_post_contact_async, payload);
    yield put(contacts_groups_post_contact_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_post_contact_failed(error.data.error));
    } else {
      yield put(contacts_groups_post_contact_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_post_contact_request() {
  yield takeEvery(CONTACTS_GROUPS_CONTACT.POST_REQUEST, contacts_groups_post_contact_request);
}
//#endregion

//#region CONTACTS_GROUPS_CONTACT.PATCH
function* contacts_groups_patch_contact_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.contacts_groups_patch_contact_async, payload);
    yield put(contacts_groups_patch_contact_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_patch_contact_failed(error.data.error));
    } else {
      yield put(contacts_groups_patch_contact_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_patch_contact_request() {
  yield takeEvery(CONTACTS_GROUPS_CONTACT.PATCH_REQUEST, contacts_groups_patch_contact_request);
}
//#endregion

//#region CONTACTS_GROUPS.DELETE
function* contacts_groups_delete_contact_request({ payload }) {
  try {
    yield put(showLoading());
    yield call(Api.contacts_groups_delete_contact_async, payload);
    yield put(contacts_groups_delete_contact_success(payload));
  } catch (error) {
    if (error?.data?.error) {
      yield put(contacts_groups_delete_contact_failed(error.data.error));
    } else {
      yield put(contacts_groups_delete_contact_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_contacts_groups_delete_contact_request() {
  yield takeEvery(CONTACTS_GROUPS_CONTACT.DELETE_REQUEST, contacts_groups_delete_contact_request);
}
//#endregion

//#region CREDIT_TRANSACTIONS.GET
function* credit_transactions_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.credit_transactions_async);
    if (res.data) {
      yield put(credit_transactions_success(res.data));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(credit_transactions_failed(error.data.error));
    } else {
      yield put(credit_transactions_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_credit_transactions_request() {
  yield takeEvery(CREDIT_TRANSACTIONS.GET_REQUEST, credit_transactions_request);
}
//#endregion

//#region CREDIT_TOP_UP.POST
function* credit_top_up_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.credit_top_up_async, payload);
    let message;
    if (res.data) {
      if (payload.get('method') === 'bank') {
        message = 'advertise.credits.success.text_1';
      } else {
        message = 'advertise.credits.success.text_2';
      }
      yield put(credit_top_up_success(message));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(credit_top_up_failed(error.data.error));
    } else {
      yield put(credit_top_up_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_credit_top_up_request() {
  yield takeEvery(CREDIT_TOP_UP.POST_REQUEST, credit_top_up_request);
}
//#endregion

//#region BANKS.GET
function* banks_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.banks_async);
    if (res.data) {
      yield put(banks_success(res.data));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(banks_failed(error.data.error));
    } else {
      yield put(banks_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_banks_request() {
  yield takeEvery(BANKS.GET_REQUEST, banks_request);
}
//#endregion

//#region ADVERTISE_CAMPAIGNS.GET
function* ads_campaigns_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_campaigns);
    yield put(ads_campaigns_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(ads_campaigns_failed(error.data.error));
    } else {
      yield put(ads_campaigns_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_ads_campaigns_request() {
  yield takeEvery(ADVERTISE_CAMPAIGNS.GET_REQUEST, ads_campaigns_request);
}
//#endregion

//#region ADVERTISE_CAMPAIGNS.GET_ONE
function* ads_campaign_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_campaign, payload);
    yield put(ads_campaign_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(ads_campaign_failed(error.data.error));
    } else {
      yield put(ads_campaign_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_ads_campaign_request() {
  yield takeEvery(ADVERTISE_CAMPAIGNS.GET_ONE_REQUEST, ads_campaign_request);
}
//#endregion

//#region ADVERTISE_CAMPAIGNS.POST
function* ads_campaigns_post_request({ payload }) {
  const { data, history } = payload;
  try {
    yield put(showLoading());
    const res = yield call(Api.post_campaign, data);
    yield put(ads_campaigns_post_success(res.data));
    history.push('/advertise/campaigns');
  } catch (error) {
    if (error?.data?.error) {
      yield put(ads_campaigns_post_failed(error.data.error));
    } else {
      yield put(ads_campaigns_post_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_ads_campaigns_post_request() {
  yield takeEvery(ADVERTISE_CAMPAIGNS.POST_REQUEST, ads_campaigns_post_request);
}
//#endregion

//#region ADVERTISE_CAMPAIGNS.PATCH
function* ads_campaigns_patch_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.patch_campaign, payload);
    yield put(ads_campaigns_patch_success(res.data.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(ads_campaigns_patch_failed(error.data.error));
    } else {
      yield put(ads_campaigns_patch_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_ads_campaigns_patch_request() {
  yield takeEvery(ADVERTISE_CAMPAIGNS.PATCH_REQUEST, ads_campaigns_patch_request);
}
//#endregion

//#region ADVERTISE_CAMPAIGNS.DELETE
function* ads_campaigns_delete_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.delete_campaign, payload);
    yield put(ads_campaigns_delete_success(res.data.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(ads_campaigns_delete_failed(error.data.error));
    } else {
      yield put(ads_campaigns_delete_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_ads_campaigns_delete_request() {
  yield takeEvery(ADVERTISE_CAMPAIGNS.DELETE_REQUEST, ads_campaigns_delete_request);
}
//#endregion

//#region FREQUENCIES.GET
function* frequencies_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_data_count_get, 'frequencies');
    if (res.data) {
      yield put(frequencies_success(res.data));
    } else {
      throw new Error('Unexpected error');
    }
  } catch (error) {
    if (error?.data?.error) {
      yield put(frequencies_failed(error.data.error));
    } else {
      yield put(frequencies_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_frequencies_request() {
  yield takeEvery(FREQUENCIES.GET_REQUEST, frequencies_request);
}
//#endregion

//#region POTENTIAL_REACH_COUNT.GET
function* potential_reach_count_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_data_count_get, 'user-count');
    yield put(potential_reach_count_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(potential_reach_count_failed(error.data.error));
    } else {
      yield put(potential_reach_count_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_potential_reach_count_request() {
  yield takeEvery(POTENTIAL_REACH_COUNT.GET_REQUEST, potential_reach_count_request);
}
//#endregion

//#region TARGET_AUDIENCE_COUNT.GET
function* target_audience_count_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_data_count_post, { path: 'target-count', payload });
    yield put(target_audience_count_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(target_audience_count_failed(error.data.error));
    } else {
      yield put(target_audience_count_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_target_audience_count_request() {
  yield takeEvery(TARGET_AUDIENCE_COUNT.GET_REQUEST, target_audience_count_request);
}
//#endregion

//#region IMPRESSION_COUNT.POST
function* impression_count_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.fetch_data_count_post, { path: 'impressions', payload });
    yield put(impression_count_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(impression_count_failed(error.data.error));
    } else {
      yield put(impression_count_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_impression_count_request() {
  yield takeEvery(IMPRESSION_COUNT.POST_REQUEST, impression_count_request);
}
//#endregion

//#region VIEWERSHIP_RATE.GET
function* viewership_rate_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.viewership_rate, payload);
    yield put(viewership_rate_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(viewership_rate_failed(error.data.error));
    } else {
      yield put(viewership_rate_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_viewership_rate_request() {
  yield takeEvery(VIEWERSHIP_RATE.GET_REQUEST, viewership_rate_request);
}
//#endregion

//#region VIEW_OF_WEEK.GET
function* views_of_the_week_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.views_of_the_week, payload);
    yield put(views_of_the_week_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(views_of_the_week_failed(error.data.error));
    } else {
      yield put(views_of_the_week_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_views_of_the_week_request() {
  yield takeEvery(VIEW_OF_WEEK.GET_REQUEST, views_of_the_week_request);
}
//#endregion

//#region CLICK_THRU_RATE.GET
function* click_thru_rate_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.click_thru_rate, payload);
    yield put(click_thru_rate_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(click_thru_rate_failed(error.data.error));
    } else {
      yield put(click_thru_rate_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_click_thru_rate_request() {
  yield takeEvery(CLICK_THRU_RATE.GET_REQUEST, click_thru_rate_request);
}
//#endregion

const advertiseSagas = [
  fork(watch_contacts_groups_get_request),
  fork(watch_contacts_groups_post_request),
  fork(watch_contacts_groups_patch_request),
  fork(watch_contacts_groups_delete_request),
  fork(watch_contacts_groups_get_contact_request),
  fork(watch_contacts_groups_post_contact_request),
  fork(watch_contacts_groups_patch_contact_request),
  fork(watch_contacts_groups_delete_contact_request),
  fork(watch_credit_transactions_request),
  fork(watch_credit_top_up_request),
  fork(watch_banks_request),
  fork(watch_ads_campaigns_request),
  fork(watch_ads_campaign_request),
  fork(watch_ads_campaigns_post_request),
  fork(watch_ads_campaigns_patch_request),
  fork(watch_ads_campaigns_delete_request),
  fork(watch_frequencies_request),
  fork(watch_potential_reach_count_request),
  fork(watch_target_audience_count_request),
  fork(watch_impression_count_request),
  fork(watch_viewership_rate_request),
  fork(watch_views_of_the_week_request),
  fork(watch_click_thru_rate_request)
];

export default advertiseSagas;
