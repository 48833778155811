import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import isEmpty from 'lodash/isEmpty';

const Table = ({ intl, title, columns, data, error, headerBtn }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <div className="flex flex-col mt-10">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle bg-white inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <div className="flex justify-between items-baseline py-8 px-10 border-b border-gray-200">
            <h6 className="font-bold">{title}</h6>
            {headerBtn}
          </div>
          {error ? (
            <div>
              {intl.formatMessage({
                id: 'table.error.text'
              })}
            </div>
          ) : (
            <>
              {isEmpty(data) ? (
                <p className="p-3 text-center text-gray-300">
                  {intl.formatMessage({
                    id: 'table.empty.text'
                  })}
                </p>
              ) : (
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className="px-6 py-4 text-left text-sm leading-4 font-normal text-gray-500 uppercase tracking-wider"
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-600"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  intl: PropTypes.any.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  error: PropTypes.any
};

export default Table;
