import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import { language_change_request } from 'redux/actions';

const ChangeLanguage = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'change-lang.page.title'
  })}`;

  const { lang } = useSelector((state) => state.authReducers);

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} id="change-lang.page.title" />
        <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:mx-32 lg:py-10 xl:mx-24">
          <div className="xl:w-3/6 xl:mx-auto">
            <div className="flex flex-wrap bg-white p-10">
              <h3 className="text-2xl font-bold">
                {intl.formatMessage({
                  id: 'change-lang.page.subtitle'
                })}
              </h3>
              <p className="text-sm text-gray-600">
                {intl.formatMessage({
                  id: 'change-lang.page.text'
                })}
              </p>
              <div className="mt-10 w-1/2">
                <select
                  className="w-full p-3 text-sm bg-gray-50 border rounded-md"
                  defaultValue={lang}
                  onChange={(e) => dispatch(language_change_request(e.currentTarget.value))}
                >
                  <option value="en">English</option>
                  <option value="fr">French</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default ChangeLanguage;
