import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
// import Select from 'components/Form/Select';
import Button from 'components/Button';

const One = ({
  intl,
  values,
  touched,
  errors,
  dirty,
  isEmailExist,
  loading,
  dispatch,
  validateEmailRequest,
  setStep,
  handleChange,
  handleBlur
  // setFieldValue,
  // setFieldTouched
}) => {
  // const genderOpts = [
  //   intl.formatMessage({
  //     id: 'signup.form.gender.male'
  //   }),
  //   intl.formatMessage({
  //     id: 'signup.form.gender.female'
  //   }),
  //   intl.formatMessage({
  //     id: 'signup.form.gender.others'
  //   })
  // ];

  return (
    <>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full mx-6 py-2">
          <Input
            type="email"
            name="email"
            placeholder="Email"
            inputValue={values.email}
            error={
              (errors.email &&
                intl.formatMessage({
                  id: errors.email
                })) ||
              (isEmailExist
                ? intl.formatMessage({
                    id: 'signup.form.error.email_3'
                  })
                : '')
            }
            touched={touched.email}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={(e) => {
              handleBlur(e);
              e.target.value && dispatch(validateEmailRequest({ email: e.target.value }));
            }}
          />
        </div>
        <div className="w-full mx-6 py-2">
          <Input
            type="password"
            name="password"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.password'
            })}
            inputValue={values.password}
            error={
              errors.password &&
              intl.formatMessage({
                id: errors.password
              })
            }
            touched={touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
            isPassField
          />
        </div>
        <div className="w-full mx-6 py-2">
          <Input
            type="password"
            name="confirm_password"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.confirm_password'
            })}
            inputValue={values.confirm_password}
            error={
              errors.confirm_password &&
              intl.formatMessage({
                id: errors.confirm_password
              })
            }
            touched={touched.confirm_password}
            onChange={handleChange}
            onBlur={handleBlur}
            isPassField
          />
        </div>
        <div className="w-full mx-6 py-2">
          {/* <Select
            name="gender"
            placeholder={intl.formatMessage({
              id: 'signup.form.placeholder.gender'
            })}
            value={values.gender}
            error={
              errors.gender &&
              intl.formatMessage({
                id: errors.gender
              })
            }
            touched={touched.gender}
            options={genderOpts}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          /> */}
        </div>
      </div>
      <div className="flex justify-end mt-4 mx-6">
        <div />
        <div>
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            color="bg-ra-dark"
            disabled={!isEmpty(errors) || !dirty || isEmailExist || loading}
            click={() => setStep(2)}
          />
        </div>
      </div>
    </>
  );
};

One.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  isEmailExist: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  validateEmailRequest: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default One;
