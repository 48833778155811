import moment from 'moment';
import sha1 from 'sha1';
import sha512 from 'js-sha512';
import getConfig from 'utils/env';

const { app } = getConfig();

export const formatDateTime = (payload) => moment(payload).format('lll');

export const hash = ({ product, params: { amount, payable } }) => {
  const salt = new Date().toISOString().slice(-24).replace(/\D/g, '').slice(0, 14);

  const total =
    sha1(salt) +
    sha1(product) +
    sha1(Number(amount).toFixed(2)) +
    sha1(Number(payable).toFixed(2)) +
    sha1(app) +
    sha1(salt);

  return {
    'Content-Type': 'application/json',
    'api-user-agent': app,
    hash: sha512(total),
    salt
  };
};
