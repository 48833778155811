import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { Formik } from 'formik';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import FormButton from 'components/Form/Button';

import { UnderLay as Loading } from 'components/Loading';

import { change_pin_request, clear_message_request } from 'redux/actions';

// VALIDAITON IMPORTS
import ValidationSchema from 'validations/settings/pin';

const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'change-pin.page.title'
  })}`;

  const { loading, message } = useSelector((state) => state.authReducers);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (message) {
        alert.show(
          intl.formatMessage({
            id: message
          }),
          {
            type: 'success',
            title: intl.formatMessage({
              id: 'change-pin.success'
            })
          }
        );
        dispatch(clear_message_request());
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, intl, message]);

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="change-pin.page.title" />
          <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:mx-32 lg:py-10 xl:mx-24">
            <div className="xl:w-3/6 xl:mx-auto">
              <Formik
                initialValues={{
                  current: '',
                  pin: '',
                  pin_confirmation: ''
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  dispatch(change_pin_request(values));
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => (
                  <form className="block" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap bg-white p-10">
                      <div className="w-full p-4">
                        <Input
                          type="password"
                          name="current"
                          placeholder={intl.formatMessage({
                            id: 'change-pin.form.placeholder.current-pin'
                          })}
                          inputValue={values.current}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.current &&
                            intl.formatMessage({
                              id: errors.current
                            })
                          }
                          touched={touched.current}
                          isPassField
                        />
                      </div>
                      <div className="w-full p-4">
                        <Input
                          type="password"
                          name="pin"
                          placeholder={intl.formatMessage({
                            id: 'change-pin.form.placeholder.new-pin'
                          })}
                          inputValue={values.pin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.pin &&
                            intl.formatMessage({
                              id: errors.pin
                            })
                          }
                          touched={touched.pin}
                          isPassField
                        />
                      </div>
                      <div className="w-full p-4">
                        <Input
                          type="password"
                          name="pin_confirmation"
                          placeholder={intl.formatMessage({
                            id: 'change-pin.form.placeholder.confirm-new-pin'
                          })}
                          inputValue={values.pin_confirmation}
                          error={
                            errors.pin_confirmation &&
                            intl.formatMessage({
                              id: errors.pin_confirmation
                            })
                          }
                          touched={touched.pin_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isPassField
                        />
                      </div>
                    </div>
                    <div className="flex justify-end w-full bg-white pb-13 px-13">
                      <div className="w-full md:w-2/5">
                        <FormButton
                          title={intl.formatMessage({
                            id: 'button.title.submit'
                          })}
                          color="bg-ra-dark"
                          disabled={!(dirty && isValid) || isSubmitting}
                          loading={isSubmitting}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ChangePassword;
