import * as yup from 'yup';

const fileValidation = (size, type) =>
  yup
    .mixed()
    .required('advertise.campaigns.errors.file_1')
    .test(
      'fileSize',
      'advertise.campaigns.errors.file_2',
      (value) => value && value.size <= 1024 * 1024 * size
    )
    .test(
      'fileType',
      'advertise.campaigns.errors.file_2',
      (value) => value && type.includes(value.type)
    );

export default yup.object().shape({
  title: yup.string().required('advertise.campaigns.camp.errors_1'),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'advertise.campaigns.camp.errors_2_a'
    )
    .required('advertise.campaigns.camp.errors_2_b'),
  type: yup.string().required('advertise.campaigns.camp.errors_3'),
  message: yup.string().required('advertise.campaigns.camp.errors_4'),
  description: yup.string().required('advertise.campaigns.camp.errors_5'),
  audience: yup.string().required('advertise.campaigns.camp.errors_6'),
  budget: yup.number().required('advertise.campaigns.camp.errors_7'),
  frequency: yup.string().required('advertise.campaigns.camp.errors_8'),
  contacts: yup.array().when('audience', {
    is: 'contact',
    then: yup.array().of(yup.string()).required('advertise.campaigns.camp.errors_9')
  }),
  media_file: yup.mixed().when('type', {
    is: 'video',
    then: fileValidation(10, ['video/mp4', 'video/x-m4v', 'video/mpeg']),
    otherwise: fileValidation(10, ['image/jpg', 'image/jpeg', 'image/png'])
  }),
  start_date: yup.date().when('selection_date', {
    is: 'later',
    then: yup.date().required('advertise.campaigns.camp.errors_10')
  }),
  end_date: yup.date().required('advertise.campaigns.camp.errors_11')
});
