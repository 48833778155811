import { Stack, Box } from '@mui/material'
import React from 'react'
const FlexBox = (props) => {
    return (
        <Stack gap={props.gap ?? '1px'}
            direction={props.direction ?? { sm: 'column', lg: 'row' }}
            height={props.h ?? '100%'} width={props.w ?? '100%'}
            alignItems={props.align ?? 'center'}
            justifyContent={props.justify ?? 'space-between'}
            flexWrap={props.wrap??''}
            {...props}
            sx={{ ...props.sx }}
        // {...props}
        >
            {props.children}
        </Stack>
    )
}
const VhContainer = (props) => {
    return (
        <Box
            height={props.h ?? { xs: 'fit-content', lg: '100vh' }} width={'100%'}
            // bgcolor={props.bgcolor ? props.bgcolor : 'white'}
            py={{ xs: '100px', lg: 0 }}
            sx={{
                ...props.sx, background: props.bgcolor ?? 'white',
               
                px: !props.notContain ? {
                    xs: '20px',
                    lg: '96px'
                } :
                    '0px'
            }}
            {...props}
        >
            {props.children}
        </Box >
    )
}

const Div = (props) => {
    return (
        <Box sx={{ width: props.w ? props.w : '100%', height: props.h ? props.h : '100%', ...props.sx }}>
            {props.children}
        </Box>
    )
}
export { FlexBox, VhContainer, Div }