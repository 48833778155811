import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, title, action, className, ...rest }) => {
  return (
    <div className={className}>
      <input
        {...rest}
        id={id}
        type="checkbox"
        onChange={action}
        className="form-checkbox h-4 w-4 text-ra-dark transition duration-150 ease-in-out"
      />
      <label htmlFor={id} className="ml-2 block text-sm leading-5 text-gray-900">
        {title}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default Checkbox;
