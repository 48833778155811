import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from 'components/VideoPlayer';
import Modal, { ModalHeader, ModalSubHeader, ModalBody } from 'components/Modal';
import { CoinsIcon } from 'assets/icons/svg';

import Alert from './Alert';

const ImageViewer = ({ src, alt, onEnded }) => {
  const [count, setCount] = useState(15);

  useEffect(() => {
    let mounted = true;
    let id = null;
    if (mounted) {
      if (count === 0) {
        onEnded();
      } else {
        id = setInterval(() => {
          setCount((count) => count - 1);
        }, 1000);
      }
    }
    return () => {
      mounted = false;
      clearInterval(id);
    };
  }, [count, onEnded]);

  return (
    <div className="w-full">
      <div className="absolute top-0 right-0 px-10 py-3 bg-ra-dark">
        <h3 className="text-4xl font-bold text-white">{count}</h3>
      </div>
      <img src={src} alt={alt} className="w-full h-150" />
    </div>
  );
};

const AdsCard = ({
  full_title,
  posted_on,
  duration,
  poster,
  description,
  value,
  campaign_type,
  url,
  type,
  callback,
  claimAction
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [isModalOpen2, toggleModal2] = useState(false);

  const handleEnded = () => {
    toggleModal2(true);
  };

  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <>
      <Alert
        isModalOpen={isModalOpen2}
        toggleModal={toggleModal2}
        earning={value}
        callback={callback}
        buttonAction={claimAction}
        handleClose={handleClose}
      />
      <Modal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        close={true}
        CloseIconColor="text-white"
        className="absolute top-3 mr-4 md:mr-0 md:p-0 md:top-10 sm:w-full md:w-5/6 xl:w-4/6 sm:p-6 rounded-md shadow-xl bg-white"
      >
        <ModalBody className="flex flex-wrap md:flex-no-wrap">
          {isModalOpen && (
            <>
              {type === 'video' ? (
                <VideoPlayer url={url} onEnded={handleEnded} />
              ) : (
                <ImageViewer src={url} alt={full_title} onEnded={handleEnded} />
              )}
            </>
          )}
        </ModalBody>
        <div className="flex flex-col p-5 text-base">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:w-2/4">
              <ModalHeader title={full_title} className="text-left text-base md:text-2xl" />
              <ModalSubHeader subtitle={description} />
            </div>
            <div className="mt-2 text-xs md:text-base md:w-40 md:text-right">{campaign_type}</div>
          </div>
          <div className="text-gray-400 mt-2 text-xs md:text-base">{posted_on}</div>
        </div>
      </Modal>
      <div className="w-full mb-4 lg:mx-auto overflow-hidden shadow-md rounded-md bg-white">
        <div className="relative cursor-pointer" onClick={() => toggleModal(!isModalOpen)}>
          <img src={poster} alt={full_title} className="w-full" />
          {type === 'video' && <div className="absolute top-4 right-6 text-white">{duration}</div>}
        </div>
        <div className="px-4 py-5 sm:p-6 relative">
          <div className="text-base font-semibold">{full_title}</div>
          <div className="text-base font-normal">{campaign_type}</div>
          <div className="text-xs text-gray-500">{posted_on}</div>
          <div className="flex mt-3 items-center absolute right-6 bottom-4">
            <CoinsIcon />
            <div className="font-normal text-xl ml-1">{value}</div>
          </div>
        </div>
      </div>
    </>
  );
};

AdsCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  duration: PropTypes.string,
  value: PropTypes.number
};

export default AdsCard;
