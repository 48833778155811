import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

// IMAGES IMPORTS
import Logo from 'assets/img/logo.png';
// LAYOUT IMPORTS
import Wrapper from 'container/layouts/Wrapper';
// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import FormButton from 'components/Form/Button';
import { UnderLay as Loading } from 'components/Loading';

import { forgot_password_request, clear_message_request } from 'redux/actions';

// VALIDAITON IMPORTS
import ForgetPasswordSchema from 'validations/forgot-password';

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'forget-password.page.title'
  })}`;

  const { user, token } = useSelector((state) => state.authReducers);

  const { error, loading, message } = useSelector((state) => state.authReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (error || message) {
        alert.show(error || message, {
          type: `${error ? 'error' : 'success'}`,
          title: `${
            error
              ? intl.formatMessage({
                  id: 'error.title'
                })
              : intl.formatMessage({
                  id: 'forgot-password.success.text'
                })
          }`
        });
        dispatch(clear_message_request());
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, error, intl, message]);

  if (user && token) return <Redirect to={'/dashboard'} />;

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper isAuthLayout {...props}>
        <main className="flex justify-center py-10">
          <div className="absolute w-full top-40 max-w-xl mx-auto sm:px-6 lg:px-8">
            <div className="bg-white overflow-hidden sm:rounded-lg border border-gray-200">
              <div className="bg-white px-4 py-5 sm:px-6 text-center">
                <div className="flex justify-center my-10">
                  <img className="h-6" src={Logo} alt="REACHAFRIKA" />
                </div>
                <h3 className="text-3xl leading-6 font-bold my-4">
                  {intl.formatMessage({ id: 'forgot-password.form.title' })}
                </h3>
                <p className="text-xs leading-5 text-gray-500">
                  {intl.formatMessage({ id: 'signin.form.sub-text' })}
                  <Link to="/signup">
                    <span className="text-ra-dark ml-1">
                      {intl.formatMessage({ id: 'signup.text' })}
                    </span>
                  </Link>
                </p>
                <Formik
                  initialValues={{
                    email: ''
                  }}
                  validationSchema={ForgetPasswordSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    dispatch(forgot_password_request(values));
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    dirty,
                    isValid,
                    isSubmitting,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <form className="block mt-6" onSubmit={handleSubmit}>
                      <div className="flex flex-wrap -mx-4">
                        <div className="w-full mx-8 py-2">
                          <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            inputValue={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.email &&
                              intl.formatMessage({
                                id: errors.email
                              })
                            }
                            touched={touched.email}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 mx-4">
                        <FormButton
                          title={intl.formatMessage({
                            id: 'button.title.submit'
                          })}
                          color="bg-ra-dark"
                          disabled={!(dirty && isValid) || isSubmitting}
                          loading={isSubmitting}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default ForgetPassword;
