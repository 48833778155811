import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import Table from 'components/Table';
import Button from 'components/Button';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Upload from 'components/Form/Upload';
import Checkbox from 'components/Form/Checkbox';
import FormButton from 'components/Form/Button';

import { UnderLay as Loading } from 'components/Loading';

import { formatDateTime } from 'utils/misc';
import { getFormattedMoney } from 'constants/helper';

import {
  reward_account_request,
  normal_account_request,
  credit_account_request,
  credit_transactions_request,
  credit_top_up_request,
  banks_request,
  log_fund_request,
  validate_pin_request
} from 'redux/actions';

// VALIDAITON IMPORTS
import ValidationSchema from 'validations/advertise/credits';

const AdvertiseCredits = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.credits.page.title'
  })}`;

  const [isModalOpen, toggleModal] = useState(false);
  const [payable, setPayable] = useState(0);

  const [pin, setPin] = useState(null);
  const [check, setCheck] = useState(false);
  const [pinTouched, setPinTouched] = useState(false);
  const [pinError, setPinError] = useState(null);

  const formRef = useRef(null);

  const { loading: authLoading, isPinCorrect } = useSelector((state) => state.authReducers);

  const {
    loading: loading2,
    reward_account_data,
    normal_account_data,
    credit_account_data,
    log_fund_data
  } = useSelector((state) => state.walletReducers);

  const { error, loading, credit_transactions, credit_top, banks } = useSelector(
    (state) => state.advertiseReducers
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(reward_account_request());
      dispatch(normal_account_request());
      dispatch(credit_account_request());
      dispatch(credit_transactions_request());
    }
    return () => (mounted = false);
  }, [dispatch, credit_top]);

  useEffect(() => {
    if (log_fund_data?.status === 'success') {
      window.location.href = log_fund_data.data.link;
    }

    if (credit_top) {
      alert.show(
        intl.formatMessage({
          id: credit_top
        }),
        {
          type: 'success',
          title: intl.formatMessage({
            id: 'advertise.credits.success.title'
          })
        }
      );
    }

    if (error) {
      alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
        type: 'error',
        title: intl.formatMessage({
          id: 'shop.products.error.title'
        })
      });
      toggleModal(true);
    }
  }, [alert, credit_top, dispatch, error, intl, log_fund_data]);

  useEffect(() => {
    if (!pin) {
      setPinError(
        intl.formatMessage({
          id: 'pin.confirm.error.pin.required'
        })
      );
    }
    if (check && !isPinCorrect) {
      setPinError(
        intl.formatMessage({
          id: 'pin.confirm.error.pin.incorrect'
        })
      );
    }
  }, [pin, check, intl, isPinCorrect]);

  const columns = [
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_1'
      }),
      accessor: 'amount'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_2'
      }),
      accessor: 'bonus'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_3'
      }),
      accessor: 'description'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_4'
      }),
      accessor: 'total'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_5'
      }),
      accessor: 'reference'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_6'
      }),
      accessor: 'transaction'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_7'
      }),
      Cell: ({ row }) => {
        const getState = (key) => {
          switch (key) {
            case 'pending':
              return { title: 'pending', color: 'blue' };
            case 'completed':
              return { title: 'completed', color: 'green' };
            case 'rejected':
            default:
              return { title: 'rejected', color: 'red' };
          }
        };
        const state = getState(row.original.status);
        return (
          <button
            className={`font-medium bg-${state.color}-50 rounded-sm border border-${state.color}-200 text-${state.color}-500  hover:text-${state.color}-300 transition ease-in-out focus:outline-none duration-150 px-3 py-2 `}
          >
            {state.title}
          </button>
        );
      }
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.credits.table.header_8'
      }),
      Cell: ({ row }) => formatDateTime(row.original.created_at)
    }
  ];

  const updatePayable = (useReward, amount) => {
    if (useReward) {
      const val = amount * 1 - reward_account_data.balance * 1;
      if (val < 0) {
        setPayable(0);
      } else {
        setPayable(val);
      }
    } else {
      setPayable(amount);
    }
  };

  const options = [
    { label: 'Card', value: 'card' },
    { label: 'Bank', value: 'bank' },
    { label: 'Reachafrika Wallet', value: 'wallet' }
  ];

  useEffect(() => {
    if (pin && isPinCorrect) {
      if (formRef.current) {
        formRef.current.handleSubmit();
        setPinTouched(false);
        setPin(null);
      }
    }
  }, [isPinCorrect, pin]);

  return (
    <>
      {loading || loading2 || authLoading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.credits.nav.title" />
          <div className="pt-2 pb-6 mx-4 md:mx-36 md:py-10 xl:mx-24">
            <Modal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              className="absolute top-10 overflow-visible sm:w-full md:w-1/3 bg-white sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
              close={true}
              closeOutside={true}
            >
              <ModalHeader
                title={intl.formatMessage({
                  id: 'advertise.credits.modal.title'
                })}
                className="text-left text-xl font-extrabold"
              />
              <ModalBody>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    amount: '',
                    method: '',
                    use_reward: false,
                    pop: null,
                    bank_id: null
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let check2 = true;
                    if (values.method !== 'card') {
                      const formData = new FormData();
                      formData.append('amount', values.amount);
                      formData.append('method', values.method);
                      formData.append('use_reward', values.use_reward);
                      formData.append('bank_id', values.bank_id);
                      formData.append('payable', payable);
                      values.pop && formData.append('pop', values.pop);
                      if (values.method === 'wallet') {
                        if (payable <= normal_account_data.balance * 1) {
                          dispatch(credit_top_up_request(formData));
                        } else {
                          check2 = false;
                          alert.show(`Total Balance: ${normal_account_data.total}`, {
                            type: 'error',
                            title: 'Insufficient Balance'
                          });
                        }
                      } else {
                        dispatch(credit_top_up_request(formData));
                      }
                    } else {
                      const data = {
                        amount: values.amount,
                        type: 'card',
                        account: 'credit',
                        payable
                      };
                      dispatch(log_fund_request(1, data));
                    }
                    if (check2) {
                      resetForm();
                      setPayable(0);
                      toggleModal(false);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting
                  }) => (
                    <form className="block" onSubmit={handleSubmit}>
                      <div className="flex flex-wrap">
                        <div className="w-full mb-1">
                          <Input
                            type="text"
                            name="amount"
                            placeholder={intl.formatMessage({
                              id: 'advertise.credits.form.placeholder.amount'
                            })}
                            inputValue={values.amount}
                            onChange={(e) => {
                              updatePayable(values.use_reward, e.target.value * 1);
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              updatePayable(values.use_reward, e.target.value * 1);
                              handleBlur(e);
                            }}
                            error={
                              errors.amount &&
                              intl.formatMessage({
                                id: errors.amount
                              })
                            }
                            touched={touched.amount}
                            useAddOnsPrefix
                            useFloatingLable={false}
                          />
                        </div>
                        <div className="w-full">
                          <Checkbox
                            id="use_reward"
                            name="use_reward"
                            title={intl.formatMessage({
                              id: 'shop.products.use_reward'
                            })}
                            value={values.use_reward}
                            action={(e) => {
                              handleChange(e);
                              updatePayable(!JSON.parse(e.target.value), values.amount * 1);
                            }}
                            className="mb-6 flex items-center"
                          />
                        </div>
                      </div>
                      <div className="w-full border-b border-t px-4">
                        <div className="flex justify-between w-full py-2">
                          <div>
                            {intl.formatMessage({
                              id: 'advertise.credits.amount'
                            })}
                          </div>
                          <div>{getFormattedMoney(values.amount)}</div>
                        </div>
                        <div className="flex justify-between w-full py-2">
                          <div>
                            {intl.formatMessage({
                              id: 'advertise.credits.credit_discount'
                            })}
                          </div>
                          <div>{getFormattedMoney(reward_account_data.balance)}</div>
                        </div>
                        <div className="flex justify-between w-full py-2">
                          <div>
                            {intl.formatMessage({
                              id: 'advertise.credits.payable'
                            })}
                          </div>
                          <div>{getFormattedMoney(payable)}</div>
                        </div>
                      </div>
                      <div className="flex flex-wrap w-full my-3">
                        <div className="w-full mb-2">
                          <Select
                            name="method"
                            placeholder={intl.formatMessage({
                              id: 'advertise.credits.form.placeholder.method'
                            })}
                            value={values.method}
                            error={
                              errors.method &&
                              intl.formatMessage({
                                id: errors.method
                              })
                            }
                            touched={touched.method}
                            options={payable === 0 ? [options[2]] : options}
                            setFieldValue={(name, obj) => {
                              if (obj.value) {
                                setFieldValue(name, obj.value);
                                if (obj.value === 'bank') {
                                  dispatch(banks_request());
                                }
                              }
                            }}
                            setFieldTouched={setFieldTouched}
                            useFloatingLable={false}
                            optionLabelKey={'label'}
                            optionValueKey={'value'}
                          />
                        </div>
                        {values.method === 'bank' && (
                          <>
                            <div className="w-full mb-2">
                              <Select
                                name="bank_id"
                                placeholder={intl.formatMessage({
                                  id: 'advertise.credits.form.placeholder.bank'
                                })}
                                value={values.bank_id}
                                error={
                                  errors.bank_id &&
                                  intl.formatMessage({
                                    id: errors.bank_id
                                  })
                                }
                                touched={touched.bank_id}
                                options={banks}
                                setFieldValue={(name, obj) => {
                                  if (obj.id) {
                                    setFieldValue(name, obj.id);
                                  }
                                }}
                                setFieldTouched={setFieldTouched}
                                useFloatingLable={false}
                                optionLabelKey="name"
                                optionValueKey="id"
                              />
                            </div>
                            <div className="relative w-full">
                              <Field
                                component={Upload}
                                placeholder={intl.formatMessage({
                                  id: 'advertise.credits.form.placeholder.pop'
                                })}
                                name="pop"
                                inputValue={values.pop}
                                accept="application/pdf, image/png, image/jpeg, image/jpg"
                                error={
                                  errors.pop &&
                                  intl.formatMessage({
                                    id: errors.pop
                                  })
                                }
                                touched={touched.pop}
                              />
                            </div>
                            <div className="w-full mt-2 mb-4">
                              <label className="block leading-5 sm:mt-px sm:pt-2 mb-2">
                                {intl.formatMessage({
                                  id: 'advertise.credits.supported-banks'
                                })}
                              </label>
                              <div className="w-full flex mx-2">
                                {banks.map((e) => (
                                  <div key={e.id} className="w-1/2 mr-4">
                                    <div className="p-3 bg-gray-50">
                                      <div className="mb-2 text-xs">
                                        {e.name} - {e.account_name}
                                      </div>
                                      <div>{e.account_number}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                        {values.method === 'wallet' && (
                          <div className="w-full">
                            <Input
                              type="password"
                              id="pin"
                              name="pin"
                              placeholder="PIN"
                              inputValue={pin || ''}
                              onChange={(e) => {
                                setPin(e.target.value);
                                setPinError(null);
                                setCheck(false);
                                if (!e.target.value) {
                                  setPinError(
                                    intl.formatMessage({
                                      id: 'pin.confirm.error.pin.required'
                                    })
                                  );
                                }
                              }}
                              onBlur={(_) => setPinTouched(true)}
                              error={pinError}
                              touched={pinTouched}
                              useFloatingLable={false}
                              isPassField
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <div className="w-1/2 mr-2">
                          <Button
                            title={intl.formatMessage({
                              id: 'button.title.cancel'
                            })}
                            textColor="text-ra-dark"
                            className="border-ra-dark"
                            click={() => toggleModal(false)}
                          />
                        </div>
                        <div className="w-1/2 ml-2">
                          {values.method === 'wallet' ? (
                            <Button
                              title={intl.formatMessage({ id: 'advertise.credits.button-submit' })}
                              className={`${
                                pinError || !(dirty && isValid) || isSubmitting || !values.amount
                                  ? 'cursor-not-allowed'
                                  : 'bg-ra-dark'
                              }`}
                              textColor={`${
                                pinError || !(dirty && isValid) || isSubmitting || !values.amount
                                  ? 'text-ra-dark'
                                  : 'text-white'
                              }`}
                              disabled={
                                pinError || !(dirty && isValid) || isSubmitting || !values.amount
                              }
                              click={() => {
                                setPinError(null);
                                dispatch(validate_pin_request({ pin }));
                                setCheck(true);
                              }}
                            />
                          ) : (
                            <FormButton
                              title={intl.formatMessage({
                                id: 'advertise.credits.button-submit'
                              })}
                              classes={`${
                                !(dirty && isValid) || isSubmitting || !values.amount
                                  ? 'cursor-not-allowed'
                                  : 'bg-ra-dark'
                              }`}
                              textColor={`${
                                !(dirty && isValid) || isSubmitting || !values.amount
                                  ? 'text-ra-dark'
                                  : 'text-white'
                              }`}
                              disabled={!(dirty && isValid) || isSubmitting || !values.amount}
                              loading={isSubmitting}
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            <div className="font-bold text-xl">{credit_account_data?.total}</div>
            <Table
              intl={intl}
              title={intl.formatMessage({
                id: 'advertise.credits.table.title'
              })}
              columns={columns}
              data={credit_transactions}
              headerBtn={
                <Button
                  icon={<span className="text-xl mr-2">+</span>}
                  title={intl.formatMessage({
                    id: 'advertise.credits.button-add'
                  })}
                  width="w-2/12"
                  color="bg-ra-dark"
                  click={() => toggleModal(!isModalOpen)}
                />
              }
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseCredits;
