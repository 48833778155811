import * as Yup from 'yup';

export default Yup.object().shape({
  current: Yup.string()
    .min(4, 'change-pin.change.errors_1')
    .max(6, 'change-pin.change.errors_2')
    .matches(/^[0-9]*$/, { message: 'change-pin.change.errors_3' })
    .required('change-pin.change.errors_4'),
  pin: Yup.string()
    .min(4, 'change-pin.change.errors_1')
    .max(6, 'change-pin.change.errors_2')
    .matches(/^[0-9]*$/, { message: 'change-pin.change.errors_3' })
    .required('change-pin.change.errors_5'),
  pin_confirmation: Yup.string()
    .oneOf([Yup.ref('pin'), null], 'signup.form.error.confirm_pin_1')
    .required('signup.form.error.pin_4')
});
