import React from 'react';

import Modal, { ModalBody } from 'components/Modal';
import Button from 'components/Button';

import { CheckIcon, CoinsIcon } from 'assets/icons/svg';

const SuccessModal = ({ intl, handleClose, isOpen, message, earn }) => {
  return (
    <Modal
      isModalOpen={isOpen}
      toggleModal={handleClose}
      className="md:absolute sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:top-40 bg-white text-ra-darker sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
      close={true}
      closeOutside={true}
    >
      <ModalBody>
        <div className="flex flex-col items-center mb-4">
          <div className="mb-2">
            <CheckIcon />
          </div>
          <h3 className="text-2xl leading-3 my-2">
            {intl.formatMessage({
              id: 'shop.success.modal.title'
            })}
          </h3>
          {earn ? (
            <h3 className="text-2xl my-2">
              <div className="flex items-center">
                {intl.formatMessage({
                  id: 'shop.success.modal.text'
                })}
                <span className="mx-1">
                  <CoinsIcon />
                </span>
                {earn}
              </div>
            </h3>
          ) : (
            <div className="my-2" />
          )}
          <p className="text-sm text-center leading-5">{message}</p>
        </div>
        <Button
          title={intl.formatMessage({
            id: 'button.title.dismiss'
          })}
          color="bg-ra-dark"
          width="w-full"
          click={(_) => handleClose(false)}
        />
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
