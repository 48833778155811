import React from 'react';
import inRange from 'lodash/inRange';

import Input from 'components/Form/Input';
import PhoneInput from 'components/Form/Phone';
import Button from 'components/Button';

const StepOne = (props) => {
  return (
    <>
      <div className="w-full lg:w-1/2 p-2">
        <Input
          type="number"
          name="amount"
          min={props.data?.minVendAmount}
          max={props.data?.maxVendAmount}
          placeholder={`${props.intl.formatMessage({
            id: 'shop.power.form.placeholder.4'
          })} (Min: ${props.data?.minVendAmount}, Max: ${props.data?.maxVendAmount})`}
          inputValue={props.values.amount}
          onChange={(e) => {
            const res = inRange(
              e.target.value * 1,
              props.data.minVendAmount,
              props.data.maxVendAmount
            );
            if (res) {
              props.setBill(e.target.value * 1);
              props.handleChange(e);
            }
          }}
          onBlur={props.handleBlur}
          error={props.errors.amount}
          touched={props.touched.amount}
          useFloatingLable={false}
        />
        {/* <div className="flex text-xs justify-between">
          <span>Min Amount: {props.data.minVendAmount}</span>
          <span>Max Amount: {props.data.maxVendAmount}</span>
        </div> */}
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <PhoneInput
          name="phone"
          placeholder={props.intl.formatMessage({
            id: 'signup.form.placeholder.phone'
          })}
          value={props.values.phone}
          error={props.errors.phone}
          touched={props.touched.phone}
          setFieldValue={props.setFieldValue}
          setFieldTouched={props.setFieldTouched}
          useFloatingLable={false}
        />
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <Input
          readOnly
          name="shop_name"
          placeholder={props.intl.formatMessage({
            id: 'shop.power.form.placeholder.6'
          })}
          inputValue={props.data.name}
          useFloatingLable={false}
        />
        <input
          type="text"
          name="reference"
          style={{ display: 'none' }}
          value={props.data.orderId}
        />
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <Input
          readOnly
          name="shop_address"
          placeholder={props.intl.formatMessage({
            id: 'shop.power.form.placeholder.7'
          })}
          inputValue={props.data.address}
          useFloatingLable={false}
        />
      </div>
      <div className="flex justify-end w-full bg-white p-2">
        <div className="w-full md:w-2/5 xl:w-1/5">
          <Button
            title={props.intl.formatMessage({
              id: 'button.title.proceed'
            })}
            className="border-ra-dark"
            textColor="text-ra-dark"
            disabled={!(props.dirty && props.isValid) || props.isSubmitting}
            click={() => {
              props.toggleModal(!props.isModalOpen);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default StepOne;
