import React from 'react'
import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text56 } from 'pages/LandingPage/styles/text'

function PersonalDiv({ headerCompnent, smallText, bigImage, direction }) {
    return (
        <VhContainer>
            <FlexBox direction={direction} >
                <FlexBox h='fit-content' direction='column' justify='center' gap={{ xs: '10px', lg: '32px' }} w={{ sm: '95%', lg: '45%' }} position='relative'>

                    <Text56>
                        {headerCompnent}
                    </Text56>
                    <Text20 color='#657585' >
                        {smallText}
                    </Text20>
                </FlexBox>
                <Img src={bigImage ?? images.landingimg2} h={{ xs: '50%', lg: '75%' }} w={{ xs: 'auto', lg: '55%' }} />
            </FlexBox>
        </VhContainer>
    )
}

export default PersonalDiv