import React from 'react';
import PropTypes from 'prop-types';

const InvoiceCard = ({ intl, available_credit, campaign_credit, balance, ac_loading }) => {
  return (
    <div className="bg-white w-full mb-5 pb-5">
      <div className="border-gray-300 rounded-md">
        <div className="flex border-b border-gray-300 py-5">
          <h4 className="font-medium px-5 text-xl">
            {intl.formatMessage({
              id: 'advertise.campaigns.cost.budget.header'
            })}
          </h4>
        </div>
        <div className="px-5 mt-2">
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.cost.budget.sub-title_1'
              })}
            </span>
            <span>{ac_loading ? 'loading...' : available_credit}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.cost.budget.sub-title_2'
              })}
            </span>
            <span>{campaign_credit}</span>
          </div>
          <div className="flex justify-between border-b border-gray-300 py-4 px-2 mt-2">
            <span>
              {intl.formatMessage({
                id: 'advertise.campaigns.cost.budget.sub-title_3'
              })}
            </span>
            <span>{balance}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

InvoiceCard.propTypes = {
  available_credit: PropTypes.number.isRequired,
  campaign_credit: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired
};

export default InvoiceCard;
