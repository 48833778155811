import { images } from 'exports'
import { FlexBox } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text } from 'pages/LandingPage/styles/text'
import React from 'react'

function Parteners() {
    return (
        <FlexBox h={{ xs: 'auto', md: '300px' }} direction='column' sx={{py:'50px'}}>
            <Text size='32px' weight='700' align='center'>Our Partners</Text>
            <FlexBox w='80%' gap='10px'>
                <Img src={images.hotels} mH='64px' mW='150px'  />
                <Img src={images.pizzahut} mH='64px' mW='150px'  />
                <Img src={images.kfc} mH='64px' mW='150px'  />
                <Img src={images.shoprite} mH='64px' mW='150px'  />
                <Img src={images.melcom} mH='64px' mW='150px'  />
                <Img src={images.chaturbate} mH='64px' mW='150px'  />
            </FlexBox>
        </FlexBox>
    )
}

export default Parteners