import React from 'react';
import YouTube from 'react-youtube';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Android from 'assets/landing/google-play.png';
import Ios from 'assets/landing/apple-store.png';
import Umbrella from 'assets/landing/umbrella.png';
import Phone from 'assets/landing/phone.png';
import Reward from 'assets/landing/reward.png';
import Bang from 'assets/landing/bang.png';
import AndroidIos from 'assets/landing/android-ios.png';
import WhatsApp from 'assets/landing/whatsapp.png';
import Email from 'assets/landing/email.png';
import Telephone from 'assets/landing/telephone.png';
import Steve from 'assets/landing/Stevo.png';
import Opeyemi from 'assets/landing/Opeyemi.png';
import Sandra from 'assets/landing/Sandra.jpeg';
import John from 'assets/landing/John.jpg';

import Header from 'components/Landing/Header';
import IconItem from 'components/Landing/Home/IconItem/IconItem';
import Footer from 'components/Landing/Footer/Footer';

import Card from 'components/Landing/Home/Card/Card';
import AppCarousel from 'components/Landing/Carousel/Carousel';
import ItemHeader from 'components/Landing/Home/ItemHeader/ItemHeader';
import CustomerDetail from 'components/Landing/Home/CustomerDetail/CustomerDetail';

import { UnderLay as Loading } from 'components/Loading';

import { AirtimeIcon, TvIcon, MoneyIcon, PenPaperIcon } from 'assets/landing/svg';

import QuickActions from 'components/Dashboard/QuickActions';

import { shop_products_request } from 'redux/actions';

const Home = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'home.page.title'
  })}`;

  const { loading, error, shop_products } = useSelector((state) => state.shopReducers);
  const { user } = useSelector((state) => state.authReducers);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && !isEmpty(user)) {
      if (!shop_products.length) {
        dispatch(shop_products_request());
      }

      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, intl, error, shop_products.length, user]);

  const firstCarouselContent = [
    {
      title: intl.formatMessage({
        id: 'home.reason.title_1'
      }),
      body: intl.formatMessage({
        id: 'home.reason.text_1'
      }),
      backgroundColor: '#44C4A1',
      imageUrl: Umbrella
    },
    {
      title: intl.formatMessage({
        id: 'home.reason.title_2'
      }),
      body: intl.formatMessage({
        id: 'home.reason.text_2'
      }),
      backgroundColor: '#1976D2',
      imageUrl: Phone
    },
    {
      title: intl.formatMessage({
        id: 'home.reason.title_3'
      }),
      body: intl.formatMessage({
        id: 'home.reason.text_3'
      }),
      backgroundColor: '#DCA815',
      imageUrl: Reward
    }
  ];

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1
    }
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="overflow-hidden">
        <Header intl={intl} user={user} />
        {!isEmpty(user) ? (
          <div className="container m-auto py-5 mb-10 md:px-32 lg:px-56">
            <QuickActions
              intl={intl}
              className="border"
              products={shop_products}
              push={props.history.push}
            />
          </div>
        ) : (
          <div className="bg-image-home px-5 md:pl-10 xl:pl-48 md:mb-10 xl:mb-20 xl:mr-20">
            <div className="w-full md:w-3/5 xl:w-1/2 flex md:justify-center align-middle items-center">
              <div className="md:mt-10 xl:-mt-48">
                <h2 className="font-bold text-32 md:text-40 lg:text-6xl leading-tight">
                  {intl.formatMessage({
                    id: 'home.section_1.title'
                  })}
                </h2>

                <div className="w-full md:w-4/5 lg:pr-10">
                  <p className="my-6 lg:my-8 text-base md:text-lg leading-6 md:leading-7 text-ra-black">
                    {intl.formatMessage({
                      id: 'home.section_1.text'
                    })}
                  </p>

                  <div className="flex justify-center md:justify-start items-center">
                    <a
                      className="mr-3"
                      href="https://appurl.io/VnEmxNaZN"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={Android} alt="android" width="130" />
                    </a>
                    <a href="https://appurl.io/VnEmxNaZN">
                      <img src={Ios} alt="ios" width="130" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container mx-auto">
          <ItemHeader
            className="px-5"
            title={intl.formatMessage({
              id: 'home.header.title'
            })}
            subTitle={intl.formatMessage({
              id: 'home.header.sub-title'
            })}
          />
          <AppCarousel>
            {firstCarouselContent?.map((item) => (
              <Card {...item} key={item.title} />
            ))}
          </AppCarousel>
        </div>

        <div className="mt-16 md:mt-24 bg-gray-250 p-10">
          <div className="container mx-auto">
            <ItemHeader
              title={intl.formatMessage({
                id: 'home.section_2.title'
              })}
              subTitle={intl.formatMessage({
                id: 'home.section_2.sub-title'
              })}
            />

            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-2/5">
                <IconItem
                  icon={<AirtimeIcon />}
                  title={intl.formatMessage({
                    id: 'home.section_2.item_1.title'
                  })}
                  subTitle={intl.formatMessage({
                    id: 'home.section_2.item_1.sub-title'
                  })}
                />
              </div>
              <div className="w-full md:w-2/5">
                <IconItem
                  icon={<TvIcon />}
                  title={intl.formatMessage({
                    id: 'home.section_2.item_2.title'
                  })}
                  subTitle={intl.formatMessage({
                    id: 'home.section_2.item_2.sub-title'
                  })}
                />
              </div>
              <div className="w-full md:w-2/5">
                <IconItem
                  icon={<MoneyIcon />}
                  title={intl.formatMessage({
                    id: 'home.section_2.item_3.title'
                  })}
                  subTitle={intl.formatMessage({
                    id: 'home.section_2.item_3.sub-title'
                  })}
                />
              </div>
              <div className="w-full md:w-2/5">
                <IconItem
                  icon={<PenPaperIcon />}
                  title={intl.formatMessage({
                    id: 'home.section_2.item_4.title'
                  })}
                  subTitle={intl.formatMessage({
                    id: 'home.section_2.item_4.sub-title'
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto pt-10 md:py-20">
          <ItemHeader
            title={intl.formatMessage({
              id: 'home.section_3.title'
            })}
            subTitle={intl.formatMessage({
              id: 'home.section_3.sub-title'
            })}
          />
          <YouTube
            videoId="xqlf60JBsUc"
            opts={opts}
            onReady={_onReady}
            className="h-50 md:w-4/5 md:h-100 xl:h-150"
            containerClassName="w-full flex justify-center p-5 md:p-0"
          />
        </div>

        <div className="mt-6 md:mt-10">
          <div className="flex mb-4 flex-col md:flex-row">
            <div className="md:w-1/2 p-5 md:p-0">
              <img src={Bang} alt="bang" className="w-full" />
            </div>
            <div className="md:w-1/2 flex flex-col justify-center p-8 md:p-12">
              <div className="flex flex-col justify-center align-middle">
                <div className="w-full md:w-3/5">
                  <h2 className="font-medium text-28 md:text-32 lg:text-40 mb-4">
                    {intl.formatMessage({
                      id: 'home.section_4.text'
                    })}
                  </h2>
                </div>
                <div className="flex justify-center md:justify-start items-center">
                  <a
                    className="mr-3"
                    href="https://appurl.io/VnEmxNaZN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Android} alt="android" width="130" />
                  </a>
                  <a href="https://appurl.io/VnEmxNaZN">
                    <img src={Ios} alt="ios" width="130" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto mb-4 mt-10 md:mt-20 ">
          <ItemHeader
            title={intl.formatMessage({
              id: 'home.section_5.title'
            })}
          />

          <AppCarousel
            removeArrowOnDeviceType={['mobile', 'tablet']}
            className="md:mt-14 mb-4"
            sm={1}
            lg={1}
            md={1}
            showDots={true}
            arrows={false}
            itemClass="mb-8"
          >
            <CustomerDetail
              imageUrl={Opeyemi}
              about={intl.formatMessage({
                id: 'home.section_5.customer_1'
              })}
              name="Opeyemi Babalola"
            />
            <CustomerDetail
              imageUrl={Steve}
              about={intl.formatMessage({
                id: 'home.section_5.customer_2'
              })}
              name="Stephen Ogunniyi"
            />
            <CustomerDetail
              imageUrl={John}
              about={intl.formatMessage({
                id: 'home.section_5.customer_3'
              })}
              name="John Oshiotse"
            />
            <CustomerDetail
              imageUrl={Sandra}
              about={intl.formatMessage({
                id: 'home.section_5.customer_4'
              })}
              name="Sandra"
            />
          </AppCarousel>
        </div>

        <div className="mx-auto mb-4 pt-10 md:pt-20 bg-gray-350 flex flex-col justify-center md:p-24">
          <div className="md:w-3/5 xl:w-2/5 self-center">
            <ItemHeader
              title={intl.formatMessage({
                id: 'home.section_6.title'
              })}
              className="px-10"
            />
          </div>
          <div className="flex justify-center items-center">
            <a className="mr-3" href="https://reachafrika.com">
              <img src={Android} alt="android" width="130" />
            </a>
            <a href="https://reachafrika.com">
              <img src={Ios} alt="ios" width="130" />
            </a>
          </div>
          <img className="mt-10 self-center" src={AndroidIos} alt={'android-ios'} />
        </div>

        <div className="bg-blue-image p-0 md:p-40">
          <div className="w-full p-10">
            <h3 className="text-32 leading-10 text-center md:text-4xl md:leading-8 font-bold tracking-tight text-white">
              {intl.formatMessage({
                id: 'home.section_7.title'
              })}
            </h3>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-white">
              {intl.formatMessage({
                id: 'home.section_7.text'
              })}
            </p>

            <div className="flex justify-center align-middle mt-5">
              <a href="https://wa.me/2349057974461">
                <img src={WhatsApp} alt="whatsapp" className="mr-8" />
              </a>
              <a href="tel:+2348023629563">
                <img src={Telephone} alt="telephone" className="mr-8" />
              </a>
              <a href="mailto:support@reachafrika.com">
                <img src={Email} alt="email" />
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Home;
