import React from 'react';
import PropTypes from 'prop-types';

const card = (props) => {
  return (
    <div
      className="flex flex-col justify-between text-white m-1 h-full rounded-md overflow-hidden"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="p-10">
        <h2 className="font-bold text-2xl leading-7">{props.title}</h2>
        <p className="mt-1 text-base leading-7">{props.body}</p>
      </div>

      <img src={props.imageUrl} alt="hi" />
    </div>
  );
};
card.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default card;
