import React from 'react';
import Select from 'react-select';
import _get from 'lodash/get';
const ReactSelect = ({
  intl,
  field,
  form,
  title,
  value,
  options,
  multi,
  className,
  labelKey,
  valueKey,
  extraAction = () => {},
  ...rest
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: '3rem',
      border: 'none'
    })
  };

  const isArrayOfObj = !!(valueKey && labelKey);

  const getValue = (value) => {
    if (!multi) {
      if (value === '') {
        return [];
      } else {
        if (isArrayOfObj) {
          return options.filter((e) => e.id === value)[0];
        }
        return { value: value, label: value };
      }
    } else {
      return value.map((key) => options.filter((e) => e[valueKey] === key)[0]);
    }
  };

  const getOptions = (options) => {
    if (isArrayOfObj) {
      return options;
    } else {
      return Object.keys(options).map((key) => {
        return { value: options[key], label: options[key] };
      });
    }
  };

  const handleChange = (value) => {
    let item;
    if (!multi) {
      item = value ? (isArrayOfObj ? value[valueKey] : value.value) : '';
    } else {
      item = value ? value.map((a) => a[valueKey]) : [];
    }
    form.setFieldValue(field.name, item);
    extraAction(field.name, item);
  };

  const handleBlur = () => form.setFieldTouched(field.name, true);

  const error = _get(form.errors, field.name);
  const touched = _get(form.touched, field.name);
  const inputValue = getValue(value);

  return (
    <>
      <div className={`relative ${className}`}>
        <div className="my-1">{title}</div>
        <div
          className={`rounded-md border ${error && touched ? 'border-red-700' : 'border-gray-300'}`}
        >
          <Select
            {...rest}
            styles={customStyles}
            isMulti={multi}
            value={inputValue}
            onChange={(value) => handleChange(value)}
            onBlur={(value) => handleBlur(value)}
            options={getOptions(options)}
          />
        </div>
      </div>
      {error && touched && inputValue.length === 0 ? (
        <p className="text-xs text-red-700">
          {intl.formatMessage({
            id: error
          })}
        </p>
      ) : null}
    </>
  );
};

export default ReactSelect;
