import {
  SHOP_PRODUCTS,
  SHOP_PRODUCT_ITEMS,
  SHOP_PRODUCT_ITEM_PACKAGES,
  SHOP_PURCHASE,
  SHOP_BENEFICIARIES,
  SUPPORTED_NETWORK,
  TOP_UP_AIRTIME,
  DATA_PACKAGES,
  TOP_UP_DATA,
  CABLE_TV_PROVIDER,
  CABLE_TV_PACKAGE,
  VERIFY_SMART_CARD,
  CABLE_TV_TOP_UP,
  PRODUCT_ITEMS,
  EDUCATION_TOP_UP,
  IROKO_PROVIDER,
  TOP_UP_IROKO,
  PAN_AFRICAN_AIRTIME_PRE,
  PAN_AFRICAN_AIRTIME_TOP_UP,
  ACTIVE_PRODUCT,
  CHECK_METER,
  PURCHASE_POWER,
  LOADIND_IFRAME,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: null,
  transaction_error: null,
  shop_products: JSON.parse(sessionStorage.getItem('_rasp')) || [], // JSON.parse(sessionStorage.getItem('_rasp')) ||
  shop_product_items: [],
  set_from_country: null,
  shop_product_item_packages: [],
  shop_purchase: null,
  shop_beneficaries_post_data: null,
  shop_beneficaries_get_data: [],
  supported_network_data: {},
  top_up_airtime_data: null,
  data_get_packages: [],
  top_up_data_data: null,
  cable_tv_provider_data: [],
  cable_tv_package_data: [],
  verify_smart_card_data: null,
  cable_tv_top_up_data: null,
  product_itmes_get_data: [],
  education_top_up_data: null,
  iroko_provider_get_data: [],
  top_up_iroko_data: null,
  pan_african_airtime_pre_data: [],
  active_product: [],
  check_meter_data: null,
  purchase_power_data: null,
  pan_african_airtime_top_up_data: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region SHOP_PRODUCTS
    case SHOP_PRODUCTS.GET_REQUEST:
      return { ...state, loading: true };
    case SHOP_PRODUCTS.GET_SUCCESS:
      return { ...state, loading: false, shop_products: action.payload };
    case SHOP_PRODUCTS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SHOP_PRODUCT_ITEM_PACKAGES
    case SHOP_PRODUCT_ITEM_PACKAGES.POST_REQUEST:
      return { ...state, loading: true };
    case SHOP_PRODUCT_ITEM_PACKAGES.POST_SUCCESS:
      return { ...state, loading: false, shop_product_item_packages: action.payload };
    case SHOP_PRODUCT_ITEM_PACKAGES.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SHOP_PRODUCT_ITEMS
    case SHOP_PRODUCT_ITEMS.POST_REQUEST:
      return { ...state, loading: true };
    case SHOP_PRODUCT_ITEMS.POST_SUCCESS:
      return { ...state, loading: false, shop_product_items: action.payload };
    case SHOP_PRODUCT_ITEMS.POST_FAILED:
      console.log('object');
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SET_FROM_COUNTRY
    case 'SET_FROM_COUNTRY':
      return { ...state, set_from_country: action.payload };
    //#endregion
    //#region SHOP_PURCHASE
    case SHOP_PURCHASE.POST_REQUEST:
      return { ...state, loading: true };
    case SHOP_PURCHASE.POST_SUCCESS:
      return { ...state, loading: false, shop_purchase: action.payload };
    case SHOP_PURCHASE.POST_FAILED:
      return { ...state, loading: false, transaction_error: action.payload };
    //#endregion
    //#region SHOP_BENEFICIARIES_POST
    case SHOP_BENEFICIARIES.POST_REQUEST:
      return { ...state, loading: true };
    case SHOP_BENEFICIARIES.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        shop_beneficaries_post_data: action.payload,
        shop_beneficaries_get_data: [...state.shop_beneficaries_get_data, action.payload]
      };
    case SHOP_BENEFICIARIES.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SHOP_BENEFICIARIES_GET
    case SHOP_BENEFICIARIES.GET_REQUEST:
      return { ...state, loading: true };
    case SHOP_BENEFICIARIES.GET_SUCCESS:
      return { ...state, loading: false, shop_beneficaries_get_data: action.payload };
    case SHOP_BENEFICIARIES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SUPPORTED_NETWORK
    case SUPPORTED_NETWORK.GET_REQUEST:
      return { ...state, loading: true };
    case SUPPORTED_NETWORK.GET_SUCCESS:
      return { ...state, loading: false, supported_network_data: action.payload };
    case SUPPORTED_NETWORK.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region TOP_UP_AIRTIME
    case TOP_UP_AIRTIME.POST_REQUEST:
      return { ...state, loading: true };
    case TOP_UP_AIRTIME.POST_SUCCESS:
      return { ...state, loading: false, top_up_airtime_data: action.payload };
    case TOP_UP_AIRTIME.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region DATA_PACKAGES
    case DATA_PACKAGES.GET_REQUEST:
      return { ...state, loading: true };
    case DATA_PACKAGES.GET_SUCCESS:
      return { ...state, loading: false, data_get_packages: action.payload };
    case DATA_PACKAGES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region TOP_UP_DATA
    case TOP_UP_DATA.POST_REQUEST:
      return { ...state, loading: true };
    case TOP_UP_DATA.POST_SUCCESS:
      return { ...state, loading: false, top_up_data_data: action.payload };
    case TOP_UP_DATA.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CABLE_TV_PROVIDER
    case CABLE_TV_PROVIDER.GET_REQUEST:
      return { ...state, loading: true };
    case CABLE_TV_PROVIDER.GET_SUCCESS:
      return { ...state, loading: false, cable_tv_provider_data: action.payload };
    case CABLE_TV_PROVIDER.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VERIFY_SMART_CARD
    case VERIFY_SMART_CARD.POST_REQUEST:
      return { ...state, loading: true };
    case VERIFY_SMART_CARD.POST_SUCCESS:
      return { ...state, loading: false, verify_smart_card_data: action.payload };
    case VERIFY_SMART_CARD.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CABLE_TV_PACKAGE
    case CABLE_TV_PACKAGE.GET_REQUEST:
      return { ...state, loading: true };
    case CABLE_TV_PACKAGE.GET_SUCCESS:
      return { ...state, loading: false, cable_tv_package_data: action.payload };
    case CABLE_TV_PACKAGE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CABLE_TV_TOP_UP
    case CABLE_TV_TOP_UP.POST_REQUEST:
      return { ...state, loading: true };
    case CABLE_TV_TOP_UP.POST_SUCCESS:
      return { ...state, loading: false, cable_tv_top_up_data: action.payload };
    case CABLE_TV_TOP_UP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PRODUCT_ITEMS
    case PRODUCT_ITEMS.GET_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_ITEMS.GET_SUCCESS:
      return { ...state, loading: false, product_itmes_get_data: action.payload };
    case PRODUCT_ITEMS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region EDUCATION_TOP_UP
    case EDUCATION_TOP_UP.POST_REQUEST:
      return { ...state, loading: true };
    case EDUCATION_TOP_UP.POST_SUCCESS:
      return { ...state, loading: false, education_top_up_data: action.payload };
    case EDUCATION_TOP_UP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region IROKO_PROVIDER
    case IROKO_PROVIDER.GET_REQUEST:
      return { ...state, loading: true };
    case IROKO_PROVIDER.GET_SUCCESS:
      return { ...state, loading: false, iroko_provider_get_data: action.payload };
    case IROKO_PROVIDER.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region TOP_UP_IROKO
    case TOP_UP_IROKO.POST_REQUEST:
      return { ...state, loading: true };
    case TOP_UP_IROKO.POST_SUCCESS:
      return { ...state, loading: false, top_up_iroko_data: action.payload };
    case TOP_UP_IROKO.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PAN_AFRICAN_AIRTIME_PRE
    case PAN_AFRICAN_AIRTIME_PRE.GET_REQUEST:
      return { ...state, loading: true };
    case PAN_AFRICAN_AIRTIME_PRE.GET_SUCCESS:
      return { ...state, loading: false, pan_african_airtime_pre_data: action.payload };
    case PAN_AFRICAN_AIRTIME_PRE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PAN_AFRICAN_AIRTIME_TOP_UP
    case PAN_AFRICAN_AIRTIME_TOP_UP.POST_REQUEST:
      return { ...state, loading: true };
    case PAN_AFRICAN_AIRTIME_TOP_UP.POST_SUCCESS:
      return { ...state, loading: false, pan_african_airtime_top_up_data: action.payload };
    case PAN_AFRICAN_AIRTIME_TOP_UP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ACTIVE_PRODUCT
    case ACTIVE_PRODUCT.GET_REQUEST:
      return { ...state, loading: true };
    case ACTIVE_PRODUCT.GET_SUCCESS:
      return { ...state, loading: false, active_product: action.payload };
    case ACTIVE_PRODUCT.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CHECK_METER
    case CHECK_METER.POST_REQUEST:
      return { ...state, loading: true };
    case CHECK_METER.POST_SUCCESS:
      return { ...state, loading: false, check_meter_data: action.payload };
    case CHECK_METER.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PURCHASE_POWER
    case PURCHASE_POWER.POST_REQUEST:
      return { ...state, loading: true };
    case PURCHASE_POWER.POST_SUCCESS:
      return { ...state, loading: false, purchase_power_data: action.payload };
    case PURCHASE_POWER.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region LOADIND_IFRAME
    case LOADIND_IFRAME.START_REQUEST:
      return { ...state, loading: true };
    case LOADIND_IFRAME.STOP_REQUEST:
      return { ...state, loading: false };
    //#endregion
    //#region CLEAR_MESSAGES
    case CLEAR_MESSAGES.REQUEST:
      return { ...state, loading: false, error: null };
    //#endregion
    default:
      return state;
  }
};
