import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import axios from 'axios';
// import httpFacade from "utils/httpFacade";

function EmailVerification(props) {

  let { token } = props.match.params;
  let payload = {
    url: 'https://app.reachafrika.com.ng/',
    token: token
  }

  const [status, setStatus] = useState({
    error: false,
    loading: true
  })

  useEffect(() => {

    verify();
  
  }, [])



  const updateStatus = (status) => {
    setStatus({
      error: status.error,
      msg: status.msg,
      loading: false
    })
  }

  const redirect = () => {
    console.log(props.history)
    props.history.push('/home')
  }

  const verify = async () => {
    try {
      let res = await axios.post('https://api.reachafrika.com/api/v1/email-verification', payload);

      if (res.error) {
        updateStatus({error: true, msg: res.response.data.error})
      }
      else {
        updateStatus({error: false, msg: res.response.data.success})
      }
    } catch (error) {
      console.log(error.response.data)
      updateStatus({error: true, msg: error.response.data.error})
    }
  }


    return (
      <div className="fixed left-0 top-0  h-screen w-screen flex">
        {
          status.loading ? (
            <div className={`p-2 bg-gray-400 m-auto rounded-md overflow-hidden`}>
              <div className="w-4/5 flex">
                <svg className="animate-spin align-baseline text-5xl -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                <span className="text-white">Loading, Please wait...</span>
                
              </div>
            </div>
          ) : (

            <div className={`w-full max-w-sm h-40 ${ status.error ? 'bg-red-400' : 'bg-green-400'} m-auto rounded-md overflow-hidden relative`}>
              <div className={`w-full p-2 ${ status.error ? 'bg-red-500' : 'bg-green-500'} border-b border-opacity-10 border-black text-white font-semibold`}>
                { status.error ? 'Action Failed' : 'Action Successful'}
              </div>
      
              <div className="text-white p-3 text-center"> {`${ status.error ? ('An error occured with a message: ' + status.msg) : 'Email verified successfullly' }   `} </div>

              <Button 
                title="OK"
                position="absolute"
                className = {`${ status.error ? 'bg-red-600 bottom-2 right-2' : 'bg-green-600 bottom-2 right-2'}`}
                width="w-32"
                textColor = 'text-white'
                click={ () => redirect() }
              />
            </div>
          )
        }
  
      </div>
    )
  // }
}

export default EmailVerification;
