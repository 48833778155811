import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/SettingWrapper';

import Accordion from 'components/Settings/Accordion';

import data from './data/faqs.json';

const FAQ = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'faqs.page.title'
  })}`;

  const { lang } = useSelector((state) => state.authReducers);

  const faqs = data?.[lang];

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden" {...props}>
      <main className="flex-1 relative z-0 md:mt-8 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="p-4 xl:p-0 xl:mt-10 xl:ml-20 xl:mr-80">
          <Accordion data={faqs} />
        </div>
      </main>
    </Wrapper>
  );
};

export default FAQ;
