export const images = {
    logo: require('assets/landing/logo.png'),
    landingImg1: require('assets/newlanding/landingImg1.svg').default,
    googleplay: require('assets/newlanding/googleplay.png'),
    appstore: require('assets/newlanding/appstore.png'),
    landingimg2: require('assets/newlanding/landingimg2.svg').default,
    eraser: require('assets/newlanding/money_front.png'),
    landingimg3: require('assets/newlanding/landingimg3.svg').default,
    landingimg4: require('assets/newlanding/landingimg4.svg').default,
    landingimg5: require('assets/newlanding/landingimg5.svg').default,
    lifemadesimple: require('assets/newlanding/lifemadesimple.svg').default,
    youtubevid: require('assets/newlanding/youtubevid.svg').default,
    earmmorebyspending: require('assets/newlanding/earmmorebyspending.svg').default,
    opeyemi: require('assets/newlanding/opeyemi.svg').default,
    sandra: require('assets/newlanding/sandra.svg').default,
    john: require('assets/newlanding/john.svg').default,
    stephen: require('assets/newlanding/stephen.svg').default,
    stephenquote: require('assets/newlanding/stephenquote.svg').default,
    opeyemiquote: require('assets/newlanding/opeyemiquote.svg').default,
    sandraquote: require('assets/newlanding/sandraquote.svg').default,
    johnquote: require('assets/newlanding/johnquote.svg').default,
    earnmorebyspendingbg: require('assets/newlanding/earnmorebyspendingbg.svg').default,
    footerbg: require('assets/newlanding/footerbg.svg').default,
    businessheader: require('assets/newlanding/businessheader.svg').default,
    rocket: require('assets/newlanding/rocket.svg').default,
    sales: require('assets/newlanding/sales.svg').default,
    retain: require('assets/newlanding/retain.svg').default,
    target: require('assets/newlanding/target.svg').default,
    hotels: require('assets/newlanding/hotels.svg').default,
    pizzahut: require('assets/newlanding/pizzahut.svg').default,
    kfc: require('assets/newlanding/kfc.svg').default,
    shoprite: require('assets/newlanding/shoprite.svg').default,
    melcom: require('assets/newlanding/melcom.svg').default,
    melcom: require('assets/newlanding/melcom.svg').default,
    chaturbate: require('assets/newlanding/chaturbate.svg').default,
    lifemadeeasyicon1: require('assets/newlanding/lifemadeeasyicon1.svg').default,
    lifemadeeasyicon2: require('assets/newlanding/lifemadeeasyicon2.svg').default,
    lifemadeeasyicon3: require('assets/newlanding/lifemadeeasyicon3.svg').default,
    lifemadeeasyicon4: require('assets/newlanding/lifemadeeasyicon4.svg').default,
    lifemadeeasyicon5: require('assets/newlanding/lifemadeeasyicon5.svg').default,
    lifemadeeasyicon6: require('assets/newlanding/lifemadeeasyicon6.svg').default,
    rewardbag: require('assets/newlanding/rewardbag.svg').default,
    rocketben: require('assets/newlanding/rocketben.svg').default,
    giveicon: require('assets/newlanding/giveicon.svg').default,

}
