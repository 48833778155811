import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay } from 'components/Loading';
import Button from 'components/Button';
import AirtimeOptionModal from 'components/Shop/AirtimeOptionModal';

import { shop_products_request } from 'redux/actions';
import { getImageFile } from 'constants/helper';

const ShopMenu = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'shop.products.page.title'
  })}`;

  const { loading, error, shop_products } = useSelector((state) => state.shopReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(shop_products_request());

      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [dispatch, error, intl]);

  const Card = ({ image, alias, name, groups, convenience_fee }) => {
    if (groups.length) {
      return (
        <>
          <AirtimeOptionModal
            intl={intl}
            handleClose={setModalOpen}
            isOpen={isModalOpen}
            local={groups.find((i) => i.name === 'local_airtime')}
            international={groups.find((i) => i.name === 'international_airtime')}
          />
          <div className="w-full bg-white overflow-hidden shadow-sm rounded-lg font-normal">
            <div className="flex flex-col items-center p-5">
              <img src={image} alt={alias} className="my-4 w-20" />
              <h4 className="mb-5 text-xl leading-3">{alias}</h4>
              <Button
                title={intl.formatMessage({
                  id: 'button.title.purchase'
                })}
                className="border-gray-300"
                textColor="text-ra-dark"
                width="w-full"
                click={(_) => setModalOpen(true)}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <NavLink
        to={{
          pathname: `shop/${name}`,
          state: { convenience_fee }
        }}
        className="w-full bg-white overflow-hidden shadow-sm rounded-lg font-normal"
      >
        <div className="flex flex-col items-center p-5">
          <img src={image} alt={alias} className="my-4 w-20" />
          <h4 className="mb-5 text-xl leading-3">{alias}</h4>
          <Button
            title={intl.formatMessage({
              id: 'button.title.purchase'
            })}
            className="border-gray-300"
            textColor="text-ra-dark"
            width="w-full"
          />
        </div>
      </NavLink>
    );
  };

  return (
    <>
      {loading ? <UnderLay /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="shop.products.nav.title" />
          <div className="pt-2 pb-6 mt-4 mx-4 md:mx-10 md:py-10 xl:mx-24">
            <div className="grid grid-cols-2 grid-flow-rows gap-4 xl:grid-cols-4">
              {shop_products.map((product) => {
                return <Card key={product.id} image={getImageFile(product.name)} {...product} />;
              })}
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ShopMenu;
