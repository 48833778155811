import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Transition from 'components/TransitionGroup/Transition.js';

// IMAGES IMPORTS
import Logo from 'assets/img/logo.png';

// COMPONENT IMPORTS
import NavMenu from 'components/NavMenu/setting';

const Sidebar = ({ intl, currentPath, isMoblieMenuOpen, location }) => {
  const [hide, setHide] = useState('none');

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setHide('');
      if (!isMoblieMenuOpen) {
        setTimeout(() => setHide('none'), 200);
      }
    }
    return () => (mounted = false);
  }, [isMoblieMenuOpen]);

  return (
    <>
      {/* <!-- Off-canvas menu for mobile --> */}
      <div className="md:hidden" style={{ display: hide }}>
        <div className="fixed w-full z-40 mt-10 inset-0">
          <Transition
            show={isMoblieMenuOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >
            <div className="relative flex-1 flex flex-col w-full bg-white">
              <div className="flex-1 min-h-screen pt-5 pb-4 overflow-y-auto">
                <nav className="mt-5 px-2">
                  <NavMenu intl={intl} currentPath={currentPath} location={location} />
                </nav>
              </div>
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14"></div>
        </div>
      </div>
      {/* <!-- Static sidebar for desktop --> */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-full bg-white">
          <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <nav className="flex-1 mt-10 pl-10 xl:pl-60 pr-10">
              <NavMenu intl={intl} currentPath={currentPath} location={location} />
            </nav>
            <div className="flex justify-end my-10">
              <img className="h-6" src={Logo} alt="REACHAFRIKA" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  intl: PropTypes.any.isRequired,
  currentPath: PropTypes.string.isRequired,
  isMoblieMenuOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

const Wrapper = ({ intl, children, location, className = 'min-h-screen bg-white' }) => {
  const [isMoblieMenuOpen, toggleMenuMobile] = useState(false);

  const currentPath = location.pathname.split('/')[1];

  return (
    <div className={className}>
      <Sidebar
        intl={intl}
        currentPath={currentPath}
        isMoblieMenuOpen={isMoblieMenuOpen}
        location={location}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="flex justify-end md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 z-50">
          {isMoblieMenuOpen ? (
            <button
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none transition ease-in-out duration-150"
              aria-label="Close sidebar"
              onClick={() => toggleMenuMobile(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3256 12.001L23.7252 1.60144C24.0913 1.2353 24.0913 0.641675 23.7252 0.275581C23.359 -0.0905116 22.7654 -0.0905584 22.3993 0.275581L11.9997 10.6752L1.60022 0.275581C1.23408 -0.0905584 0.640454 -0.0905584 0.274361 0.275581C-0.0917323 0.641721 -0.0917791 1.23535 0.274361 1.60144L10.6739 12.001L0.274361 22.4005C-0.0917791 22.7667 -0.0917791 23.3603 0.274361 23.7264C0.457407 23.9094 0.69736 24.0009 0.937313 24.0009C1.17727 24.0009 1.41717 23.9094 1.60026 23.7264L11.9997 13.3269L22.3993 23.7264C22.5823 23.9094 22.8223 24.0009 23.0622 24.0009C23.3022 24.0009 23.5421 23.9094 23.7252 23.7264C24.0913 23.3603 24.0913 22.7666 23.7252 22.4005L13.3256 12.001Z"
                  fill="black"
                />
              </svg>
            </button>
          ) : (
            <button
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none transition ease-in-out duration-150"
              aria-label="Open sidebar"
              onClick={() => toggleMenuMobile(true)}
            >
              <svg
                width="24"
                height="12"
                viewBox="0 0 24 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.749981 1.49996H23.25C23.664 1.49996 23.9999 1.16394 23.9999 0.749981C23.9999 0.335964 23.6639 0 23.25 0H0.749981C0.335964 0 0 0.336027 0 0.749981C0 1.16394 0.336026 1.49996 0.749981 1.49996Z"
                  fill="black"
                />
                <path
                  d="M23.25 5.25H0.749981C0.335964 5.25 0 5.58602 0 5.99997C0 6.41392 0.336026 6.74995 0.749981 6.74995H23.25C23.664 6.74995 23.9999 6.41392 23.9999 5.99997C23.9999 5.58602 23.664 5.25 23.25 5.25Z"
                  fill="black"
                />
                <path
                  d="M23.2502 10.5H8.25022C7.83621 10.5 7.50024 10.836 7.50024 11.25C7.50024 11.664 7.83627 12 8.25022 12H23.2502C23.6642 12 24.0002 11.6639 24.0002 11.25C24.0003 10.836 23.6642 10.5 23.2502 10.5Z"
                  fill="black"
                />
              </svg>
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  user: PropTypes.object,
  location: PropTypes.object.isRequired
};

export default Wrapper;
