import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  icon,
  title,
  color,
  width,
  className = '',
  textColor = 'text-white',
  disabled,
  position,
  click,
  gap = 'gap-2',
  ...props
}) => {
  const Icon = () => icon
  return (
    <button
      type="button"
      disabled={disabled}
      className={`${position ? position : 'relative'} ${width ? width : 'w-full'} items-baseline inline-flex justify-center border-transparent px-5 py-3 border text-xs md:text-sm leading-5 font-medium rounded-md ${className} ${disabled ? 'cursor-not-allowed text-gray-300' : textColor + ' ' + color
        }  focus:outline-none`}
      onClick={click}
      {...props}
    >
      {icon} {title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  paddingClass: PropTypes.string,
  click: PropTypes.func
};

export default Button;
