import React, { useState } from 'react'
import Footer from './components/footer'
import EarnMoreBySpending from './components/personal/EarnMoreBySpending'
import EarnMoreCash from './components/personal/EarnMoreCash'
import EnjoyBenefits from './components/personal/EnjoyBenefits'
import EnjoyRewards from './components/personal/EnjoyReward'
import GiveLove from './components/personal/GiveLove'
import HappyCustomers from './components/personal/HappyCustomers'
import LandingPageHeader from './components/personal/Header'
import HowItWorks from './components/personal/HowItWorks'
import LifeMadeSimple from './components/personal/LifeMadeSimple'
import { Box } from "@mui/material";
import { FlexBox } from './styles/containers'
import { Img } from './styles/image'
import { images } from 'exports'
import { Text16, Text20 } from './styles/text'
import { RxHamburgerMenu } from 'react-icons/rx'
import { FiGlobe, FiChevronDown } from 'react-icons/fi'
import { useParams, Link } from 'react-router-dom'
import { useEffect } from 'react'
import BusinessHeader from './components/business/BusinessHeader'
import MakeYourClients from './components/business/MakeYourClients'
import LifeMadeEasy from './components/business/LifeMadeEasy'
import Parteners from './components/business/Parteners'
import { GH, NG } from 'country-flag-icons/react/3x2'
import { useDispatch, useSelector } from 'react-redux'
import { change_country } from 'redux/actions'
import Modal from 'components/Modal'
import Button from 'components/Button'
// import { Link } from 'react-router-dom'
// import { US } from 'country-flag-icons/react/3x2'

function LandingPage() {
    const [page, setPage] = useState('personal')
    const [selectedCountry, setSelectedCountry] = useState('nigeria')
    const [modalState, setModalState] = useState(false)
    const { country } = useSelector(state => state.change_country)
    const showModal = () => {
        setModalState(!modalState)
    }

    // const navigate =  useNavigation()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(change_country(selectedCountry))
    }, [selectedCountry])
    const path = useParams()
    useEffect(() => {
        if (path.page) setPage(path.page)
    }, [path])
    const change = ({ target: { value } }) => {
        // console.log(value)
        setSelectedCountry(value)
        showModal()
    }
    const Personal = () => {
        return (
            <>
                <LandingPageHeader />
                <EarnMoreCash />
                <EnjoyRewards />
                <GiveLove />
                <EnjoyBenefits />
                <LifeMadeSimple />
                <HowItWorks />
                <EarnMoreBySpending />
                <HappyCustomers />
            </>
        )
    }
    const Business = () => {

        return (
            <>
                <BusinessHeader />
                <MakeYourClients />
                <LifeMadeEasy />
                <Parteners />
                <HowItWorks />
            </>
        )
    }

    return (<>

        <Modal isModalOpen={modalState} closeOutside close toggleModal={showModal}>
            <FlexBox h='50vh' sx={{ bgcolor: 'white', borderRadius: '8px' }} w="50vw" justify='center'>
                <FlexBox h='30px' w='100px' gap='10px' sx={{ cursor: 'pointer' }}><NG title="Nigeria" className='...'/>
                    <Button textColor='black' title={'Nigeria'} icon={<></>} click={change} value='nigeria' />
                    <Button textColor='black' title={'Ghana'} icon={() => <GH title="Ghana" />} click={change} value='ghana' />
                </FlexBox>
            </FlexBox>
        </Modal>
        <FlexBox h='70px' sx={{ position: 'absolute', top: 0, px: { xs: '20px', lg: '96px' } }} direction='row'>
            <FlexBox w='60%' justify='flex-start' gap={{ xs: '20px', lg: '50px' }} direction='row'>
                <Img src={images.logo} h='30px' mW={'215px'} />
                <FlexBox   w='250px' justify='center' direction='row' gap={"12px"}>
                    <Box sx={{ borderBottom: page === 'personal' ? '2px solid white' : 'none', cursor: 'pointer' }} ><Text20 color='white'><Link to={'/home/personal'}  >Personal</Link> </Text20></Box>
                    <Box sx={{ borderBottom: page === 'business' ? '2px solid white' : 'none', cursor: 'pointer' }} ><Text20  color='white'><Link to={'/home/business'} >Business</Link></Text20></Box>
                </FlexBox>
            </FlexBox>
            <FlexBox w='40%' justify='flex-end' gap='30px' direction='row'>
                <FlexBox w='auto' sx={{ border: '2px solid white', borderRadius: '12px', px: '10px', py: '10px' }}
                    onClick={showModal}
                    h={{ xs: '50%', lg: '70%' }} direction='row' gap='20px'>
                    {/* <FiGlobe style={{ display: 'block' }} fontSize={'20px'} color='white' />  */}
                    {country?.toLowerCase() === 'nigeria' && <NG title="Nigeria" className="..." />}
                    {country?.toLowerCase() === 'ghana' && <GH title="Ghana" className="..." />}
                    <Text16 w='fit-content' component='div' color='white'>En </Text16> <FiChevronDown fontSize={'25px'} color='white' />
                </FlexBox>
                <RxHamburgerMenu size={'30'} color='white' />
            </FlexBox>
        </FlexBox>

        {page === 'business' ? <Business /> : <Personal />}

        <Footer />
    </>
    )
}

export default LandingPage