import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import PhoneInput from 'components/Form/Phone';
import Button from 'components/Button';

const Three = ({
  intl,
  values,
  touched,
  errors,
  dispatch,
  sentOtpRequest,
  isPhoneExist,
  loading,
  validatePhoneRequest,
  countries,
  profiles,
  setStep,
  setFieldValue,
  setFieldTouched
}) => {
  return (
    <>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full mx-6 py-2">
          {countries.length ? (
            <PhoneInput
              name="phone"
              placeholder={intl.formatMessage({
                id: 'signup.form.placeholder.phone'
              })}
              countries={countries}
              profiles={profiles}
              value={values.phone}
              error={
                (errors.phone &&
                  intl.formatMessage({
                    id: errors.phone
                  })) ||
                (isPhoneExist
                  ? intl.formatMessage({
                      id: 'signup.form.error.phone_3'
                    })
                  : '')
              }
              touched={touched.phone}
              dispatch={dispatch}
              validatePhoneRequest={validatePhoneRequest}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          ) : (
            <div style={{ position: 'inherit' }} className="loading-text">
              {intl.formatMessage({
                id: 'general.loading'
              })}
            </div>
          )}
        </div>
      </div>
      <div className="flex-wrap justify-center sm:flex sm:justify-between mt-8 mx-2">
        <div>
          <Button
            title={intl.formatMessage({
              id: 'button.title.back'
            })}
            textColor="text-ra-dark"
            className="border-ra-dark"
            click={() => setStep(2)}
          />
        </div>
        <div className="mt-4 sm:mt-0">
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            color="bg-ra-dark"
            disabled={!isEmpty(errors) || isPhoneExist || loading}
            click={() => {
              dispatch(sentOtpRequest({ phone: values.phone }));
              setStep(4);
            }}
          />
        </div>
      </div>
    </>
  );
};

Three.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isPhoneExist: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  sentOtpRequest: PropTypes.func.isRequired,
  validatePhoneRequest: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default Three;
