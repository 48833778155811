import * as Yup from 'yup';

export default Yup.object().shape({
  smart_no: Yup.string().required('shop.cable-tv.form.error.smart_no'),
  provider: Yup.string().required('shop.cable-tv.form.ph.provider'),
  package: Yup.string().required('shop.cable-tv.form.ph.package'),
  phone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, {
      message: 'shop.airtime.form.error.phone'
    })
    .required('shop.airtime.form.error.phone')
});
