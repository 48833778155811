import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ProfilesModal from '../ProfilesModal';

import { ChevronIcon } from 'assets/icons/svg';

import { user_profiles_get_request } from 'redux/actions';

const PageTitle = ({ intl, id, title }) => {
  const [isOpen1, setOpen1] = useState(false);

  const { user_profiles } = useSelector((state) => state.authReducers);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted && !user_profiles?.length) {
      dispatch(user_profiles_get_request());
    }
    return () => (mounted = false);
  }, [dispatch, user_profiles.length]);

  const activeProfile = user_profiles?.find((p) => p.active)?.profile;

  return (
    <>
      <nav className="bg-gray-100 border-b mx-4 h-16 xl:mx-24 md:h-24">
        <div className="flex items-center justify-between h-full">
          <h3 className="text-2xl md:text-4xl font-bold">
            {title ||
              intl.formatMessage({
                id
              })}
          </h3>
          <div>
            {activeProfile?.avatar && (
              <button
                type="button"
                className="flex md:flex-col lg:flex-row flex-shrink-0 ml-12 pt-1 px-4 w-full focus:outline-none"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() => setOpen1(!isOpen1)}
              >
                <div className="flex items-center">
                  <div className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900 mr-2">
                    {activeProfile?.description}
                  </div>
                  <img
                    className="w-6"
                    src={activeProfile?.avatar}
                    alt={activeProfile?.description || 'Profile'}
                  />
                  <div
                    className={`mt-1 ml-2 transform duration-300 ${
                      isOpen1 ? '-rotate-180' : '-rotate-270'
                    }`}
                  >
                    <ChevronIcon />
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>
      </nav>
      <ProfilesModal
        user_profiles={user_profiles}
        intl={intl}
        handleClose={() => setOpen1(!isOpen1)}
        isOpen={isOpen1}
      />
    </>
  );
};

PageTitle.propTypes = {
  intl: PropTypes.any.isRequired,
  id: PropTypes.any,
  title: PropTypes.any
};

export default PageTitle;
