
import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import MobileNavigation from '../Navigation/MobileNavigation/MobileNavigation';
import NavigationItem from '../Navigation/NavigationItem';
import NavigationItems from '../Navigation/NavigationItems';
import Dropdown from 'components/Dropdown';

import Logo from 'assets/img/logo.png';
import { CoinsIcon } from 'assets/icons/svg';

import {
  // language_change_request,
  normal_account_request,
  reward_account_request
} from 'redux/actions';

// CONTEXT IMPORTS
import { AuthContext } from 'context/authContext';
// import { local } from 'validations/shop/airtime';
import { LangContext } from 'context/AddLangContext';

const Header = ({ user, intl }) => {
  const [mobileToggleState, setMobileToggleState] = useState(false);

  const { logout } = useContext(AuthContext);


  const auth = !isEmpty(user);
  const dispatch = useDispatch();

  const { normal_account_data, reward_account_data } = useSelector((state) => state.walletReducers);
  // const { lang } = useSelector((state) => state.authReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted && auth) {
      isEmpty(normal_account_data) && dispatch(normal_account_request());
      isEmpty(reward_account_data) && dispatch(reward_account_request());
    }
    return () => (mounted = false);
  }, [dispatch, auth, normal_account_data, reward_account_data]);

  const topNavigationItems = [
    {
      name: intl.formatMessage({
        id: 'home.header.link.text_1'
      }),
      url: "https://play.google.com/store/apps/details?id=com.reachafrika"
    },
    {
      name: intl.formatMessage({
        id: 'home.header.link.text_2'
      }),
      url: "https://play.google.com/store/apps/details?id=com.reachafrika"
    },
    {
      name: intl.formatMessage({
        id: 'home.header.link.text_3'
      }),
      url: "https://play.google.com/store/apps/details?id=com.reachafrika"
    },
    {
      name: intl.formatMessage({
        id: 'home.header.link.text_4'
      }),
      url: "https://play.google.com/store/apps/details?id=com.reachafrika"
    }
  ];

  const mobileToggleStateHandler = () => {
    setMobileToggleState(!mobileToggleState);
  };

  return (
    <>
      <div className={`bg-white md:py-4 ${auth ? 'border-b' : ''}`}>
        <div className="max-w-screen-xl mx-auto md:px-10">
          <nav className="flex items-center justify-between">
            <div className="flex justify-between w-full lg:w-1/2 p-5 lg:p-0">
              <Link to="/#" aria-label="Home" className="flex items-center">
                <img className="h-7 md:h-8 w-auto" src={Logo} alt="Logo" />
              </Link>
              <div className="-mr-2 flex items-center lg:hidden">
                {/* <span className="bg-gray-500 h-10 w-20"></span> */}
                <Country />
                <button
                  type="button"
                  onClick={() => mobileToggleStateHandler()}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  id="main-menu"
                  aria-label="Main menu"
                  aria-haspopup="true"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {!mobileToggleState ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    )}
                  </svg>
                </button>
              </div>
            </div>

            <div className="hidden lg:flex w-1/2 justify-end">
              {!auth ? (
                <>
                  <Country />

                  <NavigationItem
                    title={intl.formatMessage({
                      id: 'login.text'
                    })}
                    openNew={ true }
                    url="https://play.google.com/store/apps/details?id=com.reachafrika"
                    classItems="px-10 py-2 mr-2 border border-gray-300 text-sm leading-6 rounded-md bg-white hover:text-white hover:bg-ra-dark focus:outline-none transition duration-150 ease-in-out"
                  />
                  

                  <NavigationItem
                    title={intl.formatMessage({
                      id: 'signup.text'
                    })}
                    openNew={ true }
                    url="https://play.google.com/store/apps/details?id=com.reachafrika"
                    classItems="px-10 py-2 border border-gray-300 text-sm text-white leading-6 rounded-md text-white bg-ra-dark focus:outline-none transition duration-150 ease-in-out"
                  />
                </>
              ) : (
                <>
                  <div className="flex flex-col justify-center xl:mr-8 lg:mr-6">
                    <h5 className="text-sm">
                      {intl.formatMessage({
                        id: 'home.header.account-bal'
                      })}
                    </h5>
                    <p className="font-bold text-base">{normal_account_data?.total || '₦️ 0.00'}</p>
                  </div>
                  <div className="flex flex-col justify-center xl:mr-12">
                    <h5 className="text-sm">
                      {intl.formatMessage({
                        id: 'home.header.coins-bal'
                      })}
                    </h5>
                    <div className="flex items-center">
                      <span className="mr-1">
                        <CoinsIcon />
                      </span>
                      <p className="font-bold text-base">{reward_account_data?.coins || 0}</p>
                    </div>
                  </div>
                  <Dropdown intl={intl} user={user} logout={logout} />
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
      {mobileToggleState ? (
        <MobileNavigation
          intl={intl}
          items={topNavigationItems}
          mobileToggleState={mobileToggleState}
        />
      ) : null}

      <div className={`BlueBar bg-ra-dark mt-1 lg:mb-16 ${auth ? 'hidden' : 'hidden md:block'}`}>
        <nav className="relative flex items-center justify-between p-4 md:justify-center">
          <NavigationItems
            items={topNavigationItems}
            classItems="ml-10 hover:text-gray-300 text-white transition duration-150 ease-in-out"
          />
        </nav>
      </div>
    </>
  );
};

export default Header;




const Country = () => {
  
  const [modal, setModal] = useState(false)

  const con = useContext(LangContext);

  return (
    <>
      <div className="p-3 mr-4 text-sm bg-gray-50 rounded-md relative flex cursor-pointer" onClick={ () => setModal(!modal) }>
        <img src={ con[con.country].img } alt="Country flag" style={{ borderRadius: '3px', width: '30px' }}/> <i className="icofont-rounded-down pl-3 pt-1 align-middle"/>
      </div>

      {
        modal && (

          <div className="fixed left-0 w-screen flex h-screen top-0 z-50" onClick={ (e) => {
            var container = document.querySelector(".countryModal");
        
            if (e.target !== container && !container.contains(e.target)) {
              setModal(!modal) 
            } 
          }}>
            <div className="left-0 z-1 w-full h-full top-0 bg-black opacity-60 absolute"></div>

            <div className="countryModal p-5 bg-white shadow-lg mx-auto h-96 relative top-36 z-30 rounded w-xs-width md:w-md-width lg:w-lg-width lg:px-20">
              <h4 className="p-3 lg:pb-10 text-base font-semibold text-center">Choose your country</h4>
              {/* <h4 className="p-3 text-base font-semibold text-center">Choose your country & language</h4>

              <form className="flex w-4/5 mx-auto my-10">

                <Button 
                  title="English"
                  textColor="text-ra-dark"
                  className="mr-3 border-ra-dark rounded-full focus:bg-ra-dark focus:text-white" 
                  click={ () => alert('Hi') }
                ></Button>

                <Button 
                  title="French"
                  textColor="text-ra-dark"
                  className="border-ra-dark rounded-full focus:bg-ra-dark focus:text-white" 
                  click={ () => alert('Hi') }
                ></Button>
              </form> */}
              
              <ul className="w-full flex justify-auto">
                <li className=" cursor-pointer relative z-10 m-2 focus:border-blue">
                  <button className="rounded-full flex p-1 px-3 focus:outline-none focus:bg-gray-200">
                    <img src="/flags/gh.png" alt="Country flag" className="h-8 align-middle pr-3 w-14" style={{ borderRadius: '3px', width: '45px', height: '25px' }} /> <span className="block absolute left-0 top-0 h-full w-full" data-country="Ghana" onClick={ (e) => con.selected(e) }></span> Ghana
                  </button>
                </li>
                <li className=" cursor-pointer relative z-10 m-2 focus:border-blue ">
                  <button className="rounded-full flex p-1 px-3 focus:outline-none focus:bg-gray-200">
                    <img src="/flags/ng.png" alt="Country flag" className="h-8 align-middle pr-3 w-14" style={{ borderRadius: '3px', width: '45px', height: '25px' }} /> <span className="block absolute left-0 top-0 h-full w-full" data-country="Nigeria" onClick={ (e) => con.selected(e) }></span> Nigeria
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )
      }
    </>
  );
};
