import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import { MdVisibility, MdEdit, MdDelete } from 'react-icons/md';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import Modal, { ModalHeader, ModalBody } from 'components/Modal';
import Table from 'components/Table';
import Button from 'components/Button';
import Input from 'components/Form/Input';
import PhoneInput from 'components/Form/Phone';
import Upload from 'components/Form/Upload';
import TextArea from 'components/Form/TextArea';
import FormButton from 'components/Form/Button';

import { UnderLay as Loading } from 'components/Loading';

import {
  auth_prerequisites_request,
  contacts_groups_get_request,
  contacts_groups_post_request,
  contacts_groups_patch_request,
  contacts_groups_delete_request,
  contacts_groups_get_contact_request,
  contacts_groups_post_contact_request,
  contacts_groups_patch_contact_request,
  contacts_groups_delete_contact_request
} from 'redux/actions';

import { ContactGroupValidations, ContactValidations } from 'validations/advertise/contacts';

const AdvertiseContacts = (props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [isModalOpen2, toggleModal2] = useState(false);
  const [isModalOpen3, toggleModal3] = useState(false);
  const [check, setCheck] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEdit2, setIsEdit2] = useState(false);
  const [selectedData, setSelectedData] = useState({ name: '', description: '', upload: '' });
  const [selectedData2, setSelectedData2] = useState({ name: '', email: '', phone: '' });

  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.contacts.page.title'
  })}`;

  const { auth_prerequisites } = useSelector((state) => state.authReducers);

  const { loading, contacts_groups, contacts_list_detials, contacts_list } = useSelector(
    (state) => state.advertiseReducers
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(contacts_groups_get_request());
      if (!auth_prerequisites?.profiles) {
        dispatch(auth_prerequisites_request());
      }
    }
    return () => (mounted = false);
  }, [dispatch, auth_prerequisites]);

  useEffect(() => {
    let mounted = true;
    if (mounted && check && !isEmpty(contacts_list_detials)) {
      toggleModal2(true);
    }
    return () => (mounted = false);
  }, [contacts_list_detials, check]);

  useEffect(() => {
    if (!isModalOpen) {
      setIsEdit(false);
      setSelectedData({ name: '', description: '', upload: '' });
    }
    if (!isModalOpen3) {
      setIsEdit2(false);
      setSelectedData2({ name: '', email: '', phone: '' });
    }
  }, [isModalOpen, isModalOpen3]);

  const colA = [
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_1'
      }),
      accessor: 'name'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_2'
      }),
      accessor: 'description'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_3'
      }),
      accessor: 'contacts_count'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_4'
      }),
      Cell: ({ row }) => (
        <div className="flex justify-between w-1/3">
          <span
            title="Edit"
            className="cursor-pointer text-green-400"
            onClick={() => {
              setIsEdit(true);
              setSelectedData(row.original);
              toggleModal(true);
            }}
          >
            <MdEdit />
          </span>
          <span
            title="View"
            className="cursor-pointer text-ra-dark"
            onClick={() => {
              dispatch(contacts_groups_get_contact_request(row.original.key));
              setCheck(true);
            }}
          >
            <MdVisibility />
          </span>
          <span
            title="Delete"
            className="cursor-pointer text-red-500"
            onClick={() => dispatch(contacts_groups_delete_request(row.original.id))}
          >
            <MdDelete />
          </span>
        </div>
      )
    }
  ];

  const colB = [
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_1'
      }),
      accessor: 'name'
    },
    {
      Header: 'EMAIL',
      accessor: 'email'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_5'
      }),
      accessor: 'phone'
    },
    {
      Header: intl.formatMessage({
        id: 'advertise.contacts.table.header_4'
      }),
      Cell: ({ row }) => (
        <div className="flex justify-between w-1/3">
          <span
            title="Edit"
            className="cursor-pointer text-green-400"
            onClick={() => {
              setIsEdit2(true);
              setSelectedData2(row.original);
              toggleModal3(true);
            }}
          >
            <MdEdit />
          </span>
          <span
            title="Delete"
            className="cursor-pointer text-red-500"
            onClick={() => dispatch(contacts_groups_delete_contact_request(row.original.id))}
          >
            <MdDelete />
          </span>
        </div>
      )
    }
  ];

  let countries = [];
  if (auth_prerequisites?.profiles) {
    auth_prerequisites.profiles.forEach((profile) => {
      countries.push(profile.name);
    });
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.contacts.nav.title" />
          <div className="pt-2 pb-6 mx-4 md:mx-36 md:py-10 xl:mx-24">
            <Modal
              isModalOpen={isModalOpen2}
              toggleModal={toggleModal2}
              className="absolute top-10 w-3/5 bg-white sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
            >
              <ModalBody>
                <Table
                  intl={intl}
                  title={`${contacts_list_detials.name}'s ${intl.formatMessage({
                    id: 'advertise.contacts.table.title'
                  })}`}
                  columns={colB}
                  data={contacts_list}
                  headerBtn={
                    <Button
                      icon={<span className="text-xl mr-2">+</span>}
                      title={intl.formatMessage({
                        id: 'advertise.contacts.table.add'
                      })}
                      width="w-2/12"
                      color="bg-ra-dark"
                      click={() => toggleModal3(!isModalOpen3)}
                    />
                  }
                />
                <div className="flex justify-end mt-5">
                  <Button
                    title={intl.formatMessage({
                      id: 'button.title.close'
                    })}
                    width="w-2/12"
                    color="bg-orange-400"
                    click={() => {
                      dispatch(contacts_groups_get_request());
                      toggleModal2(!isModalOpen2);
                    }}
                  />
                </div>
              </ModalBody>
            </Modal>
            <Modal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              className="absolute top-10 sm:w-full md:w-1/3 bg-white sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
              close={true}
              closeOutside={true}
            >
              <ModalBody>
                <ModalHeader
                  title={intl.formatMessage({
                    id: 'advertise.contacts.create.modal.title'
                  })}
                  className="mt-1 md:-mt-5 text-left text-xl font-extrabold border-b pb-2"
                />
                <Formik
                  enableReinitialize
                  initialValues={selectedData}
                  validationSchema={ContactGroupValidations}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    const formData = new FormData();
                    formData.append('name', values.name);
                    formData.append('description', values.description);
                    values.upload && formData.append('upload', values.upload);
                    if (isEdit) {
                      if (values.id) {
                        formData.append('id', values.id);
                        dispatch(contacts_groups_patch_request(formData));
                      } else {
                        alert('Unexpected error');
                      }
                    } else {
                      dispatch(contacts_groups_post_request(formData));
                    }
                    resetForm();
                    setSubmitting(false);
                    toggleModal(false);
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    isSubmitting
                  }) => (
                    <form className="block mt-4" onSubmit={handleSubmit}>
                      <div className="flex flex-wrap -mx-2">
                        <div className="w-full mb-4">
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder={intl.formatMessage({
                              id: 'advertise.contacts.create.modal.form.placeholder.name'
                            })}
                            inputValue={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.name &&
                              intl.formatMessage({
                                id: errors.name
                              })
                            }
                            touched={touched.name}
                            useFloatingLable={false}
                          />
                        </div>
                        <div className="w-full">
                          <TextArea
                            id="description"
                            name="description"
                            rows="5"
                            placeholder={intl.formatMessage({
                              id: 'advertise.contacts.create.modal.form.placeholder.description'
                            })}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.description &&
                              intl.formatMessage({
                                id: errors.description
                              })
                            }
                            touched={touched.description}
                            useFloatingLable={false}
                          />
                        </div>
                      </div>
                      <div className="relative mb-4">
                        <div className="relative w-full">
                          <Field
                            component={Upload}
                            placeholder={intl.formatMessage({
                              id: 'advertise.contacts.create.modal.form.placeholder.upload'
                            })}
                            name="upload"
                            inputValue={values.upload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            error={
                              errors.upload &&
                              intl.formatMessage({
                                id: errors.upload
                              })
                            }
                            touched={touched.upload}
                          />
                        </div>
                        <a
                          className="text-sm text-ra-dark"
                          href={`${process.env.PUBLIC_URL}/doc/sample_file.xlsx`}
                          download
                        >
                          Click here to get sample file
                        </a>
                      </div>
                      <div className="flex justify-between my-4 border-t py-8">
                        <div className="w-1/2 mr-2">
                          <Button
                            title={intl.formatMessage({
                              id: 'button.title.cancel'
                            })}
                            textColor="text-ra-dark"
                            className="border-ra-dark"
                            click={() => toggleModal(false)}
                          />
                        </div>
                        <div className="w-1/2 ml-2">
                          <FormButton
                            type="submit"
                            title={intl.formatMessage({
                              id: 'button.title.submit'
                            })}
                            color="bg-ra-dark"
                            disabled={!(dirty && isValid) || isSubmitting}
                            loading={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            <Modal
              isModalOpen={isModalOpen3}
              toggleModal={toggleModal3}
              className="absolute top-10 sm:w-full md:w-1/3 bg-white sm:p-6 rounded-lg px-4 pt-5 pb-4 shadow-xl"
            >
              <ModalHeader
                title={intl.formatMessage({
                  id: 'advertise.contacts.add.modal.title'
                })}
                className="text-left text-xl font-extrabold border-b pb-2"
              />
              <ModalBody>
                <Formik
                  enableReinitialize
                  initialValues={selectedData2}
                  validationSchema={ContactValidations}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (isEdit2) {
                      if (values.id) {
                        const data = pick(values, ['id', 'name', 'phone', 'email']);
                        dispatch(
                          contacts_groups_patch_contact_request({
                            ...data,
                            key: contacts_list_detials.key
                          })
                        );
                      } else {
                        alert('Unexpected error');
                      }
                    } else {
                      dispatch(
                        contacts_groups_post_contact_request({
                          ...values,
                          key: contacts_list_detials.key
                        })
                      );
                    }
                    resetForm();
                    setSubmitting(false);
                    toggleModal3(false);
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting
                  }) => (
                    <form className="block" onSubmit={handleSubmit}>
                      <div className="flex flex-wrap">
                        <div className="w-full mb-4">
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder={intl.formatMessage({
                              id: 'advertise.contacts.add.modal.form.placeholder.name'
                            })}
                            inputValue={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.name &&
                              intl.formatMessage({
                                id: errors.name
                              })
                            }
                            touched={touched.name}
                            useFloatingLable={false}
                          />
                        </div>
                        <div className="w-full mb-4">
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            inputValue={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.email &&
                              intl.formatMessage({
                                id: errors.email
                              })
                            }
                            touched={touched.email}
                            useFloatingLable={false}
                          />
                        </div>
                        <div className="w-full md:w-1/2 mb-4">
                          {countries.length ? (
                            <PhoneInput
                              name="phone"
                              placeholder={intl.formatMessage({
                                id: 'signup.form.placeholder.phone'
                              })}
                              value={values.phone}
                              error={
                                errors.phone &&
                                intl.formatMessage({
                                  id: errors.phone
                                })
                              }
                              touched={touched.phone}
                              countries={countries}
                              profiles={auth_prerequisites.profiles}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              useFloatingLable={false}
                            />
                          ) : (
                            <div style={{ position: 'inherit' }} className="loading-text">
                              loading profile
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between my-4">
                        <div className="w-1/2 mr-2">
                          <Button
                            title={intl.formatMessage({
                              id: 'button.title.cancel'
                            })}
                            textColor="text-ra-dark"
                            className="border-ra-dark"
                            click={() => toggleModal3(false)}
                          />
                        </div>
                        <div className="w-1/2 ml-2">
                          <FormButton
                            type="submit"
                            title={intl.formatMessage({
                              id: 'button.title.submit'
                            })}
                            color="bg-ra-dark"
                            disabled={!(dirty && isValid) || isSubmitting}
                            loading={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            <Table
              title={intl.formatMessage({
                id: 'advertise.contacts.table.title_2'
              })}
              intl={intl}
              columns={colA}
              data={contacts_groups}
              headerBtn={
                <Button
                  icon={<span className="text-xl mr-2">+</span>}
                  title="Contacts"
                  width="w-2/12"
                  color="bg-ra-dark"
                  click={() => toggleModal(!isModalOpen)}
                />
              }
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseContacts;
