import React from 'react';
import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { useIntl } from 'react-intl';

const ShopBetting = (props) => {
  document.title = 'REACHAFRIKA | SHOP - BETTING';
  const intl = useIntl();

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
        <PageTitle intl={intl} title="Betting" />
        <div className="text-sm pt-2 pb-6 mx-4 md:py-10 xl:mx-24">
          <div className="flex flex-wrap flex-col-reverse xl:flex-row xl:flex-no-wrap mt-1 ml-2">
            <h1 className="text-xl"> Coming Soon!</h1>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default ShopBetting;
