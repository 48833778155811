import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

// IMAGES IMPORTS
import Logo from 'assets/img/logo.png';
// LAYOUT IMPORTS
import Wrapper from 'container/layouts/Wrapper';
// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import FormButton from 'components/Form/Button';
import { UnderLay as Loading } from 'components/Loading';

import { reset_password_request, clear_message_request } from 'redux/actions';

// VALIDAITON IMPORTS
import ResetPasswordSchema from 'validations/reset-password';

// const getMessage = (error) => {
//   let data = [{
//     type: `${error ? 'error' : 'success'}`,
//     title: `${
//       error
//         ? intl.formatMessage({
//             id: 'error.title'
//           })
//         : intl.formatMessage({
//             id: 'reset-p assword.success'
//           })
//     }`
//   }]
// }

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = "`REACHAFRIKA | Password Reset";

  const { user, token } = useSelector((state) => state.authReducers);
  const { error, loading, message } = useSelector((state) => state.authReducers);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      console.log(message)
      if (error || message) {
        alert.show(error ? (error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error || message, {
          type: `${error ? 'error' : 'success'}`,
          title: `${
            error
              ? intl.formatMessage({
                  id: 'error.title'
                })
              : intl.formatMessage({
                  id: 'reset-password.success'
                })
          }`
        }) : message, {
          type: `${error ? 'error' : 'success'}`,
          title: `${
            error
              ? intl.formatMessage({
                  id: 'error.title'
                })
              : intl.formatMessage({
                  id: 'reset-password.success'
                })
          }`
        });
        dispatch(clear_message_request());
      }
    }
    return () => (mounted = false);
  }, [dispatch, error, intl, message]);

  if (user && token || message && message.status === 'success') return <Redirect to={'/dashboard'} />;

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper isAuthLayout {...props}>
        <main className="flex justify-center py-10">
          <div className="absolute w-full top-40 max-w-xl mx-auto sm:px-6 lg:px-8">
            <div className="bg-white overflow-hidden sm:rounded-lg border border-gray-200">
              <div className="bg-white px-4 py-5 sm:px-6 text-center">
                <div className="flex justify-center my-10">
                  <img className="h-6" src={Logo} alt="REACHAFRIKA" />
                </div>
                <h3 className="text-3xl leading-6 font-bold my-4">
                  {intl.formatMessage({
                    id: 'reset-password.form.title'
                  })}
                </h3>
                <Formik
                  initialValues={{
                    password: '',
                    confirm_password: ''
                  }}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    if (props.match.params.token) {
                      dispatch(
                        reset_password_request({
                          password: values.password,
                          token: props.match.params.token
                        })
                      );
                      resetForm();
                      setSubmitting(false);
                    } else {
                      alert.show('Token is required', {
                        type: 'error',
                        title: 'Invalid data'
                      });
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    dirty,
                    isValid,
                    isSubmitting,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <form className="block mt-6" onSubmit={handleSubmit}>
                      <div className="flex flex-wrap -mx-4">
                        <div className="w-full p-4">
                          <Input
                            type="password"
                            name="password"
                            placeholder={intl.formatMessage({
                              id: 'signin.form.placeholder.password'
                            })}
                            inputValue={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.password &&
                              intl.formatMessage({
                                id: errors.password
                              })
                            }
                            touched={touched.password}
                            isPassField
                          />
                        </div>
                        <div className="w-full p-4">
                          <Input
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            // placeholder={intl.formatMessage({
                            //   id: 'signin.form.placeholder.confirm_password'
                            // })}
                            inputValue={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.confirm_password &&
                              intl.formatMessage({
                                id: errors.confirm_password
                              })
                            }
                            touched={touched.confirm_password}
                            isPassField
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4 mx-4">
                        <FormButton
                          title={intl.formatMessage({
                            id: 'button.title.submit'
                          })}
                          color="bg-ra-dark"
                          disabled={!(dirty && isValid) || isSubmitting}
                          loading={isSubmitting}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default ForgetPassword;
