import {
  LANGUAGE_CHANGE,
  REFERRAL_INVITE_INFO,
  PROFILES,
  USER_PROFILES,
  ACTIVATE_PROFILE,
  LOGIN,
  SIGN_UP,
  VALIDATE_EMAIL,
  VALIDATE_PHONE,
  SEND_OTP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_PIN,
  LANGUAGES,
  INTERESTS,
  PROFESSIONS,
  COUNTRIES,
  USER_PROFILE,
  USER_PROFILE_AVATAR,
  USER_PROFILE_MOBILE,
  VALIDATE_PIN,
  AUTH_PREREQUISITES,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

const INIT_STATE = {
  lang: localStorage.getItem('_ral') || 'en', // fr
  user: JSON.parse(sessionStorage.getItem('_rau') || localStorage.getItem('_rau')),
  token: sessionStorage.getItem('_rat') || localStorage.getItem('_rat'),
  isEmailExist: false,
  isPhoneExist: false,
  referrals_invite_info: null,
  loading: false,
  data: null,
  otp: null,
  message: null,
  error: null,
  profiles: [],
  user_profiles: JSON.parse(localStorage.getItem('_raap')) || [],
  languages: [],
  interests: [],
  professions: [],
  countries: [],
  isPinCorrect: false,
  auth_prerequisites: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region LANGUAGE_CHANGE
    case LANGUAGE_CHANGE.POST_REQUEST:
      localStorage.setItem('_ral', action.payload);
      sessionStorage.clear();
      window.location.reload();
      return { ...state, lang: action.payload };
    //#endregion
    //#region REFERRAL_INVITE_INFO
    case REFERRAL_INVITE_INFO.GET_REQUEST:
      return { ...state, loading: true };
    case REFERRAL_INVITE_INFO.GET_SUCCESS:
      return { ...state, loading: false, referrals_invite_info: action.payload };
    case REFERRAL_INVITE_INFO.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PROFILES
    case PROFILES.GET_REQUEST:
      return { ...state, loading: true };
    case PROFILES.GET_SUCCESS:
      return { ...state, loading: false, profiles: action.payload };
    case PROFILES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region USER_PROFILES
    case USER_PROFILES.GET_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILES.GET_SUCCESS:
      return { ...state, loading: false, user_profiles: action.payload };
    case USER_PROFILES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    case USER_PROFILES.POST_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILES.POST_SUCCESS:
      return { ...state, loading: false };
    case USER_PROFILES.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region ACTIVATE_PROFILE
    case ACTIVATE_PROFILE.GET_REQUEST:
      localStorage.removeItem('_raap');
      sessionStorage.removeItem('_rasp');
      return { ...state, loading: true };
    case ACTIVATE_PROFILE.GET_SUCCESS:
      return { ...state, loading: false };
    case ACTIVATE_PROFILE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region LOGIN
    case LOGIN.POST_REQUEST:
      return { ...state, loading: true };
    case LOGIN.POST_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case LOGIN.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SIGN_UP
    case SIGN_UP.POST_REQUEST:
    case SIGN_UP.POST_SUCCESS:
      return { ...state, loading: false };
    case SIGN_UP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VALIDATE_EMAIL
    case VALIDATE_EMAIL.POST_REQUEST:
      return { ...state, loading: true };
    case VALIDATE_EMAIL.POST_SUCCESS:
      return { ...state, loading: false, isEmailExist: action.payload };
    case VALIDATE_EMAIL.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VALIDATE_PHONE
    case VALIDATE_PHONE.POST_REQUEST:
      return { ...state, loading: true };
    case VALIDATE_PHONE.POST_SUCCESS:
      return { ...state, loading: false, isPhoneExist: action.payload };
    case VALIDATE_PHONE.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region SEND_OTP
    case SEND_OTP.POST_REQUEST:
      return { ...state, loading: true };
    case SEND_OTP.POST_SUCCESS:
      return { ...state, loading: false, otp: action.payload };
    case SEND_OTP.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region FORGOT_PASSWORD
    case FORGOT_PASSWORD.POST_REQUEST:
      return { ...state, loading: true };
    case FORGOT_PASSWORD.POST_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case FORGOT_PASSWORD.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region RESET_PASSWORD
    case RESET_PASSWORD.POST_REQUEST:
      return { ...state, loading: true };
    case RESET_PASSWORD.POST_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case RESET_PASSWORD.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CHANGE_EMAIL
    case CHANGE_EMAIL.POST_REQUEST:
      return { ...state, loading: true };
    case CHANGE_EMAIL.POST_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case CHANGE_EMAIL.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CHANGE_PASSWORD
    case CHANGE_PASSWORD.POST_REQUEST:
      return { ...state, loading: true };
    case CHANGE_PASSWORD.POST_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case CHANGE_PASSWORD.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CHANGE_PIN
    case CHANGE_PIN.POST_REQUEST:
      return { ...state, loading: true };
    case CHANGE_PIN.POST_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case CHANGE_PIN.POST_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region LANGUAGES
    case LANGUAGES.GET_REQUEST:
      return { ...state, loading: true };
    case LANGUAGES.GET_SUCCESS:
      return { ...state, loading: false, languages: action.payload };
    case LANGUAGES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region INTERESTS
    case INTERESTS.GET_REQUEST:
      return { ...state, loading: true };
    case INTERESTS.GET_SUCCESS:
      return { ...state, loading: false, interests: action.payload };
    case INTERESTS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region PROFESSIONS
    case PROFESSIONS.GET_REQUEST:
      return { ...state, loading: true };
    case PROFESSIONS.GET_SUCCESS:
      return { ...state, loading: false, professions: action.payload };
    case PROFESSIONS.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region COUNTRIES
    case COUNTRIES.GET_REQUEST:
      return { ...state, loading: true };
    case COUNTRIES.GET_SUCCESS:
      return { ...state, loading: false, countries: action.payload };
    case COUNTRIES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region USER_PROFILE
    case USER_PROFILE.GET_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILE.GET_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case USER_PROFILE.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region USER_PROFILE
    case USER_PROFILE.PATCH_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILE.PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Profile updated successfully'
      };
    case USER_PROFILE.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region USER_PROFILE_AVATAR
    case USER_PROFILE_AVATAR.PATCH_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILE_AVATAR.PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload
      };
    case USER_PROFILE_AVATAR.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region USER_PROFILE_MOBILE
    case USER_PROFILE_MOBILE.PATCH_REQUEST:
      return { ...state, loading: true };
    case USER_PROFILE_MOBILE.PATCH_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case USER_PROFILE_MOBILE.PATCH_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region VALIDATE_PIN
    case VALIDATE_PIN.GET_REQUEST:
      return { ...state, loading: true };
    case VALIDATE_PIN.GET_SUCCESS:
      return { ...state, loading: false, isPinCorrect: action.payload };
    case VALIDATE_PIN.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region AUTH_PREREQUISITES
    case AUTH_PREREQUISITES.GET_REQUEST:
      return { ...state, loading: true };
    case AUTH_PREREQUISITES.GET_SUCCESS:
      return { ...state, loading: false, auth_prerequisites: action.payload };
    case AUTH_PREREQUISITES.GET_FAILED:
      return { ...state, loading: false, error: action.payload };
    //#endregion
    //#region CLEAR_MESSAGES
    case CLEAR_MESSAGES.REQUEST:
      return { ...state, loading: false, message: null, error: null };
    //#endregion
    default:
      return state;
  }
};
