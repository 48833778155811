import React from 'react';
import PropTypes from 'prop-types';

const customerDetail = (props) => {
  return (
    <div className="flex flex-col justify-center">
      <img
        src={props.imageUrl}
        alt={props.name}
        className="self-center object-cover h-32 w-32 rounded-full"
      />
      <p className="text-center mt-4 mb-4 p-5 md:p-0">{props.about}</p>
      <h3 className="font-bold text-center">{props.name}</h3>
    </div>
  );
};

customerDetail.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
export default customerDetail;
