import {
  CONTACTS_GROUPS,
  CONTACTS_GROUPS_CONTACT,
  CREDIT_TRANSACTIONS,
  CREDIT_TOP_UP,
  BANKS,
  ADVERTISE_CAMPAIGNS,
  FREQUENCIES,
  POTENTIAL_REACH_COUNT,
  TARGET_AUDIENCE_COUNT,
  IMPRESSION_COUNT,
  VIEWERSHIP_RATE,
  VIEW_OF_WEEK,
  CLICK_THRU_RATE,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

//#region CONTACTS_GROUPS.GET
export const contacts_groups_get_request = () => ({
  type: CONTACTS_GROUPS.GET_REQUEST
});
export const contacts_groups_get_success = (payload) => ({
  type: CONTACTS_GROUPS.GET_SUCCESS,
  payload
});
export const contacts_groups_get_failed = (payload) => ({
  type: CONTACTS_GROUPS.GET_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS.POST
export const contacts_groups_post_request = (payload) => ({
  type: CONTACTS_GROUPS.POST_REQUEST,
  payload
});
export const contacts_groups_post_success = (payload) => ({
  type: CONTACTS_GROUPS.POST_SUCCESS,
  payload
});
export const contacts_groups_post_failed = (payload) => ({
  type: CONTACTS_GROUPS.POST_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS.PATCH
export const contacts_groups_patch_request = (payload) => ({
  type: CONTACTS_GROUPS.PATCH_REQUEST,
  payload
});
export const contacts_groups_patch_success = (payload) => ({
  type: CONTACTS_GROUPS.PATCH_SUCCESS,
  payload
});
export const contacts_groups_patch_failed = (payload) => ({
  type: CONTACTS_GROUPS.PATCH_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS.DELETE
export const contacts_groups_delete_request = (payload) => ({
  type: CONTACTS_GROUPS.DELETE_REQUEST,
  payload
});
export const contacts_groups_delete_success = (payload) => ({
  type: CONTACTS_GROUPS.DELETE_SUCCESS,
  payload
});
export const contacts_groups_delete_failed = (payload) => ({
  type: CONTACTS_GROUPS.DELETE_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS_CONTACT.GET
export const contacts_groups_get_contact_request = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.GET_REQUEST,
  payload
});
export const contacts_groups_get_contact_success = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.GET_SUCCESS,
  payload
});
export const contacts_groups_get_contact_failed = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.GET_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS_CONTACT.POST
export const contacts_groups_post_contact_request = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.POST_REQUEST,
  payload
});
export const contacts_groups_post_contact_success = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.POST_SUCCESS,
  payload
});
export const contacts_groups_post_contact_failed = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.POST_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS_CONTACT.PATCH
export const contacts_groups_patch_contact_request = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.PATCH_REQUEST,
  payload
});
export const contacts_groups_patch_contact_success = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.PATCH_SUCCESS,
  payload
});
export const contacts_groups_patch_contact_failed = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.PATCH_FAILED,
  payload
});
//#endregion

//#region CONTACTS_GROUPS_CONTACT.DELETE
export const contacts_groups_delete_contact_request = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.DELETE_REQUEST,
  payload
});
export const contacts_groups_delete_contact_success = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.DELETE_SUCCESS,
  payload
});
export const contacts_groups_delete_contact_failed = (payload) => ({
  type: CONTACTS_GROUPS_CONTACT.DELETE_FAILED,
  payload
});
//#endregion

//#region CREDIT_TRANSACTIONS.GET
export const credit_transactions_request = () => ({
  type: CREDIT_TRANSACTIONS.GET_REQUEST
});
export const credit_transactions_success = (payload) => ({
  type: CREDIT_TRANSACTIONS.GET_SUCCESS,
  payload
});
export const credit_transactions_failed = (payload) => ({
  type: CREDIT_TRANSACTIONS.GET_FAILED,
  payload
});
//#endregion

//#region CREDIT_TOP_UP.POST
export const credit_top_up_request = (payload) => ({
  type: CREDIT_TOP_UP.POST_REQUEST,
  payload
});
export const credit_top_up_success = (payload) => ({
  type: CREDIT_TOP_UP.POST_SUCCESS,
  payload
});
export const credit_top_up_failed = (payload) => ({
  type: CREDIT_TOP_UP.POST_FAILED,
  payload
});
//#endregion

//#region BANKS.GET
export const banks_request = () => ({
  type: BANKS.GET_REQUEST
});
export const banks_success = (payload) => ({
  type: BANKS.GET_SUCCESS,
  payload
});
export const banks_failed = (payload) => ({
  type: BANKS.GET_FAILED,
  payload
});
//#endregion

//#region ADVERTISE_CAMPAIGNS.GET
export const ads_campaigns_request = () => ({
  type: ADVERTISE_CAMPAIGNS.GET_REQUEST
});
export const ads_campaigns_success = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.GET_SUCCESS,
  payload
});
export const ads_campaigns_failed = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.GET_FAILED,
  payload
});
//#endregion

//#region ADVERTISE_CAMPAIGNS.POST
export const ads_campaigns_post_request = (data, history) => ({
  type: ADVERTISE_CAMPAIGNS.POST_REQUEST,
  payload: { data, history }
});
export const ads_campaigns_post_success = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.POST_SUCCESS,
  payload
});
export const ads_campaigns_post_failed = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.POST_FAILED,
  payload
});
//#endregion

//#region ADVERTISE_CAMPAIGNS.GET_ONE
export const ads_campaign_request = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.GET_ONE_REQUEST,
  payload
});
export const ads_campaign_success = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.GET_ONE_SUCCESS,
  payload
});
export const ads_campaign_failed = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.GET_ONE_FAILED,
  payload
});
//#endregion

//#region ADVERTISE_CAMPAIGNS.PATCH
export const ads_campaigns_patch_request = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.PATCH_REQUEST,
  payload
});
export const ads_campaigns_patch_success = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.PATCH_SUCCESS,
  payload
});
export const ads_campaigns_patch_failed = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.PATCH_FAILED,
  payload
});
//#endregion

//#region ADVERTISE_CAMPAIGNS.DELETE
export const ads_campaigns_delete_request = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.DELETE_REQUEST,
  payload
});
export const ads_campaigns_delete_success = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.DELETE_SUCCESS,
  payload
});
export const ads_campaigns_delete_failed = (payload) => ({
  type: ADVERTISE_CAMPAIGNS.DELETE_FAILED,
  payload
});
//#endregion

//#region FREQUENCIES.GET
export const frequencies_request = () => ({
  type: FREQUENCIES.GET_REQUEST
});
export const frequencies_success = (payload) => ({
  type: FREQUENCIES.GET_SUCCESS,
  payload
});
export const frequencies_failed = (payload) => ({
  type: FREQUENCIES.GET_FAILED,
  payload
});
//#endregion

//#region POTENTIAL_REACH_COUNT.GET
export const potential_reach_count_request = () => ({
  type: POTENTIAL_REACH_COUNT.GET_REQUEST
});
export const potential_reach_count_success = (payload) => ({
  type: POTENTIAL_REACH_COUNT.GET_SUCCESS,
  payload
});
export const potential_reach_count_failed = (payload) => ({
  type: POTENTIAL_REACH_COUNT.GET_FAILED,
  payload
});
//#endregion

//#region TARGET_AUDIENCE_COUNT.GET
export const target_audience_count_request = (payload) => ({
  type: TARGET_AUDIENCE_COUNT.GET_REQUEST,
  payload
});
export const target_audience_count_success = (payload) => ({
  type: TARGET_AUDIENCE_COUNT.GET_SUCCESS,
  payload
});
export const target_audience_count_failed = (payload) => ({
  type: TARGET_AUDIENCE_COUNT.GET_FAILED,
  payload
});
//#endregion

//#region IMPRESSION_COUNT.POST
export const impression_count_request = (payload) => ({
  type: IMPRESSION_COUNT.POST_REQUEST,
  payload
});
export const impression_count_success = (payload) => ({
  type: IMPRESSION_COUNT.POST_SUCCESS,
  payload
});
export const impression_count_failed = (payload) => ({
  type: IMPRESSION_COUNT.POST_FAILED,
  payload
});
//#endregion

//#region VIEWERSHIP_RATE.GET
export const viewership_rate_request = (payload) => ({
  type: VIEWERSHIP_RATE.GET_REQUEST,
  payload
});
export const viewership_rate_success = (payload) => ({
  type: VIEWERSHIP_RATE.GET_SUCCESS,
  payload
});
export const viewership_rate_failed = (payload) => ({
  type: VIEWERSHIP_RATE.GET_FAILED,
  payload
});
//#endregion

//#region VIEW_OF_WEEK.GET
export const views_of_the_week_request = (payload) => ({
  type: VIEW_OF_WEEK.GET_REQUEST,
  payload
});
export const views_of_the_week_success = (payload) => ({
  type: VIEW_OF_WEEK.GET_SUCCESS,
  payload
});
export const views_of_the_week_failed = (payload) => ({
  type: VIEW_OF_WEEK.GET_FAILED,
  payload
});
//#endregion

//#region CLICK_THRU_RATE.GET
export const click_thru_rate_request = (payload) => ({
  type: CLICK_THRU_RATE.GET_REQUEST,
  payload
});
export const click_thru_rate_success = (payload) => ({
  type: CLICK_THRU_RATE.GET_SUCCESS,
  payload
});
export const click_thru_rate_failed = (payload) => ({
  type: CLICK_THRU_RATE.GET_FAILED,
  payload
});
//#endregion

//#region CLEAR_MESSAGES
export const clear_ads_message_request = () => ({
  type: CLEAR_MESSAGES.REQUEST
});
//#endregion
