import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import authReducers from './auth/reducer';
import walletReducers from './wallet/reducer';
import shopReducers from './shop/reducer';
import reducePaymentReducers from './reduce-payment/reducer';
import advertiseReducers from './advertise/reducer';
import change_country from './country/reducer';
// import { change_country } from './actions';

const reducers = combineReducers({
  authReducers,
  walletReducers,
  shopReducers,
  reducePaymentReducers,
  advertiseReducers,
  change_country,
  loadingBar: loadingBarReducer
});

export default reducers;
