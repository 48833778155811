import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import pick from 'lodash/pick';

// IMAGES IMPORTS
import Logo from 'assets/img/logo.png';
// LAYOUT IMPORTS
import Wrapper from 'container/layouts/Wrapper';
// COMPONENT IMPORTS
import { StepOne, StepTwo, StepThree, StepFour } from 'components/SignUpSteps';
import Button from 'components/Button';
// VALIDAITON IMPORTS
import RegisterSchema from 'validations/signup';

import {
  signupRequest,
  validateEmailRequest,
  auth_prerequisites_request,
  validatePhoneRequest,
  sentOtpRequest,
  clear_message_request
} from 'redux/actions';

const Register = (props) => {
  const [nextStep, setStep] = useState(1);
  const [accept, setAccept] = useState(false);
  const [email, setEmail] = useState(null);

  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl
    .formatMessage({
      id: 'signup.text'
    })
    .toUpperCase()}`;

  const {
    user,
    token,
    error,
    auth_prerequisites,
    isEmailExist,
    isPhoneExist,
    otp,
    loading
  } = useSelector((state) => state.authReducers);

  const stepOne = ['email', 'password', 'confirm_password'];
  const stepTwo = ['lastname', 'firstname', 'pin', 'confirm_pin'];
  const stepThree = ['phone'];

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (error) {
        alert.show(error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'error.title'
          })
        });
        dispatch(clear_message_request());
      }
    }

    return () => (mounted = false);
  }, [alert, dispatch, error, intl]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !auth_prerequisites?.profiles) {
      dispatch(auth_prerequisites_request());
    }
    return () => (mounted = false);
  }, [dispatch, auth_prerequisites]);

  let countries = [];
  if (auth_prerequisites?.profiles) {
    auth_prerequisites.profiles.forEach((profile) => {
      countries.push(profile.name);
    });
  }

  const steps = (props) => {
    const {
      values,
      errors,
      dirty,
      isValid,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      isSubmitting
    } = props;
    switch (nextStep) {
      case 1:
        return (
          <StepOne
            intl={intl}
            values={pick(values, stepOne)}
            errors={pick(errors, stepOne)}
            touched={pick(touched, stepOne)}
            isEmailExist={isEmailExist}
            loading={loading}
            dirty={dirty}
            setStep={setStep}
            dispatch={dispatch}
            validateEmailRequest={validateEmailRequest}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        );
      case 2:
        return (
          <StepTwo
            intl={intl}
            values={pick(values, stepTwo)}
            errors={pick(errors, stepTwo)}
            touched={pick(touched, stepTwo)}
            setStep={setStep}
            accept={accept}
            setAccept={setAccept}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      case 3:
        return (
          <StepThree
            intl={intl}
            values={pick(values, stepThree)}
            errors={pick(errors, stepThree)}
            touched={pick(touched, stepThree)}
            loading={loading}
            dispatch={dispatch}
            countries={countries}
            profiles={auth_prerequisites.profiles}
            sentOtpRequest={sentOtpRequest}
            isPhoneExist={isPhoneExist}
            validatePhoneRequest={validatePhoneRequest}
            setStep={setStep}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        );
      case 4:
        return (
          <StepFour
            intl={intl}
            phone={values.phone}
            dispatch={dispatch}
            otpReceived={otp}
            sentOtpRequest={sentOtpRequest}
            setStep={setStep}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting || loading}
            dirty={dirty}
            isValid={isValid}
          />
        );
      default:
        return null;
    }
  };

  if (user && token) return <Redirect to={'/dashboard'} />;

  return (
    <Wrapper isAuthLayout={true} {...props}>
      <main className="flex justify-center py-10">
        <div className="absolute w-full top-40 max-w-xl mx-auto sm:px-6 lg:px-8">
          <div className="bg-white overflow-hidden sm:rounded-lg border border-gray-200">
            <div className="flex flex-col justify-center lg:px-8 px-4 py-5 sm:px-6 text-center">
              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                <Link to="/" className="flex justify-center">
                  <img className="mx-auto h-6 w-auto" src={Logo} alt="REACHAFRIKA" />
                </Link>

                {nextStep !== 5 ? (
                  <h3 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    {intl.formatMessage({
                      id: 'signup.form.title'
                    })}
                  </h3>
                ) : (
                  <h5 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
                    {intl.formatMessage({
                      id: 'signup.form.success.title'
                    })}
                  </h5>
                )}
                {nextStep !== 4 && (
                  <p className="text-xs leading-5 text-gray-500">
                    {nextStep === 1 && (
                      <>
                        {intl.formatMessage({
                          id: 'signup.form.sub-text'
                        })}
                        <Link to="/login">
                          <span className="text-ra-dark ml-1">
                            {intl.formatMessage({
                              id: 'login.text'
                            })}
                          </span>
                        </Link>
                      </>
                    )}
                    {nextStep === 3 && intl.formatMessage({ id: 'signup.otp.text' })}
                  </p>
                )}
              </div>
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                {nextStep !== 5 ? (
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                      confirm_password: '',
                      // gender: '',
                      lastname: '',
                      firstname: '',
                      pin: '',
                      confirm_pin: '',
                      phone: '',
                      phone_verified_at: false,
                      profile_id: ''
                    }}
                    validationSchema={RegisterSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      const data = Object.assign({}, values);
                      delete data.confirm_password;
                      delete data.confirm_pin;
                      data.referal_link = props.location?.search?.r;
                      dispatch(signupRequest(data));
                      setEmail(data.email);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({ handleSubmit, ...rest }) => (
                      <form className="block" onSubmit={handleSubmit}>
                        {nextStep === 4 && (
                          <p className="text-xs leading-5 text-gray-500 mb-4">
                            {intl.formatMessage({
                              id: 'signup.form.verification.text'
                            })}
                            {rest.values.phone}
                          </p>
                        )}
                        {steps(rest)}
                      </form>
                    )}
                  </Formik>
                ) : (
                  <>
                    {email ? (
                      <p>
                        {intl.formatMessage({
                          id: 'signup.form.sub-text_1'
                        }) +
                          email +
                          ',' +
                          intl.formatMessage({
                            id: 'signup.form.sub-text_2'
                          })}
                      </p>
                    ) : (
                      <p>
                        {intl.formatMessage({
                          id: 'signup.form.sub-text_3'
                        })}
                      </p>
                    )}
                    <div className="mt-3 w-1/3 mx-auto">
                      <Button
                        title={intl.formatMessage({ id: 'signup.form.signin-link' })}
                        textColor="text-ra-dark"
                        className="border-ra-dark"
                        click={() => props.history.push('/login')}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

Register.propTypes = {
  history: PropTypes.object.isRequired
};

export default Register;
