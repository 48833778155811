import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// COMPONENT IMPORTS
import Input from 'components/Form/Input';
import TextArea from 'components/Form/TextArea';
import Select from 'components/Form/Select';
import Button from 'components/Button';

const StepOne = ({
  intl,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setStep
}) => {
  const getOpts = [
    {
      id: 'video',
      name: intl.formatMessage({
        id: 'advertise.campaigns.form.campaign-type.options.video'
      })
    },
    {
      id: 'flyer',
      name: intl.formatMessage({
        id: 'advertise.campaigns.form.campaign-type.options.flyer'
      })
    }
  ];

  return (
    <div className="p-4">
      <div className="mb-6 w-full">
        <Input
          type="text"
          id="title"
          name="title"
          placeholder={intl.formatMessage({
            id: 'advertise.campaigns.form.placeholder.campaign-title'
          })}
          inputValue={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.title &&
            intl.formatMessage({
              id: errors.title
            })
          }
          touched={touched.title}
          useFloatingLable={false}
        />
      </div>
      <div className="mb-6 w-full">
        <Input
          type="text"
          id="url"
          name="url"
          placeholder={intl.formatMessage({
            id: 'advertise.campaigns.form.placeholder.callback-url'
          })}
          inputValue={values.url}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.url &&
            intl.formatMessage({
              id: errors.url
            })
          }
          touched={touched.url}
          useFloatingLable={false}
        />
      </div>
      <div className="mb-6 w-full">
        <Select
          name="type"
          placeholder={intl.formatMessage({
            id: 'advertise.campaigns.form.placeholder.campaign-type'
          })}
          value={values.type}
          error={
            errors.type &&
            intl.formatMessage({
              id: errors.type
            })
          }
          touched={touched.type}
          options={getOpts}
          setFieldValue={(name, obj) => {
            if (obj.id) {
              setFieldValue(name, obj.id);
            }
          }}
          setFieldTouched={setFieldTouched}
          useFloatingLable={false}
          optionLabelKey="name"
          optionValueKey="id"
        />
      </div>
      <TextArea
        id="message"
        name="message"
        rows="3"
        placeholder={intl.formatMessage({
          id: 'advertise.campaigns.form.placeholder.cta-message'
        })}
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.message &&
          intl.formatMessage({
            id: errors.message
          })
        }
        touched={touched.message}
        useFloatingLable={false}
      />
      <TextArea
        id="description"
        name="description"
        rows="3"
        placeholder={intl.formatMessage({
          id: 'advertise.campaigns.form.placeholder.description'
        })}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.description &&
          intl.formatMessage({
            id: errors.description
          })
        }
        touched={touched.description}
        useFloatingLable={false}
      />
      <div className="flex justify-between my-4">
        <div />
        <div className="w-1/3">
          <Button
            title={intl.formatMessage({
              id: 'button.title.next'
            })}
            className="border-ra-dark"
            textColor="text-ra-dark"
            disabled={!isEmpty(errors) || !dirty}
            click={() => setStep(1)}
          />
        </div>
      </div>
    </div>
  );
};

StepOne.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default StepOne;
