import { images } from 'exports'
import { Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'
import HeaderDiv from './HeaderDiv'
import MobileStores from './MobileStores'
import { Box } from '@mui/material'
function EarnMoreBySpending() {
    return (

        <Box sx={{backgroundImage:`url('${images.earnmorebyspendingbg}')`, backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}>
            <HeaderDiv
                headerCompoenent={<Text56>Earn money by spreading the word</Text56>}
                smallText={<>Encourage your friends, family, and acquaintances to use our site, and both of you will benefit financially. Begin having fun now.</>}
                extra={<MobileStores />}
                img={images.earmmorebyspending}
            />
        </Box>
    )
}

export default EarnMoreBySpending