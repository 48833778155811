import {
  NORMAL_ACCOUNT,
  REWARD_ACCOUNT,
  CREDIT_ACCOUNT,
  ACCOUNT_TRANSACTIONS,
  BANK_ACCOUNT_DETAILS,
  LOG_FUND
} from 'constants/actionTypes';

//#region NORMAL_ACCOUNT
export const normal_account_request = () => ({
  type: NORMAL_ACCOUNT.GET_REQUEST
});
export const normal_account_success = (payload) => ({
  type: NORMAL_ACCOUNT.GET_SUCCESS,
  payload
});
export const normal_account_failed = (payload) => ({
  type: NORMAL_ACCOUNT.GET_FAILED,
  payload
});
//#endregion

//#region REWARD_ACCOUNT
export const reward_account_request = () => ({
  type: REWARD_ACCOUNT.GET_REQUEST
});
export const reward_account_success = (payload) => ({
  type: REWARD_ACCOUNT.GET_SUCCESS,
  payload
});
export const reward_account_failed = (payload) => ({
  type: REWARD_ACCOUNT.GET_FAILED,
  payload
});
//#endregion

//#region CREDIT_ACCOUNT
export const credit_account_request = () => ({
  type: CREDIT_ACCOUNT.GET_REQUEST
});
export const credit_account_success = (payload) => ({
  type: CREDIT_ACCOUNT.GET_SUCCESS,
  payload
});
export const credit_account_failed = (payload) => ({
  type: CREDIT_ACCOUNT.GET_FAILED,
  payload
});
//#endregion

//#region ACCOUNT_TRANSACTIONS
export const account_transactions_request = (payload) => ({
  type: ACCOUNT_TRANSACTIONS.GET_REQUEST,
  payload
});
export const account_transactions_success = (payload) => ({
  type: ACCOUNT_TRANSACTIONS.GET_SUCCESS,
  payload
});
export const account_transactions_failed = (payload) => ({
  type: ACCOUNT_TRANSACTIONS.GET_FAILED,
  payload
});
//#endregion

//#region BANK_ACCOUNT_DETAILS
export const bank_account_details_request = () => ({
  type: BANK_ACCOUNT_DETAILS.GET_REQUEST
});
export const bank_account_details_success = (payload) => ({
  type: BANK_ACCOUNT_DETAILS.GET_SUCCESS,
  payload
});
export const bank_account_details_failed = (payload) => ({
  type: BANK_ACCOUNT_DETAILS.GET_FAILED,
  payload
});
//#endregion

//#region LOG_FUND
export const log_fund_request = (filter, body) => ({
  type: LOG_FUND.POST_REQUEST,
  payload: { filter, body }
});
export const log_fund_success = (payload) => ({
  type: LOG_FUND.POST_SUCCESS,
  payload
});
export const log_fund_failed = (payload) => ({
  type: LOG_FUND.POST_FAILED,
  payload
});
//#endregion
