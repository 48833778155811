import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text, Text20, Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'
import { Box } from "@mui/material";
import { images } from 'exports';

function MakeYourClients() {

    const Content = ({ img, headerText, smallText }) => {
        return (
            <FlexBox direction='column' gap='24px' w={{ xs: '100%', sm: '45%', md: '45%' }} sx={{mt:'50px'}} align='flex-start'>
                <Img src={img} h='100px' w='100px'/>
                <Box sx={{ mt: '16px', width:'100%' }}><Text color='black' size='32px' weight='700'>{headerText}</Text></Box>
                <Text20 color='#657585'>{smallText}</Text20>
            </FlexBox>
        )
    }
    const contents = [
        { img: images.rocket, headerText: 'Increased ROI', smallText: 'By choosing your preferred ads based on your budget, you receive the best value for your investment. This covers things like display frequency, scheduling campaigns for a time frame, and focusing on some areas, age range, gender, interest, career, or level of education.' },
        { img: images.sales, headerText: 'Increase Sales', smallText: "The platform's customer purchasing power is increased by loyalty program points, which boosts your sales as a merchant on our app and gives you the opportunity to benefit from positive word-of-mouth recommendations from pleased customers." },
        { img:images.target, headerText: 'Maximum engagement', smallText: 'By advertising on our platform, users can not skip or fast forward our ads which bring maximum engagement as users are also rewarded for their engagement with ads. This is rare with other platforms.' },
        { img: images.retain, headerText: 'Retain and gain more Customers', smallText: 'Through our platform, you give your customers a valid reason to stick with your business by giving them loyalty reward. You also get to experience improved services by collecting customer feedback.' },
    ]
    return (

        <VhContainer h='auto'>
            <FlexBox direction='column' gap='24px' sx={{ py: '96px' }} justify='center' >
                <Text56 w='100%' align='center'>
                    Make your clients feel unique.
                </Text56>
                <Text20 color='hsba(210, 24%, 52%, 1)' w='80%'>
                    Reachafrika Loyalty delivers your customers fantastic reward on every purchase and lets you administer your loyalty program. The platform also enables your company to get consumer feedback in order to provide better customer service.
                </Text20>
                <FlexBox wrap='wrap'>{contents.map(content => <Content {...content} />)}</FlexBox>
            </FlexBox>
        </VhContainer>
    )
}

export default MakeYourClients