import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';
import { Provider as AlertProvider } from 'react-alert';

import AuthContextProvider from 'context/authContext';
import AlertTemplate from 'components/Alert';

// Routers Imports
import Routers from 'routers/register';

// optional configuration
const options = {
  timeout: 10000,
  position: 'top right',
  offset: '30px',
  transition: 'fade'
};

function App() {
  if (!localStorage.getItem('_ral')) {
    localStorage.setItem('_ral', 'en');
  }
  return (
    <Provider store={configureStore()}>
      <AlertProvider template={AlertTemplate} {...options}>
        <AuthContextProvider>
          <Routers />
        </AuthContextProvider>
      </AlertProvider>
    </Provider>
  );
}

export default App;
