import React from 'react';

const FileUpload = ({ className, setFieldValue, dispatch, action }) => {
  const handleChange = (e) => {
    let files = e.currentTarget.files;
    setFieldValue(files[0]);
    dispatch(action(files[0]));
  };

  return (
    <input
      type="file"
      onChange={(e) => handleChange(e)}
      className={className}
      accept="image/jpeg, image/png"
    />
  );
};

export default FileUpload;
