
import { images } from 'exports'
import { FlexBox } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'
import MobileStores from './MobileStores'

function LifeMadeSimple() {
  return (

    <FlexBox sx={{
      positon: 'relative',
      backgroundImage: `url('${images.lifemadesimple}')`,
      backgroundRepeat: 'no-repeat', py: '150px',
      backgroundSize: 'cover', backgroundPosition:'center'
    }}
      direction='column' h='auto' justify='center' gap='48px'>
      {/* <Img src={images.lifemadesimple} props={{position:'absolute', zIndex:'-1'}}/> */}
      <div className='w-100 text-center'><Text56 w={'100%'}>Life made simple</Text56></div>
      <FlexBox wrap='wrap' w={{ xs: '100%', lg: '50%' }} h='200px'>
        <Text20 w='45%'>Earn money by watching Ads.</Text20>
        <Text20 w='45%'>Create and share gift card with friends</Text20>
        <Text20 w='45%'>Earn reward from our partners</Text20>
        <Text20 w='45%'>Buy Airtime, Data and pay your bills</Text20>
      </FlexBox>

      <MobileStores justify={'center'} />
    </FlexBox>
  )
}

export default LifeMadeSimple