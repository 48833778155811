import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import Modal, { ModalBody, ModalHeader } from 'components/Modal';
import Display from './Display';
import AddForm from './AddForm';

import {
  activate_profile_request,
  user_profiles_post_request,
  profiles_request
} from 'redux/actions';

const ProfilesModal = ({ user_profiles, intl, handleClose, isOpen }) => {
  const [formState, setFormState] = useState('my');
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, profiles } = useSelector((state) => state.authReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!profiles.length && formState === 'add') {
        dispatch(profiles_request());
      }
      if (error) {
        alert.show(error, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, error, formState, intl, profiles.length]);

  //#region
  // useSelector language_change_request
  // const { lang } = useSelector((state) => state.authReducers);
  //   const Button = ({ id, name }) => (
  //     <button
  //       className={`py-1 px-10 mr-1 ${
  //         lang === id ? 'rounded-full bg-ra-dark text-white' : 'text-ra-dark'
  //       }`}
  //       onClick={() => dispatch(language_change_request(id))}
  //     >
  //       {name}
  //     </button>
  //   );

  //   const data = [
  //     {
  //       id: 'en',
  //       name: 'English'
  //     },
  //     {
  //       id: 'fr',
  //       name: 'French'
  //     }
  //   ];

  //   <div className="flex justify-center mb-4">
  //   <div className="flex justify-between w-2/3 bg-gray-100 border border-gray-200 rounded-full p-1">
  //     {data.map(({ id, name }) => (
  //       <Button key={id} id={id} name={name} />
  //     ))}
  //   </div>
  // </div>
  //#endregion

  return (
    <>
      <Modal
        isModalOpen={isOpen}
        toggleModal={() => {
          setFormState('my');
          handleClose();
        }}
        className="md:absolute sm:w-full md:w-1/3 lg:w-1/4 lg:top-40 bg-white text-ra-darker rounded-lg pt-5 pb-4 shadow-xl"
        close={true}
        closeOutside={true}
      >
        <ModalHeader
          title={intl.formatMessage({
            id: `profile.page.title.${formState}`
          })}
          className="text-2xl text-center pb-2 border-b"
        />
        <ModalBody className="mt-3 sm:mt-4 px-8">
          {formState === 'my' && (
            <Display
              user_profiles={user_profiles}
              dispatch={dispatch}
              action={activate_profile_request}
              intl={intl}
              setFormState={setFormState}
            />
          )}
          {formState === 'add' && (
            <AddForm
              profiles={profiles}
              dispatch={dispatch}
              action={user_profiles_post_request}
              intl={intl}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProfilesModal;
