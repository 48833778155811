import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import NavigationItem from '../Navigation/NavigationItem';

import Android from 'assets/landing/google-play.png';
import Ios from 'assets/landing/apple-store.png';
import { LangContext } from 'context/AddLangContext';

const Footer = () => {
  const intl = useIntl();
  const info = useContext(LangContext)

  return (
    <div className="bg-gray-250 pt-10">
      <div className="container m-auto mt-10 p-5">
        <div className="flex flex-col md:flex-row md:justify-around">
          <div className="w-full mb-10 pr-5 md:w-52">
            <h4 className="text-xl leading-5 font-semibold tracking-wider uppercase">
              {intl.formatMessage({
                id: 'footer.hearder_1'
              })}
            </h4>
            <ul className="mt-4">
              <li className="mb-3">
                <p>{ info.country }:</p>
                <p>{ info[info.country].loc }</p>
              </li>
              {/* <li className="mb-3">
                <p>Ghana:</p>
                <p>5 Roman Road, Off Bostral Avenue Roman Ridge Accra</p>
              </li> */}
            </ul>
          </div>
          <div className="w-full mb-10 pr-5 md:w-52">
            <h4 className="text-xl leading-5 font-semibold tracking-wider uppercase">
              {intl.formatMessage({
                id: 'footer.hearder_2'
              })}
            </h4>
            <ul className="mt-4">
              <li className="mb-3">
                <a href="mailto:support@reachafrika.com">Email: { info[info.country].email}</a>
              </li>
              <li className="mb-3">
                <a href={ `tel:${(info[info.country].phone).replace(' ', '')}` }><p>Phone:</p> { info[info.country].phone}</a>
              </li>
              <li className="mb-3">
                <a href="https://wa.me/2349057974461">WhatsApp: +2349057974461</a>
              </li>
            </ul>
          </div>
          <div className="w-full mb-10 pr-5 md:w-52">
            <h4 className="text-xl leading-5 font-semibold tracking-wider uppercase">
              {intl.formatMessage({
                id: 'footer.hearder_3'
              })}
            </h4>
            <ul className="mt-4">
              <li className="mb-3">
                <NavigationItem
                  url="/about-us"
                  title={intl.formatMessage({
                    id: 'footer.nav.link_1'
                  })}
                  openNew={true}
                  classItems="text-black leading-6 text-gray-500 hover:text-gray-900"
                />
              </li>
              <li className="mb-3">
                <NavigationItem
                  url="/privacy-policy"
                  title={intl.formatMessage({
                    id: 'footer.nav.link_2'
                  })}
                  openNew={true}
                  classItems="text-black leading-6 text-gray-500 hover:text-gray-900"
                />
              </li>
              <li className="mb-3">
                <NavigationItem
                  url="/terms-&-conditions"
                  title={intl.formatMessage({
                    id: 'footer.nav.link_3'
                  })}
                  openNew={true}
                  classItems="text-black leading-6 text-gray-500 hover:text-gray-900"
                />
              </li>
            </ul>
          </div>
          <div className="w-full mb-10 pr-5 md:w-52">
            <h4 className="text-xl leading-5 font-semibold tracking-wider uppercase">Help</h4>
            <ul className="mt-4">
              <li className="mb-3">
                <NavigationItem
                  url="/faqs"
                  title={intl.formatMessage({
                    id: 'footer.nav.link_4'
                  })}
                  openNew={true}
                  classItems="text-black leading-6 text-gray-500 hover:text-gray-900"
                />
              </li>
              <li className="mb-3">
                <NavigationItem
                  url="/help-center"
                  title={intl.formatMessage({
                    id: 'footer.nav.link_5'
                  })}
                  openNew={true}
                  classItems="text-black leading-6 text-gray-500 hover:text-gray-900"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-around">
          <div className="w-full mb-10 pr-5 md:w-52">
            <h4 className="text-xl leading-5 text-center font-semibold tracking-wider uppercase">
              {intl.formatMessage({
                id: 'footer.download'
              })}
            </h4>
            <ul className="flex mt-4">
              <li className="mb-3 mr-1">
                <a href="https://appurl.io/VnEmxNaZN">
                  <img src={Android} alt="https://appurl.io/VnEmxNaZN" />
                </a>
              </li>
              <li className="mb-3">
                <a href="https://appurl.io/VnEmxNaZN">
                  <img src={Ios} alt="https://appurl.io/VnEmxNaZN" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-2 md:flex md:justify-center">
          <p className="text-sm lg:text-base leading-6 text-ra-black">
            &copy; {new Date().getFullYear()} ReachAfrika, LTD.{' '}
            {intl.formatMessage({
              id: 'footer.copy'
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
