import Home from './home';
import AboutUs from './about-us';
import ValueProposition from './value-proposition';
import Pricing from './pricing';

const Landing = {
  Home,
  AboutUs,
  ValueProposition,
  Pricing
};

export default Landing;
