import { CAMPAIGNS, CAMPAIGNS_REWARDS, VIEW_CAMPAIGN } from 'constants/actionTypes';

//#region CAMPAIGNS
export const campaigns_request = (payload) => ({
  type: CAMPAIGNS.GET_REQUEST,
  payload
});
export const campaigns_success = (payload) => ({
  type: CAMPAIGNS.GET_SUCCESS,
  payload
});
export const campaigns_failed = (payload) => ({
  type: CAMPAIGNS.GET_FAILED,
  payload
});
//#endregion

//#region CAMPAIGNS_REWARDS
export const campaigns_rewards_request = () => ({
  type: CAMPAIGNS_REWARDS.GET_REQUEST
});
export const campaigns_rewards_success = (payload) => ({
  type: CAMPAIGNS_REWARDS.GET_SUCCESS,
  payload
});
export const campaigns_rewards_failed = (payload) => ({
  type: CAMPAIGNS_REWARDS.GET_FAILED,
  payload
});
//#endregion

//#region VIEW_CAMPAIGN
export const view_campaign_request = (payload) => ({
  type: VIEW_CAMPAIGN.POST_REQUEST,
  payload
});
export const view_campaign_success = (payload) => ({
  type: VIEW_CAMPAIGN.POST_SUCCESS,
  payload
});
export const view_campaign_failed = (payload) => ({
  type: VIEW_CAMPAIGN.POST_FAILED,
  payload
});
//#endregion
