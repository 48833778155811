import React from 'react';
import { useSelector } from 'react-redux';

import InfoCard from '../Cards/Info';

const BalanceDisplay = ({
  intl,
  account_no,
  normal_error,
  normal_total,
  reward_error,
  reward_coins
}) => {
  const { user_profiles } = useSelector((state) => state.authReducers);

  const activeProfile = user_profiles?.find((p) => p.active)?.profile;

  return (
    <div className="flex flex-wrap xl:flex-no-wrap py-4">
      {activeProfile?.has_wallet && (
        <>
          {normal_error ? (
            <div>
              {intl.formatMessage({
                id: 'table.error.text'
              })}
            </div>
          ) : (
            <InfoCard
              title={intl.formatMessage({
                id: 'dashboard.bal-display.account.title_1'
              })}
              value={normal_total}
              className="card-with-linear-gradient-a w-full xl:w-1/2 xl:mr-2 mb-2 xl:mb-0"
              accountNo={`${account_no} N`}
            />
          )}
        </>
      )}

      {reward_error ? (
        <div>
          {intl.formatMessage({
            id: 'table.error.text'
          })}
        </div>
      ) : (
        <InfoCard
          title={intl.formatMessage({
            id: 'dashboard.bal-display.account.title_2'
          })}
          value={reward_coins}
          className="card-with-linear-gradient-b w-full xl:w-1/2 xl:ml-2 mt-2 xl:mt-0"
          accountNo={`${account_no} R`}
          hasIcon={true}
        />
      )}
    </div>
  );
};

export default BalanceDisplay;
