import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { ChevronIcon } from 'assets/icons/svg';

import Transition from 'components/TransitionGroup/Transition.js';

const AccordionItem = ({ item }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <li className="border-b">
      <a
        href={`#${item.tag}`}
        className="block focus:outline-none transition duration-150 ease-in-out"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <h4 className="text-md xl:text-2xl font-bold">{item.title}</h4>
          </div>
          <div className={`mt-1 transform duration-300 ${isOpen ? '-rotate-180' : 'rotate-270'}`}>
            <ChevronIcon />
          </div>
        </div>
      </a>
      <div className="w-full bg-white" style={{ display: !isOpen ? 'none' : '' }}>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="leading-6 overflow-auto focus:outline-nonesm:leading-5">
            <div className="text-sm md:text-base text-gray-900 leading-normal text-left relative px-4 py-4 sm:px-6">
              {item.text}
            </div>
          </div>
        </Transition>
      </div>
    </li>
  );
};

AccordionItem.propTypes = {
  item: PropTypes.object.isRequired
};

const Accordion = ({ data = [] }) => {
  return (
    <ul
      tabIndex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      className="py-2 bg-white text-base text-left leading-6 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
    >
      {data.map((item, i) => (
        <AccordionItem key={i} item={item} />
      ))}
    </ul>
  );
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};

export default Accordion;
