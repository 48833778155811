import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text, Text20, Text56 } from 'pages/LandingPage/styles/text'
import React from 'react'
import { Box } from "@mui/material";
import { images } from 'exports';

function LifeMadeEasy() {

    const Content = ({ img, headerText, smallText }) => {
        return (
            <FlexBox direction='column' gap='24px' w={{ xs: '100%', sm: '45%', md: '30%' }} h='300px' sx={{ mt: '50px', borderRadius: '8px', bgcolor: 'white', p: '32px' }} align='flex-start'  >
                <Img src={img} h='100px' w='100px' />
                {/* <Box sx={{ mt: '16px', width:'100%' }}><Text color='black' size='32px' weight='700'>{headerText}</Text></Box> */}
                <Text20 color='#657585'>{smallText}</Text20>
            </FlexBox>
        )
    }
    const contents = [
        { img: images.lifemadeeasyicon1, smallText: 'An increase in awareness, a high lead generation rate, a high level of social media involvement, crowdfunding, and customer surveys.' },
        { img: images.lifemadeeasyicon2, smallText: "Our system offers our merchants several methods for taking payments. You can do this with cash, POS, or mobile money, and more." },
        { img: images.lifemadeeasyicon3, smallText: 'For a tailored audience, our Ads are filtered by location, age range, gender, interest, profession, and many more' },
        { img: images.lifemadeeasyicon4, smallText: 'Manage the loyalty program to promote client retention and engagement.' },
        { img: images.lifemadeeasyicon5, smallText: 'We offer dashboard analytics for illuminating metrics and well-informed choices.' },
        { img: images.lifemadeeasyicon6, smallText: 'At the business, branch, or teller level, keep an eye on sales and client feedback..' },
    ]
    return (

        <VhContainer h='auto' bgcolor='#F5F6FA'>
            <FlexBox direction='column' gap='24px' sx={{ py: '96px' }} justify='center'  >
                <Text56 w='100%' align='center'>
                    Life made easy
                </Text56>
                <FlexBox wrap='wrap'>{contents.map(content => <Content {...content} />)}</FlexBox>
            </FlexBox>
        </VhContainer>
    )
}

export default LifeMadeEasy