import { COUNTRIES_CHANGER,  } from 'constants/actionTypes';

const INIT_STATE = {
  country: 'nigeria'
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region CAMPAIGNS

    case COUNTRIES_CHANGER.CHANGE_COUNTRY:
      return { ...state, country: action.payload };
    default:
      return state;
  }
};
