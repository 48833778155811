// #region AUTH
export const LANGUAGE_CHANGE = {
  POST_REQUEST: 'LANGUAGE_CHANGE_POST_REQUEST',
  POST_SUCCESS: 'LANGUAGE_CHANGE_POST_SUCCESS',
  POST_FAILED: 'LANGUAGE_CHANGE_POST_FAILED'
};

export const PROFILES = {
  GET_REQUEST: 'PROFILES_GET_REQUEST',
  GET_SUCCESS: 'PROFILES_GET_SUCCESS',
  GET_FAILED: 'PROFILES_GET_FAILED'
};

export const REFERRAL_INVITE_INFO = {
  GET_REQUEST: 'REFERRAL_INVITE_INFO_GET_REQUEST',
  GET_SUCCESS: 'REFERRAL_INVITE_INFO_GET_SUCCESS',
  GET_FAILED: 'REFERRAL_INVITE_INFO_GET_FAILED'
};

export const USER_PROFILES = {
  GET_REQUEST: 'USER_PROFILES_GET_REQUEST',
  GET_SUCCESS: 'USER_PROFILES_GET_SUCCESS',
  GET_FAILED: 'USER_PROFILES_GET_FAILED',
  POST_REQUEST: 'USER_PROFILES_POST_REQUEST',
  POST_SUCCESS: 'USER_PROFILES_POST_SUCCESS',
  POST_FAILED: 'USER_PROFILES_POST_FAILED'
};

export const ACTIVATE_PROFILE = {
  GET_REQUEST: 'ACTIVATE_PROFILE_GET_REQUEST',
  GET_SUCCESS: 'ACTIVATE_PROFILE_GET_SUCCESS',
  GET_FAILED: 'ACTIVATE_PROFILE_GET_FAILED'
};

export const LOGIN = {
  POST_REQUEST: 'LOGIN_POST_REQUEST',
  POST_SUCCESS: 'LOGIN_POST_SUCCESS',
  POST_FAILED: 'LOGIN_POST_FAILED'
};

export const SIGN_UP = {
  POST_REQUEST: 'SIGN_UP_POST_REQUEST',
  POST_SUCCESS: 'SIGN_UP_POST_SUCCESS',
  POST_FAILED: 'SIGN_UP_POST_FAILED'
};

export const VALIDATE_EMAIL = {
  POST_REQUEST: 'VALIDATE_EMAIL_POST_REQUEST',
  POST_SUCCESS: 'VALIDATE_EMAIL_POST_SUCCESS',
  POST_FAILED: 'VALIDATE_EMAIL_POST_FAILED'
};

export const VALIDATE_PHONE = {
  POST_REQUEST: 'VALIDATE_PHONE_POST_REQUEST',
  POST_SUCCESS: 'VALIDATE_PHONE_POST_SUCCESS',
  POST_FAILED: 'VALIDATE_PHONE_POST_FAILED'
};

export const SEND_OTP = {
  POST_REQUEST: 'SEND_OTP_POST_REQUEST',
  POST_SUCCESS: 'SEND_OTP_POST_SUCCESS',
  POST_FAILED: 'SEND_OTP_POST_FAILED'
};

export const FORGOT_PASSWORD = {
  POST_REQUEST: 'FORGOT_PASSWORD_POST_REQUEST',
  POST_SUCCESS: 'FORGOT_PASSWORD_POST_SUCCESS',
  POST_FAILED: 'FORGOT_PASSWORD_POST_FAILED'
};

export const RESET_PASSWORD = {
  POST_REQUEST: 'RESET_PASSWORD_POST_REQUEST',
  POST_SUCCESS: 'RESET_PASSWORD_POST_SUCCESS',
  POST_FAILED: 'RESET_PASSWORD_POST_FAILED'
};

export const CHANGE_EMAIL = {
  POST_REQUEST: 'CHANGE_EMAIL_POST_REQUEST',
  POST_SUCCESS: 'CHANGE_EMAIL_POST_SUCCESS',
  POST_FAILED: 'CHANGE_EMAIL_POST_FAILED'
};

export const CHANGE_PASSWORD = {
  POST_REQUEST: 'CHANGE_PASSWORD_POST_REQUEST',
  POST_SUCCESS: 'CHANGE_PASSWORD_POST_SUCCESS',
  POST_FAILED: 'CHANGE_PASSWORD_POST_FAILED'
};

export const CHANGE_PIN = {
  POST_REQUEST: 'CHANGE_PIN_POST_REQUEST',
  POST_SUCCESS: 'CHANGE_PIN_POST_SUCCESS',
  POST_FAILED: 'CHANGE_PIN_POST_FAILED'
};

export const LANGUAGES = {
  GET_REQUEST: 'LANGUAGES_GET_REQUEST',
  GET_SUCCESS: 'LANGUAGES_GET_SUCCESS',
  GET_FAILED: 'LANGUAGES_GET_FAILED'
};

export const PROFESSIONS = {
  GET_REQUEST: 'PROFESSIONS_GET_REQUEST',
  GET_SUCCESS: 'PROFESSIONS_GET_SUCCESS',
  GET_FAILED: 'PROFESSIONS_GET_FAILED'
};

export const INTERESTS = {
  GET_REQUEST: 'INTERESTS_GET_REQUEST',
  GET_SUCCESS: 'INTERESTS_GET_SUCCESS',
  GET_FAILED: 'INTERESTS_GET_FAILED'
};

export const COUNTRIES = {
  GET_REQUEST: 'COUNTRIES_GET_REQUEST',
  GET_SUCCESS: 'COUNTRIES_GET_SUCCESS',
  GET_FAILED: 'COUNTRIES_GET_FAILED'
};

export const USER_PROFILE = {
  GET_REQUEST: 'USER_PROFILE_GET_REQUEST',
  GET_SUCCESS: 'USER_PROFILE_GET_SUCCESS',
  GET_FAILED: 'USER_PROFILE_GET_FAILED',
  PATCH_REQUEST: 'USER_PROFILE_PATCH_REQUEST',
  PATCH_SUCCESS: 'USER_PROFILE_PATCH_SUCCESS',
  PATCH_FAILED: 'USER_PROFILE_PATCH_FAILED'
};

export const USER_PROFILE_MOBILE = {
  PATCH_REQUEST: 'USER_PROFILE_MOBILE_PATCH_REQUEST',
  PATCH_SUCCESS: 'USER_PROFILE_MOBILE_PATCH_SUCCESS',
  PATCH_FAILED: 'USER_PROFILE_MOBILE_PATCH_FAILED'
};

export const USER_PROFILE_AVATAR = {
  PATCH_REQUEST: 'USER_PROFILE_AVATAR_PATCH_REQUEST',
  PATCH_SUCCESS: 'USER_PROFILE_AVATAR_PATCH_SUCCESS',
  PATCH_FAILED: 'USER_PROFILE_AVATAR_PATCH_FAILED'
};

export const VALIDATE_PIN = {
  GET_REQUEST: 'VALIDATE_PIN_GET_REQUEST',
  GET_SUCCESS: 'VALIDATE_PIN_GET_SUCCESS',
  GET_FAILED: 'VALIDATE_PIN_GET_FAILED'
};

export const AUTH_PREREQUISITES = {
  GET_REQUEST: 'AUTH_PREREQUISITES_GET_REQUEST',
  GET_SUCCESS: 'AUTH_PREREQUISITES_GET_SUCCESS',
  GET_FAILED: 'AUTH_PREREQUISITES_GET_FAILED'
};
// #endregion

// #region WALLET
export const NORMAL_ACCOUNT = {
  GET_REQUEST: 'NORMAL_ACCOUNT_GET_REQUEST',
  GET_SUCCESS: 'NORMAL_ACCOUNT_GET_SUCCESS',
  GET_FAILED: 'NORMAL_ACCOUNT_GET_FAILED'
};

export const CREDIT_ACCOUNT = {
  GET_REQUEST: 'CREDIT_ACCOUNT_GET_REQUEST',
  GET_SUCCESS: 'CREDIT_ACCOUNT_GET_SUCCESS',
  GET_FAILED: 'CREDIT_ACCOUNT_GET_FAILED'
};

export const REWARD_ACCOUNT = {
  GET_REQUEST: 'REWARD_ACCOUNT_GET_REQUEST',
  GET_SUCCESS: 'REWARD_ACCOUNT_GET_SUCCESS',
  GET_FAILED: 'REWARD_ACCOUNT_GET_FAILED'
};

export const ACCOUNT_TRANSACTIONS = {
  GET_REQUEST: 'ACCOUNT_TRANSACTIONS_GET_REQUEST',
  GET_SUCCESS: 'ACCOUNT_TRANSACTIONS_GET_SUCCESS',
  GET_FAILED: 'ACCOUNT_TRANSACTIONS_GET_FAILED'
};

export const BANK_ACCOUNT_DETAILS = {
  GET_REQUEST: 'BANK_ACCOUNT_DETAILS_GET_REQUEST',
  GET_SUCCESS: 'BANK_ACCOUNT_DETAILS_GET_SUCCESS',
  GET_FAILED: 'BANK_ACCOUNT_DETAILS_GET_FAILED'
};

export const LOG_FUND = {
  POST_REQUEST: 'LOG_FUND_POST_REQUEST',
  POST_SUCCESS: 'LOG_FUND_POST_SUCCESS',
  POST_FAILED: 'LOG_FUND_POST_FAILED'
};
//#endregion

// #region SHOPS
export const SHOP_PRODUCTS = {
  GET_REQUEST: 'SHOP_PRODUCTS_GET_REQUEST',
  GET_SUCCESS: 'SHOP_PRODUCTS_GET_SUCCESS',
  GET_FAILED: 'SHOP_PRODUCTS_GET_FAILED'
};

export const SHOP_PRODUCT_ITEMS = {
  POST_REQUEST: 'SHOP_PRODUCT_ITEMS_POST_REQUEST',
  POST_SUCCESS: 'SHOP_PRODUCT_ITEMS_POST_SUCCESS',
  POST_FAILED: 'SHOP_PRODUCT_ITEMS_POST_FAILED'
};

export const SHOP_PRODUCT_ITEM_PACKAGES = {
  POST_REQUEST: 'SHOP_PRODUCT_ITEM_PACKAGES_POST_REQUEST',
  POST_SUCCESS: 'SHOP_PRODUCT_ITEM_PACKAGES_POST_SUCCESS',
  POST_FAILED: 'SHOP_PRODUCT_ITEM_PACKAGES_POST_FAILED'
};

export const SHOP_PURCHASE = {
  POST_REQUEST: 'SHOP_PURCHASE_POST_REQUEST',
  POST_SUCCESS: 'SHOP_PURCHASE_POST_SUCCESS',
  POST_FAILED: 'SHOP_PURCHASE_POST_FAILED'
};

export const SHOP_BENEFICIARIES = {
  GET_REQUEST: 'SHOP_BENEFICIARIES_GET_REQUEST',
  GET_SUCCESS: 'SHOP_BENEFICIARIES_GET_SUCCESS',
  GET_FAILED: 'SHOP_BENEFICIARIES_GET_FAILED',
  POST_REQUEST: 'SHOP_BENEFICIARIES_POST_REQUEST',
  POST_SUCCESS: 'SHOP_BENEFICIARIES_POST_SUCCESS',
  POST_FAILED: 'SHOP_BENEFICIARIES_POST_FAILED'
};

export const SUPPORTED_NETWORK = {
  GET_REQUEST: 'SUPPORTED_NETWORK_GET_REQUEST',
  GET_SUCCESS: 'SUPPORTED_NETWORK_GET_SUCCESS',
  GET_FAILED: 'SUPPORTED_NETWORK_GET_FAILED'
};

export const TOP_UP_AIRTIME = {
  POST_REQUEST: 'TOP_UP_AIRTIME_POST_REQUEST',
  POST_SUCCESS: 'TOP_UP_AIRTIME_POST_SUCCESS',
  POST_FAILED: 'TOP_UP_AIRTIME_POST_FAILED'
};

export const DATA_PACKAGES = {
  GET_REQUEST: 'DATA_PACKAGES_GET_REQUEST',
  GET_SUCCESS: 'DATA_PACKAGES_GET_SUCCESS',
  GET_FAILED: 'DATA_PACKAGES_GET_FAILED'
};

export const TOP_UP_DATA = {
  POST_REQUEST: 'TOP_UP_DATA_POST_REQUEST',
  POST_SUCCESS: 'TOP_UP_DATA_POST_SUCCESS',
  POST_FAILED: 'TOP_UP_DATA_POST_FAILED'
};

export const CABLE_TV_PROVIDER = {
  GET_REQUEST: 'CABLE_TV_PROVIDER_GET_REQUEST',
  GET_SUCCESS: 'CABLE_TV_PROVIDER_GET_SUCCESS',
  GET_FAILED: 'CABLE_TV_PROVIDER_GET_FAILED'
};

export const CABLE_TV_PACKAGE = {
  GET_REQUEST: 'CABLE_TV_PACKAGE_GET_REQUEST',
  GET_SUCCESS: 'CABLE_TV_PACKAGE_GET_SUCCESS',
  GET_FAILED: 'CABLE_TV_PACKAGE_GET_FAILED'
};

export const VERIFY_SMART_CARD = {
  POST_REQUEST: 'VERIFY_SMART_CARD_POST_REQUEST',
  POST_SUCCESS: 'VERIFY_SMART_CARD_POST_SUCCESS',
  POST_FAILED: 'VERIFY_SMART_CARD_POST_FAILED'
};

export const CABLE_TV_TOP_UP = {
  POST_REQUEST: 'CABLE_TV_TOP_UP_POST_REQUEST',
  POST_SUCCESS: 'CABLE_TV_TOP_UP_POST_SUCCESS',
  POST_FAILED: 'CABLE_TV_TOP_UP_POST_FAILED'
};

export const PRODUCT_ITEMS = {
  GET_REQUEST: 'PRODUCT_ITEMS_GET_REQUEST',
  GET_SUCCESS: 'PRODUCT_ITEMS_GET_SUCCESS',
  GET_FAILED: 'PRODUCT_ITEMS_GET_FAILED'
};

export const EDUCATION_TOP_UP = {
  POST_REQUEST: 'EDUCATION_TOP_UP_POST_REQUEST',
  POST_SUCCESS: 'EDUCATION_TOP_UP_POST_SUCCESS',
  POST_FAILED: 'EDUCATION_TOP_UP_POST_FAILED'
};

export const IROKO_PROVIDER = {
  GET_REQUEST: 'IROKO_PROVIDER_GET_REQUEST',
  GET_SUCCESS: 'IROKO_PROVIDER_GET_SUCCESS',
  GET_FAILED: 'IROKO_PROVIDER_GET_FAILED'
};

export const TOP_UP_IROKO = {
  POST_REQUEST: 'TOP_UP_IROKO_POST_REQUEST',
  POST_SUCCESS: 'TOP_UP_IROKO_POST_SUCCESS',
  POST_FAILED: 'TOP_UP_IROKO_POST_FAILED'
};

export const PAN_AFRICAN_AIRTIME_PRE = {
  GET_REQUEST: 'PAN_AFRICAN_AIRTIME_PRE_GET_REQUEST',
  GET_SUCCESS: 'PAN_AFRICAN_AIRTIME_PRE_GET_SUCCESS',
  GET_FAILED: 'PAN_AFRICAN_AIRTIME_PRE_GET_FAILED'
};

export const PAN_AFRICAN_AIRTIME_TOP_UP = {
  POST_REQUEST: 'PAN_AFRICAN_AIRTIME_TOP_UP_POST_REQUEST',
  POST_SUCCESS: 'PAN_AFRICAN_AIRTIME_TOP_UP_POST_SUCCESS',
  POST_FAILED: 'PAN_AFRICAN_AIRTIME_TOP_UP_POST_FAILED'
};

export const LOADIND_IFRAME = {
  START_REQUEST: 'LOADIND_IFRAME_START_REQUEST',
  STOP_REQUEST: 'LOADIND_IFRAME_STOP_REQUEST'
};

export const ACTIVE_PRODUCT = {
  GET_REQUEST: 'ACTIVE_PRODUCT_GET_REQUEST',
  GET_SUCCESS: 'ACTIVE_PRODUCT_GET_SUCCESS',
  GET_FAILED: 'ACTIVE_PRODUCT_GET_FAILED'
};

export const CHECK_METER = {
  POST_REQUEST: 'CHECK_METER_POST_REQUEST',
  POST_SUCCESS: 'CHECK_METER_POST_SUCCESS',
  POST_FAILED: 'CHECK_METER_POST_FAILED'
};

export const PURCHASE_POWER = {
  POST_REQUEST: 'PURCHASE_POWER_POST_REQUEST',
  POST_SUCCESS: 'PURCHASE_POWER_POST_SUCCESS',
  POST_FAILED: 'PURCHASE_POWER_POST_FAILED'
};

export const CLEAR_MESSAGES = {
  REQUEST: 'CLEAR_MESSAGES_REQUEST'
};
//#endregion

// #region REDUCE PAYMENT
export const CAMPAIGNS = {
  GET_REQUEST: 'CAMPAIGNS_GET_REQUEST',
  GET_SUCCESS: 'CAMPAIGNS_GET_SUCCESS',
  GET_FAILED: 'CAMPAIGNS_GET_FAILED'
};

export const CAMPAIGNS_REWARDS = {
  GET_REQUEST: 'CAMPAIGNS_REWARDS_GET_REQUEST',
  GET_SUCCESS: 'CAMPAIGNS_REWARDS_GET_SUCCESS',
  GET_FAILED: 'CAMPAIGNS_REWARDS_GET_FAILED'
};

export const VIEW_CAMPAIGN = {
  POST_REQUEST: 'VIEW_CAMPAIGN_POST_REQUEST',
  POST_SUCCESS: 'VIEW_CAMPAIGN_POST_SUCCESS',
  POST_FAILED: 'VIEW_CAMPAIGN_POST_FAILED'
};
//#endregion

// #region ADVERTISE
export const ADVERTISE_CAMPAIGNS = {
  GET_REQUEST: 'ADVERTISE_CAMPAIGNS_GET_REQUEST',
  GET_SUCCESS: 'ADVERTISE_CAMPAIGNS_GET_SUCCESS',
  GET_FAILED: 'ADVERTISE_CAMPAIGNS_GET_FAILED',

  POST_REQUEST: 'ADVERTISE_CAMPAIGNS_POST_REQUEST',
  POST_SUCCESS: 'ADVERTISE_CAMPAIGNS_POST_SUCCESS',
  POST_FAILED: 'ADVERTISE_CAMPAIGNS_POST_FAILED',

  GET_ONE_REQUEST: 'ADVERTISE_CAMPAIGNS_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'ADVERTISE_CAMPAIGNS_GET_ONE_SUCCESS',
  GET_ONE_FAILED: 'ADVERTISE_CAMPAIGNS_GET_ONE_FAILED',

  PATCH_REQUEST: 'ADVERTISE_CAMPAIGNS_PATCH_REQUEST',
  PATCH_SUCCESS: 'ADVERTISE_CAMPAIGNS_PATCH_SUCCESS',
  PATCH_FAILED: 'ADVERTISE_CAMPAIGNS_PATCH_FAILED',

  DELETE_REQUEST: 'ADVERTISE_CAMPAIGNS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ADVERTISE_CAMPAIGNS_DELETE_SUCCESS',
  DELETE_FAILED: 'ADVERTISE_CAMPAIGNS_DELETE_FAILED'
};

export const VIEWERSHIP_RATE = {
  GET_REQUEST: 'VIEWERSHIP_RATE_REQUEST',
  GET_SUCCESS: 'VIEWERSHIP_RATE_SUCCESS',
  GET_FAILED: 'VIEWERSHIP_RATE_FAILED'
};

export const VIEW_OF_WEEK = {
  GET_REQUEST: 'VIEW_OF_WEEK_REQUEST',
  GET_SUCCESS: 'VIEW_OF_WEEK_SUCCESS',
  GET_FAILED: 'VIEW_OF_WEEK_FAILED'
};

export const CLICK_THRU_RATE = {
  GET_REQUEST: 'CLICK_THRU_RATE_REQUEST',
  GET_SUCCESS: 'CLICK_THRU_RATE_SUCCESS',
  GET_FAILED: 'CLICK_THRU_RATE_FAILED'
};

export const CONTACTS_GROUPS = {
  GET_REQUEST: 'CONTACTS_GROUPS_GET_REQUEST',
  GET_SUCCESS: 'CONTACTS_GROUPS_GET_SUCCESS',
  GET_FAILED: 'CONTACTS_GROUPS_GET_FAILED',
  POST_REQUEST: 'CONTACTS_GROUPS_POST_REQUEST',
  POST_SUCCESS: 'CONTACTS_GROUPS_POST_SUCCESS',
  POST_FAILED: 'CONTACTS_GROUPS_POST_FAILED',
  PATCH_REQUEST: 'CONTACTS_GROUPS_PATCH_REQUEST',
  PATCH_SUCCESS: 'CONTACTS_GROUPS_PATCH_SUCCESS',
  PATCH_FAILED: 'CONTACTS_GROUPS_PATCH_FAILED',
  DELETE_REQUEST: 'CONTACTS_GROUPS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CONTACTS_GROUPS_DELETE_SUCCESS',
  DELETE_FAILED: 'CONTACTS_GROUPS_DELETE_FAILED'
};

export const CONTACTS_GROUPS_CONTACT = {
  GET_REQUEST: 'CONTACTS_GROUPS_CONTACT_GET_REQUEST',
  GET_SUCCESS: 'CONTACTS_GROUPS_CONTACT_GET_SUCCESS',
  GET_FAILED: 'CONTACTS_GROUPS_CONTACT_GET_FAILED',
  POST_REQUEST: 'CONTACTS_GROUPS_CONTACT_POST_REQUEST',
  POST_SUCCESS: 'CONTACTS_GROUPS_CONTACT_POST_SUCCESS',
  POST_FAILED: 'CONTACTS_GROUPS_CONTACT_POST_FAILED',
  PATCH_REQUEST: 'CONTACTS_GROUPS_CONTACT_PATCH_REQUEST',
  PATCH_SUCCESS: 'CONTACTS_GROUPS_CONTACT_PATCH_SUCCESS',
  PATCH_FAILED: 'CONTACTS_GROUPS_CONTACT_PATCH_FAILED',
  DELETE_REQUEST: 'CONTACTS_GROUPS_CONTACT_DELETE_REQUEST',
  DELETE_SUCCESS: 'CONTACTS_GROUPS_CONTACT_DELETE_SUCCESS',
  DELETE_FAILED: 'CONTACTS_GROUPS_CONTACT_DELETE_FAILED'
};

export const CREDIT_TRANSACTIONS = {
  GET_REQUEST: 'CREDIT_TRANSACTIONS_GET_REQUEST',
  GET_SUCCESS: 'CREDIT_TRANSACTIONS_GET_SUCCESS',
  GET_FAILED: 'CREDIT_TRANSACTIONS_GET_FAILED'
};

export const CREDIT_TOP_UP = {
  POST_REQUEST: 'CREDIT_TOP_UP_POST_REQUEST',
  POST_SUCCESS: 'CREDIT_TOP_UP_POST_SUCCESS',
  POST_FAILED: 'CREDIT_TOP_UP_POST_FAILED'
};

export const BANKS = {
  GET_REQUEST: 'BANKS_GET_REQUEST',
  GET_SUCCESS: 'BANKS_GET_SUCCESS',
  GET_FAILED: 'BANKS_GET_FAILED'
};

export const FREQUENCIES = {
  GET_REQUEST: 'FREQUENCIES_GET_REQUEST',
  GET_SUCCESS: 'FREQUENCIES_GET_SUCCESS',
  GET_FAILED: 'FREQUENCIES_GET_FAILED'
};

export const POTENTIAL_REACH_COUNT = {
  GET_REQUEST: 'POTENTIAL_REACH_COUNT_GET_REQUEST',
  GET_SUCCESS: 'POTENTIAL_REACH_COUNT_GET_SUCCESS',
  GET_FAILED: 'POTENTIAL_REACH_COUNT_GET_FAILED'
};

export const TARGET_AUDIENCE_COUNT = {
  GET_REQUEST: 'TARGET_AUDIENCE_COUNT_GET_REQUEST',
  GET_SUCCESS: 'TARGET_AUDIENCE_COUNT_GET_SUCCESS',
  GET_FAILED: 'TARGET_AUDIENCE_COUNT_GET_FAILED'
};

export const IMPRESSION_COUNT = {
  POST_REQUEST: 'IMPRESSION_COUNT_POST_REQUEST',
  POST_SUCCESS: 'IMPRESSION_COUNT_POST_SUCCESS',
  POST_FAILED: 'IMPRESSION_COUNT_POST_FAILED'
};

export const COUNTRIES_CHANGER = {
  CHANGE_COUNTRY: 'COUNTRY_COUNTRY'
}
//#endregion
