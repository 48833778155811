import React from 'react';
import { useIntl } from 'react-intl';

const NotFound = () => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl
    .formatMessage({
      id: 'not-found.text'
    })
    .toUpperCase()}`;

  return (
    <div className="w-full flex h-screen">
      <p className="m-auto">
        404 |{' '}
        {intl.formatMessage({
          id: 'not-found.text'
        })}
      </p>
    </div>
  );
};

export default NotFound;
