import { Typography } from '@mui/material'
import React from 'react'

const Text72 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'white',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: '50px', lg: "72px" },
                fontWeight: 700,
                lineHeight: { xs: '40px', lg: "76px" },
                letterSpacing: "0em",
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    )
}
const Text56 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${56 * .65}px`, lg: "56px" },
                fontWeight: 700,
                lineHeight: { xs: 'auto', lg: "64px" },
                letterSpacing: "0em",
                textAlign: props.align ?? "left"
            }}
        >
            {props.children}
        </Typography>
    )
}

const Text20 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${20 * .90}px`, lg: "20px" },
                fontWeight: { xs: 100, lg: 400 },
                lineHeight: "28px",
                letterSpacing: "0em",
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    )
}

const Text28 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${28 * .65}px`, lg: "28px" },
                fontWeight: 400,
                lineHeight: "36px",
                letterSpacing: "0em",
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    )
}
const Text24 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${24 * .65}px`, lg: "24px" },
                fontWeight: 700,
                lineHeight: "29px",
                letterSpacing: "0em",
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    )
}
const Text16 = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${16 * .65}px`, lg: "16px" },
                fontWeight: props.weight ?? 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    )
}
const Text = (props) => {
    return (
        <Typography {...props} component={props.component ?? 'p'}
            width={props.w ?? '100%'} sx={{
                color: props.color ?? 'black',
                fontFamily: "Helvetica Neue",
                fontSize: { xs: `${props.size * .7}px`, lg: props.size },
                fontWeight: props.weight ?? 400,
                lineHeight: props.lh ??  "12px" ,
                letterSpacing: "0em",
                textAlign: props.align ?? "left"
            }}
        >
            {props.children}
        </Typography>
    )
}
export { Text72, Text56, Text20, Text28, Text24, Text16, Text }