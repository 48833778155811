import React, { useState, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import { UnderLay as Loading } from 'components/Loading';
import Input from 'components/Form/Input';

import {
  viewership_rate_request,
  views_of_the_week_request,
  click_thru_rate_request
} from 'redux/actions';

const AdvertiseCampaignStatistics = (props) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'advertise.campaigns.page.title_3'
  })}`;

  const { loading, viewership_rate, click_thru_rate, views_of_the_week } = useSelector(
    (state) => state.advertiseReducers
  );

  useEffect(() => {
    let mounted = true;
    if (mounted && props?.match?.params?.key) {
      dispatch(viewership_rate_request(props?.match?.params?.key));
      dispatch(click_thru_rate_request(props?.match?.params?.key));
      dispatch(views_of_the_week_request(props?.match?.params?.key));
    }
    return () => (mounted = false);
  }, [dispatch, props.match.params.key]);

  const data1 = {
    labels: [
      intl.formatMessage({
        id: 'advertise.campaigns.stat.label.1'
      }),
      intl.formatMessage({
        id: 'advertise.campaigns.stat.label.2'
      })
    ],
    datasets: [
      {
        data: [viewership_rate?.views, viewership_rate?.total],
        backgroundColor: ['#1976D2', '#44C4A1'],
        hoverBackgroundColor: ['#1976D2', '#44C4A1']
      }
    ]
  };

  const data2 = {
    labels: [
      intl.formatMessage({
        id: 'advertise.campaigns.stat.label.1'
      }),
      intl.formatMessage({
        id: 'advertise.campaigns.stat.label.3'
      })
    ],
    datasets: [
      {
        data: [click_thru_rate?.clicks, click_thru_rate?.views],
        backgroundColor: ['#1976D2', '#9B51E0'],
        hoverBackgroundColor: ['#1976D2', '#9B51E0']
      }
    ]
  };

  const legend = {
    display: true,
    position: 'bottom',
    fullWidth: true,
    labels: {
      boxWidth: 8,
      fontColor: '#9C9C9C',
      usePointStyle: true,
      padding: 50
    }
  };

  const options = {
    cutoutPercentage: 40
  };

  const days_of_week = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

  const getLineData = (days, counts) => {
    if (days.length < 7) {
      const avaDays = days_of_week.map((e) => e.toLowerCase());
      let newCounts = [...counts];
      let newDays = days.map((e) => e.toLowerCase());
      avaDays.forEach((e) => {
        if (!newDays.includes(e)) {
          newCounts.splice(avaDays.indexOf(e), 0, 0);
        }
      });
      return newCounts;
    } else {
      return counts;
    }
  };

  const data3 = {
    labels: days_of_week,
    datasets: [
      {
        label: intl.formatMessage({
          id: 'advertise.campaigns.stat.label.4'
        }),
        fill: true,
        lineTension: 0.5,
        backgroundColor: 'rgba(25, 118, 210, 0.2)',
        borderColor: 'rgba(25,118,210,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(25,118,210,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(25,118,210,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: getLineData(views_of_the_week.days, views_of_the_week.counts)
      }
    ]
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="advertise.campaigns.nav.title_1" />
          <div className="pt-2 pb-6 mx-4 md:mx-10 md:py-5 lg:mx-32 lg:py-10 xl:mx-24">
            <div className="flex flex-wrap xl:flex-no-wrap xl:w-4/6 xxl:w-5/6">
              <div className="w-full p-5 mb-4 xl:w-1/2 xl:mr-5 bg-white shadow-sm border rounded-md">
                <div className="mb-5">
                  <h3 className="text-2xl font-semibold">
                    {intl.formatMessage({
                      id: 'advertise.campaigns.graph_1.title'
                    })}
                  </h3>
                </div>
                <Doughnut data={data1} legend={legend} options={options} width={30} height={30} />
              </div>
              <div className="w-full p-5 mb-4 xl:w-1/2 xl:ml-5 bg-white shadow-sm border rounded-md">
                <div className="mb-5">
                  <h3 className="text-2xl font-semibold">
                    {intl.formatMessage({
                      id: 'advertise.campaigns.graph_2.title'
                    })}
                  </h3>
                </div>
                <Doughnut data={data2} legend={legend} options={options} width={30} height={30} />
              </div>
            </div>
            <div className="xl:w-4/6 xxl:w-5/6">
              <div className="w-full p-5 mb-4 bg-white shadow-sm border rounded-md">
                <div className="flex justify-between mb-5">
                  <h3 className="text-2xl font-semibold">
                    {intl.formatMessage({
                      id: 'advertise.campaigns.filter.label'
                    })}
                  </h3>
                  <div className="w-1/4 mx-6 py-2">
                    <Input
                      type="date"
                      name="date"
                      inputValue={date}
                      max={date}
                      onChange={(e) => setDate(e.target.value)}
                      useFloatingLable={false}
                    />
                  </div>
                </div>
                <Line data={data3} />
              </div>
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default AdvertiseCampaignStatistics;
