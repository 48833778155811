import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Iframe from 'react-iframe';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay as Loading } from 'components/Loading';

import { startLoadingIframeRequest, stopLoadingIframeRequest } from 'redux/actions';

const ShopAirline = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'shop.airline.page.title'
  })}`;

  const { loading } = useSelector((state) => state.shopReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(startLoadingIframeRequest());
    }
    return () => (mounted = false);
  }, [dispatch]);

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="shop.airline.nav.title" />
          <div className="text-sm pt-2 pb-6 mx-4 md:py-10 xl:mx-24">
            <Iframe
              url="https://admin.reachafrika.com/airline-for-mobile-app"
              id="myId"
              className="w-full h-screen"
              display="initial"
              position="relative"
              loading="eager"
              onLoad={() => dispatch(stopLoadingIframeRequest())}
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default ShopAirline;
