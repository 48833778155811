import getConfig from 'utils/env';
import httpFacade from 'utils/httpFacade';

const { baseURL } = getConfig();

const http = new httpFacade(baseURL + '/v1/');

export const contacts_groups_get_async = async () =>
  await http.get({ url: 'advertise/contacts-groups' });
export const contacts_groups_post_async = async (payload) =>
  await http.post({ url: 'advertise/contacts-groups', body: payload });
export const contacts_groups_patch_async = async (payload) =>
  await http.post({ url: 'advertise/contacts-groups/update', body: payload });
export const contacts_groups_delete_async = async (payload) =>
  await http.delete({ url: `advertise/contacts-groups/${payload}` });
export const contacts_groups_get_contact_async = async (payload) =>
  await http.get({ url: `advertise/contacts-groups/contacts/${payload}` });
export const contacts_groups_post_contact_async = async (payload) =>
  await http.post({ url: 'advertise/contacts-groups/contacts', body: payload });
export const contacts_groups_patch_contact_async = async (payload) =>
  await http.patch({ url: `advertise/contacts-groups/contacts/${payload.id}`, body: payload });
export const contacts_groups_delete_contact_async = async (payload) =>
  await http.delete({ url: `advertise/contacts-groups/contacts/${payload}` });
export const credit_transactions_async = async () =>
  await http.get({ url: 'advertise/credit/transactions' });
export const credit_top_up_async = async (payload) =>
  await http.post({ url: 'advertise/credit/top-up', body: payload });
export const banks_async = async () => await http.get({ url: 'banks' });

export const fetch_data_count_get = async (payload) =>
  await http.get({ url: `advertise/all-campaigns/${payload}` });
export const fetch_data_count_post = async ({ path, payload }) =>
  await http.post({ url: `advertise/all-campaigns/${path}`, body: payload });

export const post_campaign = async (payload) =>
  await http.post({ url: 'advertise/campaigns', body: payload });
export const fetch_campaigns = async () => await http.get({ url: 'advertise/campaigns' });

export const fetch_campaign = async (payload) =>
  await http.get({ url: `advertise/all-campaigns/fetch-campaigns/${payload}` });

export const viewership_rate = async (payload) =>
  await http.get({ url: `advertise/all-campaigns/viewer-ship-rate/${payload}` });
export const views_of_the_week = async (payload) =>
  await http.get({ url: `advertise/all-campaigns/group-by-day/${payload}` });
export const click_thru_rate = async (payload) =>
  await http.get({ url: `advertise/all-campaigns/ctr/${payload}` });

export const patch_campaign = async (payload) =>
  await http.patch({ url: `advertise/all-campaigns/toggle-block/${payload}` });
export const delete_campaign = async (payload) =>
  await http.delete({ url: `advertise/all-campaigns/delete/${payload}` });
