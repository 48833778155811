import React from 'react'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20 } from 'pages/LandingPage/styles/text'
const HeaderDiv = ({ headerCompoenent, smallText, img, extra, bgcolor = 'none' }) => {
    return (
        <>
            <VhContainer bgcolor={bgcolor}>
                <FlexBox >
                    <FlexBox direction='column' justify='center' gap={{ xs: '10px', lg: '32px' }} w={{ sm: '95%', lg: '85%' }}>
                        {headerCompoenent}
                        <Text20 color='white'>
                            {smallText}
                        </Text20>
                        {extra}
                    </FlexBox>
                    <Img src={img} h={{ xs: '50%', lg: '75%' }} />
                </FlexBox>
            </VhContainer>
        </>
    )
}
export default HeaderDiv