import { images } from 'exports'
import { FlexBox } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import React from 'react'

function MobileStores({ justify }) {
    const link = () => {
        window.open('https://appurl.io/VnEmxNaZN')
    }
    return (
        <FlexBox justify={justify ?? 'none'} h='fit-content' gap={{ xs: '20px', lg: '24px' }} direction='row'>
            <Img src={images.googleplay} mW={{ xs: '120px', lg: '145px' }} minH={{ xs: '44px', lg: '54px' }} mH={{ xs: '44px', lg: '54px' }} onClick={link} />
            <Img src={images.appstore} mW={{ xs: '120px', lg: '145px' }} minH={{ xs: '54px', lg: '64px' }} mH={{ xs: '54px', lg: '64px' }} onClick={link} />
        </FlexBox>
    )
}

export default MobileStores