import * as yup from 'yup';

const fileValidation = (size, type) =>
  yup
    .mixed()
    .required('advertise.campaigns.errors.file_1')
    .test(
      'fileSize',
      'advertise.campaigns.errors.file_2',
      (value) => value && value.size <= 1024 * 1024 * size
    )
    .test(
      'fileType',
      'advertise.campaigns.errors.file_2',
      (value) => value && type.includes(value.type)
    );

export default yup.object().shape({
  amount: yup
    .number()
    .typeError('advertise.campaigns.credit.errors_1')
    .positive('advertise.campaigns.credit.errors_1')
    .moreThan(499, 'advertise.campaigns.credit.errors_2')
    .required('advertise.campaigns.credit.errors_3'),
  method: yup.string().required('advertise.campaigns.credit.errors_4'),
  pop: yup.mixed().when('method', {
    is: 'bank',
    then: fileValidation(2, ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'])
  }),
  bank_id: yup.mixed().when('method', {
    is: 'bank',
    then: yup.number().required('advertise.campaigns.credit.errors_5')
  })
});
