import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import CardInfo from 'components/Wallet/Card';
import Table from 'components/Table';
import { UnderLay as Loading } from 'components/Loading';

import { formatDateTime } from 'utils/misc';

import { reward_account_request, account_transactions_request } from 'redux/actions';

const WalletReward = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'wallet.normal.page.title'
  })}`;

  const { error, loading, reward_account_data, account_transactions_data } = useSelector(
    (state) => state.walletReducers
  );

  const { user } = useSelector((state) => state.authReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(reward_account_request());
      dispatch(account_transactions_request({ type: 'reward' }));
    }
    return () => (mounted = false);
  }, [dispatch]);

  const columns = [
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h1'
      }),
      Cell: ({ row }) => formatDateTime(row.original.created_at)
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h2'
      }),
      accessor: 'description'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h3'
      }),
      accessor: 'reference'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h4'
      }),
      accessor: 'amount'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h5'
      }),
      accessor: 'transaction'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h6'
      }),
      accessor: 'method'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h7'
      }),
      Cell: ({ row }) => {
        const getState = (key) => {
          switch (key) {
            case 'pending':
              return { title: 'pending', color: 'tb-pending-btn' };
            case 'completed':
              return { title: 'completed', color: 'tb-completed-btn' };
            case 'rejected':
            default:
              return { title: 'rejected', color: 'tb-rejected-btn' };
          }
        };
        const state = getState(row.original.status);
        return <button className={`${state.color} px-3 py-2 `}>{state.title}</button>;
      }
    }
  ];

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="wallet.reward.nav.title" />
          <div className="pt-2 pb-6 mt-4 mx-4 md:mx-10 md:py-10 xl:mx-24">
            <div className="flex justify-around">
              <CardInfo title="Reward Account" value={`${user.account_no} N`} className="mr-2" />
              {error ? (
                <div>
                  {intl.formatMessage({
                    id: 'table.error.text'
                  })}
                </div>
              ) : (
                <CardInfo
                  title={intl.formatMessage({
                    id: 'wallet.account.bal.title'
                  })}
                  coins={reward_account_data.coins}
                  className="ml-2"
                />
              )}
            </div>

            <Table
              title={intl.formatMessage({
                id: 'wallet.table.title'
              })}
              intl={intl}
              columns={columns}
              data={account_transactions_data}
              error={error}
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default WalletReward;
