import {
  SHOP_PRODUCTS,
  SHOP_PRODUCT_ITEMS,
  SHOP_PRODUCT_ITEM_PACKAGES,
  SHOP_PURCHASE,
  SUPPORTED_NETWORK,
  SHOP_BENEFICIARIES,
  TOP_UP_AIRTIME,
  DATA_PACKAGES,
  TOP_UP_DATA,
  CABLE_TV_PROVIDER,
  CABLE_TV_PACKAGE,
  VERIFY_SMART_CARD,
  CABLE_TV_TOP_UP,
  PRODUCT_ITEMS,
  EDUCATION_TOP_UP,
  IROKO_PROVIDER,
  TOP_UP_IROKO,
  PAN_AFRICAN_AIRTIME_PRE,
  PAN_AFRICAN_AIRTIME_TOP_UP,
  ACTIVE_PRODUCT,
  CHECK_METER,
  PURCHASE_POWER,
  LOADIND_IFRAME,
  CLEAR_MESSAGES
} from 'constants/actionTypes';

//#region SHOP_PRODUCTS
export const shop_products_request = () => ({
  type: SHOP_PRODUCTS.GET_REQUEST
});
export const shop_products_success = (payload) => ({
  type: SHOP_PRODUCTS.GET_SUCCESS,
  payload
});
export const shop_products_failed = (payload) => ({
  type: SHOP_PRODUCTS.GET_FAILED,
  payload
});
//#endregion

//#region SHOP_PRODUCT_ITEM_PACKAGES
export const shop_product_item_packages_request = (payload) => ({
  type: SHOP_PRODUCT_ITEM_PACKAGES.POST_REQUEST,
  payload
});
export const shop_product_item_packages_success = (payload) => ({
  type: SHOP_PRODUCT_ITEM_PACKAGES.POST_SUCCESS,
  payload
});
export const shop_product_item_packages_failed = (payload) => ({
  type: SHOP_PRODUCT_ITEM_PACKAGES.POST_FAILED,
  payload
});
//#endregion

//#region SHOP_PRODUCT_ITEMS
export const shop_product_items_request = (payload) => ({
  type: SHOP_PRODUCT_ITEMS.POST_REQUEST,
  payload
});
export const shop_product_items_success = (payload) => ({
  type: SHOP_PRODUCT_ITEMS.POST_SUCCESS,
  payload
});
export const shop_product_items_failed = (payload) => ({
  type: SHOP_PRODUCT_ITEMS.POST_FAILED,
  payload
});
//#endregion

//#region SET_FROM_COUNTRY
export const set_from_country = (payload) => ({
  type: 'SET_FROM_COUNTRY',
  payload
});
//#endregion

//#region SHOP_PURCHASE
export const shop_purchase_request = (payload) => ({
  type: SHOP_PURCHASE.POST_REQUEST,
  payload
});
export const shop_purchase_success = (payload) => ({
  type: SHOP_PURCHASE.POST_SUCCESS,
  payload
});
export const shop_purchase_failed = (payload) => ({
  type: SHOP_PURCHASE.POST_FAILED,
  payload
});
//#endregion

//#region SHOP_BENEFICIARIES_GET
export const shop_beneficaries_get_request = (payload) => ({
  type: SHOP_BENEFICIARIES.GET_REQUEST,
  payload
});
export const shop_beneficaries_get_success = (payload) => ({
  type: SHOP_BENEFICIARIES.GET_SUCCESS,
  payload
});
export const shop_beneficaries_get_failed = (payload) => ({
  type: SHOP_BENEFICIARIES.GET_FAILED,
  payload
});
//#endregion

//#region SHOP_BENEFICIARIES_POST
export const shop_beneficaries_post_request = (payload) => ({
  type: SHOP_BENEFICIARIES.POST_REQUEST,
  payload
});
export const shop_beneficaries_post_success = (payload) => ({
  type: SHOP_BENEFICIARIES.POST_SUCCESS,
  payload
});
export const shop_beneficaries_post_failed = (payload) => ({
  type: SHOP_BENEFICIARIES.POST_FAILED,
  payload
});
//#endregion

//#region SUPPORTED_NETWORK
export const supported_network_request = (payload) => ({
  type: SUPPORTED_NETWORK.GET_REQUEST,
  payload
});
export const supported_network_success = (payload) => ({
  type: SUPPORTED_NETWORK.GET_SUCCESS,
  payload
});
export const supported_network_failed = (payload) => ({
  type: SUPPORTED_NETWORK.GET_FAILED,
  payload
});
//#endregion

//#region TOP_UP_AIRTIME
export const top_up_airtime_request = (payload) => ({
  type: TOP_UP_AIRTIME.POST_REQUEST,
  payload
});
export const top_up_airtime_success = (payload) => ({
  type: TOP_UP_AIRTIME.POST_SUCCESS,
  payload
});
export const top_up_airtime_failed = (payload) => ({
  type: TOP_UP_AIRTIME.POST_FAILED,
  payload
});
//#endregion

//#region DATA_PACKAGES
export const data_packages_get_request = (payload) => ({
  type: DATA_PACKAGES.GET_REQUEST,
  payload
});
export const data_packages_get_success = (payload) => ({
  type: DATA_PACKAGES.GET_SUCCESS,
  payload
});
export const data_packages_get_failed = (payload) => ({
  type: DATA_PACKAGES.GET_FAILED,
  payload
});
//#endregion

//#region TOP_UP_DATA
export const top_up_data_request = (payload) => ({
  type: TOP_UP_DATA.POST_REQUEST,
  payload
});
export const top_up_data_success = (payload) => ({
  type: TOP_UP_DATA.POST_SUCCESS,
  payload
});
export const top_up_data_failed = (payload) => ({
  type: TOP_UP_DATA.POST_FAILED,
  payload
});
//#endregion

//#region CABLE_TV_PROVIDER
export const cable_tv_provider_request = (payload) => ({
  type: CABLE_TV_PROVIDER.GET_REQUEST,
  payload
});
export const cable_tv_provider_success = (payload) => ({
  type: CABLE_TV_PROVIDER.GET_SUCCESS,
  payload
});
export const cable_tv_provider_failed = (payload) => ({
  type: CABLE_TV_PROVIDER.GET_FAILED,
  payload
});
//#endregion

//#region CABLE_TV_PACKAGE
export const cable_tv_package_request = (payload) => ({
  type: CABLE_TV_PACKAGE.GET_REQUEST,
  payload
});
export const cable_tv_package_success = (payload) => ({
  type: CABLE_TV_PACKAGE.GET_SUCCESS,
  payload
});
export const cable_tv_package_failed = (payload) => ({
  type: CABLE_TV_PACKAGE.GET_FAILED,
  payload
});
//#endregion

//#region VERIFY_SMART_CARD
export const verify_smart_card_request = (payload) => ({
  type: VERIFY_SMART_CARD.POST_REQUEST,
  payload
});
export const verify_smart_card_success = (payload) => ({
  type: VERIFY_SMART_CARD.POST_SUCCESS,
  payload
});
export const verify_smart_card_failed = (payload) => ({
  type: VERIFY_SMART_CARD.POST_FAILED,
  payload
});
//#endregion

//#region CABLE_TV_TOP_UP
export const cable_tv_top_up_request = (payload) => ({
  type: CABLE_TV_TOP_UP.POST_REQUEST,
  payload
});
export const cable_tv_top_up_success = (payload) => ({
  type: CABLE_TV_TOP_UP.POST_SUCCESS,
  payload
});
export const cable_tv_top_up_failed = (payload) => ({
  type: CABLE_TV_TOP_UP.POST_FAILED,
  payload
});
//#endregion

//#region PRODUCT_ITEMS_GET
export const product_items_get_request = (payload) => ({
  type: PRODUCT_ITEMS.GET_REQUEST,
  payload
});
export const product_items_get_success = (payload) => ({
  type: PRODUCT_ITEMS.GET_SUCCESS,
  payload
});
export const product_items_get_failed = (payload) => ({
  type: PRODUCT_ITEMS.GET_FAILED,
  payload
});
//#endregion

//#region EDUCATION_TOP_UP
export const education_top_up_request = (payload) => ({
  type: EDUCATION_TOP_UP.POST_REQUEST,
  payload
});
export const education_top_up_success = (payload) => ({
  type: EDUCATION_TOP_UP.POST_SUCCESS,
  payload
});
export const education_top_up_failed = (payload) => ({
  type: EDUCATION_TOP_UP.POST_FAILED,
  payload
});
//#endregion

//#region IROKO_PROVIDER_GET
export const iroko_provider_get_request = (payload) => ({
  type: IROKO_PROVIDER.GET_REQUEST,
  payload
});
export const iroko_provider_get_success = (payload) => ({
  type: IROKO_PROVIDER.GET_SUCCESS,
  payload
});
export const iroko_provider_get_failed = (payload) => ({
  type: IROKO_PROVIDER.GET_FAILED,
  payload
});
//#endregion

//#region TOP_UP_IROKO
export const top_up_iroko_request = (payload) => ({
  type: TOP_UP_IROKO.POST_REQUEST,
  payload
});
export const top_up_iroko_success = (payload) => ({
  type: TOP_UP_IROKO.POST_SUCCESS,
  payload
});
export const top_up_iroko_failed = (payload) => ({
  type: TOP_UP_IROKO.POST_FAILED,
  payload
});
//#endregion

//#region PAN_AFRICAN_AIRTIME_PRE
export const pan_african_airtime_pre_request = () => ({
  type: PAN_AFRICAN_AIRTIME_PRE.GET_REQUEST
});
export const pan_african_airtime_pre_success = (payload) => ({
  type: PAN_AFRICAN_AIRTIME_PRE.GET_SUCCESS,
  payload
});
export const pan_african_airtime_pre_failed = (payload) => ({
  type: PAN_AFRICAN_AIRTIME_PRE.GET_FAILED,
  payload
});
//#endregion

//#region PAN_AFRICAN_AIRTIME_TOP_UP
export const pan_african_airtime_top_up_request = (payload) => ({
  type: PAN_AFRICAN_AIRTIME_TOP_UP.POST_REQUEST,
  payload
});
export const pan_african_airtime_top_up_success = (payload) => ({
  type: PAN_AFRICAN_AIRTIME_TOP_UP.POST_SUCCESS,
  payload
});
export const pan_african_airtime_top_up_failed = (payload) => ({
  type: PAN_AFRICAN_AIRTIME_TOP_UP.POST_FAILED,
  payload
});
//#endregion

//#region ACTIVE_PRODUCT
export const active_product_request = (payload) => ({
  type: ACTIVE_PRODUCT.GET_REQUEST,
  payload
});
export const active_product_success = (payload) => ({
  type: ACTIVE_PRODUCT.GET_SUCCESS,
  payload
});
export const active_product_failed = (payload) => ({
  type: ACTIVE_PRODUCT.GET_FAILED,
  payload
});
//#endregion

//#region CHECK_METER
export const check_meter_request = (payload) => ({
  type: CHECK_METER.POST_REQUEST,
  payload
});
export const check_meter_success = (payload) => ({
  type: CHECK_METER.POST_SUCCESS,
  payload
});
export const check_meter_failed = (payload) => ({
  type: CHECK_METER.POST_FAILED,
  payload
});
//#endregion

//#region PURCHASE_POWER
export const purchase_power_request = (payload) => ({
  type: PURCHASE_POWER.POST_REQUEST,
  payload
});
export const purchase_power_success = (payload) => ({
  type: PURCHASE_POWER.POST_SUCCESS,
  payload
});
export const purchase_power_failed = (payload) => ({
  type: PURCHASE_POWER.POST_FAILED,
  payload
});
//#endregion

//#region LOADING IFRAME
export const startLoadingIframeRequest = () => ({
  type: LOADIND_IFRAME.START_REQUEST
});

export const stopLoadingIframeRequest = () => ({
  type: LOADIND_IFRAME.STOP_REQUEST
});
//#endregion

//#region CLEAR_MESSAGES
// export const clear_message_request = () => ({
//   type: CLEAR_MESSAGES.REQUEST
// });
//#endregion
