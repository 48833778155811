import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  NORMAL_ACCOUNT,
  REWARD_ACCOUNT,
  CREDIT_ACCOUNT,
  ACCOUNT_TRANSACTIONS,
  BANK_ACCOUNT_DETAILS,
  LOG_FUND
} from 'constants/actionTypes';

import {
  normal_account_success,
  normal_account_failed,
  reward_account_success,
  reward_account_failed,
  credit_account_success,
  credit_account_failed,
  account_transactions_success,
  account_transactions_failed,
  bank_account_details_success,
  bank_account_details_failed,
  log_fund_success,
  log_fund_failed
} from './actions';

import * as Api from './api';

//#region NORMAL_ACCOUNT_REQUEST
function* normal_account_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.account_async, 'normal');
    yield put(normal_account_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(normal_account_failed(error.data.error));
    } else {
      yield put(normal_account_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_normal_account_request() {
  yield takeEvery(NORMAL_ACCOUNT.GET_REQUEST, normal_account_request);
}
//#endregion

//#region REWARD_ACCOUNT_REQUEST
function* reward_account_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.account_async, 'reward');
    yield put(reward_account_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(reward_account_failed(error.data.error));
    } else {
      yield put(reward_account_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_reward_account_request() {
  yield takeEvery(REWARD_ACCOUNT.GET_REQUEST, reward_account_request);
}
//#endregion

//#region CREDIT_ACCOUNT
function* credit_account_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.account_async, 'credit');
    yield put(credit_account_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(credit_account_failed(error.data.error));
    } else {
      yield put(credit_account_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_credit_account_request() {
  yield takeEvery(CREDIT_ACCOUNT.GET_REQUEST, credit_account_request);
}
//#endregion

//#region ACCOUNT_TRANSACTIONS_REQUEST
function* account_transactions_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.account_transactions_async, payload);
    yield put(account_transactions_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(account_transactions_failed(error.data.error));
    } else {
      yield put(account_transactions_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_account_transactions_request() {
  yield takeEvery(ACCOUNT_TRANSACTIONS.GET_REQUEST, account_transactions_request);
}
//#endregion

//#region BANK_ACCOUNT_DETAILS
function* bank_account_details_request() {
  try {
    yield put(showLoading());
    const res = yield call(Api.bank_account_details_async);
    yield put(bank_account_details_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(bank_account_details_failed(error.data.error));
    } else {
      yield put(bank_account_details_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_bank_account_details_request() {
  yield takeEvery(BANK_ACCOUNT_DETAILS.GET_REQUEST, bank_account_details_request);
}
//#endregion

//#region LOG_FUND_REQUEST
function* log_fund_request({ payload }) {
  try {
    yield put(showLoading());
    const res = yield call(Api.log_fund_async, payload);
    yield put(log_fund_success(res.data));
  } catch (error) {
    if (error?.data?.error) {
      yield put(log_fund_failed(error.data.error));
    } else {
      yield put(log_fund_failed({ code: 500, mgs: 'network.error.text' }));
    }
  } finally {
    yield put(hideLoading());
  }
}
export function* watch_log_fund_request() {
  yield takeEvery(LOG_FUND.POST_REQUEST, log_fund_request);
}
//#endregion

const walletSagas = [
  fork(watch_normal_account_request),
  fork(watch_reward_account_request),
  fork(watch_credit_account_request),
  fork(watch_account_transactions_request),
  fork(watch_bank_account_details_request),
  fork(watch_log_fund_request)
];

export default walletSagas;
