import React from 'react';
import PropTypes from 'prop-types';

const itemHeader = (props) => {
  return (
    <div className={props.className}>
      <h1 className="font-medium text-center text-3xl lg:text-4xl mb-4">{props.title}</h1>
      {props.subTitle && (
        <h3 className="text-center md:text-lg text-ra-black mb-8">{props.subTitle} </h3>
      )}
    </div>
  );
};

itemHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  className: PropTypes.string
};

export default itemHeader;
