import getConfig from 'utils/env';
import httpFacade from 'utils/httpFacade';
import { hash } from 'utils/misc';

const { baseURL } = getConfig();

const httpv1 = new httpFacade(baseURL + '/v1/shop');
const httpv2 = new httpFacade(baseURL + '/v2/shop');

export const shop_products_async = async (query) => await httpv2.get({ url: '/products', query });
export const shop_product_items_async = async (payload) =>
  await httpv2.post({ url: '/products/items', body: payload });
export const shop_product_item_packages_async = async (payload) =>
  await httpv2.post({ url: '/products/items/packages', body: payload });
export const check_meter_async = async (payload) =>
  await httpv2.post({ url: '/products/meter-check', body: payload });
export const shop_purchase_async = async (body) =>
  await (await httpv2.headers(hash(body))).post({ url: '', body });
export const shop_beneficaries_post_async = async (body) =>
  await httpv2.post({ url: '/beneficiaries', body });
export const shop_beneficaries_get_async = async (query) =>
  await httpv2.get({ url: '/beneficiaries', query });

export const verify_smart_card_async = async (body) =>
  await httpv2.post({ url: 'products/cable-tv-check', body });

export const supported_network_async = async (query) =>
  await httpv1.get({ url: 'supported-networks', query });
export const beneficaries_get_async = async (payload) =>
  await httpv1.get({ url: `beneficiaries/${payload}` });
export const beneficaries_post_async = async (body) =>
  await httpv1.post({ url: 'save-beneficiary', body });
export const top_up_airtime_async = async (body) =>
  await httpv1.post({ url: 'top-up-airtime', body });
export const active_data_bundle_packages_async = async (payload) =>
  await httpv1.get({ url: `get-active-api-bundle-packages/${payload}` });
export const top_up_data_async = async (body) =>
  await httpv1.post({ url: 'top-data-bundles', body });
export const cable_tv_provider_async = async (payload) =>
  await httpv1.get({ url: 'cable-tv-provider', query: payload });
export const cable_tv_package_async = async (payload) =>
  await httpv1.get({ url: `cable-tv-package/${payload}` });
export const cable_tv_top_up_async = async (body) =>
  await httpv1.post({ url: 'cable-tv-top-up', body });
export const product_items_get_async = async (payload) =>
  await httpv1.get({ url: `product-items/${payload}` });
export const education_top_up_async = async (body) =>
  await httpv1.post({ url: 'top-up-education', body });
export const iroko_provider_get_async = async (query) =>
  await httpv1.get({ url: 'iroko-provider', query });
export const top_up_iroko_async = async (body) => await httpv1.post({ url: 'top-up-iroko', body });
export const pan_african_airtime_pre_async = async () =>
  await httpv1.get({ url: 'pan-african/airtime-prerequisites' });
export const pan_african_airtime_top_up_async = async (body) =>
  await httpv1.post({ url: 'pan-african/airtime-top-up', body });
export const active_product_async = async (body) =>
  await httpv1.post({ url: 'active-product-api', body });
export const purchase_power_async = async (body) =>
  await httpv1.post({ url: 'purchase-power', body });
