import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from './item';

const NavMenu = ({ intl, currentPath = 'faq', location }) => {
  const menuData = [
    {
      title: intl.formatMessage({
        id: 'faqs.page.title'
      }),
      parent: 'faqs'
    },
    {
      title: intl.formatMessage({
        id: 'knowledge-base.page.title'
      }),
      parent: 'knowledge-base'
    },
    {
      title: intl.formatMessage({
        id: 'help-center.page.title'
      }),
      parent: 'help-center'
    },
    {
      title: intl.formatMessage({
        id: 'privacy-policy.page.title'
      }),
      parent: 'privacy-policy'
    },
    {
      title: intl.formatMessage({
        id: 'footer.nav.link_3'
      }),
      parent: 'terms-&-conditions'
    }
  ];

  return (
    <>
      {menuData.map((e, i) => (
        <MenuItem
          key={i}
          inverse
          title={e.title}
          icon={e.icon}
          parent={`${e.parent}`}
          active={currentPath === e.parent}
          location={location}
          submenu={e.submenu}
        />
      ))}
    </>
  );
};

NavMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default NavMenu;
