import * as Yup from 'yup';

export const Jamb = Yup.object().shape({
  product_id: Yup.string().required('Checker type is required!'),
  type: Yup.string().required('JAMB type is required!'),
  account: Yup.string().required('Your JAMB account number is required!')
});

export const Normal = Yup.object().shape({
  product_id: Yup.string().required('Checker type is required!')
});
