import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { ErrorIcon } from 'assets/icons/svg';

const TextArea = ({ name, placeholder, error, touched, useFloatingLable = true, ...rest }) => {
  const errorClass = `border-red-300 text-red-900 focus:border-red-300`;
  const isTrue = error && touched;
  return (
    <div className="relative text-left mb-6">
      {!useFloatingLable && <div className="">{placeholder}</div>}
      <div className="rounded-md shadow-sm">
        <textarea
          {...rest}
          className={`form-textarea mt-1 block w-full transition duration-150 ease-in-out focus:border-ra-dark focus:shadow-none sm:text-sm sm:leading-5 ${
            isTrue && errorClass
          }`}
          placeholder={useFloatingLable ? '' : placeholder}
        />
        {useFloatingLable && (
          <span className="floating-label absolute pointer-events-none transition-all duration-200 ease-in-out opacity-25 left-3 top-3">
            {placeholder}
          </span>
        )}
      </div>
      {isTrue && (
        <>
          <div className="absolute -mt-8 right-0 pr-3 flex items-center pointer-events-none">
            <ErrorIcon />
          </div>
          <p className="mt-0 text-xs text-red-600" id={`${name}-error`}>
            {error}
          </p>
        </>
      )}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool
};

export default TextArea;
