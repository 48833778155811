import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';

import CardInfo from 'components/Wallet/Card';
import AddFund from 'components/Wallet/AddFundModel';
import SelectPayment from 'components/Wallet/SelectPaymentModal';

import Table from 'components/Table';
import Button from 'components/Button';

import { formatDateTime } from 'utils/misc';

import { UnderLay as Loading } from 'components/Loading';

import {
  normal_account_request,
  account_transactions_request,
  bank_account_details_request,
  log_fund_request
} from 'redux/actions';

const WalletNormal = (props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [isModalOpen2, toggleModal2] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'wallet.normal.page.title'
  })}`;

  const {
    loading,
    error,
    normal_account_data,
    account_transactions_data,
    bank_account_details_data,
    log_fund_data
  } = useSelector((state) => state.walletReducers);

  const { user } = useSelector((state) => state.authReducers);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(normal_account_request());
      dispatch(account_transactions_request({ type: 'normal' }));
      dispatch(bank_account_details_request());
    }
    return () => (mounted = false);
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    let mounted = true;
    if (mounted && log_fund_data) {
      if (log_fund_data.status === 'success') {
        window.location.replace(log_fund_data.data.link);
      }
    }
    return () => (mounted = false);
  }, [log_fund_data]);

  const columns = [
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h1'
      }),
      Cell: ({ row }) => formatDateTime(row.original.created_at)
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h2'
      }),
      accessor: 'description'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h3'
      }),
      accessor: 'reference'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h4'
      }),
      accessor: 'amount'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h5'
      }),
      accessor: 'transaction'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h6'
      }),
      accessor: 'method'
    },
    {
      Header: intl.formatMessage({
        id: 'wallet.table.col.h7'
      }),
      Cell: ({ row }) => {
        const getState = (key) => {
          switch (key) {
            case 'pending':
              return { title: 'pending', color: 'blue' };
            case 'completed':
              return { title: 'completed', color: 'green' };
            case 'rejected':
            default:
              return { title: 'rejected', color: 'red' };
          }
        };
        const state = getState(row.original.status);
        return (
          <button
            className={`font-medium bg-${state.color}-50 rounded-sm border border-${state.color}-200 text-${state.color}-500  hover:text-${state.color}-300 transition ease-in-out focus:outline-none duration-150 px-3 py-2 `}
          >
            {state.title}
          </button>
        );
      }
    }
  ];

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <AddFund
            intl={intl}
            toggleModal={toggleModal2}
            isModalOpen={isModalOpen2}
            action={log_fund_request}
            dispatch={dispatch}
          />
          <SelectPayment
            intl={intl}
            alert={alert}
            toggleModal={toggleModal}
            isModalOpen={isModalOpen}
            toggleModal2={toggleModal2}
            user={user}
            bank={bank_account_details_data}
          />
          <PageTitle intl={intl} id="wallet.normal.nav.title" />
          <div className="pt-2 pb-6 mt-4 mx-4 md:mx-10 md:py-10 xl:mx-24">
            <div className="flex justify-around">
              <CardInfo
                title={intl.formatMessage({
                  id: 'wallet.normal.card.title'
                })}
                value={`${user.account_no} N`}
                className="mr-2"
              />
              {error ? (
                <div>
                  {intl.formatMessage({
                    id: 'table.error.text'
                  })}
                </div>
              ) : (
                <CardInfo
                  title={intl.formatMessage({
                    id: 'wallet.account.bal.title'
                  })}
                  value={normal_account_data.total}
                  className="ml-2"
                />
              )}
            </div>
            <Table
              intl={intl}
              title={intl.formatMessage({
                id: 'wallet.table.title'
              })}
              columns={columns}
              data={account_transactions_data}
              error={error}
              headerBtn={
                <Button
                  icon={<span className="text-xl mr-2">+</span>}
                  title={intl.formatMessage({
                    id: 'wallet.table.button.title'
                  })}
                  width="w-1/12"
                  color="bg-ra-dark"
                  click={() => toggleModal(!isModalOpen)}
                />
              }
            />
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default WalletNormal;
