import React from 'react'
import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text56 } from 'pages/LandingPage/styles/text'
import PersonalDiv from './PersonalDiv'

function EnjoyRewards() {
    return (
        // <VhContainer>
        //     <FlexBox >
        //         <FlexBox h='fit-content' direction='column' justify='center' gap={{ xs: '20px', lg: '32px' }} w={{ sm: '95%', lg: '85%' }} position='relative'>

        //             <Text56>Enjoy 

        //                 <Text56 component='span' color='#1976D2' position='relative'>
        //                     <Img src={images.erase} h='60px' w={'72px'} props={{ position: 'absolute', right: '10%', top: '-60px' }} />
        //                     rewards
        //                 </Text56> by shopping from partner stores.</Text56>
        //             <Text20 color='#657585' >
        //             Why spend more when you can spend less? Make the most of your money. Enjoy money on every transaction when you shop at one of our partner stores.
        //             </Text20>
        //         </FlexBox>
        //         <Img src={images.landingimg3} h='75%' />
        //     </FlexBox>
        // </VhContainer>
        <PersonalDiv
            headerCompnent={
                <Text56>Enjoy
                    <Text56 component='span' color='#1976D2' position='relative'>
                        <Img src={images.rewardbag} h='60px' w={'72px'} props={{ position: 'absolute', right: '10%', top: '-60px' }} />
                        {' '} rewards <br />
                    </Text56> by shopping from partner stores.</Text56>
            }
            smallText='Why spend more when you can spend less? Make the most of your money. Enjoy money on every transaction when you shop at one of our partner stores.'
            bigImage={images.landingimg3}
            direction={{ xs: 'column', lg: 'row' }}
        />
    )
}

export default EnjoyRewards