import React from 'react'
import { images } from 'exports'
import { FlexBox, VhContainer } from 'pages/LandingPage/styles/containers'
import { Img } from 'pages/LandingPage/styles/image'
import { Text20, Text56 } from 'pages/LandingPage/styles/text'
import PersonalDiv from './PersonalDiv'

function GiveLove() {
    return (

        <PersonalDiv
            headerCompnent={
                <Text56>Give love with
                    <Text56 component='span' color='#FF3B30' position='relative'>
                        {' '} gift cards.
                        
                        <Img src={images.giveicon} h='60px' w={'72px'} props={{ position: 'absolute', right: '-60px', top: '0px' }} />
                    </Text56>  </Text56>
            }
            smallText=' When a special day may be enjoyable, why would you miss it? Create gift cards that you may give to loved ones and friends.'
            bigImage={images.landingimg4}
            direction={{xs: 'column-reverse', lg: 'row-reverse' }}
        />
    )
}

export default GiveLove