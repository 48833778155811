import React from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

import fav from 'assets/favicon.ico';

export function Plain() {
  const intl = useIntl();

  return (
    <>
      <div className="loading" />
      <div className="loading-logo">
        <img alt="ReachAfrika" src={fav} />
      </div>
      <div className="loading-text">
        {intl.formatMessage({
          id: 'loading.text_1'
        })}
      </div>
    </>
  );
}

export function UnderLay() {
  return (
    <div className="show transition-opacity duration-200 ease-in-out">
      <div className="fixed z-10 overlay-cover">
        <div className="flex flex-wrap items-center justify-center h-screen w-screen">
          <div className="relative w-85 md:w-110 p-10">
            <Plain />
          </div>
        </div>
      </div>
    </div>
  );
}

export function UnderLayOnclick() {
  const alert = useAlert();
  const intl = useIntl();

  return (
    <div
      className="show transition-opacity duration-200 ease-in-out"
      onClick={() => {
        console.log('object');
        alert.show(
          `${intl.formatMessage({
            id: 'loading.text_2'
          })}!`,
          {
            type: 'error',
            title: intl.formatMessage({
              id: 'loading.text_3'
            })
          }
        );
      }}
    >
      <div className="fixed z-10 overlay-cover">
        <div className="flex flex-wrap items-center justify-center h-screen w-screen">
          <div className="relative text-center w-85 md:w-110 p-10">
            <h3>
              {intl.formatMessage({
                id: 'loading.text_3'
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: 'loading.text_2'
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
