import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';

import Wrapper from 'container/layouts/Wrapper';
import PageTitle from 'components/PageTitle';
import { UnderLay as Loading } from 'components/Loading';

import { InviteIcon } from 'assets/icons/svg';

import { referrals_invite_info_request } from 'redux/actions';

const Invite = (props) => {
  const {
    error,
    loading,
    referrals_invite_info,
    user: { username }
  } = useSelector((state) => state.authReducers);

  const alert = useAlert();
  const intl = useIntl();
  const dispatch = useDispatch();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'invite.page.title'
  })}`;

  const inviteLink =
    window.location.protocol + '//' + window.location.host + '/signup?r=' + username;

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(referrals_invite_info_request());

      if (error) {
        const a = error.code === 500 ? intl.formatMessage({ id: error?.mgs }) : error;
        alert.show(a, {
          type: 'error',
          title: intl.formatMessage({
            id: 'shop.products.error.title'
          })
        });
      }
    }
    return () => (mounted = false);
  }, [alert, dispatch, intl, error]);

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper intl={intl} className="h-screen flex overflow-hidden bg-gray-100" {...props}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
          <PageTitle intl={intl} id="invite.nav.title" />
          <div className="flex justify-center items-center text-center pt-2 pb-6 mx-4 md:mx-36 md:py-10 xl:mx-24 relative h-full">
            <div className="absolute w-1/4 flex flex-col items-center">
              {referrals_invite_info && (
                <>
                  <div className="relative flex justify-center items-center rounded-full w-1/4 px-12 py-12 bg-ra-300 text-ra-dark">
                    <div className="absolute">
                      <InviteIcon />
                    </div>
                  </div>
                  <div className="my-3">
                    <h3 className="font-semibold text-xl leading-5 mb-3">
                      {intl.formatMessage({
                        id: 'invite.title'
                      })}
                    </h3>
                    <p className="text-sm font-normal leading-5">
                      {intl.formatMessage({
                        id: 'invite.text_1'
                      })}
                      <span className="text-ra-dark">
                        {referrals_invite_info.invite_bonus}{' '}
                        {intl.formatMessage({
                          id: 'invite.coins'
                        })}
                      </span>
                      {intl.formatMessage({
                        id: 'invite.text_2'
                      })}
                    </p>
                  </div>
                  <CopyToClipboard
                    text={inviteLink}
                    onCopy={() =>
                      alert.show(inviteLink, {
                        type: 'success',
                        title: intl.formatMessage({
                          id: 'invite.aler.copy'
                        })
                      })
                    }
                  >
                    <span
                      className="relative w-full items-baseline inline-flex justify-center border-transparent py-3 border text-sm leading-5 font-medium rounded-md  text-white bg-ra-dark
                focus:outline-none cursor-pointer"
                    >
                      {intl.formatMessage({
                        id: 'invite.copy-link'
                      })}
                    </span>
                  </CopyToClipboard>
                </>
              )}
            </div>
          </div>
        </main>
      </Wrapper>
    </>
  );
};

export default Invite;
