import React from 'react';
import NavigationItem from './NavigationItem';
import PropTypes from 'prop-types';

const navigationItems = (props) => {
  return props.items.map((item) => (
    <NavigationItem
      title={item.name}
      url={item.url}
      key={item.name}
      openNew={ true }
      classItems={props.classItems}
      role={props.role}
    />
  ));
};

navigationItems.propTypes = {
  items: PropTypes.array.isRequired,
  classItems: PropTypes.string,
  role: PropTypes.string
};
export default navigationItems;
