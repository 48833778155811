import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Wrapper from 'container/layouts/SettingWrapper';

import data from './data/terms.json';

const TermsCondition = (props) => {
  const intl = useIntl();

  document.title = `REACHAFRIKA | ${intl.formatMessage({
    id: 'terms-and-condition.page.title'
  })}`;

  const { lang } = useSelector((state) => state.authReducers);

  const PageData = data?.[lang];

  return (
    <Wrapper intl={intl} className="h-screen flex overflow-hidden" {...props}>
      <main className="flex-1 relative z-0 md:mt-8 overflow-y-auto focus:outline-none" tabIndex="0">
        <div className="p-4 xl:p-0 xl:mt-10 xl:ml-20 xl:mr-80">
          <div className="text-xs mb-2 text-gray-400">{PageData.updatedAt}</div>
          <div className="min-w-0 flex-1 flex flex-wrap items-center mt-8">
            <h4 className="text-md xl:text-2xl font-bold">{PageData.header.title}</h4>
            <p className="text-sm xl:text-base mt-4">{PageData.header.text}</p>
          </div>
          <div className="mt-8">
            {PageData.contents.map((content, index) => {
              return (
                <div className="mb-8 text-sm xl:text-base" key={index}>
                  <h4 className="font-bold">{content.title}</h4>
                  {content.texts.map((e, i) => (
                    <>
                      <p key={i} className="my-4">
                        <span className="font-bold mr-2">{e.id}.</span>
                        {e.text}
                      </p>
                      {e.list &&
                        e.list.map((ee, ii) => (
                          <div key={ii}>
                            {typeof ee === 'string' ? (
                              <p className="my-2">{ee}</p>
                            ) : (
                              <p className="my-2">
                                <span className="font-bold mr-2">{ee.id}.</span>
                                {ee.text}
                              </p>
                            )}
                          </div>
                        ))}
                    </>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default TermsCondition;
